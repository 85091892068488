import { PartnerType } from 'lib';
import { capitalize, Chip } from 'ui';

import { ListPartner } from '../../../api/partners/types';

export const PartnerTypeChip = ({ partner, compact = false }: { partner: ListPartner, compact?: boolean }) => {
  const partnerTypeToColor = {
    [PartnerType.AGENT]: 'warning',
    [PartnerType.OWNER]: 'success',
    [PartnerType.PM]: 'secondary',
    [PartnerType.CONTRACTOR]: 'primary',
    [PartnerType.LENDER]: 'info',
  } as const;

  return (
    <Chip
      color={partnerTypeToColor[partner.partnerType]}
      label={partner.partnerType === PartnerType.PM ? partner.partnerType : capitalize(partner.partnerType)}
      size="small"
      sx={compact ? {
        width: 'fit-content',
        px: 2,
      } : undefined}
    />
  );
};

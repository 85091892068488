import { sum } from 'lib';

import { Property } from '../../types';

export const calculateCashInvested = (purchasePrice: number | undefined, mortgageAmount: number | undefined): number => {
  if (!purchasePrice) {
    return 0;
  }

  return purchasePrice - (mortgageAmount ?? 0);
};

type MortgageField = 'mortgage';
export const calculateTotalMortgageAmount = (properties: Pick<Property, MortgageField>[]) => sum(
  properties.map(({ mortgage }) => mortgage?.amount ?? 0),
);
type AmortizationTableField = 'amortizationTable';
type Mortgage = 'mortgage';

export const calculateTotalMortgagePaydown = (properties: Pick<Property, AmortizationTableField | Mortgage>[]) => sum(
  properties.map(({ amortizationTable, mortgage }) => ((mortgage?.amount ?? 0) - (amortizationTable?.totalPrincipal ?? 0))),
);

export const calculateTotalPrincipal = (properties: Pick<Property, AmortizationTableField>[]) => sum(
  properties.map(({ amortizationTable }) => (amortizationTable?.totalPrincipal ?? 0)),
);

export const sum = (numbers: number[]) => numbers.reduce((a, b) => a + b, 0);

export const coalesceNegative = (num: number | undefined | null, fallback: any) => {
  if (num === undefined || num === null || num < 0) {
    return fallback;
  }
  return num;
};

export const roundToNearestHundredth = (num: number) => {
  let negative = false;
  let rounded = Math.ceil(num);

  if (num < 0) {
    negative = true;
    rounded = -num;
  }
  const y = 10 ** (rounded.toString().length - 2);

  rounded /= y;
  rounded = Math.ceil(rounded);
  rounded *= y;

  if (negative) {
    rounded *= -1;
  }
  return rounded;
};

export const generateRandomInt = (min: number, max: number) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  return Math.floor(Math.random() * (maxInt - minInt + 1)) + minInt;
};

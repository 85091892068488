import { useCallback, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { useAnalytics } from 'lib';
import { MdClose, MdFilterList } from 'react-icons/md';
import { useLabels } from 'ui';
import {
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { TFilterDate } from './types';

const StyledBox = styled(Box)(({ theme }) => {
  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));
  return isDesktop
    ? {
      width: '400px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.divider}`,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    }
    : {
      position: 'absolute' as 'absolute',
      zIndex: 1600,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      background: theme.palette.background.paper,
    };
});

export const FilterModal = ({
  categories,
  properties,
  onApply,
  firstDate,
  lastDate,
}: { categories: string[], properties: string[],
  onApply: ({
    newProperties, newCategories, startDate, endDate,
  }: { newProperties: string[], newCategories: string[], startDate: TFilterDate, endDate: TFilterDate }) => void,
  firstDate: string,
  lastDate: string
}) => {
  const [open, setOpen] = useState(false);

  const appliedValues = useRef<{ categories: string[], properties: string[], startDate: TFilterDate, endDate: TFilterDate }>({
    categories: [], properties: [], startDate: null, endDate: null,
  });
  const [categoriesChecked, setCategoriesChecked] = useState<string[]>([]);
  const [propertiesChecked, setPropertiesChecked] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [startDate, setStartDate] = useState<TFilterDate>(dayjs(firstDate).toDate());
  const [endDate, setEndDate] = useState<TFilterDate>(dayjs(lastDate).toDate());

  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleOpen = (event: any) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = (val: string, type: string) => () => {
    const [checked, setChecked] = type === 'category'
      ? [categoriesChecked, setCategoriesChecked]
      : [propertiesChecked, setPropertiesChecked];
    const currentIndex = checked.indexOf(val);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(val);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  // reset the values to the last applied values
  const handleCancel = () => {
    setCategoriesChecked(appliedValues.current?.categories || []);
    setPropertiesChecked(appliedValues.current?.properties || []);
    setStartDate(appliedValues.current?.startDate);
    setEndDate(appliedValues.current?.endDate);
    handleClose();
  };

  // clear all filters
  const handleReset = () => {
    setCategoriesChecked([]);
    setPropertiesChecked([]);
    setStartDate(dayjs(firstDate).toDate());
    setEndDate(dayjs(lastDate).toDate());

    onApply({
      newCategories: [],
      newProperties: [],
      startDate: null,
      endDate: null,
    });

    handleClose();
  };

  const handleSubmit = () => {
    const newProperties = {
      categories: categoriesChecked,
      properties: propertiesChecked,
      startDate,
      endDate,
    };

    analytics.track('Form Submitted', {
      formName: 'Transactions Filter',
      properties: newProperties.properties.join(', '),
      categories: newProperties.categories.join(', '),
      startDate: newProperties.startDate ? dayjs(newProperties.startDate).format('MMM, YYYY') : null,
      endDate: newProperties.endDate ? dayjs(newProperties.endDate).format('MMM, YYYY') : null,
    });

    appliedValues.current = newProperties;
    onApply({
      newCategories: categoriesChecked,
      newProperties: propertiesChecked,
      startDate,
      endDate,
    });
    handleClose();
  };

  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));
  const isViewportTooNarrow = useMediaQuery((_theme: Theme) => _theme.breakpoints.down(385));

  const Container = useCallback((props: any) => (isDesktop
    ? (
      <Popover
        {...props}
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      />
    )
    : <Modal {...props} />), [isDesktop, anchorEl]);

  return (
    <div>
      <Button
        color="secondary"
        onClick={handleOpen}
        variant="outlined"
        startIcon={isViewportTooNarrow ? null : <MdFilterList />}
      >
        {l.filter}
      </Button>
      <Container
        sx={{
          zIndex: 1600,
        }}
        open={open}
        onClose={handleClose}
      >
        <StyledBox>
          <Stack direction="row" py={3} px={4} pb={0} justifyContent="space-between" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Filter</Typography>
            <IconButton aria-label="delete" color="primary" onClick={handleClose}>
              <MdClose />
            </IconButton>
          </Stack>
          <Divider />
          <Stack
            p={4}
            sx={{
              overflowY: 'scroll',
              height: '200px',
              flexGrow: 1,
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{l['transactions.filters.period']}</Typography>
            <Stack gap={4} direction="row" mt={4} mb={5}>
              <DesktopDatePicker
                views={['year', 'month']}
                inputFormat="MMM, YYYY"
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={l['transactions.filters.period']}
                    inputProps={{ placeholder: 'MMM, YYYY', ...params.inputProps }}
                    sx={{ width: '45%' }}
                  />
                )}
                onChange={(v) => {
                  setStartDate(v);
                }}
                value={startDate}
              />

              <DesktopDatePicker
                views={['year', 'month']}
                inputFormat="MMM, YYYY"
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={l['transactions.filters.toDate']}
                    inputProps={{ placeholder: 'MMM, YYYY', ...params.inputProps }}
                    sx={{ width: '45%' }}
                  />
                )}
                onChange={(v) => {
                  setEndDate(v);
                }}
                value={endDate}
              />
            </Stack>

            <Typography sx={{ fontWeight: 'bold' }}>{l['transactions.filters.category']}</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {categories.map((category) => {
                const labelId = `checkbox-list-label-${category}`;

                return (
                  <ListItem
                    key={category}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(category, 'category')} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={categoriesChecked.includes(category)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={category}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Typography sx={{ fontWeight: 'bold' }}>{l['transactions.filters.property']}</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {properties.map((property) => {
                const labelId = `checkbox-list-label-${property}`;

                return (
                  <ListItem
                    key={property}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(property, 'property')} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={propertiesChecked.includes(property)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={property} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            p={4}
            justifyContent="space-between"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Button onClick={() => {
              handleCancel();
              analytics.track('Button Clicked', {
                buttonName: 'Transactions Filter Cancel',
              });
            }}
            >
              {l.cancel}
            </Button>
            <Stack gap={2} direction="row">
              <Button onClick={() => {
                handleReset();
                analytics.track('Button Clicked', {
                  buttonName: 'Transactions Filter Reset',
                });
              }}
              >
                {l.reset}
              </Button>
              <Button color="primary" variant="contained" onClick={handleSubmit}>{l.apply}</Button>
            </Stack>
          </Stack>
        </StyledBox>
      </Container>
    </div>
  );
};

import { Button, SxProps } from '@mui/material';

import { MissingDataContainer } from './styled';
import { useLabels } from '../lib/translations';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

type Props = {
  onAdd: () => void;
  label?: string;
  containerSx?: SxProps;
};

export const MissingData = ({ onAdd, label = '', containerSx = {} }: Props) => {
  const l = useLabels();

  return (
    <MissingDataContainer sx={{
      py: 1, mt: 0, mb: 3, ...containerSx,
    }}
    >
      <SemiBoldTypography variant="body2">{label || l.provideMissingData}</SemiBoldTypography>
      <Button variant="text" color="primary" onClick={onAdd}>{l.add}</Button>
    </MissingDataContainer>
  );
};

import { ApexOptions } from 'apexcharts';
import Apexcharts from 'react-apexcharts';
import {
  Box, Stack, Theme, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { expensesWithLegendChartConfig } from './expensesWithLegendChartConfig';
import { StyledStack } from './styled';
import { ExpenseWithLegendProps } from './types';

export const ExpensesWithLegend = ({ expenses }: { expenses: ExpenseWithLegendProps[] }) => {
  const theme = useTheme();
  const series: number[] = [];
  const labels: string[] = [];
  const colors: string[] = [];
  expenses.forEach((value) => {
    if (value.amount === 0) {
      return;
    }
    series.push(value.amount);
    labels.push(value.title);
    colors.push(value.color);
  });

  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));

  const options: ApexOptions = {
    labels,
    colors,
    ...expensesWithLegendChartConfig(theme),
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      position="relative"
      direction="column"
      sx={{
        '& .apexcharts-canvas': {
          right: isDesktop ? 180 : 0,
        },
      }}
    >
      <Apexcharts options={options} series={series} type="donut" width={350} height={130} />
      <StyledStack>
        {options.labels?.map((l, idx) => (
          <Stack direction="row" alignItems="center" gap={3} key={`expense-chart-label-${l}`}>
            <Box
              height={10}
              width={10}
              borderRadius={100}
              sx={{
                background: options.colors?.[idx],
              }}
            />
            {l}
          </Stack>
        ))}
      </StyledStack>
    </Stack>
  );
};

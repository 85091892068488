import { ReactNode } from 'react';

import { useAnalytics } from 'lib';
import { MdClose } from 'react-icons/md';
import { RightTransition, useLabels } from 'ui';
import {
  Box, Button, Container, Dialog, DialogContent,
  Divider, IconButton,
  Stack, useTheme,
} from '@mui/material';

export const SellPropertyDialog = ({
  showActions = false, open, openNextDialog = () => {}, children, nextButtonText = '',
  nextButtonDisabled = false,
}: {
  showActions?: boolean, open: boolean, openNextDialog?: () => void,
  children: ReactNode, nextButtonText?: string,
  nextButtonDisabled?: boolean
}) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Dialog open={open} fullScreen TransitionComponent={RightTransition} sx={{ zIndex: 1600 }}>
      <Container maxWidth="sm" sx={{ p: 0, flexGrow: 1 }}>
        <Box>
          <Stack alignItems="flex-end" p={1}>
            <IconButton
              aria-label="Close"
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Sell Property - Close',
                });

                window.history.back();
              }}
              size="medium"
              color="secondary"
            >
              <MdClose />
            </IconButton>
          </Stack>
          <Divider sx={{ m: 0 }} />
        </Box>
        <DialogContent sx={{ pb: 20 }}>
          {children}
        </DialogContent>
        {showActions && (
          <Box sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            maxWidth: theme.breakpoints.values.sm,
            background: theme.palette.background.paper,
            zIndex: 100,
          }}
          >
            <Divider sx={{ m: 0 }} />
            <Stack direction="row" justifyContent="space-between" p={3}>
              <Button
                onClick={() => {
                  analytics.track('Button Clicked', {
                    buttonName: 'Sell Property - Cancel',
                  });

                  window.history.back();
                }}
                variant="text"
                sx={{ py: 3, color: theme.palette.text.primary }}
              >
                {l.cancel}
              </Button>
              <Button
                disabled={nextButtonDisabled}
                onClick={openNextDialog}
                variant="contained"
                sx={{ py: 3 }}
              >
                {nextButtonText}
              </Button>
            </Stack>
          </Box>
        )}
      </Container>
    </Dialog>
  );
};

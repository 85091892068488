import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAnalytics, useTrackPageBottom } from 'lib';
import { FallbackSpinner, useLabels } from 'ui';
import {
  Box, MenuItem, Select,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useListOwnerProperties } from '../../api/properties';
import { sortPropertiesByPurchaseDate } from '../../lib/sort';
import { CashflowSummary } from '../../templates/dashboard/CashflowSummary';
import { MonthlyExpensesBreakdown } from '../../templates/dashboard/MonthlyExpensesBreakdown';
import { NOISummary } from '../../templates/dashboard/NOISummary';
import { PortfolioSummary } from '../../templates/dashboard/PortfolioSummary';
import { PortfolioTotalEquity } from '../../templates/dashboard/PortfolioTotalEquity';
import { PortfolioValue } from '../../templates/dashboard/PortfolioValue';

export const Properties = () => {
  const {
    data: ownerProperties,
    isLoading,
    isError,
  } = useListOwnerProperties();
  const { propertyID } = useParams();
  const unsortedProperties = ownerProperties?.properties ?? [];
  const properties = [...(unsortedProperties || [])].sort(sortPropertiesByPurchaseDate);

  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedPropertyID, setSelectedPropertyID] = useState<string | null>(null);
  const selectedProperty = properties?.find((property) => property.id === selectedPropertyID) || '';
  useTrackPageBottom(() => {
    analytics.track('Page Bottom Reached', {
      pageName: 'Dashboard Properties',
    });
  });

  const selectProperty = (e: any) => {
    analytics.track('Selector Changed', {
      value: e.target.value,
      selectorName: 'Property',
    });
    navigate(`/properties/${e.target.value}`);
  };

  useEffect(() => {
    if (properties?.length) {
      if (properties.length === 1) {
        navigate('/');
        return;
      }
      if (!propertyID) {
        selectProperty({ target: { value: properties[0].id } });
      }
    }
  }, [properties?.length]);

  useEffect(() => {
    if (propertyID) {
      setSelectedPropertyID(propertyID);
    }
  }, [propertyID]);

  if (isLoading || !properties || isError || !selectedProperty) {
    return <FallbackSpinner />;
  }

  const mobileView = (
    <Box sx={{ marginBottom: '70px' }}>
      <Box
        sx={{
          background: theme.palette.background.paper,
          zIndex: 'appBar',
          position: 'sticky',
          top: 0,
          mb: 3,
          p: 3,
          boxShadow: 10,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Select
          fullWidth
          value={selectedPropertyID}
          onChange={selectProperty}
        >
          <MenuItem value="" disabled>
            <em>{l['select-property']}</em>
          </MenuItem>
          {properties.map((property) => (
            <MenuItem key={property.id} value={property.id} sx={{ whiteSpace: 'break-spaces' }}>
              {property.address.street1}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <Grid
          container
          spacing={3}
          sx={{
            paddingRight: '12px',
            width: isLowerThanMd ? '100%' : '900px',
            margin: 'auto',
          }}
        >
          <Grid item xs={12} md={12}>
            <PortfolioSummary properties={[selectedProperty]} inSelectedProperty />
          </Grid>
          <Grid item xs={12} md={12}>
            <NOISummary properties={[selectedProperty]} />
          </Grid>
          <Grid item xs={12} md={12}>
            <PortfolioValue properties={[selectedProperty]} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CashflowSummary properties={[selectedProperty]} />
          </Grid>
          <Grid item xs={12} md={12}>
            <MonthlyExpensesBreakdown properties={[selectedProperty]} />
          </Grid>
          <Grid item xs={12} md={12}>
            <PortfolioTotalEquity properties={[selectedProperty]} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const desktopView = (
    <Grid container spacing={3}>
      <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12} md={3.5} mx={isLowerThanLg ? 3 : 0}>
        <PortfolioSummary properties={[selectedProperty]} inSelectedProperty />
      </Grid>
      <Grid item xs={12} md={3.5} mx={isLowerThanLg ? 3 : 0}>
        <PortfolioValue properties={[selectedProperty]} />
      </Grid>
      <Grid item xs={12} md={5} mx={isLowerThanLg ? 3 : 0}>
        <PortfolioTotalEquity properties={[selectedProperty]} />
      </Grid>

      <Grid item xs={12} md={7} mx={isLowerThanLg ? 3 : 0}>
        <CashflowSummary properties={[selectedProperty]} />
      </Grid>
      <Grid item xs={12} md={5} mx={isLowerThanLg ? 3 : 0}>
        <Box sx={{ pb: 3 }}>
          <NOISummary properties={[selectedProperty]} />
        </Box>
        <MonthlyExpensesBreakdown properties={[selectedProperty]} />
      </Grid>
    </Grid>
  );

  return isLowerThanLg ? mobileView : desktopView;
};

import { Link } from 'react-router-dom';

import { styled } from '@mui/material';

export const HeaderLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
});

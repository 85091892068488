import { ReactElement, ReactNode } from 'react';

import { SemiBoldTypography, ThemeColor } from 'ui';
import {
  Stack, Typography,
} from '@mui/material';

import { StyledGrid } from './styled';

export type Data = {
  stats: ReactNode
  title: string
  color: ThemeColor
  icon: ReactElement
};

type Props = {
  data: Data
};

export const StatisticsBox = ({
  data,
}: Props) => (
  <StyledGrid
    gap={2}
    item
    xs={7}
  >
    {data.icon}
    <Stack justifyContent="space-between" flexDirection="row" flexGrow={1}>
      <Typography sx={{ mb: 0.5 }} variant="body2">{data.title}</Typography>
      <SemiBoldTypography>
        {data.stats}
      </SemiBoldTypography>
    </Stack>
  </StyledGrid>
);

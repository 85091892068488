import { ReactNode } from 'react';

import { useAnalytics } from 'lib';
import {
  Card, CardContent, Stack, SxProps, Typography, useTheme,
} from '@mui/material';

import { InfoTooltip } from '../tooltip/InfoTooltip';
import { BoldTypography } from '../typography/BoldTypography';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

export const TooltipMetric = ({
  label,
  displayValue,
  icon,
  tooltipName,
  tooltipContent,
  cardSx = undefined,
}: {
  label: string,
  displayValue: ReactNode,
  icon: ReactNode,
  tooltipName: string,
  tooltipContent: ReactNode,
  cardSx?: SxProps
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  return (
    <Card elevation={0} sx={{ borderRadius: '6px', border: `1px solid ${theme.palette.divider}`, ...cardSx }}>
      <CardContent sx={{ height: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" height="100%">
          <Stack direction="row" alignItems="center" gap={2}>
            {icon}
            <Stack gap={2}>
              {displayValue}
              <SemiBoldTypography variant="caption" sx={{ opacity: 0.8 }}>
                {label}
              </SemiBoldTypography>
            </Stack>
          </Stack>
          <InfoTooltip
            title={tooltipContent}
            isOutlined
            arrow
            isLight
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName,
              });
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export const BasicMetric = ({ label, value, icon }: { label: string, value: number | undefined, icon: ReactNode }) => {
  const theme = useTheme();

  return (
    <Stack
      direction={{ xs: 'row', md: 'column' }}
      alignItems={{ xs: 'center', md: 'flex-start' }}
      sx={{
        background: theme.palette.background.paper,
        px: 4,
        pt: 4,
        pb: { xs: 4, md: 2 },
        borderRadius: '6px',
        border: `1px solid ${theme.palette.grey.A100}`,
      }}
      gap={{ xs: 2, md: 5 }}
    >
      {icon}
      <Stack direction={{ xs: 'column', md: 'row' }} gap={2} alignItems={{ xs: 'flex-start', md: 'center' }}>
        <BoldTypography variant="h6">
          {value}
        </BoldTypography>
        <Typography variant="body2">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
};

type MetricData = {
  id: string,
  value: ReactNode,
  label: string,
  icon: ReactNode,
};

export const DetailedMetric = ({
  title,
  tooltipContent = null,
  metrics,
  onClick,
}: {
  title: string,
  tooltipContent?: ReactNode,
  onClick: () => void,
  metrics: MetricData[]
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  return (
    <Stack
      onClick={onClick}
      sx={{
        background: theme.palette.background.paper,
        p: 4,
        borderRadius: '6px',
        border: `1px solid ${theme.palette.grey.A100}`,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      gap={3}
    >
      <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
        <BoldTypography variant="h6">
          {title}
        </BoldTypography>
        {tooltipContent && (
          <InfoTooltip
            title={tooltipContent}
            arrow
            isLight
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Portfolio Value Tooltip',
              });
            }}
          />
        )}
      </Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        gap={3}
      >
        {metrics.map((metric) => (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            key={metric.id}
          >
            {metric.icon}
            <Stack direction={{ xs: 'row', md: 'column' }} alignItems={{ xs: 'center', md: 'flex-start' }} gap={2}>
              <BoldTypography variant="h6">
                {metric.value}
              </BoldTypography>
              <Typography variant="body2">
                {metric.label}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

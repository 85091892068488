import { SetStateAction, useState } from 'react';

import { useAnalytics } from 'lib';
import { toast } from 'react-toastify';
import {
  FallbackSpinner,
  ModalContainer, useLabels,
} from 'ui';
import {
  FormControl, FormControlLabel, FormLabel,
  Modal, Radio, RadioGroup, Stack, Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useCreateExpense, useUpdateAllRecurringExpenses } from './apiHelpers';
import { TransactionForm } from './Form';
import { EditTransactionModalProps, ITransactionForm } from './types';
import { useDeleteSingleRecurringExpense, useGetRecurringExpenseByTransactionId } from '../../api/properties';
import { sleep } from '../../lib/sleep';
import { QueryKey } from '../../types/enums';

export const EditRecurringTransactionsModal = ({
  isOpen, toggle, initialValues,
}: EditTransactionModalProps) => {
  const l = useLabels();
  const queryClient = useQueryClient();
  const { isLoading: isUpdateRecurring, mutateAsync: updateAllRecurringExpenses } = useUpdateAllRecurringExpenses();
  const [deleteSelectionSelected, setDeleteSelectionSelected] = useState<'all' | 'only_selected'>('all');
  const [falseLoading, setFalseLoading] = useState(false);
  const { mutateAsync: createMutate } = useCreateExpense();
  const {
    mutateAsync: deleteSingleRecurringMutate,
  } = useDeleteSingleRecurringExpense();

  const {
    isLoading: isLoadingRecurring,
    data: recurringExpense,
  } = useGetRecurringExpenseByTransactionId(initialValues?.id ?? '', isOpen);
  const analytics = useAnalytics();

  const onSubmit = async (data: ITransactionForm) => {
    analytics.track('Form Submitted', {
      formName: 'Edit Recurring Transaction',
      type: data.type,
      propertyId: data.propertyId,
      category: data.category,
      date: data.dates.date,
      amount: data.amount,
      transactionId: data.id,
    });

    setFalseLoading(true);
    try {
      if (deleteSelectionSelected === 'all') {
        await updateAllRecurringExpenses({
          ...data,
          id: recurringExpense?.data.id,
        });
        await sleep(4000);
      } else {
        await deleteSingleRecurringMutate({ recurringId: recurringExpense?.data.id ?? '', transactionId: data.id ?? '' });
        await createMutate({
          ...data,
          id: '',
          type: 'one_time',
        });
      }

      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
    setFalseLoading(false);
  };

  const handelRecurringTypeChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setDeleteSelectionSelected(event.target.value as 'all' | 'only_selected');
  };

  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      sx={{
        zIndex: 1600,
      }}
    >
      {isLoadingRecurring ? (
        <FallbackSpinner />
      ) : (
        <ModalContainer onClose={toggle}>
          <Stack gap={3}>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              {l.editTransaction}
            </Typography>
            {initialValues?.type === 'recurring' && (
              <FormControl>
                <FormLabel>{l.recurringAllOrSingleEditSelection}</FormLabel>
                <RadioGroup defaultValue={deleteSelectionSelected} onChange={handelRecurringTypeChange}>
                  <FormControlLabel value="all" control={<Radio />} label="All" />
                  <FormControlLabel value="only_selected" control={<Radio />} label="Only selected" />
                </RadioGroup>
              </FormControl>
            )}
            {deleteSelectionSelected === 'only_selected' && (
              <TransactionForm
                hideOptions
                toggle={toggle}
                onSubmit={onSubmit}
                isLoading={isUpdateRecurring || falseLoading}
                initialValues={initialValues && {
                  ...initialValues,
                  type: 'one_time',
                }}
              />
            )}
            {deleteSelectionSelected === 'all' && (
              <TransactionForm
                hideOptions
                toggle={toggle}
                onSubmit={onSubmit}
                isLoading={isUpdateRecurring || falseLoading}
                initialValues={initialValues && {
                  ...initialValues,
                  type: 'recurring',
                  dates: {
                    date: recurringExpense?.data.startDate,
                  },
                }}
              />
            )}
          </Stack>
        </ModalContainer>
      )}
    </Modal>
  );
};

import { useAnalytics } from 'lib';
import {
  BoldTypography, ControlledFormattedNumberField, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';
import { Stack } from '@mui/material';

import { DetailRow } from './Row';
import { editModeSignal, useGetPropertyIdParam } from './state';
import { saveFieldToSessionStorage, StorageData } from './storage';
import {
  metricRelatedExpenses, RowProps,
} from './types';
import { useProjections } from './useProjections';

export const EstimatedRent = ({ form }: RowProps) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const propertyID = useGetPropertyIdParam();
  const rent = form.watch(`${propertyID}.income.rent`);
  const editMode = editModeSignal.value;
  const projections = useProjections(form);

  const handleRentChange = () => {
    const dataToSave: StorageData = {
      [propertyID]: {
        'income.rent': rent,
      },
    };

    metricRelatedExpenses.forEach((expenseKey) => {
      const value = (form.getValues(`${propertyID}.metrics.${expenseKey}`) / 100) * rent;

      form.setValue(`${propertyID}.expenses.${expenseKey}`, value);

      dataToSave[propertyID][`expenses.${expenseKey}`] = value;
    });

    saveFieldToSessionStorage(dataToSave);
  };

  return (
    <DetailRow
      rowID="estimated-rent"
      columns={[
        {
          id: 'estimated-rent',
          content: (
            <SemiBoldTypography variant="body1" sx={{ pl: 8 }}>
              {l.estimatedRent}
            </SemiBoldTypography>
          ),
        },
        ...projections.years.map((year, i) => ({
          id: `estimated-rent-year-${year}`,
          content: editMode && year === 1 ? (
            <Stack>
              <ControlledFormattedNumberField
                control={form.control}
                name={`${propertyID}.income.rent`}
                label={l.estimatedRent}
                prefix="$"
                size="small"
                onBlur={() => {
                  handleRentChange();

                  analytics.track('Input Changed', {
                    value: rent,
                    inputName: 'Property Proforma - Estimated Rent',
                  });
                }}
              />
            </Stack>
          ) : (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.rents[i], 0)}
            </BoldTypography>
          ),
        })),
      ]}
    />
  );
};

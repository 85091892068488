import { useState } from 'react';

import { OwnerReferralsIncentive } from 'lib';

import { ReferAnOwnerButton } from './ReferAnOwnerButton';
import { ReferAnOwnerDialog } from './ReferAnOwnerDialog';
import { useGetB2CReferralProgram } from '../../hooks/useGetB2CReferralProgram';
import { WantToSellPropertyButton } from '../sell-property/SellPropertyButton';

export const ReferAnOwnerNavItem = ({ onNavigate }: { onNavigate: () => void }) => {
  const { shouldDisplayReferralProgram, b2cReferralIncentive, isLoading: isLoadingReferralProgram } = useGetB2CReferralProgram();
  const [isReferAndEarnDialogOpen, setIsReferAndEarnDialogOpen] = useState(false);

  if (isLoadingReferralProgram) {
    return null;
  }

  return (
    <>
      {!(shouldDisplayReferralProgram) && (
        <WantToSellPropertyButton onClick={onNavigate} height={72} />
      )}
      {shouldDisplayReferralProgram && (
        <ReferAnOwnerButton
          onClick={() => {
            setIsReferAndEarnDialogOpen(true);
          }}
          ownerReferralsIncentive={b2cReferralIncentive ?? OwnerReferralsIncentive.NO_INCENTIVE}
        />
      )}
      <ReferAnOwnerDialog
        open={isReferAndEarnDialogOpen}
        onClose={() => {
          setIsReferAndEarnDialogOpen(false);
        }}
        b2cReferralIncentive={b2cReferralIncentive}
      />
    </>
  );
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useAnalytics, useAuth, useHideChat, useIsUserPhoneInputEnabled,
} from 'lib';
import {
  MdCheckCircle, MdClose, MdHome, MdPriceChange,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Avatar, BoldTypography, LeftTransition, useLabels,
} from 'ui';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
  Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { SuccessPopupContent } from './styled';
import { useCreateOffer } from '../../../api/properties';
import { useUpdateUser } from '../../../api/users';
import { UserPhoneInput } from '../../../components/phone-number/UserPhoneInput';
import { GetStartedStep } from '../../../components/sell-property/GetStartedStep';

type Props = {
  propertyID: string,
  open: boolean,
  onClose: () => void,
};

export const InterestedPopup = ({ propertyID, open, onClose }: Props) => {
  const theme = useTheme();
  const l = useLabels();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const lowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const createOfferMutation = useCreateOffer();
  const { user, refreshUser } = useAuth();

  const {
    value: userPhoneInputEnabled,
  } = useIsUserPhoneInputEnabled();

  const updateUser = useUpdateUser();

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsConsent, setSmsConsent] = useState(false);

  const showPhoneInput = (user?.userProvidedPhone === '' && userPhoneInputEnabled);

  const handleSubmit = async () => {
    analytics.track('Form Submitted', {
      formName: 'I Am Interested Popup',
      propertyID,
    });

    try {
      await createOfferMutation.mutateAsync(propertyID);

      if (showPhoneInput) {
        await updateUser.mutateAsync({ phoneNumber, smsConsent });
        await refreshUser();
      }

      onClose();
      setSuccessPopupOpen(true);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleConfirm = () => {
    navigate('/marketplace');
  };

  useHideChat(true);

  return (
    <>
      <Dialog
        open={open}
        fullScreen={lowerThanMd}
        onClose={onClose}
        sx={{ zIndex: 1600 }}
        maxWidth="xs"
      >
        <DialogTitle component={Stack} alignItems="flex-end" borderBottom={`1px solid ${theme.palette.divider}`} sx={{ p: 3 }}>
          <IconButton aria-label="Close" onClick={onClose} size="medium" color="secondary">
            <MdClose />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pb: lowerThanMd ? 0 : 60 }}>
          <Stack p={3}>
            <BoldTypography variant="h5" sx={{ mb: 3 }}>{l['listings.interested']}</BoldTypography>
            <GetStartedStep
              icon={<MdHome color={theme.palette.text.primary} size={40} />}
              title=""
              description={l['listings.interested.description1']}
            />
            <GetStartedStep
              icon={<MdPriceChange color={theme.palette.text.primary} size={40} />}
              title=""
              description={l['listings.interested.description2']}
            />
            <GetStartedStep
              icon={<MdCheckCircle color={theme.palette.text.primary} size={40} />}
              title=""
              description={l['listings.interested.description3']}
            />
          </Stack>
          {showPhoneInput && (
            <UserPhoneInput
              smsConsentDefaultValue={user?.smsConsent === true}
              onValidityChange={setIsPhoneValid}
              onPhoneNumberChange={setPhoneNumber}
              onSmsConsentChange={setSmsConsent}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ borderBottom: `1px solid ${theme.palette.divider}`, p: 0 }}>
          <Stack direction="row" justifyContent="space-between" p={3} width="100%">
            <Button
              onClick={onClose}
              variant="text"
              sx={{ py: 3, color: theme.palette.text.primary }}
            >
              {l.cancel}
            </Button>
            <Button
              disabled={createOfferMutation.isLoading || (showPhoneInput && !isPhoneValid)}
              onClick={handleSubmit}
              variant="contained"
              sx={{ py: 3 }}
            >
              {createOfferMutation.isLoading ? l.pleaseWait : l.iAmInterested}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog
        open={successPopupOpen}
        fullScreen={lowerThanMd}
        onClose={onClose}
        TransitionComponent={LeftTransition}
        sx={{ zIndex: 1600 }}
        maxWidth="xs"
      >
        <DialogTitle component={Stack} alignItems="flex-end" borderBottom={`1px solid ${theme.palette.divider}`} sx={{ p: 3 }}>
          <IconButton aria-label="Close" onClick={handleConfirm} size="medium" color="secondary">
            <MdClose />
          </IconButton>
        </DialogTitle>
        <SuccessPopupContent>
          <Avatar skin="light" variant="circular" color="info" sx={{ height: 96, width: 96, mb: 3 }}>
            <MdCheckCircle size={40} color={theme.palette.primary.main} />
          </Avatar>
          <BoldTypography variant="h5" sx={{ mb: 3 }}>
            {l['listings.marketplace.successPopup.title']}
          </BoldTypography>
          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mb: 6 }}>
            {l['listings.marketplace.successPopup.description']}
          </Typography>
          <Button
            onClick={handleConfirm}
            variant="text"
            sx={{ color: theme.palette.text.primary }}
          >
            {l.gotIt}
          </Button>
        </SuccessPopupContent>
      </Dialog>
    </>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ListingItemTag, useAnalytics, useAuth, useListingCardGrossYieldAsDollar,
} from 'lib';
import { GiHouse } from 'react-icons/gi';
import {
  BoldTypography,
  Chip,
  formatDecimalToPercentage, formatNumber, formatNumberToCurrency, useLabels,
} from 'ui';
import {
  CardContent, CardMedia,
  Skeleton,
  Stack, Typography,
  useMediaQuery, useTheme,
} from '@mui/material';

import { FavoriteButton } from './FavoriteButton';
import { ShareButton } from './ShareButton';
import { ListingCard as StyledListingCard } from './styled';
import { ListingProperty } from '../../types/property';

export type ListingCardProps = {
  listingItem: ListingProperty,
  listingIndex: number,
  isRecommendedListing?: boolean,
};

const Tag = ({ label }: { label: string }) => {
  const theme = useTheme();

  return (
    <Chip
      label={label}
      sx={{
        top: 16,
        left: 16,
        position: 'absolute',
        '&.MuiChip-root': {
          background: theme.palette.primary.dark,
        },
      }}
    />
  );
};

export const ListingCard = ({ listingItem: li, listingIndex, isRecommendedListing = false }: ListingCardProps) => {
  const mediaHeight = 200;

  const theme = useTheme();
  const l = useLabels();
  const { user } = useAuth();
  const analytics = useAnalytics();
  const { value: showGrossYieldAsDollar, loading: loadingGrossYieldAsDollarFF } = useListingCardGrossYieldAsDollar();

  const [listingItem, setListingItem] = useState(li);

  const aboveMd = useMediaQuery(theme.breakpoints.up('md'));

  const tag = useMemo(() => {
    if (li.listingItem?.tags.includes(ListingItemTag.handpicked_by_blanket)) {
      return l.handpicked;
    }

    if (li.listingItem?.tags.includes(ListingItemTag.good_crime_rate)) {
      return l.lowCrime;
    }

    if (li.listingItem?.tags.includes(ListingItemTag.one_percent_rule)) {
      return l.onePercentRule;
    }

    return null;
  }, [li.listingItem?.tags]);

  const displayPropertyMeasures = listingItem.measures.grossYield !== 0 && listingItem.measures.capRate !== 0;

  const grossYield = showGrossYieldAsDollar ? (
    formatNumberToCurrency(listingItem.measures.grossYield * li.askingPrice, 1, {
      style: 'currency',
      notation: 'compact',
      compactDisplay: 'short',
    })
  ) : formatDecimalToPercentage(listingItem.measures.grossYield, 2);

  useEffect(() => {
    analytics.identify(user!.realID, {
      showGrossYieldAsDollar,
    });
  }, [showGrossYieldAsDollar]);

  return (
    <Link
      to={`/marketplace/${listingItem.id}`}
      style={{
        textDecoration: 'none',
        color: 'inherit',
        display: 'block',
        pointerEvents: 'auto',
        height: '100%',
      }}
    >
      <StyledListingCard
        data-testid={listingItem.id}
        onClick={() => {
          analytics.track('Card Clicked', {
            cardName: isRecommendedListing ? 'Recommended Listing' : 'Listing',
            position: listingIndex,
            propertyAddress: listingItem.address.fullAddress,
            propertyId: listingItem.propertyID,
            listingId: listingItem.id,
          });
        }}
        sx={{ height: aboveMd ? '100%' : 'auto' }}
      >
        <FavoriteButton
          onChangeFavorite={(id: string | null) => {
            setListingItem({
              ...listingItem,
              favoriteId: id,
            });
          }}
          favoriteId={listingItem.favoriteId}
          marketplacePropertyId={listingItem.id}
        />
        <ShareButton listingID={listingItem.id} />
        {tag && <Tag label={tag} />}
        {listingItem.primaryPicture ? (
          <CardMedia
            component="img"
            height={mediaHeight}
            width="370"
            image={listingItem.primaryPicture}
          />
        ) : (
          <Stack alignItems="center">
            <GiHouse size={mediaHeight} color={theme.palette.primary.main} />
          </Stack>
        )}
        <CardContent component={Stack} sx={{ p: 0, pb: `${theme.spacing(3)} !important`, height: 'calc(100% - 200px)' }}>
          <Stack p={3} minHeight={120} justifyContent="space-between">
            <Stack>
              <Typography variant="h6">
                {formatNumberToCurrency(listingItem.askingPrice ?? 0, 0)}
              </Typography>
            </Stack>
            <Stack>
              <Stack>
                <Stack direction="row" spacing={3} justifyContent="flex-start" alignItems="center" mt={1}>
                  {listingItem.property.bedrooms ? (
                    <Typography variant="body2">
                      {listingItem.property.bedrooms}
                      {' '}
                      {l.bedsShort}
                    </Typography>
                  ) : null}
                  {listingItem.property.bathrooms ? (
                    <Typography variant="body2">
                      {listingItem.property.bathrooms}
                      {' '}
                      {l.bathsShort}
                    </Typography>
                  ) : null}
                  {listingItem.property.sqft ? (
                    <Typography variant="body2">
                      {formatNumber(listingItem.property.sqft, 0)}
                      {' '}
                      {l.sqft}
                    </Typography>
                  ) : null}
                  {listingItem.property.yearBuilt ? (
                    <Typography variant="body2">
                      {l.builtIn}
                      {' '}
                      {listingItem.property.yearBuilt}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
              <Typography variant="body2">
                {listingItem.address.fullAddress}
              </Typography>
            </Stack>
          </Stack>
          {displayPropertyMeasures ? (
            <Stack height={35} borderTop={`1px solid ${theme.palette.divider}`} p={3} mt="auto">
              <Stack
                direction="row"
                gap={3}
                alignItems="center"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{ display: listingItem.measures.grossYield !== 0 ? 'flex' : 'none' }}
                >
                  {loadingGrossYieldAsDollarFF ? (
                    <Skeleton variant="text" width="80px" />
                  ) : (
                    <BoldTypography variant="body1">
                      {grossYield}
                    </BoldTypography>
                  )}
                  <Typography variant="body2">
                    {l.grossYield}
                  </Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={1}
                  sx={{ display: listingItem.measures.capRate !== 0 ? 'flex' : 'none' }}
                >
                  <BoldTypography variant="body1">
                    {formatDecimalToPercentage(listingItem.measures.capRate, 2)}
                  </BoldTypography>
                  <Typography variant="body2">
                    {l.capRate}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
        </CardContent>
      </StyledListingCard>
    </Link>
  );
};

/// <reference types="google.maps" />

import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  PropertyAddress, SellableProperty, useAnalytics,
} from 'lib';
import NumberFormat from 'react-number-format';
import {
  Box,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  ToggleButton, ToggleButtonGroup,
  Typography, useMediaQuery,
  useTheme,
} from '@mui/material';

import { GoogleAutocomplete } from '../google-autocomplete';
import { RangeGraph } from '../graph/RangeGraph';
import { useLabels } from '../lib/translations';
import { InfoTooltip } from '../tooltip/InfoTooltip';
import { BoldTypography } from '../typography/BoldTypography';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&': {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    background: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.divider}`,
    fontWeight: 800,
  },
  '&.Mui-selected': {
    background: theme.palette.primary.dark,
    color: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

export type SellProperty = {
  id: string,
  price: number | ''
  hasPriceInMind: boolean
  isOtherOptionSelected: boolean
  externalPropertyAddress: string
  address: PropertyAddress | undefined
};

export const SellSelector = ({
  sellableProperties, onSellPropertyChanged,
}: {
  sellableProperties: SellableProperty[],
  onSellPropertyChanged: (sellProperty: SellProperty) => void,
}) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedPropertyID, setSelectedPropertyID] = useState(sellableProperties[0]?.id || '');
  const boxHeight = isMobile ? '70px' : '120px'; // change the values as per your requirement
  const propertyMap: Map<string, SellableProperty> = useMemo(() => {
    const properties = new Map<string, SellableProperty>();

    sellableProperties.forEach((property) => {
      properties.set(property.id, property);
    });

    return properties;
  }, [sellableProperties]);
  const selectedProperty = propertyMap.get(selectedPropertyID);
  const otherProperty = 'other-property';
  const isSellablePropertiesEmpty = sellableProperties.length === 0;
  const isOtherOptionSelected = (selectedPropertyID === otherProperty) || isSellablePropertiesEmpty;
  const formTitle = isSellablePropertiesEmpty
    ? l['sellProperty.sell.selectPropertyNoAvailable'] : l['sellProperty.sell.selectProperty'];

  const [selectedOption, setSelectedOption] = useState<google.maps.places.AutocompletePrediction | null>(null);

  const [price, setPrice] = useState<number | ''>(selectedProperty?.prices?.priceMean || '');
  const [hasPriceInMind, setHasPriceInMind] = useState(false);

  useEffect(() => {
    onSellPropertyChanged({
      id: selectedPropertyID,
      price,
      hasPriceInMind,
      isOtherOptionSelected,
      externalPropertyAddress: selectedOption?.description ?? '',
      address: selectedProperty?.address,
    });
  }, [selectedPropertyID, price, hasPriceInMind, selectedOption]);

  const [graphHeight, setGraphHeight] = useState('0');
  const graphRef = useCallback((node: HTMLDivElement) => {
    if (node === null) return;
    setGraphHeight(`${node.getBoundingClientRect().height * 0.25}px`);
  }, []);

  return (
    <Box>
      <Stack p={3}>
        <BoldTypography variant="h6" sx={{ mb: 3 }}>{formTitle}</BoldTypography>
        {!isSellablePropertiesEmpty && (
          <FormControl sx={{ mb: 6 }}>
            <InputLabel id="select-property">{l.property}</InputLabel>
            <Select
              data-testid="select-property"
              labelId="select-property"
              value={selectedPropertyID}
              label={l.property}
              onChange={(e) => {
                analytics.track('Selector Changed', {
                  value: e.target.value,
                  selectorName: 'Sell Property - Select Property',
                });
                setSelectedPropertyID(e.target.value);
                setPrice(sellableProperties.find((property) => property.id === e.target.value)?.prices?.priceMean || '');
              }}
            >
              {sellableProperties.map((property) => (
                <MenuItem key={property.id} value={property.id} data-testid={property.id}>
                  {property.displayName}
                </MenuItem>
              )) }
              <MenuItem value={otherProperty}>
                {l.other}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <Collapse in={isOtherOptionSelected || isSellablePropertiesEmpty} timeout="auto" unmountOnExit>
          <GoogleAutocomplete
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            placeHolderLabel={l['sellProperty.sell.propertyAddress']}
            autoCompleteTrackingName="Other Listing"
          />
        </Collapse>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
          <BoldTypography variant="h6">{l.propertyPrice}</BoldTypography>
          <InfoTooltip
            title={(
              <Typography variant="body2">
                {l['sellProperty.sell.priceTooltip']}
              </Typography>
            )}
            isOutlined
            arrow
            isLight
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Sell Property - Price Tooltip',
              });
            }}
          />
        </Stack>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          {l['sellProperty.sell.propertyPrice.description']}
        </Typography>
        <Stack direction="row" alignItems="center" gap={3} mb={3}>
          <Typography variant="body1" color={theme.palette.primary.dark}>
            {l['sellProperty.sell.propertyPrice.idkPrice']}
          </Typography>
          <ToggleButtonGroup
            color="primary"
            size="small"
            exclusive
            value={hasPriceInMind}
            onChange={(_, value) => {
              setHasPriceInMind(value);

              analytics.track('Switch Toggled', {
                value,
                switchName: 'Do You Have Price In Mind Switch',
              });
            }}
          >
            <StyledToggleButton value>{l.yes}</StyledToggleButton>
            <StyledToggleButton value={false}>{l.no}</StyledToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Collapse in={hasPriceInMind}>
          <NumberFormat
            prefix={l.dollarCurrency}
            data-hj-allow
            customInput={TextField}
            thousandSeparator
            allowNegative={false}
            autoComplete="off"
            label={l.askingPrice}
            onValueChange={(v) => setPrice(v.value ? v.floatValue || 0 : '')}
            value={price}
            sx={{ mb: 3, width: '100%' }}
            onBlur={() => {
              analytics.track('Input Changed', {
                inputName: 'Sell Property - Asking Price',
                value: price,
              });
            }}
          />
          {selectedProperty?.prices && (
            <Box
              ref={graphRef}
              sx={{ background: theme.palette.background.default, borderRadius: '4px' }}
              p={3}
              pb={0}
              mb={graphHeight}
            >
              <SemiBoldTypography variant="body1">{l['sellProperty.sell.marketPrice.title']}</SemiBoldTypography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 5 }}>
                {l['sellProperty.sell.marketPrice.description']}
              </Typography>
              <Box height={boxHeight}>
                <RangeGraph
                  clipPathColor={theme.palette.background.default}
                  min={selectedProperty.prices.priceLower}
                  max={selectedProperty.prices.priceUpper}
                  value={price || 0}
                />
              </Box>
            </Box>
          )}
        </Collapse>
      </Stack>
    </Box>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';
import {
  OwnerLeadsWorkflowStatus,
  useAnalytics,
} from 'lib';
import {
  AdminView,
  Avatar,
  BronzeMedalIcon,
  EmptyFullPageContainer,
  formatNumberToCurrency,
  formatPotentialTodayDate,
  FullScreenBlur,
  GoldMedalIcon,
  PartnersIcon,
  SilverMedalIcon,
  SpinnerWithLogo,
  TableControls,
  useEnableScroll,
  useLabels,
} from 'ui';
import {
  Alert,
  Button, Stack, Typography, useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';
import { ColumnDef } from '@tanstack/react-table';

import { PartnerTypeChip } from './components/PartnerType';
import { ViewPartnerDialog } from './ViewPartnerDialog';
import { useGetGrowthPolicy } from '../../api/growth-policy';
import { useListPartnersByPMInfinite } from '../../api/partners';
import { ListPartner } from '../../api/partners/types';
import { AddPartnerDialog } from '../../components/add-partner/AddPartnerDialog';
import { GrowthPolicyDialog } from '../../templates/growth-policy/GrowthPolicyDialog';
import { growthPolicyDialogState } from '../../templates/growth-policy/state';

const medalIconRatioWidth = 28;
const medalIconRatioHeight = 38;

const getMedalOrNumber = (id: string | undefined, sortedIDs: string[], scale = 1) => {
  if (!id) return null;

  const rank = sortedIDs.indexOf(id) + 1;

  if (rank <= 0) {
    return null;
  }
  if (rank === 1) {
    return <GoldMedalIcon width={medalIconRatioWidth * scale} height={medalIconRatioHeight * scale} />;
  }
  if (rank === 2) {
    return <SilverMedalIcon width={medalIconRatioWidth * scale} height={medalIconRatioHeight * scale} />;
  }
  if (rank === 3) {
    return <BronzeMedalIcon width={medalIconRatioWidth * scale} height={medalIconRatioHeight * scale} />;
  }

  return rank;
};

const getRankCount = (id: string, sortedIDs: string[]) => {
  const rank = sortedIDs.indexOf(id) + 1;

  return rank;
};

const tableControlsState = signal<TableControls>({
  resetExpandedRows: () => {},
  setSorting: () => {},
});

export const Network = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { data: partnerPages, isLoading: isLoadingPartners } = useListPartnersByPMInfinite();
  const { data: growthPolicy, isLoading: isLoadingGrowthPolicy } = useGetGrowthPolicy();
  const [searchParams] = useSearchParams();
  const [viewPartnerDialogOpen, setViewPartnerDialogOpen] = useState(false);
  const [viewPartnerDialogPartnerID, setViewPartnerDialogPartnerID] = useState('');

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [growthPolicyDialogOpen, setGrowthPolicyDialogOpen] = useState(false);

  const partners = partnerPages?.pages.flatMap((page) => page.partners) ?? [];
  const partnerIDsByLeads = partners.sort((
    a, b,
  ) => b.leads.length - a.leads.length).map((partner) => partner.id);
  const viewedPartner = partners.find((partner) => partner.id === viewPartnerDialogPartnerID);

  const handleClickPartner = (partnerID: string) => {
    analytics.track('Button Clicked', {
      buttonName: 'View Partner',
      partnerID,
    });

    setViewPartnerDialogOpen(true);
    setViewPartnerDialogPartnerID(partnerID);
  };

  const columns = useMemo(() => {
    const cols: (ColumnDef<ListPartner, string> | null)[] = [
      {
        id: 'ranking',
        header: '',
        enableSorting: true,
        maxSize: 50,
        sortingFn: (a, b) => (
          getRankCount(a.original.id, partnerIDsByLeads) - getRankCount(b.original.id, partnerIDsByLeads)),
        accessorFn: (row) => row.id,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1" textAlign="center" width="100%">
            {getMedalOrNumber(info.row.original.id, partnerIDsByLeads)}
          </Typography>
        ),
      },
      {
        header: l['growth.table.partner'],
        enableSorting: true,
        accessorFn: (row) => `${row.firstName ?? ''} ${row.lastName ?? ''}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Stack gap={2} direction="row" alignItems="center" flexWrap="nowrap" width="100%">
            <Avatar
              src={info.row.original.avatar
                || `https://ui-avatars.com/api/?name=${info.row.original.firstName}+${info.row.original.lastName}`}
              sx={{ width: 52, height: 52 }}
              color="secondary"
              style={{ opacity: info.row.original.avatar ? 1 : 0.6 }}
            />
            <Typography
              variant="body1"
              onClick={() => handleClickPartner(info.row.original.id)}
              sx={{
                textDecoration: 'underline',
                textWrap: 'nowrap',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              {info.row.original.firstName}
              {' '}
              {info.row.original.lastName}
            </Typography>
          </Stack>
        ),
      },
      {
        header: l['growth.table.partnerType'],
        enableSorting: false,
        accessorFn: (row) => row.partnerType,
        minSize: 160,
        size: 160,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <PartnerTypeChip partner={info.row.original} />,
      },
      {
        header: l['growth.table.leads'],
        enableSorting: true,
        minSize: 160,
        size: 160,
        sortingFn: (a, b) => (a.original.leads.length ?? 0) - (b.original.leads.length ?? 0),
        accessorFn: (row) => row.leads.length.toString(),
        cell: (info) => info.row.original.leads.length.toString(),
        meta: { shownAsText: true },
      },
      {
        header: l['growth.table.clients'],
        enableSorting: true,
        minSize: 160,
        size: 160,
        sortingFn: (a, b) => (
          a.original.leads.filter((p) => p.status === OwnerLeadsWorkflowStatus.WON).length ?? 0)
          - (b.original.leads.filter((p) => p.status === OwnerLeadsWorkflowStatus.WON).length ?? 0),
        accessorFn: (row) => row.leads.filter((p) => p.status === OwnerLeadsWorkflowStatus.WON).length.toString(),
        cell: (info) => info.row.original.leads.filter((p) => p.status === OwnerLeadsWorkflowStatus.WON).length.toString(),
        meta: { shownAsText: true },
      },
      {
        header: l['growth.table.clientEarned'],
        enableSorting: true,
        minSize: 220,
        size: 220,
        sortingFn: (a, b) => (
          a.original.leads.filter((p) => p.status === OwnerLeadsWorkflowStatus.WON).length * a.original.referralAmount)
          - (b.original.leads.filter((p) => p.status === OwnerLeadsWorkflowStatus.WON).length * b.original.referralAmount),
        accessorFn: (row) => row.leads.filter((p) => p.status === OwnerLeadsWorkflowStatus.WON).length.toString(),
        cell: (info) => formatNumberToCurrency(info.row.original.leads.filter(
          (p) => p.status === OwnerLeadsWorkflowStatus.WON,
        ).length * info.row.original.referralAmount, 0),
        meta: { shownAsText: true },
      },
      {
        header: l['growth.table.dateAdded'],
        enableSorting: true,
        minSize: 160,
        size: 160,
        sortingFn: (a, b) => dayjs(a.original.createdTime).diff(dayjs(b.original.createdTime)),
        accessorFn: (row) => row.leads.length.toString(),
        cell: (info) => formatPotentialTodayDate(info.row.original.createdTime),
        meta: { shownAsText: true },
      },
    ];

    return cols;
  }, [partners]);

  const filterPartners = (rows: ListPartner[], search: string) => rows.filter((partner) => {
    const searchable = `${partner.firstName ?? ''} ${partner.lastName ?? ''}`;

    return searchable.toLowerCase().includes(search.toLowerCase());
  });

  const shouldShowBlurAlert = !growthPolicy && !isLoadingGrowthPolicy;

  useEnableScroll(!shouldShowBlurAlert && !growthPolicyDialogState.value.open);

  useEffect(() => {
    growthPolicyDialogState.value = {
      open: growthPolicyDialogOpen,
      openDialog: () => setGrowthPolicyDialogOpen(true),
    };

    return () => {
      growthPolicyDialogState.value = {
        open: false,
        openDialog: () => { },
      };
    };
  }, [growthPolicyDialogOpen, setGrowthPolicyDialogOpen]);

  const isLoading = isLoadingPartners || isLoadingGrowthPolicy;

  if (isLoading) {
    return (
      <EmptyFullPageContainer>
        <SpinnerWithLogo />
      </EmptyFullPageContainer>
    );
  }

  return (
    <Stack position="relative" p={{ xs: 3, md: 6 }} gap={3} height="100%">
      <FullScreenBlur
        shown={shouldShowBlurAlert}
      >
        <Alert
          variant="filled"
          severity="error"
          icon={<PartnersIcon height={18} width={18} />}
          sx={{ color: theme.palette.primary.contrastText, alignItems: 'center' }}
          action={(
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => growthPolicyDialogState.value.openDialog()}
            >
              {l.setup}
            </Button>
          )}
        >
          {l['partners.setupGrowthPolicy']}
        </Alert>
      </FullScreenBlur>
      <AdminView
        initSearch={searchParams.get('partner') ?? ''}
        tableControlsState={tableControlsState}
        title={l['growth.partners']}
        getRowCanExpand={() => false}
        searchPlaceholder={l['growth.searchPartners']}
        data={partners || []}
        columns={columns}
        tableHeader={(
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => {
                setAddDialogOpen(true);
              }}
              style={{
                backgroundColor: theme.palette.primary.dark,
              }}
            >
              {l.invite}
            </Button>
          </Stack>
        )}
        filter={filterPartners}
        emptyStateText={l['growth.noPartnersFound']}
      />
      <AddPartnerDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        defaultReferralAmount={growthPolicy?.pmReferralsFeeAmount ?? 0}
      />
      <GrowthPolicyDialog open={growthPolicyDialogOpen} onClose={() => setGrowthPolicyDialogOpen(false)} />
      <ViewPartnerDialog
        open={viewPartnerDialogOpen}
        onClose={() => setViewPartnerDialogOpen(false)}
        partner={viewedPartner}
        rank={getMedalOrNumber(viewedPartner?.id, partnerIDsByLeads, 1.25)}
      />
    </Stack>
  );
};

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useHideChat } from 'lib';
import {
  FallbackSpinner, RightTransition, TextSlider, useLabels,
} from 'ui';
import {
  Box, Dialog, useMediaQuery, useTheme,
} from '@mui/material';

import { PropertiesDialog } from './PropertiesDialog';
import { WelcomeDialog } from './WelcomeDialog';

import 'keen-slider/keen-slider.min.css';

export const Onboarding = () => {
  const l = useLabels();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const firstLogin = !!location.state?.firstLogin;

  const [propertiesDialogOpen, setPropertiesDialogOpen] = useState<boolean>(!firstLogin);
  const [spinnerDialogOpen, setSpinnerDialogOpen] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(20);
  const [stopCountdown, setStopCountdown] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const spinnerTime = Math.max(5, countdown);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (firstLogin && countdown > 0 && !stopCountdown) {
      const timer = setTimeout(() => {
        setCountdown((currCount) => currCount - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, stopCountdown, firstLogin]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (firstLogin && stopCountdown && spinnerDialogOpen) {
      const timer = setTimeout(() => {
        setSpinnerDialogOpen(false);
        setPropertiesDialogOpen(true);
      }, spinnerTime * 1000);

      return () => clearTimeout(timer);
    }
  }, [spinnerTime, stopCountdown, spinnerDialogOpen, setSpinnerDialogOpen, firstLogin]);

  const handleSkip = () => {
    navigate('/');
  };

  useHideChat(isMobile);

  return (
    <>
      <WelcomeDialog
        fullScreen={isMobile}
        open={firstLogin}
        openNextDialog={() => {
          setStopCountdown(true);
          setSpinnerDialogOpen(true);
        }}
      />
      <Dialog
        fullScreen={isMobile}
        open={spinnerDialogOpen}
        TransitionComponent={RightTransition}
        PaperProps={{
          sx: {
            height: isMobile ? undefined : '100vh',
            width: isMobile ? undefined : '400px',
          },
        }}
      >
        <FallbackSpinner>
          {spinnerDialogOpen && (
            <Box pt={50} width="100%">
              <TextSlider
                showSteps
                minTimeout={2000}
                maxTimeout={5000}
                textSlides={[
                  l['loader-phrase-1'],
                  l['loader-phrase-2'],
                  l['loader-phrase-3'],
                  l['loader-phrase-4'],
                  l['loader-phrase-5'],
                ]}
              />
            </Box>
          )}
        </FallbackSpinner>
      </Dialog>
      <PropertiesDialog
        fullScreen={isMobile}
        open={propertiesDialogOpen}
        skipCallback={handleSkip}
        openPrevDialog={() => setPropertiesDialogOpen(false)}
        mountOnEnter
        unmountOnExit
      />
    </>
  );
};

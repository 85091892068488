import {
  axiosWithPayloadContext, config, createHeaders, useAuth,
} from 'lib';
import { useMutation } from '@tanstack/react-query';

export const useMutatePhoneCall = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (userID: string) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/calls`,
      method: 'POST',
      headers: createHeaders(token),
      data: {
        userID,
      },
    });
  });
};

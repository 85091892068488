import {
  ReactElement, useLayoutEffect, useMemo, useState,
} from 'react';

import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import {
  getActualAnnualIncome, getActualCashflow, getAllExpenses, roundToNearestHundredth, useAnalytics,
} from 'lib';
import Apexcharts from 'react-apexcharts';
import {
  ArrowCircleLeft, ArrowCircleRight,
  formatNumberToCurrency, GraphTrendDown, GraphTrendUp, hexToRGBA, InfoTooltip,
  MissingDataIcon, SemiBoldTypography, useLabels,
} from 'ui';
import { Box, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Theme, useTheme } from '@mui/material/styles';

import { MissingExpenses } from './MissingExpenses';
import { TooltipContent } from './TooltipContent';
import { RequiresPropertiesProps } from './types';
import { CenteredBox } from '../../components/CenteredBox';
import { InsightTypography } from '../../components/typography/InsightTypography';
import { useMissingData } from '../../hooks/useMissingData';
import {
  sumMonthlyExpenseTransactions,
  sumMonthlyIncomeTransactions,
} from '../../lib/calc';

type DataPoint = {
  stats: string;
  title: string;
  color: string;
  backgroundColor: string;
  icon: ReactElement;
};

enum MonthGroup {
  FIRST_SIX = 'first',
  LAST_SIX = 'last',
}

const getMonthNamesBetweenDates = (fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => {
  const categories = [];
  let date = fromDate.clone();

  for (; date.isSameOrBefore(toDate); date = date.add(1, 'month')) {
    categories.push(date.format('MMM'));
  }

  return categories;
};

const renderChart = (categories: string[], series: ApexAxisChartSeries, theme: Theme, maxY: GLfloat) => {
  const options: ApexOptions = {
    chart: {
      toolbar: { show: false },
      width: '100%',
      height: '100%',
      redrawOnParentResize: true,
    },
    grid: {
      strokeDashArray: 15,
      yaxis: {
        lines: { show: false },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: -5,
        right: 0,
        bottom: -10,
        left: 0,
      },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    colors: [theme.palette.success.main, hexToRGBA(theme.palette.primary.main, 0.12)],
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: '50%',
      },
    },
    states: {
      hover: {
        filter: { type: 'none' },
      },
      active: {
        filter: { type: 'none' },
      },
    },
    xaxis: {
      labels: { show: true },
      axisTicks: { show: false },
      axisBorder: { show: false },
      categories,
    },
    yaxis: {
      min: 0,
      max: roundToNearestHundredth(maxY),
      tickAmount: 3,
      labels: {
        show: true,
        padding: 8,
        offsetX: 0,
        formatter: (val) => formatNumberToCurrency(val, 0, {
          style: 'decimal',
          notation: 'compact',
          compactDisplay: 'short',
        }),
      },
    },
    tooltip: {
      y: {
        formatter: (val) => formatNumberToCurrency(val, 1, {
          notation: 'compact',
          compactDisplay: 'short',
        }),
      },
    },
  };
  return <Apexcharts height="100%" type="bar" options={options} series={series} />;
};

const renderStats = (dataPoints: DataPoint[]) => dataPoints.map((sale: DataPoint) => (
  <Grid item xs={6} sm={4} key={sale.title}>
    <CenteredBox key={sale.title}>
      <Box mr={4}>
        {sale.icon}
      </Box>
      <CenteredBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="caption">{sale.title}</Typography>
        <SemiBoldTypography variant="h6">
          {sale.stats}
        </SemiBoldTypography>
      </CenteredBox>
    </CenteredBox>
  </Grid>
));

const largestDelta = (incomes: number[], expenses: number[]) => {
  const delta = incomes.map((income, index) => income + expenses[index]);
  const maxDelta = Math.max(...delta);
  const index = delta.indexOf(maxDelta);

  return { maxDelta, index };
};

const maxABSAmount = (incomes: number[], expenses: number[]) => Math.max(...incomes, ...expenses.map(Math.abs));

const createSeries = (incomes: number[], expenses: number[]): ApexAxisChartSeries => [
  {
    name: 'Income',
    data: incomes,
  },
  {
    name: 'Expense',
    data: expenses,
  },
];

const createDataPoints = (rentIncomeYTD: number, expensesYTD: number, theme: Theme): DataPoint[] => [
  {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    stats: formatNumberToCurrency(rentIncomeYTD, 0),
    icon: <GraphTrendUp />,
    title: 'Total Income',
  },
  {
    icon: <GraphTrendDown />,
    stats: formatNumberToCurrency(expensesYTD, 0),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
    title: 'Total Expense',
  },
];

export const CashflowSummary = ({ properties }: RequiresPropertiesProps) => {
  const [isChartVisible, setChartVisible] = useState(false);
  const [monthGroup, setMonthGroup] = useState<MonthGroup>(MonthGroup.LAST_SIX);
  const l = useLabels();
  const analytics = useAnalytics();

  useLayoutEffect(() => {
    // This is here to fix a weird issue with apexcharts' height not being set correctly
    setChartVisible(true);
  }, []);

  const theme = useTheme();
  const lastMonth = dayjs().subtract(1, 'months');
  const sixMonthAgo = dayjs().subtract(6, 'months');
  const sevenMonthAgo = dayjs().subtract(7, 'months');
  const lastYear = dayjs().subtract(1, 'years').startOf('month');

  const tooltipContent = [
    {
      bold: l['tooltip.cashflow.title'],
      label: l['tooltip.cashflow.desc'],
    },
    {
      bold: l['tooltip.totalIncome.title'],
      label: l['tooltip.totalIncome.desc'],
    },
    {
      bold: l['tooltip.totalExpense.title'],
      label: l['tooltip.totalExpense.desc'],
    },
  ];

  const sumMonthlyIncomeTransactionsForLastSixMonth = sumMonthlyIncomeTransactions(
    properties,
    sixMonthAgo.startOf('day').startOf('month'),
    lastMonth.endOf('day').endOf('month'),
  );
  const sumMonthlyIncomeTransactionsForLastTwelveToSixMonth = sumMonthlyIncomeTransactions(
    properties,
    lastYear.startOf('day').startOf('month'),
    sevenMonthAgo.endOf('day').endOf('month'),
  );
  const sumMonthlyExpenseTransactionsForLastSixMonth = sumMonthlyExpenseTransactions(
    properties,
    sixMonthAgo.startOf('day').startOf('month'),
    lastMonth.endOf('day').endOf('month'),
  );
  const sumMonthlyExpenseTransactionsForLastTwelveToSixMonth = sumMonthlyExpenseTransactions(
    properties,
    lastYear.startOf('day').startOf('month'),
    sevenMonthAgo.endOf('day').endOf('month'),
  );

  const { maxDelta, index } = largestDelta(
    sumMonthlyIncomeTransactionsForLastTwelveToSixMonth.concat(sumMonthlyIncomeTransactionsForLastSixMonth),
    sumMonthlyExpenseTransactionsForLastTwelveToSixMonth.concat(sumMonthlyExpenseTransactionsForLastSixMonth),
  );

  const maxAmount = maxABSAmount(
    sumMonthlyIncomeTransactionsForLastTwelveToSixMonth.concat(sumMonthlyIncomeTransactionsForLastSixMonth),
    sumMonthlyExpenseTransactionsForLastTwelveToSixMonth.concat(sumMonthlyExpenseTransactionsForLastSixMonth),
  );

  const { dates, sumByMonths } = useMemo(() => {
    if (monthGroup === MonthGroup.FIRST_SIX) {
      return {
        sumByMonths: {
          income: sumMonthlyIncomeTransactionsForLastTwelveToSixMonth,
          expense: sumMonthlyExpenseTransactionsForLastTwelveToSixMonth,
        },
        dates: { from: lastYear, to: sevenMonthAgo },
      };
    }
    return {
      sumByMonths: {
        income: sumMonthlyIncomeTransactionsForLastSixMonth,
        expense: sumMonthlyExpenseTransactionsForLastSixMonth,
      },
      dates: { from: sixMonthAgo, to: lastMonth },
    };
  }, [monthGroup, properties]);
  const { missingData } = useMissingData(properties);

  const nextMonths = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Cashflow Summary Next Months',
    });
    setMonthGroup(MonthGroup.LAST_SIX);
  };

  const prevMonths = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Cashflow Summary Previous Months',
    });
    setMonthGroup(MonthGroup.FIRST_SIX);
  };

  const metrics = properties.map((property) => property.metrics);
  const showInsight = !missingData.expenses.isMissing && maxDelta > 0;
  const income = getActualAnnualIncome(metrics);
  const expenses = getAllExpenses(metrics);
  const cashFlow = getActualCashflow(metrics);

  return (
    <Card
      component={Stack}
      sx={{
        height: '100%', justifyContent: 'space-between',
      }}
    >
      <Stack height="100%">
        <CardHeader
          sx={{ p: 4, pb: 2 }}
          title={(
            <Stack direction="column" gap={5}>
              <Typography variant="h6">{l.netCashFlow}</Typography>
            </Stack>
          )}
          titleTypographyProps={{ variant: 'h6' }}
          action={(
            <InfoTooltip
              title={<TooltipContent bullets={tooltipContent} />}
              isOutlined
              arrow
              isLight
              track={(value) => {
                analytics.track('Tooltip Toggled', {
                  value,
                  tooltipName: 'Cashflow Summary Tooltip',
                });
              }}
            />
          )}
        />
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Stack sx={{ paddingBottom: '5px', flexGrow: 1 }} direction="column" gap={1}>
                <Typography
                  variant="h5"
                  color={missingData.expenses.isMissing ? 'secondary' : undefined}
                  sx={{
                    mr: 1.5, display: 'flex', alignItems: 'center', gap: 2,
                  }}
                >
                  {formatNumberToCurrency(cashFlow, 0)}
                  {missingData.expenses.isMissing && <MissingDataIcon boxProps={{ sx: { ml: 2 } }} />}
                </Typography>
                <Typography variant="body2">{l.lastYear}</Typography>
              </Stack>
            </Grid>
            {renderStats(createDataPoints(income, Math.abs(expenses), theme))}
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            {isChartVisible && renderChart(
              getMonthNamesBetweenDates(dates.from, dates.to),
              createSeries(sumByMonths.income.map(Math.abs), sumByMonths.expense.map(Math.abs)),
              theme,
              maxAmount || Infinity,
            )}
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton aria-label="Prev" onClick={prevMonths}>
                <ArrowCircleRight fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography>
                {dates.from.format('MMM')}
                {' - '}
                {dates.to.format('MMM')}
                {', '}
                {dates.to.year()}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="Prev" onClick={nextMonths}>
                <ArrowCircleLeft fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          {showInsight && (
            <InsightTypography marginTop={2}>
              {l.mostProfitableMonth}
              {' '}
              <span
                style={{ color: theme.palette.info.main }}
              >
                {dayjs().subtract(12 - index, 'months').format('MMMM, YYYY')}
              </span>
              {' with '}
              <span style={{ color: theme.palette.info.main }}>
                {formatNumberToCurrency(maxDelta, 0)}
              </span>
            </InsightTypography>
          )}
        </CardContent>
      </Stack>
      <MissingExpenses
        properties={properties}
        containerSx={showInsight ? { mt: 0 } : {}}
        onAdd={() => {
          analytics.track('Button Clicked', {
            buttonName: 'Missing Expenses CTA',
            widgetName: 'Cashflow Summary',
          });
        }}
      />
    </Card>
  );
};

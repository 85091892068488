import { Theme } from '@mui/material/styles';

import { themeConfig } from '../../configs/themeConfig';
import { hexToRGBA } from '../../lib/hexToRGBA';

export const overrideButton = (theme: Theme) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 700,
        borderRadius: 4,
        lineHeight: 1.715,
        padding: `${theme.spacing(1.75, 3)}`,
        '&.MuiButton-textPrimary:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&.MuiButton-textSecondary:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
        '&.MuiButton-textSuccess:hover': {
          backgroundColor: theme.palette.success.dark,
        },
        '&.MuiButton-textError:hover': {
          backgroundColor: theme.palette.error.dark,
        },
        '&.MuiButton-textWarning:hover': {
          backgroundColor: theme.palette.warning.dark,
        },
        '&.MuiButton-textInfo:hover': {
          backgroundColor: theme.palette.info.dark,
        },
        '&.MuiButton-root:hover': {
          boxShadow: theme.shadows[0],
        },
      },
      contained: {
        boxShadow: theme.shadows[0],
        padding: `${theme.spacing(1.75, 5.5)}`,
      },
      text: {
        lineHeight: 1.572,
        padding: `${theme.spacing(1.75, 5.25)}`,
        '&.MuiButton-textPrimary:hover': {
          backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
        },
        '&.MuiButton-textSecondary:hover': {
          backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.08),
        },
        '&.MuiButton-textSuccess:hover': {
          backgroundColor: hexToRGBA(theme.palette.success.main, 0.08),
        },
        '&.MuiButton-textError:hover': {
          backgroundColor: hexToRGBA(theme.palette.error.main, 0.08),
        },
        '&.MuiButton-textWarning:hover': {
          backgroundColor: hexToRGBA(theme.palette.warning.main, 0.08),
        },
        '&.MuiButton-textInfo:hover': {
          backgroundColor: hexToRGBA(theme.palette.info.main, 0.08),
        },
      },
      outlined: {
        lineHeight: 1.572,
        padding: `${theme.spacing(1.75, 5.25)}`,
        '&.MuiButton-outlinedPrimary:hover': {
          backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
        },
        '&.MuiButton-outlinedSecondary:hover': {
          backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.08),
        },
        '&.MuiButton-outlinedSuccess:hover': {
          backgroundColor: hexToRGBA(theme.palette.success.main, 0.08),
        },
        '&.MuiButton-outlinedError:hover': {
          backgroundColor: hexToRGBA(theme.palette.error.main, 0.08),
        },
        '&.MuiButton-outlinedWarning:hover': {
          backgroundColor: hexToRGBA(theme.palette.warning.main, 0.08),
        },
        '&.MuiButton-outlinedInfo:hover': {
          backgroundColor: hexToRGBA(theme.palette.info.main, 0.08),
        },
      },
      sizeSmall: {
        lineHeight: 1.693,
        padding: `${theme.spacing(1, 2.25)}`,
        '&.MuiButton-contained': {
          padding: `${theme.spacing(1, 3.25)}`,
        },
        '&.MuiButton-outlined': {
          lineHeight: 1.539,
          padding: `${theme.spacing(1, 3.25)}`,
        },
      },
      sizeLarge: {
        lineHeight: 1.734,
        padding: `${theme.spacing(2.75, 5.5)}`,
        '&.MuiButton-contained': {
          padding: `${theme.spacing(2.75, 6.5)}`,
        },
        '&.MuiButton-outlined': {
          lineHeight: 1.6,
          padding: `${theme.spacing(2, 6.25)}`,
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: themeConfig.disableRipple,
    },
  },
});

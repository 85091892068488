import ReactConfetti from 'react-confetti';
import { useMediaQuery, useTheme } from '@mui/material';

export const Confetti = ({ run, onConfettiComplete }: { run: boolean, onConfettiComplete: () => void }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!run) {
    return null;
  }

  return (
    <ReactConfetti
      run={run}
      numberOfPieces={isMobile ? 200 : 400}
      confettiSource={{
        x: 0, y: 0, w: window.screen.width, h: window.screen.height,
      }}
      style={{ zIndex: 9999 }}
      onConfettiComplete={onConfettiComplete}
      recycle={false}
      color={theme.palette.primary.dark}
      colors={[
        '#F26798',
        '#EE3A80',
        '#F2A1B8',
        '#C81D65',
        '#F2D1E0',
        '#FFC0CB',
        '#FF69B4',
        '#FF1493',
        '#E91E63',
        '#FFA6C9',
        '#D74D77',
        '#F50057',
        '#FFB6C1',
        '#DB7093',
        '#C71585',
        '#FFC0E1',
        '#E297B1',
        '#F48FB1',
        '#EC407A',
      ]}
    />
  );
};

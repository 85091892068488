import { MdCheckCircle } from 'react-icons/md';
import {
  Stack, StepConnector, stepConnectorClasses, StepIconProps, styled, useTheme,
} from '@mui/material';

export const StyledColorConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.dark,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.primary.dark,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

export const StyledField = styled(Stack)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '58% auto',
  gridTemplateRows: '1fr',
  alignItems: 'center',
  padding: theme.spacing(3),
  gap: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '100%',
  },
}));

export const StyledCompactLabelField = styled(StyledField)(() => ({
  gridTemplateColumns: '38% auto',
}));

export const StyledStepIcon = ({ completed }: StepIconProps) => {
  const theme = useTheme();

  if (completed) {
    return (
      <Stack mt={-1}>
        <MdCheckCircle size={24} color={theme.palette.primary.dark} />
      </Stack>
    );
  }

  return (
    <Stack
      height={20}
      width={20}
      borderRadius="50%"
      border={`2px solid ${theme.palette.primary.dark}`}
      sx={{ background: theme.palette.background.paper }}
    />
  );
};

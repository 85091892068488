import { MdKeyboardArrowRight } from 'react-icons/md';
import { styled } from '@mui/material';

import { ReactComponent as Arrow } from './svg/arrow.svg';

export const ArrowToggleOpen = styled(MdKeyboardArrowRight)({
  transition: '0.3s',
  '&.open': {
    transform: 'rotate(90deg)',
  },
});

export const ArrowToggleOpenBottom = styled(Arrow)({
  transition: '0.3s',
  '&.open': {
    transform: 'scale(-1, -1)',
  },
});

import { useAnalytics } from 'lib';
import {
  formatDecimalToPercentage, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';
import {
  Box, Grid, useTheme,
} from '@mui/material';

import { Widget } from '.';

export const PortfolioValueWidget = ({ value, isPortfolio = false }: { value: number, isPortfolio?: boolean }) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Grid xs={4} md={4} item>
      <Widget
        isDark
        title={isPortfolio ? l['reports.portfolio.estValue'] : l['reports.estValue']}
        tooltip={isPortfolio ? l['reports.portfolio.estValue.tooltip'] : l['reports.estValue.tooltip']}
        tooltipTrack={(v) => {
          analytics.track('Tooltip Toggled', {
            value: v,
            tooltipName: isPortfolio ? 'Portfolio Value Widget Tooltip' : 'Property Value Widget Tooltip',
          });
        }}
      >
        <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
          <SemiBoldTypography
            data-testid="dashboard.report.portfolio"
            sx={{ color: theme.palette.primary.contrastText }}
            variant="h4"
          >
            {formatNumberToCurrency(value, 0)}
          </SemiBoldTypography>
        </Box>
      </Widget>
    </Grid>
  );
};

export const MonthlyAppreciationWidget = ({ amount, isPortfolio }: { amount: number, isPortfolio: boolean }) => {
  const l = useLabels();
  const analytics = useAnalytics();

  return (

    <Grid xs={4} md={4} item>
      <Widget
        title={l['reports.monthlyAppreciation']}
        tooltip={isPortfolio ? l['reports.portfolio.monthlyAppreciation.tooltip'] : l['reports.portfolio.tooltip']}
        tooltipTrack={(v) => {
          analytics.track('Tooltip Toggled', {
            value: v,
            tooltipName: isPortfolio ? 'Portfolio Monthly Appreciation Widget Tooltip'
              : 'Property Monthly Appreciation Widget Tooltip',
          });
        }}
      >
        <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
          <SemiBoldTypography
            data-testid="dashboard.report.monthlyAppreciation"
            variant="h4"
          >
            {formatNumberToCurrency(amount, 0)}
          </SemiBoldTypography>
        </Box>
      </Widget>
    </Grid>
  );
};

export const TotalEquityWidget = ({ totalEquity, isPortfolio }: { totalEquity: number, isPortfolio: boolean }) => {
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Grid xs={4} md={4} item>
      <Widget
        title={l['reports.totalEquity']}
        tooltip={isPortfolio ? l['reports.portfolio.totalEquity.tooltip'] : l['reports.totalEquity.tooltip']}
        tooltipTrack={(v) => {
          analytics.track('Tooltip Toggled', {
            value: v,
            tooltipName: isPortfolio ? 'Portfolio Total Equity Widget Tooltip'
              : 'Property Total Equity Widget Tooltip',
          });
        }}
      >
        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <SemiBoldTypography
            data-testid="dashboard.report.totalEquity"
            variant="h4"
          >
            {formatNumberToCurrency(totalEquity, 0)}
          </SemiBoldTypography>
        </Box>
      </Widget>
    </Grid>
  );
};
export const OccupancyWidget = ({ totalOccupiedUnits, totalUnits, isPortfolio = false }:
{ totalOccupiedUnits: number, totalUnits: number, isPortfolio?: boolean }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const occupancyPercentage = formatDecimalToPercentage(totalOccupiedUnits / totalUnits, 0);

  if (isPortfolio) {
    return (
      <Grid xs={4} md={4} item>
        <Widget
          title={l['reports.occupancy']}
          tooltip={l['reports.portfolio.occupancy.tooltip']}
          tooltipTrack={(v) => {
            analytics.track('Tooltip Toggled', {
              value: v,
              tooltipName: 'Portfolio Occupancy Widget Tooltip',
            });
          }}
        >
          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <SemiBoldTypography
              data-testid="dashboard.report.occupancy"
              variant="h4"
            >
              {`${totalOccupiedUnits}/${totalUnits} (${occupancyPercentage}) `}
            </SemiBoldTypography>
          </Box>
        </Widget>
      </Grid>
    );
  }

  return (
    <Grid xs={4} md={4} item>
      <Widget
        title={l['reports.occupancy']}
        tooltip={l['reports.occupancy.tooltip']}
        tooltipTrack={(v) => {
          analytics.track('Tooltip Toggled', {
            value: v,
            tooltipName: 'Property Occupancy Widget Tooltip',
          });
        }}
      >
        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <SemiBoldTypography
            data-testid="dashboard.report.occupancy"
            variant="h4"
          >
            {totalUnits > 1 ? `${totalOccupiedUnits}/${totalUnits} (${occupancyPercentage}) ` : (
              <>
                {totalOccupiedUnits === 1 ? l['reports.occupancy.yes'] : l['reports.occupancy.no']}
                {' '}
              </>
            )}
          </SemiBoldTypography>
        </Box>
      </Widget>
    </Grid>
  );
};

import { Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexWrap: 'wrap',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  right: 'unset',
  gap: '0 6px',
  margin: '10px 0',
  [theme.breakpoints.up('sm')]: {
    width: '300px',
    right: 0,
    position: 'absolute',
  },
}));

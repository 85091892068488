import React, { useState } from 'react';

import { useAnalytics } from 'lib';
import { useLabels } from 'ui';
import {
  Box,
  Checkbox, FormControlLabel, Stack, TextField, Typography,
} from '@mui/material';

type UserPhoneInputProps = {
  smsConsentDefaultValue: boolean;
  onValidityChange: (isValid: boolean) => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
  onSmsConsentChange: (smsConsent: boolean) => void;
};

export const UserPhoneInput: React.FC<UserPhoneInputProps> = ({
  smsConsentDefaultValue, onValidityChange, onPhoneNumberChange, onSmsConsentChange,
}) => {
  const analytics = useAnalytics();
  const l = useLabels();

  const [isValid, setIsValid] = useState(false);
  const [touched, setTouched] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pattern = /^\+?[1-9]\d{1,14}$/;
    const originalPhoneNumber = event.target.value;
    const cleanPhoneNumber = originalPhoneNumber.replace(/[-()]/g, '');
    const isPhoneNumberValid = pattern.test(cleanPhoneNumber);
    setIsValid(isPhoneNumberValid);

    onValidityChange(isPhoneNumberValid);

    onPhoneNumberChange(originalPhoneNumber);
  };

  return (
    <Box>
      <Stack spacing={2} alignItems="flex" p={3}>
        <TextField
          id="user-phone"
          label={l['user.phone.input']}
          variant="outlined"
          onChange={handleInputChange}
          error={touched && !isValid}
          onFocus={() => {
            setTouched(true); // set touched to true on blur
          }}
          onBlur={(event) => {
            analytics.track('Input Changed', {
              inputName: 'User provided phone',
              value: event.target.value,
            });
          }}
        />
        {!smsConsentDefaultValue && (
          <FormControlLabel
            control={(
              <Checkbox
                onChange={(event) => {
                  analytics.track('Checkbox Toggled', {
                    checkboxName: 'SMS Consent',
                    value: event.target.checked,
                  });
                  onSmsConsentChange(event.target.checked);
                }}
              />
            )}
            label={(
              <Typography variant="body2" color="text.secondary">
                {l['user.smsConsent']}
              </Typography>
            )}
          />
        )}
      </Stack>
    </Box>
  );
};

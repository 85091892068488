import {
  useCallback, useEffect, useState,
} from 'react';

import { config, useAnalytics } from 'lib';
import { SpinnerWithLogo } from 'ui';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, Stack } from '@mui/material';

const Marker = ({ options, map }: { options: google.maps.MarkerOptions, map: undefined | google.maps.Map }) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) setMarker(new google.maps.Marker());
    if (marker && map && options) {
      marker.setMap(map);
      marker.setOptions(options);
    }
  }, [marker, map, options]);

  return null;
};

// this component is built loosely based on this shitty tutorial from google:
// https://developers.google.com/maps/documentation/javascript/react-map
export const GoogleMap = ({ mapName, location }: { mapName: string, location: google.maps.LatLngLiteral }) => {
  const analytics = useAnalytics();
  const [map, setMap] = useState<google.maps.Map>();
  const ref = useCallback((node: HTMLDivElement) => {
    if (node === null || map) return;
    setMap(new google.maps.Map(node, {
      scrollwheel: false,
      scaleControl: false,
      rotateControl: false,
      panControl: false,
      keyboardShortcuts: false,
      isFractionalZoomEnabled: false,
      draggable: false,
      disableDoubleClickZoom: true,
      disableDefaultUI: true,
      center: location,
      zoom: 16,
    }));
  }, [map]);

  return (
    <Wrapper
      apiKey={config.googleMapsApiKey}
      render={() => <Stack justifyContent="center" alignItems="center"><SpinnerWithLogo size={48} /></Stack>}
    >
      <Box
        ref={ref}
        sx={{ height: '200px', borderRadius: '6px' }}
        onClick={() => {
          analytics.track('Map Clicked', {
            mapName,
          });
        }}
      />
      <Marker options={{ position: location }} map={map} />
    </Wrapper>
  );
};

import { useAnalytics } from 'lib';
import { MdCheckCircle, MdHome, MdPriceChange } from 'react-icons/md';
import { BoldTypography, useLabels } from 'ui';
import { Stack, Typography, useTheme } from '@mui/material';

import { SellPropertyDialog } from './SellPropertyDialog';
import { GetStartedStep } from '../../components/sell-property/GetStartedStep';

export const GetStarted = ({
  open, openNextDialog,
}: {
  open: boolean, openNextDialog: () => void,
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();
  const l = useLabels();

  return (
    <SellPropertyDialog
      open={open}
      openNextDialog={async () => {
        openNextDialog();
        analytics.track('Button Clicked', {
          buttonName: 'Sell Property - Get Started',
        });
      }}
      nextButtonText={l.getStarted}
      showActions
    >
      <Stack p={3}>
        <BoldTypography variant="h5" sx={{ mb: 3 }}>{l['sellProperty.getStarted.title']}</BoldTypography>
        <Typography variant="body2" sx={{ mb: 6 }} color="text.secondary">{l['sellProperty.getStarted.intro']}</Typography>
        <GetStartedStep
          icon={<MdHome color={theme.palette.text.primary} size={40} />}
          title={l['sellProperty.getStarted.selectProperty.header']}
          description={l['sellProperty.getStarted.selectProperty.description']}
        />
        <GetStartedStep
          icon={<MdPriceChange color={theme.palette.text.primary} size={40} />}
          title={l['sellProperty.getStarted.agent.header']}
          description={l['sellProperty.getStarted.agent.description']}
        />
        <GetStartedStep
          icon={<MdCheckCircle color={theme.palette.text.primary} size={40} />}
          title={l.success}
          description={l['sellProperty.getStarted.success.description']}
        />
      </Stack>

    </SellPropertyDialog>
  );
};

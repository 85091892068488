import dayjs, { Dayjs } from 'dayjs';
import { sortByAsc, sum } from 'lib';

import { Property } from '../../types';
import { Valuation } from '../../types/property';

export const calculateTotalPurchase = (properties: Pick<Property, 'purchaseHistory'>[]) => sum(
  properties.map(({ purchaseHistory }) => (purchaseHistory?.amount ?? 0)
      + (purchaseHistory?.closingCost ?? 0)
      + (purchaseHistory?.homeImprovementCost ?? 0)),
);

export const calculateTotalValuation = (properties: Pick<Property, 'latestValuation'>[]) => sum(
  properties.map(({ latestValuation }) => latestValuation?.priceMean ?? 0),
);

export const getLastValuationDate = (properties: Pick<Property, 'latestValuation'>[]): Dayjs | null => {
  const valuations = properties
    .map(({ latestValuation }) => latestValuation)
    .filter(Boolean) as Valuation[];

  if (valuations.length === 0) {
    return null;
  }

  const lastValuationDate = sortByAsc<Valuation>(valuations, 'date')[0]!.date;

  return dayjs(lastValuationDate);
};

import { useNavigate } from 'react-router-dom';

import { useAnalytics, useIsSellPropertyEnabled } from 'lib';
import { BoldTypography, InvertedButton, useLabels } from 'ui';
import {
  Box, Stack,
} from '@mui/material';

import sellPropertyImage from '../../assets/sell-property.png';

export const SellPropertyButton = ({ onClick }: { onClick: () => void }) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const l = useLabels();
  const { value: sellPropertyEnabled, loading } = useIsSellPropertyEnabled();

  if (loading || !sellPropertyEnabled) {
    return null;
  }

  return (
    <Box
      onClick={() => {
        onClick();
        navigate('/marketplace/sell-property', { state: { from: window.location.pathname } });
        analytics.track('Button Clicked', {
          buttonName: 'Want to sell your properties?',
        });
      }}
      sx={{
        position: 'relative',
        px: 4,
        pt: 4,
        pb: 2,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <img src={sellPropertyImage} alt="Sell Property" width="100%" style={{ borderRadius: 6 }} />
      <Stack
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: 0,
          height: '100%',
          p: 4,
        }}
      >
        <BoldTypography
          variant="body2"
          sx={{
            width: '70%',
          }}
        >
          {l['sellProperty.wantToSell']}
        </BoldTypography>
      </Stack>
    </Box>
  );
};

// this will replace SellPropertyButton in the future
export const WantToSellPropertyButton = ({ onClick, height }: { onClick: () => void, height: number }) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const l = useLabels();
  const { value: sellPropertyEnabled, loading } = useIsSellPropertyEnabled();

  if (loading || !sellPropertyEnabled) {
    return null;
  }

  return (
    <Stack
      height={height}
      justifyContent="center"
      sx={{
        background: `url(${sellPropertyImage}) no-repeat center center`,
        backgroundSize: 'cover',
        borderRadius: '6px',
        mx: 4,
        p: 3,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => {
        onClick();
        navigate('/marketplace/sell-property', { state: { from: window.location.pathname } });
        analytics.track('Button Clicked', {
          buttonName: 'Want to sell your properties?',
        });
      }}
    >
      <BoldTypography
        variant="body2"
        sx={{
          width: '80%',
        }}
      >
        {l['sellProperty.wantToSell']}
      </BoldTypography>
    </Stack>
  );
};

// this will replace SellPropertyButton in the future
export const WantToSellPropertyWidget = ({ onClick, height }: { onClick: () => void, height: number }) => {
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const l = useLabels();
  const { value: sellPropertyEnabled, loading } = useIsSellPropertyEnabled();

  if (loading || !sellPropertyEnabled) {
    return null;
  }

  return (
    <Stack
      height={height}
      justifyContent="center"
      sx={{
        background: `url(${sellPropertyImage}) no-repeat center center`,
        backgroundSize: 'cover',
        borderRadius: '6px',
        mx: 4,
        width: '320px',
        marginLeft: '20px',
        p: 3,
      }}
    >
      <BoldTypography
        variant="body2"
        sx={{
          width: '65%',
        }}
      >
        {l['sellProperty.wantToSell']}
      </BoldTypography>
      <InvertedButton
        variant="contained"
        size="small"
        disabled={loading || !sellPropertyEnabled}
        onClick={() => {
          onClick();
          navigate('/marketplace/sell-property', { state: { from: window.location.pathname } });
          analytics.track('Button Clicked', {
            buttonName: 'Want to sell your properties widget',
          });
        }}
        sx={{
          width: '120px',
          marginTop: '16px',

        }}
      >
        {l.getStarted}
      </InvertedButton>
    </Stack>
  );
};

import {
  ReactElement,
  useCallback,
  useState,
} from 'react';

import { IconType } from 'react-icons';
import { Button, SxProps, useTheme } from '@mui/material';

import { LightTooltip } from '../tooltip/InfoTooltip';

export const IconButton = ({
  Icon, onClick, disabled = false, disabledTooltip = '',
  actionName, disableRipple = false, buttonSx = {},
  testID = '',
}: {
  Icon: IconType
  onClick: () => void
  disabled?: boolean
  disabledTooltip?: string
  actionName: string
  disableRipple?: boolean
  buttonSx?: SxProps
  testID?: string
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const Container = useCallback(({ children, ...props }: { children: ReactElement, title: string }) => {
    if (disabled) {
      return (
        <LightTooltip
          sx={{ zIndex: 12444444 }}
          {...props}
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
        >
          {children}
        </LightTooltip>
      );
    }
    return (
      children
    );
  }, [disabled, open]);

  return (
    <Container title={disabledTooltip}>
      <Button
        data-testid={testID}
        id={actionName}
        disableRipple={disableRipple}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={disabled ? () => {} : onClick}
        sx={{
          all: 'unset',
          p: 2,
          cursor: disabled ? 'default' : 'pointer',
          color: disabled ? `${theme.palette.action.disabled} !important` : `${theme.palette.text.secondary} !important`,
          '&:hover': {
            background: 'none !important',
            color: disabled ? `${theme.palette.action.disabled} !important` : `${theme.palette.text.primary} !important`,
          },
          ...buttonSx,
        }}
      >
        <Icon style={{ width: '24px', height: '24px' }} />
      </Button>
    </Container>
  );
};

import { useState } from 'react';

import { HamburgerBar, HamburgerContainer } from './styled';

export const Hamburger = ({ open, onClick }: { open: boolean, onClick: () => void }) => {
  const [silent, setSilent] = useState(true);

  return (
    <HamburgerContainer
      data-testid="mobile-menu-btn"
      type="button"
      className={open ? 'open' : ''}
      onClick={() => {
        setSilent(false);
        onClick();
      }}
    >
      <HamburgerBar className={`bar ${silent ? 'silent' : ''}`} />
      <HamburgerBar className={`bar ${silent ? 'silent' : ''}`} />
      <HamburgerBar className={`bar ${silent ? 'silent' : ''}`} />
    </HamburgerContainer>
  );
};

import { toast } from 'react-toastify';
import { useLabels } from 'ui';
import { Button } from '@mui/material';

export const CopyLinkButton = ({ link }: { link: string }) => {
  const l = useLabels();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success(l['referral-partners.copyText']);
    }).catch(() => {
      toast.error(l['error.unknownError']);
    });
  };

  return (
    <Button variant="outlined" onClick={copyToClipboard}>
      {l['referral-partners.copyLink']}
    </Button>
  );
};

import {
  BoldTypography, formatDecimalToPercentage, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';

import { Expense } from './Expense';
import { DetailRow } from './Row';
import { useProjections } from './useProjections';
import { CalculationType, RowProps } from '../types';

export const EstimatedVacancy = ({ form }: RowProps) => {
  const l = useLabels();
  const rent = form.watch('details.income.rent');
  const estimatedVacancy = form.watch('details.estimatedVacancy.value');
  const calculationType = form.watch('details.estimatedVacancy.type');

  const projections = useProjections(form);

  return (
    <DetailRow
      form={form}
      rowID="estimated-vacancy"
      columns={[
        {
          id: 'estimated-vacancy',
          content: (
            <SemiBoldTypography variant="body1">
              {l.estimatedVacancy}
            </SemiBoldTypography>
          ),
        },
        {
          id: 'estimated-vacancy-year-1',
          content: (
            <Expense
              form={form}
              showAsNegative
              name="details.estimatedVacancy.value"
              calculationType={form.watch('details.estimatedVacancy.type')}
              value={estimatedVacancy}
              label={l.estimatedVacancy}
              onClickDollar={() => {
                form.setValue('details.estimatedVacancy.type', CalculationType.NUMBER);
                form.setValue('details.estimatedVacancy.value', Math.round(rent * (estimatedVacancy / 100)));
              }}
              onClickPercentage={() => {
                form.setValue('details.estimatedVacancy.type', CalculationType.PERCENTAGE);
                form.setValue('details.estimatedVacancy.value', (estimatedVacancy / rent) * 100);
              }}
            />
          ),
        },
        {
          id: 'estimated-vacancy-year-2',
          content: (
            <BoldTypography variant="body1">
              {estimatedVacancy !== 0 && '-'}
              {calculationType === CalculationType.NUMBER ? (
                formatNumberToCurrency(projections.vacancy.year2 || 0, 0)
              ) : (
                formatDecimalToPercentage((estimatedVacancy || 0) / 100, 2)
              )}
            </BoldTypography>
          ),
        },
        {
          id: 'estimated-vacancy-year-3',
          content: (
            <BoldTypography variant="body1">
              {estimatedVacancy !== 0 && '-'}
              {calculationType === CalculationType.NUMBER ? (
                formatNumberToCurrency(projections.vacancy.year3 || 0, 0)
              ) : (
                formatDecimalToPercentage((estimatedVacancy || 0) / 100, 2)
              )}
            </BoldTypography>
          ),
        },
      ]}
    />
  );
};

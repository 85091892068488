const env = (window as any).env ?? {};

export const config = {
  devMode: process.env.NODE_ENV === 'development',
  emailSuffix: env.REACT_APP_EMAIL_SUFFIX ?? (process.env.REACT_APP_EMAIL_SUFFIX || ''),
  gqlEndpoint: env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT ?? process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  awsRegion: env.REACT_APP_AWS_APPSYNC_REGION ?? process.env.REACT_APP_AWS_APPSYNC_REGION,
  appSyncAuthType: env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE ?? process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  appSyncApiKey: env.REACT_APP_AWS_APPSYNC_APIKEY ?? process.env.REACT_APP_AWS_APPSYNC_APIKEY,
  apiBaseURL: env.REACT_APP_API_BASE_URL ?? (process.env.REACT_APP_API_BASE_URL || ''),
  sentryDsn: env.REACT_APP_SENTRY_DSN ?? (process.env.REACT_APP_SENTRY_DSN || ''),
  sentryDebug: (env.REACT_APP_SENTRY_DEBUG ?? (process.env.REACT_APP_SENTRY_DEBUG || '')).toLowerCase() === 'true',
  analyticsToken: env.REACT_APP_ANALYTICS_KEY ?? (process.env.REACT_APP_ANALYTICS_KEY || ''),
  googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY ?? (process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''),
  purchaseQuestionnaireFormId: (
    env.REACT_APP_PURCHASE_QUESTIONNAIRE_FORM_ID ?? (process.env.REACT_APP_PURCHASE_QUESTIONNAIRE_FORM_ID || '')
  ),

  jotFormInsuranceObpFormId: (
    env.REACT_APP_JOT_FORM_INSURANCE_OBP_FORM_ID ?? (process.env.REACT_APP_JOT_FORM_INSURANCE_OBP_FORM_ID || '')
  ),

  jotForm1031ExchangeObpFormId: (
    env.REACT_APP_JOT_FORM_1031_EXCHANGE_OBP_FORM_ID ?? (process.env.REACT_APP_JOT_FORM_1031_EXCHANGE_OBP_FORM_ID || '')
  ),

  jotFormInspectionObpFormId: (
    env.REACT_APP_JOT_FORM_INSPECTION_OBP_FORM_ID ?? (process.env.REACT_APP_JOT_FORM_INSPECTION_OBP_FORM_ID || '')
  ),

  jotFormFinancingObpFormId: (
    env.REACT_APP_JOT_FORM_FINANCING_OBP_FORM_ID ?? (process.env.REACT_APP_JOT_FORM_FINANCING_OBP_FORM_ID || '')
  ),

  jotFormOwnerSurveyFormId: (
    env.REACT_APP_JOT_FORM_OWNER_SURVEY_FORM_ID ?? (process.env.REACT_APP_JOT_FORM_OWNER_SURVEY_FORM_ID || '')
  ),

  jotFormFinancingObpAgentHelpQuestion: '5',
  jotFormFinancingObpAgentFacilitateHelpQuestion: '7',

  purchaseQuestionnaireInvestmentPlansQuestion: '14',
  purchaseQuestionnaireInvestmentPlansSellAnswer: 'I want to sell a property',
  purchaseQuestionnaireInvestmentPlansBuyerAnswer: 'I want to buy investment properties',

  purchaseQuestionnaireConsultQuestion: '15',
  purchaseQuestionnaireConsulYesAnswer: 'Yes',

  performanceReviewFormId: (
    env.REACT_APP_PERFORMANCE_REVIEW_FORM_ID ?? (process.env.REACT_APP_PERFORMANCE_REVIEW_FORM_ID || '')
  ),
  performanceReviewOwnerEmailQuestionID: (
    env.REACT_APP_PERFORMANCE_REVIEW_OWNER_EMAIL_QUESTION_ID
    ?? (process.env.REACT_APP_PERFORMANCE_REVIEW_OWNER_EMAIL_QUESTION_ID || '')
  ),
  configCatApiKey: env.REACT_APP_CONFIGCAT_API_KEY ?? (process.env.REACT_APP_CONFIGCAT_API_KEY || ''),
  configCatLogLevel: parseInt(env.REACT_APP_CONFIGCAT_LOG_LEVEL ?? (process.env.REACT_APP_CONFIGCAT_LOG_LEVEL || 3), 10),

  descopeProjectId: env.REACT_APP_DESCOPE_PROJECT_ID ?? (process.env.REACT_APP_DESCOPE_PROJECT_ID || ''),
  descopeBaseURL: env.REACT_APP_DESCOPE_BASE_URL ?? (process.env.REACT_APP_DESCOPE_BASE_URL || ''),
  descopeFlowId: env.REACT_APP_DESCOPE_FLOW_ID ?? (process.env.REACT_APP_DESCOPE_FLOW_ID || ''),
  descopeDebugMode: (
    env.REACT_APP_DESCOPE_DEBUG_MODE ?? (process.env.REACT_APP_DESCOPE_DEBUG_MODE || '')
  ).toLowerCase() === 'true',
};

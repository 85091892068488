import { useCallback, useState } from 'react';

import { FaRegDotCircle } from 'react-icons/fa';
import { Stack, useTheme } from '@mui/material';

import {
  BarContainer, BarValue, GraphClipPath, GraphContainer,
} from './styled';
import { formatNumberToCurrency } from '../lib/formatFuncs';

const Bar = ({ min, max, value }: { min: number, max: number, value: number }) => {
  const theme = useTheme();
  const [sizes, setSizes] = useState({
    value: { width: 0, height: 0 },
    min: { width: 0, height: 0 },
    max: { width: 0, height: 0 },
  });
  const setSize = (node: HTMLDivElement, key: 'min' | 'max' | 'value') => {
    if (node === null) return;

    const computedStyle = node ? getComputedStyle(node, ':before') : null;
    const beforeElWidth = computedStyle ? parseFloat(computedStyle.width.replace('px', '')) : 0;
    const beforeElHeight = computedStyle ? parseFloat(computedStyle.height.replace('px', '')) : 0;
    setSizes((prev) => ({
      ...prev,
      [key]: {
        width: beforeElWidth,
        height: beforeElHeight,
      },
    }));
  };

  const valueRef = useCallback((node: HTMLDivElement) => {
    setSize(node, 'value');
  }, [value]);
  const minRef = useCallback((node: HTMLDivElement) => {
    setSize(node, 'min');
  }, []);
  const maxRef = useCallback((node: HTMLDivElement) => {
    setSize(node, 'max');
  }, []);

  const createStyles = (val: number) => ({
    position: 'absolute',
    content: `"${formatNumberToCurrency(val, 0)}"`,
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    py: 1,
    px: 2,
    borderRadius: '4px',
    transition: '0.3s',
  });
  const minPosition = -33;
  const maxPosition = 133;

  let valuePosition = (100 * (value - min)) / (max - min);
  if (valuePosition < minPosition) valuePosition = minPosition;
  if (valuePosition > maxPosition) valuePosition = maxPosition;

  return (
    <BarContainer data-testid="range-graph">
      <Stack
        justifyContent="center"
        position="relative"
        ref={minRef}
        sx={{
          '&::before': {
            ...createStyles(min),
            left: `-${sizes.min.width / 2}px`,
            bottom: `-${sizes.min.height * 1.5}px`,
          },
        }}
      >
        <FaRegDotCircle
          style={{ position: 'absolute', left: '-8px' }}
        />
      </Stack>
      <Stack justifyContent="center">
        <FaRegDotCircle
          size={20}
          color={theme.palette.primary.main}
          style={{
            position: 'absolute',
            zIndex: 3,
            left: valuePosition === 100 ? `calc(${valuePosition}% - 11px)` : `calc(${valuePosition}% - 10px)`,
            transition: '0.3s',
          }}
        />
        <BarValue
          ref={valueRef}
          sx={{
            left: `${valuePosition}%`,
            '&::before': {
              ...createStyles(value),
              top: '-2px',
              left: (
                // eslint-disable-next-line no-nested-ternary
                valuePosition >= maxPosition
                  ? `-${sizes.value.width}px`
                  : valuePosition <= minPosition
                    ? '-2px'
                    : `-${sizes.value.width / 2}px`
              ),
              zIndex: 5,
            },
          }}
        />
      </Stack>
      <Stack
        justifyContent="center"
        position="relative"
        ref={maxRef}
        sx={{
          '&::before': {
            ...createStyles(max),
            left: `-${sizes.max.width / 2}px`,
            bottom: `-${sizes.max.height * 1.5}px`,
          },
        }}
      >
        <FaRegDotCircle style={{ position: 'absolute', right: '-7px' }} />
      </Stack>
    </BarContainer>
  );
};

export const RangeGraph = ({
  min, max, value, clipPathColor,
}: { min: number, max: number, value: number, clipPathColor: string }) => (
  <GraphContainer>
    <GraphClipPath sx={{ background: clipPathColor }} />
    <Bar min={min} max={max} value={value} />
  </GraphContainer>
);

import { useState } from 'react';

import { useAnalytics } from 'lib';
import {
  Box, ImageList, ImageListItem,
} from '@mui/material';

import { ImageGalleryPopup } from './ImageGalleryPopup';

type Props = {
  images: string[],
  address: string
};

type DesktopImageListItem = {
  img: string,
  title: string,
  rows: number,
  cols: number,
};

const createDesktopPictures = (pictures: string[], title:string):DesktopImageListItem[] => {
  const desktopPictures : DesktopImageListItem[] = [];
  if (pictures.length === 1) {
    desktopPictures.push({
      img: pictures[0], title, rows: 2, cols: 12,
    });
  } else {
    desktopPictures.push({
      img: pictures[0], title, rows: 2, cols: 6,
    });
  }
  if (pictures.length === 2) {
    desktopPictures.push({
      img: pictures[1], title, rows: 2, cols: 6,
    });
  } else if (pictures.length === 3) {
    pictures.slice(1, 3).forEach((value, index) => {
      desktopPictures.push({
        img: value, title: `${title} #${index + 1}`, rows: 1, cols: 3,
      });
    });
  } else if (pictures.length === 4) {
    pictures.slice(2, 4).forEach((value, index) => {
      desktopPictures.push({
        img: value, title: `${title} #${index + 1}`, rows: 1, cols: 3,
      });
    });
    desktopPictures.push({
      img: pictures[1], title, rows: 1, cols: 6,
    });
  } else if (pictures.length >= 5) {
    pictures.slice(1, 5).forEach((value, index) => {
      desktopPictures.push({
        img: value, title: `${title} #${index + 1}`, rows: 1, cols: 3,
      });
    });
  }
  return desktopPictures;
};

export const DesktopImageList = ({ images, address }:Props) => {
  const analytics = useAnalytics();
  const [imageViewPopupOpen, setImageViewPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const desktopPictures = createDesktopPictures(images, address);
  return (
    <>
      <Box sx={{
        my: 3,
        width: '100%',
        height: 400,
        borderRadius: '6px',
        overflow: 'hidden',
      }}
      >
        <ImageList
          sx={{ width: '100%', height: 400, margin: '0px' }}
          variant="quilted"
          cols={12}
          rowHeight={198}
        >
          {desktopPictures.slice(0, 5).map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols}
              rows={item.rows}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedImage(item.img);
                setImageViewPopupOpen(true);

                analytics.track('Image Clicked', {
                  imageName: item.title,
                  image: item.img,
                });
              }}
            >
              <Box
                sx={{
                  minWidth: '100%',
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <img
                  src={item.img}
                  style={{ minWidth: '100%' }}
                  alt={item.title}
                />
              </Box>

            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <ImageGalleryPopup
        image={selectedImage}
        images={images}
        open={imageViewPopupOpen}
        onClose={() => {
          setImageViewPopupOpen(false);
        }}
      />
    </>
  );
};

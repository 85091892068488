import { useState } from 'react';

import { useAnalytics } from 'lib';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { z } from 'zod';
import { Autocomplete, TextField } from '@mui/material';

import { useLabels } from '../lib/translations';

const matchedSubstringSchema = z.object({
  length: z.number(),
  offset: z.number(),
});

const structuredFormattingSchema = z.object({
  main_text: z.string(),
  main_text_matched_substrings: z.array(matchedSubstringSchema),
  secondary_text: z.string(),
});

const termSchema = z.object({
  offset: z.number(),
  value: z.string(),
});

export const placeSchema = z.object({
  description: z.string(),
  matched_substrings: z.array(matchedSubstringSchema),
  place_id: z.string(),
  structured_formatting: structuredFormattingSchema,
  terms: z.array(termSchema),
  types: z.array(z.string()),
});

export const GoogleAutocomplete = ({
  selectedOption, setSelectedOption, placeHolderLabel, autoCompleteTrackingName,
}: {
  selectedOption: AutocompletePrediction | null,
  setSelectedOption: (value: AutocompletePrediction | null) => void,
  placeHolderLabel: string,
  autoCompleteTrackingName: string,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [showOptions, setShowOptions] = useState(false);
  const {
    ready,
    value: search,
    suggestions: { status, data, loading },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: true,
    requestOptions: {
      componentRestrictions: {
        country: 'us',
      },
    },
  });

  return (
    <Autocomplete
      fullWidth
      open={showOptions}
      onOpen={() => { setShowOptions(!!search.trim()); }}
      onClose={() => setShowOptions(false)}
      componentsProps={{
        popper: {
          sx: {
            zIndex: '20000 !important',
          },
        },
      }}
      loading={!ready || status !== 'OK' || loading}
      getOptionLabel={(option) => option.description}
      onInputChange={(event, newValue) => {
        if (event?.type !== 'click') setValue(newValue);
      }}
      filterSelectedOptions
      options={data}
      value={selectedOption}
      isOptionEqualToValue={(option, value) => option.description === value.description}
      onChange={(_, newValue) => {
        analytics.track('Autocomplete Options Changed', {
          autocompleteName: autoCompleteTrackingName,
          value: newValue?.description ?? l.na,
        });

        setSelectedOption(newValue);
        setShowOptions(false);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeHolderLabel}
          InputProps={{ ...params.InputProps }}
        />
      )}
    />
  );
};

export type AutocompletePrediction = z.infer<typeof placeSchema>;

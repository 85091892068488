import {
  BoldTypography,
  formatNumberToCurrency, InfoTooltip, useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';

import { CalculatedRow, DetailRow } from './Row';
import { RowProps } from './types';
import { useProjections } from './useProjections';

export const OperatingIncome = ({ form }: RowProps) => {
  const l = useLabels();
  const projections = useProjections(form);

  return (
    <>
      <DetailRow
        rowID="operating-income"
        bottomBorder={false}
        columns={[
          {
            id: 'operating-income',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.operatingIncome}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.netOperatingIncome']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          ...projections.years.map(() => ({})),
        ]}
      />
      <CalculatedRow
        label={l.noi}
        values={projections.years.map((_, i) => formatNumberToCurrency(projections.calculations.getNOI(i) || 0, 0))}
      />
    </>
  );
};

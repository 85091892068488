import { useAnalytics } from 'lib';
import { useLabels } from 'ui';
import {
  FormControlLabel, Stack, Typography,
} from '@mui/material';

import { BedsBathsSelector } from './BedsBathsSelector';
import { BedsUseExactMatchCheckbox } from './styled';
import { SingleFilterProps } from '../props';

export const BedsBathsFilter = ({ filters, setFilters }: SingleFilterProps) => {
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Stack p={3} gap={3}>
      <Typography variant="body2">{l.numOfBedsBaths}</Typography>
      <Stack>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>{l.bedrooms}</Typography>
        <BedsBathsSelector
          isExactMatch={filters.bedsUseExactMatch}
          keyPrefix="beds"
          value={filters.minBeds}
          onClick={(value) => {
            analytics.track('Button Clicked', {
              buttonName: 'Min Beds',
              beds: value,
            });

            setFilters({
              minBeds: value,
            });
          }}
        />
        <Stack direction="row" alignItems="center" gap={1} mt={2} pl={3}>
          <FormControlLabel
            control={(
              <BedsUseExactMatchCheckbox
                size="small"
                value={filters.bedsUseExactMatch}
                checked={filters.bedsUseExactMatch}
                onChange={() => {
                  analytics.track('Checkbox Toggled', {
                    checkboxName: 'Beds Exact Match',
                    value: !filters.bedsUseExactMatch,
                  });

                  setFilters({
                    bedsUseExactMatch: !filters.bedsUseExactMatch,
                  });
                }}
              />
            )}
            label={l.useExactMatch}
          />
        </Stack>
      </Stack>
      <Stack>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>{l.bathRooms}</Typography>
        <BedsBathsSelector
          isExactMatch={false}
          keyPrefix="beds"
          value={filters.minBaths}
          onClick={(value) => {
            analytics.track('Button Clicked', {
              buttonName: 'Min Baths',
              baths: value,
            });

            setFilters({
              minBaths: value,
            });
          }}
        />
      </Stack>
    </Stack>
  );
};

import { Theme } from '@mui/material/styles';

import { Skin } from '../../layouts/types';

export const overrideAutocomplete = (theme: Theme, skin: Skin) => ({
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        boxShadow: theme.shadows[6],
        ...(skin === 'bordered' && { boxShadow: 'none', border: `1px solid ${theme.palette.divider}` }),
      },
    },
  },
});

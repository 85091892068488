import { lazy, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useIsMarketplaceEnabled, usePermissions } from 'lib';
import {
  MenuItem,
  PriceTagIcon,
  Store,
  useLabels,
} from 'ui';
import { Stack } from '@mui/material';

const MarketplaceIcon = lazy(async () => ({
  default: (await (import('ui'))).Marketplace,
}));

const menuIconSize = 22;

export const DrawerMenuItems = ({ onNavigate = () => { } }: { onNavigate: () => void }) => {
  const l = useLabels();
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: marketplaceEnabled, loading: loadingMarketplaceFF } = useIsMarketplaceEnabled();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  const marketplaceActive = pathname !== '/marketplace/activities' && pathname?.startsWith('/marketplace');

  if (loadingMarketplaceFF || isLoadingPermissions || !marketplaceEnabled) return null;

  return marketplaceEnabled && (
    <Stack>
      <MenuItem
        label={l['listings.menu.marketplace']}
        isActive={marketplaceActive}
        onClick={() => handleNavigate('/marketplace')}
        icon={(
          <Suspense fallback={<Store />}>
            <MarketplaceIcon size={20} invert={marketplaceActive} hoverable />
          </Suspense>
        )}
      />
      {/* "viewLeads" is talking about the PM dashboard deals page, so this is to avoid duplicates */}
      {!permissions?.viewLeads && (
        <MenuItem
          label={l['listings.activitiesTitle']}
          isActive={pathname === '/marketplace/activities'}
          onClick={() => handleNavigate('/marketplace/activities')}
          icon={<PriceTagIcon />}
        />
      )}
    </Stack>
  );
};

export const ProductMenuItems = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const { value: marketplaceEnabled, loading: loadingMarketplaceFF } = useIsMarketplaceEnabled();
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  if (loadingMarketplaceFF || !marketplaceEnabled || isLoadingPermissions) return null;

  return marketplaceEnabled && (
    <>
      <MenuItem
        label={l['listings.menu.marketplace']}
        isActive={pathname === '/marketplace'}
        onClick={() => handleNavigate('/marketplace')}
        icon={(
          <Suspense fallback={<Store />}>
            <MarketplaceIcon size={20} invert={pathname === '/marketplace'} hoverable />
          </Suspense>
        )}
      />
      {/* "viewLeads" is talking about the PM dashboard deals page, so this is to avoid duplicates */}
      {!permissions?.viewLeads && (
        <MenuItem
          label={l['listings.activitiesTitle']}
          isActive={pathname === '/marketplace/activities'}
          onClick={() => handleNavigate('/marketplace/activities')}
          icon={<PriceTagIcon size={menuIconSize} />}
        />
      )}
    </>
  );
};

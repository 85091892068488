import dayjs from 'dayjs';

import { ITransactionForm } from './types';
import {
  useCreateExpenseOneTime, useCreateExpenseRecurrent, useDeleteExpense, useUpdateExpense, useUpdateRecurringExpense,
} from '../../api/properties';

export const useUpdateOneTimeExpense = () => {
  const updateExpense = useUpdateExpense();

  return {
    isLoading: updateExpense.isLoading,
    mutateAsync: async (data: ITransactionForm) => {
      const amount = data.amount !== undefined ? data.amount : 0;
      const object = {
        id: data.id,
        amount: amount > 0 ? amount * -1 : amount,
        date: dayjs(data.dates.date).format('YYYY-MM-DD'),
        category: data.category,
        propertyId: data.propertyId,
      };
      return updateExpense.mutateAsync(object);
    },
  };
};

export const useUpdateAllRecurringExpenses = () => {
  const updateExpense = useUpdateRecurringExpense();

  return {
    isLoading: updateExpense.isLoading,
    mutateAsync: async (data: ITransactionForm) => {
      const amount = data.amount !== undefined ? data.amount : 0;
      const object = {
        id: data.id,
        amount: amount < 0 ? amount * -1 : amount,
        startDate: dayjs(data.dates.date).format('YYYY-MM-DD'),
        category: data.category,
        propertyId: data.propertyId,
      };
      return updateExpense.mutateAsync(object);
    },
  };
};

export const useCreateExpense = () => {
  const createOneTimeExpense = useCreateExpenseOneTime();
  const createRecurrentExpense = useCreateExpenseRecurrent();
  const deleteOneTimeExpense = useDeleteExpense();

  const handleBiAnnual = async (data: ITransactionForm) => {
    const amount = data.amount !== undefined ? data.amount : 0;

    const firstRes = await createOneTimeExpense.mutateAsync({
      propertyId: data.propertyId,
      category: data.category,
      date: dayjs(data.dates.firstPaymentDate).format('YYYY-MM-DD'),
      amount: amount > 0 ? amount * -1 : amount,
      type: 'expense',
    });

    try {
      await createOneTimeExpense.mutateAsync({
        propertyId: data.propertyId,
        category: data.category,
        date: dayjs(data.dates.secondPaymentDate).format('YYYY-MM-DD'),
        amount: amount > 0 ? amount * -1 : amount,
        type: 'expense',
      });
    } catch (err) {
      await deleteOneTimeExpense.mutateAsync(firstRes.data.ID);

      throw err;
    }
  };

  return {
    isLoading: createOneTimeExpense.isLoading || createRecurrentExpense.isLoading,
    mutateAsync: (data: ITransactionForm) => {
      const amount = data.amount !== undefined ? data.amount : 0;
      switch (data.type) {
        case 'one_time':
          return createOneTimeExpense.mutateAsync({
            propertyId: data.propertyId,
            category: data.category,
            date: dayjs(data.dates.date).format('YYYY-MM-DD'),
            amount: amount > 0 ? amount * -1 : amount,
            type: 'expense',
          });
        case 'bi_annual':
          return handleBiAnnual(data);
        case 'recurring':
          return createRecurrentExpense.mutateAsync({
            propertyId: data.propertyId,
            category: data.category,
            startDate: dayjs(data.dates.date).format('YYYY-MM-DD'),
            amount,
          });
        default:
          return Promise.reject(new Error(`Invalid transaction type: ${data.type}`));
      }
    },
  };
};

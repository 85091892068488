import { useState } from 'react';

import { useAnalytics } from 'lib';
import { MdClose } from 'react-icons/md';
import { BoldTypography, ScaleGraph, useLabels } from 'ui';
import {
  Box,
  Dialog, DialogContent, DialogTitle, IconButton,
  Stack, Tab,
  Tabs, Typography, useTheme,
} from '@mui/material';

import { ListingDetailsContainer } from './styled';
import { useToggle } from '../../../hooks/useToggle';
import { ListingProperty } from '../../../types/property';

enum ECrimeTypes {
  ALL = 'all',
  PROPERTY = 'property',
  VIOLENT = 'violent',
}

const labels = {
  all: ECrimeTypes.ALL,
  property: ECrimeTypes.PROPERTY,
  violent: ECrimeTypes.VIOLENT,
};

export const CrimeDetails = ({ property }: { property: ListingProperty }) => {
  const { crime } = property;
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const [selectedTab, setSelectedTab] = useState<ECrimeTypes>(ECrimeTypes.ALL);

  const [isOpen, toggle] = useToggle();

  const handleClick = () => {
    toggle();

    analytics.track('Button Clicked', {
      buttonName: 'Crime Details',
      listingId: property.id,
    });
  };

  if (!crime?.all.county) {
    return null;
  }

  return (
    <ListingDetailsContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          mt: 4,
          mb: 3,
        }}
      >
        <BoldTypography variant="body1">
          {l['listings.marketplace.crime.title']}
        </BoldTypography>
        <BoldTypography
          variant="body2"
          color="primary.main"
          onClick={handleClick}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          {l['listings.marketplace.crime.details']}
        </BoldTypography>
      </Stack>
      <Typography variant="body1" sx={{ mb: 8 }}>
        {l['listings.marketplace.crime.desc.part1']}
        <b>
          {` ${crime?.all.county}% `}
        </b>
        {l['listings.marketplace.crime.desc.part2']}
      </Typography>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={toggle}
        sx={{ zIndex: 1600 }}
        maxWidth="xs"
      >
        <DialogTitle
          component={Stack}
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          borderBottom={`1px solid ${theme.palette.divider}`}
          sx={{ p: 3 }}
        >
          <Typography variant="h6">
            {l['listings.marketplace.crime.title']}
          </Typography>
          <IconButton aria-label="Close" onClick={toggle} size="medium" color="secondary">
            <MdClose />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ transition: 'height 0.3s' }}>
          <Stack>
            <Tabs
              value={selectedTab}
              onChange={(_, value) => setSelectedTab(value)}
              centered
              sx={{
                mt: 1,
                mb: 3,
              }}
            >
              {Object.values(ECrimeTypes).map((k) => <Tab key={`crime-type-${k}`} label={k} value={labels[k]} />)}
            </Tabs>
            <Typography variant="body1">
              {l['listings.marketplace.crime.modal.county']}
            </Typography>
            <Typography variant="body2">
              {l['listings.marketplace.crime.desc.part1']}
              {` ${crime?.[selectedTab].county}% `}
              {l['listings.marketplace.crime.desc.part2']}
            </Typography>
            <Box mt={2} mb={8}>
              <ScaleGraph leftLabel={l.lowest} rightLabel={l.highest} percentage={crime?.[selectedTab].county as number} />
            </Box>
            <Typography variant="body1">
              {l['listings.marketplace.crime.modal.national']}
            </Typography>
            <Typography variant="body2">
              {l['listings.marketplace.crime.desc.part1']}
              {` ${crime?.[selectedTab].national}% `}
              {l['listings.marketplace.crime.desc.national.part2']}
            </Typography>
            <Box mt={2} mb={8}>
              <ScaleGraph leftLabel={l.lowest} rightLabel={l.highest} percentage={crime?.[selectedTab].national as number} />
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </ListingDetailsContainer>
  );
};

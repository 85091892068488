import dayjs from 'dayjs';
import { ExpenseCategory, sum } from 'lib';

import {
  Category, Property, Transaction, TransactionType,
} from '../../types';

export type PotentiallyIncompleteNumber = {
  value: number;
  incomplete: boolean;
  couldNotCalculate: boolean;
};

export const convertExpenseCategoryToCategory = (category: Category): ExpenseCategory => {
  switch (category) {
    case Category.PROPERTY_HOA:
      return ExpenseCategory.property_hoa;
    case Category.PROPERTY_INSURANCE:
      return ExpenseCategory.property_insurance;
    case Category.OTHER_EXPENSES:
      return ExpenseCategory.other;
    case Category.PROPERTY_TAX:
      return ExpenseCategory.property_tax;
    default:
      console.warn('Unknown category. settings "other"', category); // eslint-disable-line no-console
      return ExpenseCategory.other;
  }
};

export const transactionCategoryToDisplay = new Map([
  [Category.RENT, 'Rent'],
  [Category.PROPERTY_TAX, 'Property Tax'],
  [Category.MORTGAGE, 'Mortgage'],
  [Category.PROPERTY_INSURANCE, 'Insurance'],
  [Category.PROPERTY_HOA, 'HOA'],
  [Category.MANAGEMENT_FEE, 'Management Fee'],
  [Category.OTHER_EXPENSES, 'Other Expenses'],
  [Category.OTHER_INCOME, 'Other Income'],
  [Category.UTILITIES, 'Utilities'],
  [Category.LEGAL_AND_PROFESSIONAL_FEES, 'Prof. Fees'],
  [Category.LEASING_AND_MARKETING, 'Leasing & Marketing'],
  [Category.RENTAL_REGISTRATION, 'Rental Registration'],
  [Category.AUTO_AND_TRAVEL, 'Auto & Travel'],
  [Category.SUPPLIES, 'Supplies'],
  [Category.MAINTENANCE, 'Maintenance'],
  [Category.CAPITAL_EXPENDITURE, 'Capital Expenditure'],
]);

export const createSumTransactions = (
  predicate: (value: Transaction, index: number, array: Transaction[]) => unknown,
) => (
  properties: Pick<Property, 'transactions'>[],
  from: dayjs.Dayjs | null,
  to: dayjs.Dayjs | null = dayjs()
    .endOf('day'),
) => sum(properties.map(({ transactions }) => {
  const filteredByPredicateTransactions = transactions.filter(predicate);
  const filteredByDatesTransactions = (!from && !to) ? filteredByPredicateTransactions // if no filter - return the whole array.
    : filteredByPredicateTransactions.filter(({ transactionDate }) => {
      const dateObj = dayjs(transactionDate);
      return dateObj.isBetween(from, to, null, '[]');
    });
  return sum(filteredByDatesTransactions.map(({ amount }) => amount));
}));

export const sumExpenseTransactions = createSumTransactions(
  ({ type }) => type === TransactionType.EXPENSE,
);

export const sumIncomeTransactions = createSumTransactions(
  ({ type }) => type === TransactionType.INCOME,
);

const createSumMonthlyTransactions = (sumFunc: typeof sumIncomeTransactions | typeof sumExpenseTransactions) => (
  properties: Pick<Property, 'transactions'>[],
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
): number[] => {
  const months: dayjs.Dayjs[] = [];
  let clonedFrom = from.clone();
  while (clonedFrom.isSameOrBefore(to)) {
    months.push(clonedFrom);
    clonedFrom = clonedFrom.add(1, 'month');
  }

  return months.map((month) => sumFunc(
    properties,
    month.startOf('month')
      .startOf('day'),
    month.endOf('month')
      .endOf('day'),
  ));
};

export const sumMonthlyExpenseTransactions = createSumMonthlyTransactions(sumExpenseTransactions);
export const sumMonthlyIncomeTransactions = createSumMonthlyTransactions(sumIncomeTransactions);

export const getCategoryDisplayName = (category: Category): string => transactionCategoryToDisplay.get(category) ?? 'Misc';

export const calcYearlyExpenseByCategory = (property: Pick<Property, 'transactions'>): Map<Category, number> => {
  const yearlyExpenseByCategory: Map<Category, number> = new Map();
  const fromDate = dayjs()
    .subtract(1, 'year').endOf('day');
  const toDate = dayjs();
  property.transactions.forEach((transaction) => {
    const dateObj = dayjs(transaction.transactionDate);
    if (!dateObj.isBetween(fromDate, toDate, null, '[]')) {
      return;
    }
    if (transaction.type !== TransactionType.EXPENSE) {
      return;
    }
    const { category, amount } = transaction;
    const currentAmount = yearlyExpenseByCategory.get(category) ?? 0;
    yearlyExpenseByCategory.set(category, currentAmount + amount);
  });
  return yearlyExpenseByCategory;
};

export const getMissingCategories = (expenseByCategory: Map<Category, number>, extraCategories: Category[] = []): Category[] => {
  const missingCategories: Category[] = [];
  [Category.PROPERTY_INSURANCE, Category.PROPERTY_TAX, ...extraCategories].forEach((category) => {
    if ((expenseByCategory.get(category) || 0) === 0) {
      missingCategories.push(category);
    }
  });

  return missingCategories;
};

export const hasMissingCategoriesLastYear = (
  properties: Pick<Property, 'transactions'>[],
  extraCategories: Category[] = [],
): boolean => properties.some(
  (property) => {
    const expenseByCategory = calcYearlyExpenseByCategory(property);
    return getMissingCategories(expenseByCategory, extraCategories).length > 0;
  },
);

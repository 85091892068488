import { formatDecimalToPercentage, useLabels } from 'ui';

import { CalculatedRow } from './Row';
import { Form } from './types';
import { useProjections } from './useProjections';

export const CapRate = ({ form }: { form: Form }) => {
  const l = useLabels();
  const projections = useProjections(form);

  return (
    <CalculatedRow
      label={l.capRate}
      bottomBorder={false}
      values={projections.years.map((_, i) => formatDecimalToPercentage(projections.calculations.getCapRate(i) || 0, 2))}
    />
  );
};

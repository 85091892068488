import { OwnerLeadsWorkflowStatus } from 'lib';
import { Chip, replaceUnderscoreWithSpace } from 'ui';

const statusToColor = {
  [OwnerLeadsWorkflowStatus.WON]: 'success',
  [OwnerLeadsWorkflowStatus.LOST]: 'error',
  [OwnerLeadsWorkflowStatus.NEW]: 'info',
  [OwnerLeadsWorkflowStatus.IN_PROGRESS]: 'warning',
} as const;

export const LeadStatusChip = ({ status } : { status: OwnerLeadsWorkflowStatus }) => (
  <Chip
    color={statusToColor[status]}
    label={replaceUnderscoreWithSpace(status)}
    size="small"
    sx={{
      width: 120,
    }}
  />
);

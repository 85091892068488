import { MdSell } from 'react-icons/md';
import {
  Avatar, EmptyFullPageContainer, FallbackSpinner, PageTitle, useLabels,
} from 'ui';
import {
  Stack, Typography,
} from '@mui/material';

import { ActivityRow } from './activity';
import { useListActivities } from '../../api/activities';
import { Activity } from '../../types/activity';

export const Activities = () => {
  const l = useLabels();

  const {
    data: activities,
    isLoading: activitiesLoading,
  } = useListActivities();

  if (activitiesLoading || !activities) {
    return (
      <FallbackSpinner />
    );
  }
  const pagedActivities: Activity[] = [];
  activities.pages.forEach((activityPage) => {
    pagedActivities.push(...activityPage.activities);
  });

  if (activities && pagedActivities.length === 0) {
    return (
      <EmptyFullPageContainer>
        <Avatar
          skin="light"
          variant="circular"
          color="primary"
          sx={{
            mr: 3, mb: 3, width: 72, height: 72,
          }}
        >
          <MdSell size={40} />
        </Avatar>
        <Typography variant="h6">
          {l['listings.activitiesEmptyTitle']}
        </Typography>
        <Typography variant="body2">
          {l['listings.activitiesEmptyDescription']}
        </Typography>
      </EmptyFullPageContainer>
    );
  }

  return (
    <Stack p={3}>
      <PageTitle title={l['listings.activitiesTitle']} />
      <Stack gap={2}>
        {pagedActivities.map((activity) => (
          <ActivityRow key={activity.id} activity={activity} />
        ))}
      </Stack>
    </Stack>
  );
};

import { SemiBoldTypography, useLabels } from 'ui';
import {
  LinearProgress,
  linearProgressClasses,
  Stack,
  useTheme,
} from '@mui/material';

import { OnboardingProgressProps } from './types';

export const OnboardingProgress = ({ amount, total }: OnboardingProgressProps) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Stack position="sticky" top="0" sx={{ background: theme.palette.background.paper, zIndex: 1600 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 3, backgroundColor: theme.palette.common.white }}
      >
        <SemiBoldTypography variant="subtitle1" color="text.primary">
          {l.property}
          {' '}
          {amount}
          /
          {total}
          :
          {' '}
          {l.reviewDetails}
        </SemiBoldTypography>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={(amount / total) * 100}
        data-testid="onboarding-progress"
        sx={{
          backgroundColor: theme.palette.common.white,
          borderRadius: 0,
          [`& .${linearProgressClasses.bar1Determinate}`]: {
            borderRadius: 0,
          },
          [`& .${linearProgressClasses.bar}`]: {
            transition: 'transform 0.6s linear',
          },
        }}
      />
    </Stack>
  );
};

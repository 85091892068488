import {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';

import { Confetti } from './Confetti';

const ConfettiContext = createContext({
  trigger: () => {},
});

export const useConfetti = () => useContext(ConfettiContext);

export const ConfettiProvider = ({ children }: { children: ReactNode }) => {
  const [run, setRun] = useState(false);

  const value = useMemo(() => ({
    trigger: () => {
      setRun(true);
    },
  }), [run, setRun]);

  return (
    <ConfettiContext.Provider value={value}>
      {children}
      <Confetti run={run} onConfettiComplete={() => setRun(false)} />
    </ConfettiContext.Provider>
  );
};

import {
  ListingItemTag,
  ListRecommendationsByOwnerQuery,
  MarketplaceProperty,
  MarketPlacePropertyStatus,
  SearchableMarketplacePropertyListingItemFilterInput,
  SearchableMarketplacePropertyListingItemSortableFields as SearchableFields,
  SearchableMarketplacePropertyListingItemSortInput,
  SearchableSortDirection,
  SearchMarketplacePropertyListingItemsQuery,
} from 'lib';
import { z } from 'zod';

export const priceOptions = [
  50_000,
  100_000,
  150_000,
  200_000,
  250_000,
  300_000,
  350_000,
  400_000,
  450_000,
  500_000,
];

export const bedsBathsOptions = [1, 2, 3, 4, 5];

export const sortOptions = [
  'top-picks',
  'cap-rate-desc',
  'gross-yield-desc',
  'asking-price-desc',
  'asking-price-asc',
] as const;

export type SortOption = typeof sortOptions[number];

export const sortOptionToBuildFunction: Record<SortOption,
() => SearchableMarketplacePropertyListingItemSortInput[]> = {
  'top-picks': () => [
    { field: SearchableFields.categoryOrder, direction: SearchableSortDirection.desc },
    { field: SearchableFields.isManagedByThePM, direction: SearchableSortDirection.desc },
    { field: SearchableFields.canBeManagedByThePM, direction: SearchableSortDirection.desc },
    { field: SearchableFields.capRate, direction: SearchableSortDirection.desc },
  ],
  'cap-rate-desc': () => [{ field: SearchableFields.capRate, direction: SearchableSortDirection.desc }],
  'gross-yield-desc': () => [{ field: SearchableFields.grossYield, direction: SearchableSortDirection.desc }],
  'asking-price-desc': () => [{ field: SearchableFields.askingPrice, direction: SearchableSortDirection.desc }],
  'asking-price-asc': () => [{ field: SearchableFields.askingPrice, direction: SearchableSortDirection.asc }],
};

const predefinedFilters = ['all', '1%_rule', 'managed_by_pm'] as const;
const categories = ['wholesale', 'turnkey', 'new_construction', 'internal'] as const;

export type PredefinedFilter = typeof predefinedFilters[number];
export type Category = typeof categories[number];

export const filterSchema = z.object({
  predefinedFilters: z.array(z.enum(predefinedFilters)).catch(['all']),
  minPrice: z.coerce.number().min(0).max(priceOptions[priceOptions.length - 1]).catch(0),
  maxPrice: z.coerce.number().min(0).max(priceOptions[priceOptions.length - 1]).catch(0),
  minBeds: z.coerce.number().min(0).max(bedsBathsOptions[bedsBathsOptions.length - 1]).catch(0),
  minBaths: z.coerce.number().min(0).max(bedsBathsOptions[bedsBathsOptions.length - 1]).catch(0),
  bedsUseExactMatch: z.enum(['true', 'false']).transform((value) => value === 'true').catch(false),
  sortBy: z.enum(sortOptions).catch('top-picks'),
  selectedSearchOptions: z.array(z.string()).catch([]),
  categories: z.array(z.enum(categories)).catch([]),
  ids: z.array(z.string()).catch([]),
});

export type Filters = z.infer<typeof filterSchema>;

const getTagsByPredefinedFilter = (filters: Filters['predefinedFilters']) => {
  const tags: ListingItemTag[] = [];

  if (filters.includes('1%_rule')) tags.push(ListingItemTag.one_percent_rule);
  if (filters.includes('managed_by_pm')) tags.push(ListingItemTag.managed_by_pm);

  return tags;
};

export const getPropertyIDsFilter = (propertyIDs: string[]): SearchableMarketplacePropertyListingItemFilterInput => ({
  or: propertyIDs.map((id) => ({ marketplacePropertyListingItemsId: { eq: id } })),
});

export const buildFilters = (
  filters: Filters,
): SearchableMarketplacePropertyListingItemFilterInput => {
  const filterInput: SearchableMarketplacePropertyListingItemFilterInput = {
    status: { eq: MarketPlacePropertyStatus.active },
  };

  const ands: SearchableMarketplacePropertyListingItemFilterInput[] = [];

  if (filters.predefinedFilters.length) {
    const tags = getTagsByPredefinedFilter(filters.predefinedFilters);

    if (tags.length) ands.push({ or: tags.map((tag) => ({ tags: { match: tag } })) });
  }

  if (filters.minPrice) filterInput.askingPrice = { gte: filters.minPrice };

  if (filters.maxPrice) filterInput.askingPrice = { ...filterInput.askingPrice, lte: filters.maxPrice };

  if (filters.minBeds) {
    filterInput.bedrooms = filters.bedsUseExactMatch ? { eq: filters.minBeds } : { gte: filters.minBeds };
  }

  if (filters.minBaths) filterInput.bathrooms = { gte: filters.minBaths };

  if (filters.selectedSearchOptions.length) {
    ands.push({ or: filters.selectedSearchOptions.map((pa) => ({ partialAddresses: { matchPhrase: pa } })) });
  }

  if (filters.categories.length) ands.push({ or: filters.categories.map((category) => ({ category: { eq: category } })) });

  if (filters.ids.length) {
    ands.push(getPropertyIDsFilter(filters.ids));
  }

  if (ands.length) filterInput.and = ands;

  return filterInput;
};

// API.ts doesn't include "property" so we have to add it here
type ListingItem = NonNullable<
SearchMarketplacePropertyListingItemsQuery['searchMarketplacePropertyListingItems']>['items'][number] & {
  property?: MarketplaceProperty | null;
};

type ExtendedItemsArray = Array<ListingItem | null>;

export type FavoriteListingItemsQuery = Omit<
SearchMarketplacePropertyListingItemsQuery, 'searchMarketplacePropertyListingItems'
> & {
  favoriteListingItems?: Omit<
  SearchMarketplacePropertyListingItemsQuery['searchMarketplacePropertyListingItems'], 'items'
  > & {
    nextToken?: string | null,
    items: ExtendedItemsArray | null;
  };
};

export type ListingItemsQuery = Omit<SearchMarketplacePropertyListingItemsQuery, 'searchMarketplacePropertyListingItems'> & {
  listingItems?: Omit<
  SearchMarketplacePropertyListingItemsQuery['searchMarketplacePropertyListingItems'], 'items'
  > & {
    nextToken?: string | null,
    items: ExtendedItemsArray | null;
  };
};

// API.ts doesn't include "property" so we have to add it here
type MarketplacePropertyRecommendation = NonNullable<
ListRecommendationsByOwnerQuery['listRecommendationsByOwner']>['items'][number] & {
  property?: MarketplaceProperty | null;
};

type ExtendedRecommendationsArray = Array<MarketplacePropertyRecommendation | null>;

export type RecommendationsQuery = Omit<ListRecommendationsByOwnerQuery, 'listRecommendationsByOwner'> & {
  listRecommendationsByOwner?: Omit<
  ListRecommendationsByOwnerQuery['listRecommendationsByOwner'], 'items'
  > & {
    nextToken?: string | null,
    items: ExtendedRecommendationsArray | null;
  };
};

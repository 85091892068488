import { forwardRef, ReactNode } from 'react';

import { MdClose } from 'react-icons/md';
import { Box, useTheme } from '@mui/material';

import { modalStyle } from './yesOrNoModalSyle';

export const ModalContainer = forwardRef(({ children, onClose }: { children: ReactNode, onClose: () => void }, ref) => {
  const theme = useTheme();
  return (
    <Box sx={modalStyle} ref={ref}>
      <Box
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 4,
          right: 4,
          p: 2,
          cursor: 'pointer',
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        }}
      >
        <MdClose style={{ width: '24px', height: '24px' }} />
      </Box>

      {children}
    </Box>
  );
});

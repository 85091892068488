import { Theme } from '@mui/material/styles';

export const overrideTypography = (theme: Theme) => ({
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: theme.spacing(2),
      },
    },
  },
});

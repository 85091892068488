import { ReactNode } from 'react';

import { BoldTypography, SemiBoldTypography } from 'ui';
import {
  Grid, Stack, SxProps, useTheme,
} from '@mui/material';

import { Form } from '../types';

export const DetailRow = ({
  form, rowID, columns, totalRow = false, bottomBorder = true, alignTopOnEdit = false,
}: {
  form: Form,
  rowID: string,
  bottomBorder?: boolean,
  totalRow?: boolean,
  columns: { content?: ReactNode, id?: string }[]
  alignTopOnEdit?: boolean
}) => {
  const theme = useTheme();
  const editMode = form.watch('editMode');

  const getBackground = (index: number) => {
    if (totalRow) {
      return '#EFEBF2';
    }

    if (index === 1) {
      return '#F7F7FA';
    }

    return 'transparent';
  };

  const getSx = (index: number): SxProps => ({
    background: getBackground(index),
  });

  const firstColumnWidth = 4;
  const totalWidth = 12;
  const otherColumnsWidth = (totalWidth - firstColumnWidth) / 3;

  // in edit mode, the second column (idx 1) should be wider than the others (idx 2 and 3)
  const editModeMultiplier = editMode ? 1.2 : 1;

  const columnToWidth: Record<number, number> = {
    0: firstColumnWidth,
    1: otherColumnsWidth * editModeMultiplier,
    2: (totalWidth - firstColumnWidth - otherColumnsWidth * editModeMultiplier) / 2,
    3: (totalWidth - firstColumnWidth - otherColumnsWidth * editModeMultiplier) / 2,
  };

  return (
    <Grid container borderBottom={bottomBorder ? `1px solid ${theme.palette.grey[300]}` : undefined}>
      {columns.map((column, index) => (
        <Grid
          item
          xs={columnToWidth[index] ?? otherColumnsWidth}
          // eslint-disable-next-line react/no-array-index-key
          key={`row${rowID}-${column.id}-${index}`}
          py={1}
          sx={getSx(index)}
        >
          <Stack
            px={4}
            py={editMode ? 1.5 : 1}
            justifyContent={alignTopOnEdit && editMode ? 'flex-start' : 'center'}
            alignItems={index === 0 ? 'flex-start' : 'flex-end'}
            height="100%"
          >
            {column.content}
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export const CalculatedRow = ({
  form,
  label,
  year1,
  year2,
  year3,
  bottomBorder = true,
  totalRow = false,
}: {
  form: Form,
  label: string,
  year1: string,
  year2: string,
  year3: string,
  bottomBorder?: boolean,
  totalRow?: boolean,
}) => (
  <DetailRow
    form={form}
    rowID={label}
    bottomBorder={bottomBorder}
    totalRow={totalRow}
    columns={[
      {
        id: label,
        content: (
          <SemiBoldTypography variant="body1">
            {label}
          </SemiBoldTypography>
        ),
      },
      {
        id: `${label}-year-1`,
        content: (
          <BoldTypography variant="body1" color="success.main">
            {year1}
          </BoldTypography>
        ),
      },
      {
        id: `${label}-year-2`,
        content: (
          <BoldTypography variant="body1" color="success.main">
            {year2}
          </BoldTypography>
        ),
      },
      {
        id: `${label}-year-3`,
        content: (
          <BoldTypography variant="body1" color="success.main">
            {year3}
          </BoldTypography>
        ),
      },
    ]}
  />
);

import { ManagedPropertyType, ManagePropertyAreas, useAnalytics } from 'lib';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ControlledFormattedNumberField, useLabels } from 'ui';
import {
  Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography, useTheme,
} from '@mui/material';

import { GrowthPolicyAutocompleteField } from './GrowthPolicyAutocompleteField';
import { GrowthPolicyBooleanField } from './GrowthPolicyBooleanField';
import { StyledField } from './styled';
import { GrowthPolicyData } from '../../api/suggested-partners/forms';

export const OwnerLeadProfileForm = ({ form }: { form: UseFormReturn<GrowthPolicyData> }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const areaPropertiesManagedOptions = [
    {
      label: l['partners.wizard.question.areaPropertiesManaged.onlyInMyCurrentMarket'],
      value: ManagePropertyAreas.ONLY_IN_MY_CURRENT_MARKET,
    },
    {
      label: l['partners.wizard.question.areaPropertiesManaged.openToExpandToOtherMarkets'],
      value: ManagePropertyAreas.OPEN_TO_EXPAND_TO_OTHER_MARKETS,
    },
    {
      label: l.iDontMind,
      value: ManagePropertyAreas.DONT_MIND,
    },
  ];

  const typeOfPropertiesManagedOptions = [
    { label: l['partners.wizard.question.typeOfPropertiesManaged.singleFamily'], value: ManagedPropertyType.SINGLE_FAMILY },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.townhome'], value: ManagedPropertyType.TOWNHOME },
    {
      label: l['partners.wizard.question.typeOfPropertiesManaged.smallMultiFamily'],
      value: ManagedPropertyType.SMALL_MULTI_FAMILY,
    },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.multiFamily'], value: ManagedPropertyType.MULTI_FAMILY },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.condo'], value: ManagedPropertyType.CONDO },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.section8'], value: ManagedPropertyType.SECTION_8 },
    { label: l['partners.wizard.question.typeOfPropertiesManaged.commercial'], value: ManagedPropertyType.COMMERCIAL },
    { label: l.all, value: ManagedPropertyType.ALL },
  ];

  return (
    <Stack gap={5}>
      <Stack sx={{ borderRadius: '10px', border: `1px solid ${theme.palette.divider}` }}>
        <StyledField id="type-of-properties-managed">
          <Typography variant="body1">
            {l['partners.wizard.question.typeOfPropertiesManaged']}
          </Typography>
          <GrowthPolicyAutocompleteField
            name="typeOfPropertiesManaged"
            form={form}
            options={typeOfPropertiesManagedOptions}
            placeholder={l.pleaseSpecify}
            normalizeValues={(values, details) => {
              if (details?.option.value === ManagedPropertyType.ALL) {
                return [ManagedPropertyType.ALL];
              }

              return values.map((o) => o.value).filter((v) => v !== ManagedPropertyType.ALL);
            }}
          />
        </StyledField>
        <Divider />
        <StyledField id="areas-properties-managed">
          <Typography variant="body1">
            {l['partners.wizard.question.areaPropertiesManaged']}
          </Typography>
          <Controller
            name="areaPropertiesManaged"
            control={form.control}
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="areaPropertiesManaged">
                  {l['partners.wizard.question.areaPropertiesManaged.placeholder']}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="areaPropertiesManaged"
                  label={l['partners.wizard.question.areaPropertiesManaged.placeholder']}
                  error={!!form.formState.errors.areaPropertiesManaged}
                  {...field}
                  onChange={(e, ...args) => {
                    field.onChange(e, ...args);

                    if (e.target.value) form.trigger('areaPropertiesManaged');
                  }}
                  value={field.value ?? ''}
                >
                  <MenuItem value="" disabled>{l['partners.wizard.question.areaPropertiesManaged.placeholder']}</MenuItem>
                  {areaPropertiesManagedOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {form.formState.errors.areaPropertiesManaged && (
                  <FormHelperText error>
                    {form.formState.errors.areaPropertiesManaged?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </StyledField>
        <Divider />
        <StyledField id="minimum-rent-amount-from-properties-with-to-manage">
          <Typography variant="body1">
            {l['partners.wizard.question.minimumRentAmountFromPropertiesWishToManage']}
          </Typography>
          <GrowthPolicyBooleanField
            invert
            name="dontCareAboutMinimumRentAmountFromPropertiesWishToManage"
            form={form}
            yesLabel={l.iDontMind}
            noLabel={l.enterAmount}
            collapsibleContent={(
              <ControlledFormattedNumberField
                name="minimumRentAmountFromPropertiesWishToManage"
                label={l.amount}
                control={form.control}
                fullWidth
                size="small"
                useExternalValue
                externalValue={form.watch('minimumRentAmountFromPropertiesWishToManage')}
                error={!!form.formState.errors.minimumRentAmountFromPropertiesWishToManage}
                helperText={form.formState.errors.minimumRentAmountFromPropertiesWishToManage?.message}
                onValueChange={(val) => {
                  if (typeof val === 'number') form.trigger('minimumRentAmountFromPropertiesWishToManage');
                }}
                onBlur={() => {
                  analytics.track('Input Changed', {
                    inputName: 'Referral Program - Minimum Rent Amount',
                    value: form.getValues('minimumRentAmountFromPropertiesWishToManage') ?? 0,
                  });
                }}
              />
            )}
            showCollapsibleContent={form.watch('dontCareAboutMinimumRentAmountFromPropertiesWishToManage') === false}
          />
        </StyledField>
        <Divider />
        <StyledField id="minimum-home-value-for-properties-with-to-manage">
          <Typography variant="body1">
            {l['partners.wizard.question.minimumHomeValueForPropertiesWishToManage']}
          </Typography>
          <GrowthPolicyBooleanField
            invert
            name="dontCareAboutMinimumHomeValueForPropertiesWishToManage"
            form={form}
            yesLabel={l.iDontMind}
            noLabel={l.enterAmount}
            collapsibleContent={(
              <ControlledFormattedNumberField
                name="minimumHomeValueForPropertiesWishToManage"
                label={l.amount}
                control={form.control}
                fullWidth
                size="small"
                useExternalValue
                externalValue={form.watch('minimumHomeValueForPropertiesWishToManage')}
                error={!!form.formState.errors.minimumHomeValueForPropertiesWishToManage}
                helperText={form.formState.errors.minimumHomeValueForPropertiesWishToManage?.message}
                onValueChange={(val) => {
                  if (typeof val === 'number') form.trigger('minimumHomeValueForPropertiesWishToManage');
                }}
                onBlur={() => {
                  analytics.track('Input Changed', {
                    inputName: 'Referral Program - Minimum Home Value',
                    value: form.getValues('minimumHomeValueForPropertiesWishToManage') ?? 0,
                  });
                }}
              />
            )}
            showCollapsibleContent={form.watch('dontCareAboutMinimumHomeValueForPropertiesWishToManage') === false}
          />
        </StyledField>
      </Stack>
      <Typography variant="body2" color="secondary" sx={{ px: { xs: 3, md: 10 }, textAlign: 'center' }}>
        {l['partners.wizard.step2.disclaimer']}
      </Typography>
    </Stack>
  );
};

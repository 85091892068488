import { useState } from 'react';

import { Question } from 'lib';
import {
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

export const QuestionNumber = ({
  question,
  id,
  onChange,
}: { id: string, question: Question, onChange: (id: string, answer: string) => void }) => {
  const theme = useTheme();

  const [answer, setAnswer] = useState<string>(question.answer);

  return (
    <Stack
      justifyContent="space-between"
      p={3}
      sx={{
        backgroundColor: theme.palette.common.white,
        height: '100%',
      }}
    >
      <Stack sx={{ height: '100%', width: '100%' }}>
        <Stack pl={2} direction="row" mt={2} mb={2}>
          <Typography variant="h5">
            {question.name}
          </Typography>
        </Stack>
        <Stack flexDirection="row" flexGrow={1}>
          <Stack width="30%">
            <Typography variant="body2">
              {question.description}
            </Typography>
            <Stack>
              <TextField
                minRows={2}
                type="number"
                value={answer}
                onChange={(event) => {
                  onChange(id, event.target.value);
                  setAnswer(event.target.value);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAnalytics, useIsLeadsListViewEnabled, useIsReferralLeadsTabEnabled } from 'lib';
import { IoMdPeople, IoMdPricetag } from 'react-icons/io';
import { MdCenterFocusWeak, MdMoney } from 'react-icons/md';
import {
  EmptyFullPageContainer,
  PageTitle,
  Spacer, SpinnerWithLogo, Tab, Tabs,
  useLabels,
} from 'ui';
import {
  Box,
  Divider,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import { BuyBoxLeads } from './BuyBoxLeads';
import { BuyerLeads } from './BuyerLeads';
import { OwnerLeads } from './OwnerLeads';
import { SellerLeads } from './SellerLeads';
import {
  useListBuyBoxLeads, useListBuyerLeads, useListOwnerLeads, useListSellerLeads,
} from '../../api/workflows';

const tabValues = ['referrals', 'sellers', 'buyers', 'buybox'] as const;
type TabValue = typeof tabValues[number];

const VIEW_STORAGE_KEY = 'isLeadsListViewEnabled';

const loadViewSettings = (): Record<TabValue, boolean> | null => {
  const settings = localStorage.getItem(VIEW_STORAGE_KEY);

  if (!settings) return null;

  return JSON.parse(settings);
};

const saveViewSettings = (settings: Record<TabValue, boolean>) => {
  localStorage.setItem(VIEW_STORAGE_KEY, JSON.stringify(settings));
};

export const Leads = () => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [isKanbanViewEnabled, setIsKanbanViewEnabled] = useState<Record<TabValue, boolean>>(loadViewSettings() ?? {
    referrals: true,
    sellers: false,
    buyers: false,
    buybox: false,
  });
  const { value: isReferralLeadsTabEnabled, loading: loadingIsReferralLeadsTabEnabled } = useIsReferralLeadsTabEnabled();
  const { value: isLeadsListViewEnabled, loading: loadingIsLeadsListViewEnabled } = useIsLeadsListViewEnabled();

  const { data: buyerLeadPages, isLoading: loadingBuyerLeads } = useListBuyerLeads();
  const { data: sellerLeadPages, isLoading: loadingSellerLeads } = useListSellerLeads();
  const { data: buyBoxLeadPages, isLoading: loadingBuyBoxLeads } = useListBuyBoxLeads();
  const { data: ownerLeadPages, isLoading: loadingOwnerLeads } = useListOwnerLeads();

  const buyerLeads = useMemo(() => buyerLeadPages?.pages.flatMap((page) => page.leads) ?? [], [buyerLeadPages]);
  const sellerLeads = useMemo(() => sellerLeadPages?.pages.flatMap((page) => page.leads) ?? [], [sellerLeadPages]);
  const buyBoxLeads = useMemo(() => buyBoxLeadPages?.pages.flatMap((page) => page.leads) ?? [], [buyBoxLeadPages]);
  const ownerLeads = useMemo(() => ownerLeadPages?.pages.flatMap((page) => page.leads) ?? [], [ownerLeadPages]);

  const activeTab: TabValue | null = useMemo(() => {
    if (loadingIsReferralLeadsTabEnabled) return null;

    let tabParam = urlSearchParams.get('tab') as TabValue;

    if (tabParam === 'referrals' && !isReferralLeadsTabEnabled) {
      tabParam = 'sellers';

      setUrlSearchParams({ tab: 'sellers' });
    }

    if (tabValues.includes(tabParam)) return tabParam;

    // return tab with most leads
    const tabs: [TabValue, number][] = [
      ['referrals', ownerLeads.length],
      ['sellers', sellerLeads.length],
      ['buyers', buyerLeads.length],
      ['buybox', buyBoxLeads.length],
    ];

    tabs.sort((a, b) => b[1] - a[1]);

    return tabs[0][0];
  }, [
    urlSearchParams,
    buyerLeads.length,
    sellerLeads.length,
    buyBoxLeads.length,
    isReferralLeadsTabEnabled,
    loadingIsReferralLeadsTabEnabled,
  ]);

  const sellers = useMemo(
    () => <SellerLeads isKanbanViewEnabled={isKanbanViewEnabled.sellers} />,
    [sellerLeads, isKanbanViewEnabled.sellers],
  );
  const buyers = useMemo(
    () => <BuyerLeads isKanbanViewEnabled={isKanbanViewEnabled.buyers} />,
    [buyerLeads, isKanbanViewEnabled.buyers],
  );
  const buyBox = useMemo(
    () => <BuyBoxLeads isKanbanViewEnabled={isKanbanViewEnabled.buybox} />,
    [buyBoxLeads, isKanbanViewEnabled.buybox],
  );
  const referrals = useMemo(
    () => <OwnerLeads isKanbanViewEnabled={isKanbanViewEnabled.referrals} />,
    [ownerLeads, isKanbanViewEnabled.referrals],
  );

  const isLoading = (
    loadingBuyerLeads || loadingSellerLeads || loadingBuyBoxLeads
     || loadingOwnerLeads || loadingIsReferralLeadsTabEnabled || loadingIsLeadsListViewEnabled
  );

  const handleSwitchChange = (newValue: boolean) => {
    analytics.track('Switch Toggled', {
      value: newValue ? 'Kanban View' : 'List View',
      switchName: 'Leads View',
    });

    const settings = {
      ...isKanbanViewEnabled,
      [activeTab!]: newValue,
    };

    setIsKanbanViewEnabled(settings);
    saveViewSettings(settings);
  };

  const tabs: Tab<TabValue>[] = [
    isReferralLeadsTabEnabled && {
      label: l.referrals,
      value: 'referrals',
      icon: <IoMdPeople size={18} />,
      badgeContent: ownerLeads.length,
    },
    {
      label: l.sellers,
      value: 'sellers',
      icon: <IoMdPricetag size={18} style={{ transform: 'scaleX(-1)' }} />,
      badgeContent: sellerLeads.length,
    },
    {
      label: l.buyers,
      value: 'buyers',
      icon: <MdMoney size={18} />,
      badgeContent: buyerLeads.length,
    },
    {
      label: l.buyBox,
      value: 'buybox',
      icon: <MdCenterFocusWeak size={18} />,
      badgeContent: buyBoxLeads.length,
    },
  ];

  return (
    <Stack height="100%" p={3}>
      <PageTitle title={l.leads} />
      <Spacer spacing={2} />
      <Paper component={Stack} elevation={4} sx={{ flexGrow: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
          <Tabs
            isLoading={isLoading}
            tabs={tabs}
            activeTab={activeTab}
            setTab={(value) => setUrlSearchParams({ tab: value })}
          />
          {isLeadsListViewEnabled && (
            <Typography variant="body2" color="text.secondary">
              {activeTab && isKanbanViewEnabled[activeTab] ? l.kanbanView : l.listView}
              <Switch
                color="primary"
                checked={!activeTab || isKanbanViewEnabled[activeTab]}
                onChange={(_, newValue) => handleSwitchChange(newValue)}
                disabled={isLoading}
              />
            </Typography>
          )}
        </Stack>
        <Divider sx={{ mt: 0, mb: 6 }} />
        <Box position="relative" flexGrow={1} sx={{ overflow: 'hidden' }} px={5}>
          {isLoading && (
            <EmptyFullPageContainer>
              <SpinnerWithLogo />
            </EmptyFullPageContainer>
          )}
          {!isLoading && (
            <>
              {isReferralLeadsTabEnabled && activeTab === 'referrals' && referrals}
              {activeTab === 'sellers' && sellers}
              {activeTab === 'buyers' && buyers}
              {activeTab === 'buybox' && buyBox}
            </>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

import { Theme } from '@mui/material/styles';

export const overrideDivider = (theme: Theme) => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        margin: `${theme.spacing(2)} 0`,
      },
    },
  },
});

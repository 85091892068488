export const PropertiesIcon = ({ size = 24 }: { size?: number }) => {
  const clipPathID = `clip-path-${Math.random()}`;
  const clipPathID2 = `clip-path-${Math.random()}`;

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipPathID2})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 0L0 4.27799V10H9V4.27799L4.5 0ZM4.5 2L7.44 4.78024V8.45H1.5V4.78024L4.5 2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 10L0 14.278V20H9V14.278L4.5 10ZM4.5 12L7.44 14.7802V18.45H1.5V14.7802L4.5 12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 0L11 4.27799V10H20V4.27799L15.5 0ZM15.5 2L18.44 4.78024V8.45H12.5V4.78024L15.5 2Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 10L11 14.278V20H20V14.278L15.5 10Z"
          fill={`url(#${clipPathID})`}
        />
      </g>
      <defs>
        <linearGradient
          id={clipPathID}
          x1="11.6"
          y1="20"
          x2="18.2899"
          y2="13.1764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F24476" />
          <stop offset="1" stopColor="#E7C8D1" stopOpacity="0.46" />
        </linearGradient>
        <clipPath id={clipPathID2}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { ReactNode } from 'react';

import { ExpenseCategory, Label } from 'lib';

import { Category, Property, Transaction } from '../../types';

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export type OrderByType = 'date' | 'amount';

export type TableItem = {
  id: string;
  propertyName: string;
  amount: number;
  date: string;
  originalCategory: string;
  category: string;
  transactions: Transaction[]
  label?: ReactNode,
  rawLabel?: Label | null,
  isEditable: boolean,
  propertyId: string;
  fullAddress?: string
};

export type TFilterDate = Date | null | undefined;

type BaseTransactionPayload = {
  propertyId: string,
  category: ExpenseCategory,
  amount: number,
  id?: string,
};

export type TransactionPayload = BaseTransactionPayload & {
  type?: 'expense',
  date: string,
};

export type RecurringTransactionPayload = BaseTransactionPayload & {
  startDate: string,
};

export interface ITransactionForm {
  type: 'one_time' | 'recurring' | 'bi_annual',
  propertyId: string,
  category: ExpenseCategory,
  dates: {
    date?: string,
    firstPaymentDate?: string,
    secondPaymentDate?: string,
  }
  amount?: number,
  id?: string,
}

export type AddTransactionModalProps = {
  isOpen: boolean;
  toggle: any;
  initialValues: ITransactionForm | null;
};

export type EditTransactionModalProps = {
  isOpen: boolean;
  toggle: any;
  initialValues: ITransactionForm | null;
};

export type DeleteTransactionModalProps = {
  isOpen: boolean;
  toggle: any;
  id: string;
};

export type DeleteRecurringTransactionModalProps = {
  isOpen: boolean;
  toggle: any;
  id: string;
};

export type OnClickAddTransaction = (propertyID?: string, category?: Category) => void;

export type AlertsProps = {
  properties: Property[];
  onClick: OnClickAddTransaction;
  initialExpanded: boolean;
};

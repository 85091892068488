import { Paper, styled } from '@mui/material';

export const StyledPaperMobile = styled(Paper)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'white',
  boxSizing: 'border-box',
  borderRadius: '6px',
  padding: theme.spacing(2),
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(2),
  width: '100%',
}));

export const StyledPaperDesktop = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  height: '120px',
  boxSizing: 'border-box',
  borderRadius: '6px',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

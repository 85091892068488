import axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';

// this class exists just for the rename so we can filter out AxiosErrors from Sentry
class APIError extends Error {
  name = 'APIError';
}

export const axiosWithPayloadContext = async<T = any> (config: AxiosRequestConfig) => {
  try {
    return await axios<T>(config);
  } catch (e) {
    Sentry.withScope((scope) => {
      let err = e;
      scope.setContext('payload', config.data);

      if (config.url) {
        scope.setFingerprint(['{{ default }}', `${config.method} ${config.url}`]);

        err = new APIError(`Request Failed: ${config.method} ${config.url} (${(e as any).response?.status ?? 0})`);
      }

      if (e && (e as any).response?.data) {
        let res = (e as any).response.data;

        if (typeof res === 'string') {
          res = { messageFromServer: res };
        }

        scope.setContext('response', res);
      }

      Sentry.captureException(err);
    });

    throw e;
  }
};

export const createHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
});

import {
  SellableProperty,
} from './types';
import { createDisplayAddress } from '../../lib/address';
import { convertArrayToFirstElement } from '../../lib/convert';
import { filterNulls } from '../../lib/filter';
import { sortByDesc } from '../../lib/sort';
import { DashboardProperty, DashboardPropertyValuation } from '../API';

export const convertSellableProperty = (dashboardProperty: DashboardProperty): SellableProperty => {
  const valuations = sortByDesc<DashboardPropertyValuation>(
    filterNulls<DashboardPropertyValuation>(dashboardProperty?.propertyValuation?.items),
    'date',
  );
  const latestValuation = convertArrayToFirstElement<DashboardPropertyValuation>({
    items: valuations,
  });

  return {
    id: dashboardProperty.property.id,
    displayName: createDisplayAddress(dashboardProperty.address),
    address: {
      ...dashboardProperty.address,
      fullAddress: createDisplayAddress(dashboardProperty.address),
    },
    prices: latestValuation && latestValuation.priceUpper && latestValuation.priceLower && latestValuation.priceMean ? {
      priceMean: latestValuation.priceMean,
      priceLower: latestValuation.priceLower,
      priceUpper: latestValuation.priceUpper,
    } : null,
  };
};

import { ReactNode } from 'react';

import {
  Stack, Theme, useMediaQuery,
} from '@mui/material';

export const EmptyFullPageContainer = ({ children }: { children: ReactNode }) => {
  const isMobile = useMediaQuery((_theme: Theme) => _theme.breakpoints.down('lg'));

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        width: '100%',
        ...(isMobile && { position: 'absolute', top: 0 }),
      }}
    >
      {children}
    </Stack>
  );
};

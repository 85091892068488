import dayjs from 'dayjs';
import {
  DashboardProperty as APIProperty,
  ListDashboardPropertiesByPmQuery,
  ListDashboardPropertyByOwnerQuery,
  queryGraphQL,
  useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { convertOwnerPropertyData } from './converters';
import { OwnerPropertyData } from './types';
import { QueryKey } from '../../types/enums';
import { getOwnerPropertiesQuery, listDashboardPropertyIDsFilterDeletedQuery, listPropertyValuations } from '../graphql/queries';

export const useListPropertyValuations = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchProperties = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    valuations: number[];
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listPropertyValuations,
      authToken: token,
      variables: {
        nextToken: nextToken || undefined,
        pmId: user?.pm,
      },
    }) as GraphQLResult<ListDashboardPropertiesByPmQuery>;

    if (!res.data?.listDashboardPropertiesByPm) {
      return { nextToken: null, valuations: [] };
    }

    const valuations: number[] = [];

    const filtered = res.data.listDashboardPropertiesByPm.items.filter(Boolean) as APIProperty[];

    filtered.forEach((property) => {
      if (property.propertyValuation?.items?.length) {
        const valuation = property.propertyValuation?.items[0];

        if (!valuation || !valuation.priceMean) return;

        const today = dayjs();
        const updatedTime = dayjs(valuation.updatedTime);

        if (today.month() !== updatedTime.month() || today.year() !== updatedTime.year()) return;

        valuations.push(valuation.priceMean);
      }
    });

    return {
      nextToken: res.data.listDashboardPropertiesByPm.nextToken,
      valuations,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.PROPERTY_VALUATIONS],
    queryFn: fetchProperties,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

export const useGetOwnerPropertyData = (owner?: string) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.OWNER_PROPERTY_DATA, owner], async (): Promise<OwnerPropertyData | null > => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: getOwnerPropertiesQuery,
      authToken: token,
      variables: { owner },
    }) as GraphQLResult<ListDashboardPropertyByOwnerQuery>;

    if (!res.data?.listDashboardPropertyByOwner) {
      return null;
    }

    const properties = res.data.listDashboardPropertyByOwner.items.filter(Boolean) as APIProperty[];

    return convertOwnerPropertyData(properties);
  }, { enabled: !!owner });
};

export const useGetDashboardPropertyIDs = () => {
  const { getAccessTokenSilently, user } = useAuth();

  const pm = user?.pm;

  const query = useInfiniteQuery({
    queryKey: [QueryKey.DASHBOARD_PROPERTY_IDS],
    queryFn: async ({ pageParam: nextToken }): Promise<{
      propertyIDs: Set<string>;
      nextToken: string | null | undefined;
    }> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listDashboardPropertyIDsFilterDeletedQuery,
        variables: { pm, nextToken },
        authToken: token,
      }) as GraphQLResult<ListDashboardPropertiesByPmQuery>;

      if (!res.data?.listDashboardPropertiesByPm?.items?.length) {
        return { propertyIDs: new Set(), nextToken: null };
      }

      const propertyIDs = new Set<string>();

      res.data.listDashboardPropertiesByPm.items.forEach((property) => {
        if (!property) return;

        propertyIDs.add(property.id);
      });

      return {
        propertyIDs,
        nextToken: res.data.listDashboardPropertiesByPm.nextToken,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

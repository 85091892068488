import { calculateMortgage, useAnalytics } from 'lib';
import { Controller } from 'react-hook-form';
import {
  BoldTypography, ControlledFormattedNumberField, formatNumberToCurrency, InfoTooltip, SemiBoldTypography, useLabels,
} from 'ui';
import {
  FormControl, InputLabel, MenuItem,
  Select, Stack, Typography,
} from '@mui/material';

import { DetailRow } from './Row';
import { Form, RowProps } from '../types';

export const refreshDownpaymentPercent = (form: Form): { percentageValue: number, downpaymentValue: number } => {
  const values = form.getValues();
  const { downpaymentPercent: percent } = values.details.loanPayments;
  const { purchasePrice: price } = values.details;

  let percentageValue: number;
  let downpaymentValue: number;

  const calculatedDownpayment = (percent / 100) * price;

  if (calculatedDownpayment > price) {
    percentageValue = 100;
    downpaymentValue = price;
  } else if (calculatedDownpayment < 0) {
    percentageValue = 0;
    downpaymentValue = 0;
  } else {
    percentageValue = percent;
    downpaymentValue = calculatedDownpayment;
  }

  form.setValue('details.loanPayments.downpayment', downpaymentValue);
  form.setValue('details.loanPayments.downpaymentPercent', percentageValue);

  return {
    percentageValue,
    downpaymentValue,
  };
};

const EditableMortgage = ({ form }: RowProps) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const {
    control, watch,
  } = form;

  const interestRate = watch('details.loanPayments.interestRate');
  const mortgageAmortizationPeriod = watch('details.loanPayments.mortgageAmortizationPeriod');

  return (
    <Stack gap={3}>
      <ControlledFormattedNumberField
        control={control}
        name="details.loanPayments.downpayment"
        label={l.downPayment}
        prefix="$"
        size="small"
        onBlur={() => {
          const values = form.getValues();

          const { downpayment: dp } = values.details.loanPayments;
          const { purchasePrice: price } = values.details;

          let percentageValue: number;
          let downpaymentValue: number;

          const percentage = (dp / price) * 100;
          if (percentage > 100) {
            percentageValue = 100;
            downpaymentValue = price;
          } else if (percentage < 0) {
            percentageValue = 0;
            downpaymentValue = 0;
          } else {
            percentageValue = Math.floor(percentage) || 0;
            downpaymentValue = dp;
          }

          form.setValue('details.loanPayments.downpaymentPercent', percentageValue);
          form.setValue('details.loanPayments.downpayment', downpaymentValue);

          analytics.track('Input Changed', {
            value: downpaymentValue,
            percentage: percentageValue,
            inputName: 'Listing Details - Downpayment',
          });
        }}
      />
      <ControlledFormattedNumberField
        control={control}
        name="details.loanPayments.downpaymentPercent"
        label={l.percentage}
        suffix="%"
        prefix=""
        size="small"
        onBlur={() => {
          const { downpaymentValue, percentageValue } = refreshDownpaymentPercent(form);

          analytics.track('Input Changed', {
            value: downpaymentValue,
            percentage: percentageValue,
            inputName: 'Listing Details - Downpayment',
          });
        }}
      />
      <ControlledFormattedNumberField
        control={control}
        name="details.loanPayments.interestRate"
        label={l.interestRate}
        suffix="%"
        prefix=""
        size="small"
        onBlur={() => {
          analytics.track('Input Changed', {
            value: interestRate,
            inputName: 'Listing Details - Interest Rate',
          });
        }}
      />
      <Controller
        name="details.loanPayments.mortgageAmortizationPeriod"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth size="small">
            <InputLabel>{l.mortgageAmortizationPeriod}</InputLabel>
            <Select
              label={l.mortgageAmortizationPeriod}
              data-hj-allow
              {...field}
              onChange={(...args) => {
                field.onChange(...args);

                analytics.track('Input Changed', {
                  value: mortgageAmortizationPeriod,
                  inputName: 'Listing Details - Mortgage Amortization Period',
                });
              }}
            >
              <MenuItem value={120}>
                {l['120-months']}
              </MenuItem>
              <MenuItem value={180}>
                {l['180-months']}
              </MenuItem>
              <MenuItem value={240}>
                {l['240-months']}
              </MenuItem>
              <MenuItem value={360}>
                {l['360-months']}
              </MenuItem>
            </Select>
          </FormControl>
        )}
      />
    </Stack>
  );
};

export const LoanPayments = ({ form }: RowProps) => {
  const l = useLabels();

  const editMode = form.watch('editMode');
  const purchasePrice = form.watch('details.purchasePrice');
  const downpayment = form.watch('details.loanPayments.downpayment');
  const interestRate = form.watch('details.loanPayments.interestRate');
  const mortgageAmortizationPeriod = form.watch('details.loanPayments.mortgageAmortizationPeriod');

  const mortgage = calculateMortgage({
    loanAmount: purchasePrice - downpayment,
    interestRate,
    loanTerm: mortgageAmortizationPeriod,
  });

  return (
    <>
      <DetailRow
        form={form}
        rowID="loan-payments"
        bottomBorder={false}
        columns={[
          {
            id: 'loan-payments',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.loanPayments}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.loanPayments']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          {}, {}, {},
        ]}
      />
      <DetailRow
        form={form}
        rowID="mortgage"
        bottomBorder={false}
        alignTopOnEdit
        columns={[
          {
            id: 'mortgage',
            content: (
              <SemiBoldTypography variant="body1">
                {l.mortgage}
              </SemiBoldTypography>
            ),
          },
          {
            id: 'mortgage-year-1',
            content: editMode ? (
              <EditableMortgage form={form} />
            ) : (
              <BoldTypography variant="body1">
                {formatNumberToCurrency(mortgage, 0)}
              </BoldTypography>
            ),
          },
          {
            id: 'mortgage-year-2',
            content: (
              <BoldTypography variant="body1">
                {formatNumberToCurrency(mortgage, 0)}
              </BoldTypography>
            ),
          },
          {
            id: 'mortgage-year-3',
            content: (
              <BoldTypography variant="body1">
                {formatNumberToCurrency(mortgage, 0)}
              </BoldTypography>
            ),
          },
        ]}
      />
    </>
  );
};

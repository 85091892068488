import { OwnerReferralsIncentive, useIsB2CReferralProgramEnabled, usePermissions } from 'lib';

import { useGetB2CIncentive } from '../api/growthPolicies';

export const useGetB2CReferralProgram = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: b2cProgramFFEnabled, loading: isB2cProgramFFLoading } = useIsB2CReferralProgramEnabled();
  const { data: b2cIncentiveData, isLoading: isLoadingGrowthPolicy } = useGetB2CIncentive(
    !isLoadingPermissions && !isB2cProgramFFLoading,
  );

  const shouldDisplayReferralProgram = !!(permissions?.viewB2CReferralProgram
      && b2cIncentiveData?.enableOwnerReferralProgram && b2cProgramFFEnabled);
  const isLoading = isLoadingGrowthPolicy || isLoadingPermissions || isB2cProgramFFLoading;
  const b2cReferralIncentive = b2cIncentiveData?.ownerReferralsIncentive ?? OwnerReferralsIncentive.NO_INCENTIVE;

  return {
    shouldDisplayReferralProgram,
    isLoading,
    b2cReferralIncentive,
  };
};

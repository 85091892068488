import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import {
  BuyerLeadsWorkflowStatus, ContactDetails, useAnalytics, useAuth,
} from 'lib';
import { BiMailSend } from 'react-icons/bi';
import { FaDollarSign } from 'react-icons/fa';
import {
  MdHome, MdLocationPin, MdPhone, MdSupportAgent,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  ColumnCard, Columns, FallbackSpinner, formatNumberToCurrency, InvertedButton, KanbanBoard, LightTooltip, useLabels,
} from 'ui';
import {
  Button, IconButton, Stack, useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';

import { CardDetail, ColCard } from './ColCard';
import { useMutatePhoneCall } from '../../../api/calls';
import { useListBuyerLeads, useMutateBuyerLead } from '../../../api/workflows';
import { BuyerLead } from '../../../api/workflows/converters';
import { LeadCardHeader } from '../components/LeadCardHeader';
import { StatusChanger } from '../components/StatusChanger';
import { moveCardToColumn, updateCard } from '../utils';
import { useBuyerLeadStatuses } from '../WorkflowStatus';

const columnsSignal = signal<Columns<BuyerLeadCard>>({});

export const BuyerLeadsKanban = ({ orderedStatuses }: { orderedStatuses: BuyerLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { getStatus } = useBuyerLeadStatuses();
  const { data, isLoading, isError } = useListBuyerLeads();
  const { mutateAsync } = useMutateBuyerLead();

  useEffect(() => {
    if (data) {
      const cols: Columns<BuyerLeadCard> = {};

      orderedStatuses.forEach((columnID) => {
        const status = getStatus(columnID);

        cols[columnID] = {
          id: columnID,
          icon: status.icon,
          title: status.displayValue,
          color: status.color,
          tooltip: status.tooltip,
          cards: [],
        };
      });

      data.pages.forEach((page) => {
        page.leads.forEach((lead) => {
          const columnID = lead.status;

          cols[columnID].cards.push({
            ...lead,
            originalColumnID: columnID,
            disabled: false,
            disableStatusChange: true,
          });
        });
      });

      Object.keys(cols).forEach((columnID) => {
        cols[columnID].cards.sort((a, b) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));
      });

      columnsSignal.value = cols;
    }

    if (isError) toast.error(l['error.unknownError']);
  }, [data, isLoading, isError, columnsSignal]);

  const handleColumnChange = async (lead: BuyerLeadCard, newStatus: string) => {
    analytics.track('Card Moved', {
      cardName: 'Buyer Lead',
      from: lead.status,
      to: newStatus,
      id: lead.id,
    });

    const oldStatus = lead.status;

    columnsSignal.value = updateCard(columnsSignal.value, {
      ...lead,
      disabled: true,
    });

    try {
      await mutateAsync({
        id: lead.id,
        status: newStatus as BuyerLeadsWorkflowStatus,
      });
      columnsSignal.value = updateCard(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: newStatus as BuyerLeadsWorkflowStatus,
        originalColumnID: newStatus as BuyerLeadsWorkflowStatus,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);

      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: oldStatus,
        originalColumnID: oldStatus,
      });
    }
  };

  useEffect(() => {
    document.querySelector('html')?.classList.add('full-height');

    return () => {
      document.querySelector('html')?.classList.remove('full-height');
    };
  }, []);

  if (isLoading) {
    return <FallbackSpinner />;
  }

  return (
    <Stack flexGrow={1} height="100%">
      <KanbanBoard
        orderedColumnIDs={orderedStatuses}
        CardComponent={BuyerLeadColCard}
        columnsSignal={columnsSignal}
        onColumnChange={handleColumnChange}
      />
    </Stack>
  );
};

type BuyerLeadCard = BuyerLead & ColumnCard;

const BuyerLeadColCard = ({
  card,
  isOverlay = false,
}: {
  card: BuyerLeadCard,
  isOverlay?: boolean,
}) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const { user } = useAuth();
  const { statuses } = useBuyerLeadStatuses();
  const { mutateAsync: reveal, isLoading: isRevealing } = useMutateBuyerLead();
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateBuyerLead();
  const { mutateAsync: sendPhoneCallRequest, isLoading: isCalling } = useMutatePhoneCall();

  const disabled = isRevealing || isCalling || card.disabled;

  const revealLead = async () => {
    await reveal({
      id: card.id,
      revealed: true,
    });
    columnsSignal.value = updateCard(columnsSignal.value, {
      ...card,
      revealed: true,
    });
  };

  const call = async (contact: ContactDetails | null | undefined, called: string) => {
    analytics.track('Button Clicked', {
      buttonName: 'Call',
      called,
      id: card.id,
    });

    if (!contact?.phoneNumber) {
      toast.error(l['error.missingContactPhone']);
      return;
    }

    try {
      await sendPhoneCallRequest(contact.id);
      toast.success(l['leads.call.success']);
    } catch (err) {
      console.error(err);
      toast.error(l['error.unknownError']);
    }
  };
  const handleClickEmail = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Copy Email',
      email: card.sellerAgentContact?.email,
    });

    if (!card.sellerAgentContact?.email) {
      toast.error(l['error.missingContactEmail']);
      return;
    }

    window.navigator.clipboard.writeText(card.sellerAgentContact.email);
    toast.success(l['leads.emailCopied']);
  };

  const handleStatusChange = async (status: BuyerLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Buyer Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: card.id,
        status,
      });
      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...card,
        disabled: false,
        status,
        originalColumnID: status,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  // split card.name on first comma
  const firstCommaIndex = card.name.indexOf(',');
  const name = firstCommaIndex > -1 ? card.name.slice(0, firstCommaIndex) : card.name;
  const location = firstCommaIndex > -1 ? card.name.slice(firstCommaIndex + 1) : undefined;

  const sellerAgentIsSelf = card.sellerAgentContact?.id === user?.id;
  const buyerIsSelf = card.buyerContact?.id === user?.id;

  const showFooter = (
    (!sellerAgentIsSelf || !buyerIsSelf)
      && (card.sellerAgentContact?.phoneNumber || card.buyerContact?.phoneNumber)
  );

  return (
    <ColCard
      card={card}
      revealCard={revealLead}
      revealed={!!card.revealed}
      isRevealing={isRevealing}
      createdTime={card.createdTime}
      updatedTime={card.updatedTime}
      isOverlay={isOverlay}
      footer={showFooter && (
        <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
          {!buyerIsSelf && card.buyerContact?.phoneNumber && (
            <InvertedButton
              fullWidth
              disabled={disabled}
              color="primary"
              variant="contained"
              onClick={() => call(card.buyerContact, 'Buyer')}
              sx={{ px: `${theme.spacing(2)} !important` }}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <MdPhone />
                {l.owner}
              </Stack>
            </InvertedButton>
          )}
          {!sellerAgentIsSelf && card.sellerAgentContact?.phoneNumber && (
            <Button
              fullWidth
              disabled={disabled}
              variant="contained"
              onClick={() => call(card.sellerAgentContact, 'Seller Agent')}
              sx={{ px: `${theme.spacing(2)} !important` }}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <MdPhone />
                {l.agent}
              </Stack>
            </Button>
          )}
        </Stack>
      )}
      afterContent={(
        <StatusChanger
          id={card.id}
          status={card.status}
          statuses={statuses}
          changeStatus={handleStatusChange}
          isLoading={isUpdatingStatus}
        />
      )}
    >
      <LeadCardHeader
        firstName={card.buyerContact?.firstName}
        lastName={card.buyerContact?.lastName}
        email={card.buyerContact?.email}
      />
      <CardDetail
        label={<MdHome />}
        content={card.propertyId && card.isPropertyVisible ? (
          <Link
            to={`/marketplace/${card.propertyId}`}
            target="_blank"
            style={{ color: theme.palette.info.main, textDecoration: 'none' }}
          >
            {name}
          </Link>
        ) : name}
      />
      {location && <CardDetail label={<MdLocationPin size={18} />} content={location} />}
      <CardDetail
        label={<FaDollarSign />}
        content={card.askingPrice ? formatNumberToCurrency(card.askingPrice, 0) : l.toDiscuss}
      />
      {user && card.sellerAgentContact && user.email !== card.sellerAgentContact.email && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" height={20}>
          <CardDetail
            label={(
              <LightTooltip title={l.agent} placement="top" arrow>
                <Stack>
                  <MdSupportAgent />
                </Stack>
              </LightTooltip>
            )}
            content={`${card.sellerAgentContact.firstName} ${card.sellerAgentContact.lastName || ''}`}
          />
          <IconButton onClick={handleClickEmail}>
            <BiMailSend color={theme.palette.primary.main} size={16} />
          </IconButton>
        </Stack>
      )}
    </ColCard>
  );
};

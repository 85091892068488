import { useState } from 'react';

import { useAnalytics } from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  FallbackSpinner,
  modalStyle,
  useLabels, YesOrNoLayout,
} from 'ui';
import {
  Box, Button,
  Modal, Stack, Typography, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { DeleteRecurringTransactionModalProps } from './types';
import {
  useDeleteRecurringExpense,
  useDeleteSingleRecurringExpense,
  useGetRecurringExpenseByTransactionId,
} from '../../api/properties';
import { useToggle } from '../../hooks/useToggle';
import { sleep } from '../../lib/sleep';
import { QueryKey } from '../../types/enums';

export const DeleteRecurringTransactionsModal = ({
  isOpen, toggle, id,
}: DeleteRecurringTransactionModalProps) => {
  const l = useLabels();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  const [isDeleteOpen, toggleDeleteOpen] = useToggle();
  const [allDeleteSelected, setAllDeleteSelected] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const {
    mutateAsync: deleteSingleRecurringMutate,
  } = useDeleteSingleRecurringExpense();
  const {
    mutateAsync: deleteRecurringMutate,
  } = useDeleteRecurringExpense();
  const {
    isLoading: isLoadingRecurring,
    data: recurringExpense,
  } = useGetRecurringExpenseByTransactionId(id, isOpen);

  const onSubmit = async () => {
    analytics.track('Form Submitted', {
      formName: 'Delete Recurring Transaction',
      transactionId: recurringExpense?.data.id,
    });

    if (!recurringExpense) return;

    try {
      setForceLoading(true);
      if (allDeleteSelected) {
        await deleteRecurringMutate(recurringExpense.data.id);
      } else {
        await deleteSingleRecurringMutate({ recurringId: recurringExpense.data.id, transactionId: id });
      }

      await sleep(4000);
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      toggle();
      toggleDeleteOpen();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
    setForceLoading(false);
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={toggle}
        sx={{
          zIndex: 1600,
        }}
      >
        {isLoadingRecurring ? (
          <FallbackSpinner />
        ) : (
          <Box>
            <Box sx={modalStyle}>
              <Box
                onClick={toggle}
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  p: 2,
                  cursor: 'pointer',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: theme.palette.text.primary,
                  },
                }}
              >
                <MdClose style={{ width: '24px', height: '24px' }} />
              </Box>

              <Stack gap={3}>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  {l.deleteRecurringTitle}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  {l.deleteRecurringDescription}
                </Typography>
              </Stack>
              <Stack direction="row" gap={3} mt={8}>
                <Button
                  variant="contained"
                  onClick={() => { setAllDeleteSelected(true); toggleDeleteOpen(); }}
                  style={{ flexGrow: 1 }}
                  disabled={isLoadingRecurring}
                >
                  {isLoadingRecurring ? l.pleaseWait : 'all'}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => { toggleDeleteOpen(); }}
                  style={{ flexGrow: 1 }}
                  disabled={isLoadingRecurring}
                >
                  {isLoadingRecurring ? l.pleaseWait : 'Only this'}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Modal>

      <Modal
        open={isDeleteOpen}
        onClose={toggleDeleteOpen}
        sx={{
          zIndex: 1600,
        }}
      >
        <YesOrNoLayout
          isLoading={forceLoading}
          toggle={toggleDeleteOpen}
          onSubmit={onSubmit}
          title={l['delete-modal-title']}
          description=""
        />
      </Modal>
    </>

  );
};

import { useAnalytics } from 'lib';
import { useLabels } from 'ui';
import {
  Stack, Typography,
} from '@mui/material';

import { PriceSelector } from './PriceSelector';
import { SingleFilterProps } from '../props';

export const PriceFilter = ({ filters, setFilters }: SingleFilterProps) => {
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Stack p={3} gap={3}>
      <Typography variant="body2">{l.priceRange}</Typography>
      <Stack direction="row" alignItems="center" gap={3}>
        <PriceSelector
          idPrefix="min"
          value={filters.minPrice}
          zeroLabel={l.noMin}
          isRelevantSelection={(n) => filters.maxPrice === 0 || n < filters.maxPrice}
          onChange={(value) => {
            analytics.track('Selector Changed', {
              value,
              selectorName: 'Price Range - Min Price',
            });

            setFilters({
              minPrice: value,
            });
          }}
        />
        <PriceSelector
          isRelevantSelection={(n) => filters.minPrice === 0 || n > filters.minPrice}
          idPrefix="max"
          value={filters.maxPrice}
          zeroLabel={l.noMax}
          onChange={(value) => {
            analytics.track('Selector Changed', {
              value,
              selectorName: 'Price Range - Max Price',
            });

            setFilters({
              maxPrice: value,
            });
          }}
        />
      </Stack>
    </Stack>
  );
};

import React from 'react';

import { MdClose } from 'react-icons/md';
import {
  Box, Button, Stack, Typography, useTheme,
} from '@mui/material';

import { useLabels } from '../../lib/translations';
import { modalStyle } from '../yesOrNoModalSyle';

export const YesOrNoLayout = ({
  title, description, toggle, onSubmit,
  isLoading = false,
}: {
  title: string,
  description: React.ReactNode,
  toggle: () => void,
  onSubmit: () => void,
  isLoading?: boolean
}) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Box sx={modalStyle}>
      <Box
        onClick={toggle}
        sx={{
          position: 'absolute',
          top: 4,
          right: 4,
          p: 2,
          cursor: 'pointer',
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        }}
      >
        <MdClose style={{ width: '24px', height: '24px' }} />
      </Box>

      <Stack gap={3}>
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          {description}
        </Typography>
      </Stack>
      <Stack direction="row" gap={3} mt={8}>
        <Button variant="contained" onClick={onSubmit} style={{ flexGrow: 1 }} disabled={isLoading}>
          {isLoading ? l.pleaseWait : l.yes}
        </Button>
        <Button variant="outlined" onClick={toggle} style={{ flexGrow: 1 }}>
          {l.no}
        </Button>
      </Stack>
    </Box>
  );
};

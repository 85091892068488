import {
  lazy, Suspense, useEffect, useState,
} from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import { ConfigCatProvider, createConsoleLogger } from 'configcat-react';
import { App as Dashboard } from 'dashboard';
import {
  AnalyticsConsumer, AnalyticsProvider, config, useAppSettings,
  useIsOBPEnabled, useIsOldNavigationEnabled, useIsTeamMembersViewEnabled, usePermissions, useTrackHistory,
} from 'lib';
import { App as Marketplace } from 'marketplace';
import { App as PMDashboard, RedirectsFromOldPMDashboard } from 'pm-dashboard';
import { ToastContainer } from 'react-toastify';
import { App as ReferralPartners } from 'referral-partners';
import {
  ConfettiProvider,
  FallbackSpinner,
  SettingsConsumer, SettingsProvider, ThemeComponent, UserLayout,
} from 'ui';
import { Theme, useMediaQuery } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppSettingsMonitor } from './components/app-settings/AppSettingsMonitor';
import { AuthProvider } from './components/auth/AuthProvider';
import { MobileLayout } from './components/layouts/mobile';
import { NavigationMenu } from './components/layouts/navigation/NavigationMenu';
import { ScrollToTop } from './components/scroll-to-top/ScrollToTop';
import { usePostLogin } from './hooks/usePostLogin';
import { analytics } from './lib/3rd-parties/analytics';
import { OwnerBenefits } from './views/owner-benefits';
import { Settings } from './views/settings';
import { Team } from './views/settings/components/Team';

import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line import/extensions
const ReactQueryDevtoolsProduction = lazy(() => import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
  default: d.ReactQueryDevtools,
})));

const queryClient = new QueryClient();

Amplify.configure({
  aws_appsync_graphqlEndpoint: config.gqlEndpoint,
  aws_appsync_region: config.awsRegion,
  aws_appsync_authenticationType: config.appSyncAuthType,
  aws_appsync_apiKey: config.appSyncApiKey,
});

export const RoutedApp = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: obpEnabled, loading: loadingObpFF } = useIsOBPEnabled();
  const { value: isOldNavigationEnabled, loading: loadingIsOldNavigationEnabled } = useIsOldNavigationEnabled();
  const { value: isTeamMembersViewEnabled, loading: loadingIsTeamMembersViewEnabled } = useIsTeamMembersViewEnabled();
  const showMobileNav = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { data: appSettings, isLoading } = useAppSettings();

  const pmPath = isOldNavigationEnabled ? '/admin' : '/pm';

  usePostLogin();
  useTrackHistory();

  if (isLoadingPermissions || loadingObpFF || loadingIsOldNavigationEnabled || loadingIsTeamMembersViewEnabled) {
    return <FallbackSpinner />;
  }

  return (
    <Routes>
      <Route
        element={showMobileNav ? (
          <MobileLayout />
        ) : (
          <UserLayout
            headerLogoProps={{ logo: appSettings?.logo, logoLoading: isLoading }}
            navigationMenu={<NavigationMenu />}
          />
        )}
      >
        {(permissions?.viewPMDashboard || permissions?.viewLeads) && <Route path={`${pmPath}/*`} element={<PMDashboard />} />}
        {(permissions?.viewPMDashboard || permissions?.viewLeads) && !isOldNavigationEnabled && (
          <Route path="/admin/*" element={<RedirectsFromOldPMDashboard />} />
        )}
        {permissions?.viewMarketplace && <Route path="/marketplace/*" element={<Marketplace />} />}
        {permissions?.viewReferralPartner && <Route path="/referral-partners/*" element={<ReferralPartners />} />}
        {obpEnabled && permissions?.viewOBP && <Route path="/owner-benefits" element={<OwnerBenefits />} />}
        {permissions?.viewDashboard && <Route path="/*" element={<Dashboard />} />}
        {!isOldNavigationEnabled && permissions?.viewPMDashboard && (
          <Route path="/settings" element={<Settings />} />
        )}
        {/* TODO: remove this route when switching to the new navigation */}
        {isTeamMembersViewEnabled && isOldNavigationEnabled && <Route path="/admin/team" element={<Team showTitle />} />}
        <Route
          path="*"
          element={
            <Navigate to="/" />
          }
        />
      </Route>
    </Routes>
  );
};

export const App = () => {
  const logger = createConsoleLogger(config.configCatLogLevel);
  const [showDevtools, setShowDevtools] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <BrowserRouter>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <AnalyticsProvider analyticsObj={analytics}>
              <AnalyticsConsumer>
                {() => (
                  <QueryClientProvider client={queryClient}>
                    <ThemeComponent settings={settings}>
                      <AuthProvider>
                        <ConfigCatProvider sdkKey={config.configCatApiKey} options={{ logger }}>
                          <ConfettiProvider>
                            <ToastContainer position="top-center" pauseOnHover />
                            <ScrollToTop />
                            <AppSettingsMonitor />
                            <RoutedApp />
                          </ConfettiProvider>
                          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                          {showDevtools && (
                            <Suspense fallback={null}>
                              <ReactQueryDevtoolsProduction />
                            </Suspense>
                          )}
                        </ConfigCatProvider>
                      </AuthProvider>
                    </ThemeComponent>
                  </QueryClientProvider>
                )}
              </AnalyticsConsumer>
            </AnalyticsProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </BrowserRouter>
  );
};

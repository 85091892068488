export const carouselResponsiveConfig = {
  xs: {
    breakpoint: { max: 597, min: 0 },
    items: 1,
    partialVisibilityGutter: 20,
  },
  s: {
    breakpoint: { max: 750, min: 598 },
    items: 1,
    partialVisibilityGutter: 120,
  },
  s2: {
    breakpoint: { max: 850, min: 751 },
    items: 1,
    partialVisibilityGutter: 250,
  },
  s3: {
    breakpoint: { max: 899, min: 801 },
    items: 2,
    partialVisibilityGutter: 10,
  },
  s4: {
    breakpoint: { max: 4000, min: 900 },
    items: 3,
  },
};

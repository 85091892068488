import { useState } from 'react';

import { useAnalytics } from 'lib';
import { MdClose } from 'react-icons/md';
import {
  BoldTypography,
  useLabels,
} from 'ui';
import {
  Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { PropertyAutocomplete } from './Autocomplete';
import { BedsBathsFilter } from './BedsBathsFilter';
import { CategoryFilter } from './CategoryFilter';
import { FilterButton } from './FilterButton';
import { PredefinedFilters } from './PredefinedFilters';
import { PriceFilter } from './PriceFilter';
import { Filters } from '../../../api/properties/searchable';
import { SettableFilters } from '../props';
import { SortingOptionsSelect } from '../SortingOptionsSelect';

type FilterProps = {
  filters: Filters,
  setFilters: (filters: Partial<SettableFilters>) => void,
  resetFilters: () => void,
  setSelectedOptions: (options: string[]) => void,
  activeFilterCount: number,
  usedFilters: (keyof SettableFilters)[],
};

export const FiltersContainer = ({
  filters,
  setFilters,
  resetFilters,
  setSelectedOptions,
  activeFilterCount,
  usedFilters,
}: FilterProps) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [filtersOpen, setFiltersOpen] = useState(false);

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const toggleFiltersOpen = (open: boolean, buttonName: string) => {
    setFiltersOpen(open);

    analytics.track('Button Clicked', {
      buttonName,
      value: open,
    });
  };

  return (
    <>
      <Stack direction="row" gap={3} flexWrap="wrap" alignItems="center">
        <PropertyAutocomplete
          selectedOptions={filters.selectedSearchOptions}
          setSelectedOptions={setSelectedOptions}
        />
        <FilterButton
          toggleFiltersOpen={toggleFiltersOpen}
          activeFilterCount={activeFilterCount}
          resetFilters={resetFilters}
        />
        <SortingOptionsSelect
          setSortOption={(value) => setFilters({
            sortBy: value,
          })}
          sortOption={filters.sortBy}
          hasSelection={usedFilters.includes('sortBy')}
        />
      </Stack>
      <Dialog open={filtersOpen} fullScreen={isLowerThanMd} onClose={() => toggleFiltersOpen(false, 'Filter Popup Backdrop')}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" px={4} py={3}>
          <BoldTypography variant="h6">{l.filter}</BoldTypography>
          <IconButton onClick={() => toggleFiltersOpen(false, 'Filters - X')}>
            <MdClose size={24} />
          </IconButton>
        </Stack>
        <Divider sx={{ m: 0 }} />
        <DialogContent sx={{ px: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <CategoryFilter filters={filters} setFilters={setFilters} />
            <PredefinedFilters filters={filters} setFilters={setFilters} />
          </Stack>
          <PriceFilter filters={filters} setFilters={setFilters} />
          <BedsBathsFilter filters={filters} setFilters={setFilters} />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'space-between', px: 4, py: 3 }}>
          <Button
            variant="text"
            onClick={() => {
              analytics.track('Button Clicked', {
                buttonName: 'Clear All Filters',
              });

              resetFilters();
            }}
          >
            {l.clearAll}
          </Button>
          <Button variant="contained" onClick={() => toggleFiltersOpen(false, 'Apply')}>{l.apply}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

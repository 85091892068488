import {
  axiosWithPayloadContext,
  BuyBoxLeadWorkflowItem,
  BuyerLeadWorkflowItem,
  config,
  createHeaders,
  ListBuyBoxLeadWorkflowItemsByPmQuery,
  ListBuyerLeadWorkflowItemsByPmQuery,
  ListOwnerLeadWorkflowItemsByPmQuery,
  ListSellerLeadWorkflowItemsByPmQuery,
  OwnerLeadWorkflowItem,
  queryGraphQL,
  SellerLeadWorkflowItem,
  useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';

import {
  BuyerLead, convertBuyerLead, convertSellerLead, SellerLead,
} from './converters';
import { QueryKey } from '../../types/enums';
import {
  listBuyBoxLeadIDs,
  listBuyBoxLeads, listBuyerLeadIDs, listBuyerLeads, listOwnerLeadIDs, listOwnerLeads, listSellerLeadIDs, listSellerLeads,
} from '../graphql/queries';

export const useListBuyerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchBuyerLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    leads: BuyerLead[];
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listBuyerLeads,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListBuyerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listBuyerLeadWorkflowItemsByPm) {
      return { nextToken: null, leads: [] };
    }

    const filtered = res.data.listBuyerLeadWorkflowItemsByPm.items.filter(Boolean) as BuyerLeadWorkflowItem[];

    return {
      nextToken: res.data.listBuyerLeadWorkflowItemsByPm.nextToken,
      leads: filtered.map(convertBuyerLead),
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.BUYER_LEADS],
    queryFn: fetchBuyerLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useCountBuyerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchBuyerLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    count: number;
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listBuyerLeadIDs,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListBuyerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listBuyerLeadWorkflowItemsByPm) {
      return { nextToken: null, count: 0 };
    }

    return {
      nextToken: res.data.listBuyerLeadWorkflowItemsByPm.nextToken,
      count: res.data.listBuyerLeadWorkflowItemsByPm.items.length,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.COUNT_BUYER_LEADS],
    queryFn: fetchBuyerLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useListSellerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchSellerLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    leads: SellerLead[];
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listSellerLeads,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListSellerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listSellerLeadWorkflowItemsByPm) {
      return { nextToken: null, leads: [] };
    }

    const filtered = res.data.listSellerLeadWorkflowItemsByPm.items.filter(Boolean) as SellerLeadWorkflowItem[];

    return {
      nextToken: res.data.listSellerLeadWorkflowItemsByPm.nextToken,
      leads: filtered.map(convertSellerLead),
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.SELLER_LEADS],
    queryFn: fetchSellerLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useCountSellerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchSellerLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    count: number;
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listSellerLeadIDs,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListSellerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listSellerLeadWorkflowItemsByPm) {
      return { nextToken: null, count: 0 };
    }

    return {
      nextToken: res.data.listSellerLeadWorkflowItemsByPm.nextToken,
      count: res.data.listSellerLeadWorkflowItemsByPm.items.length,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.COUNT_SELLER_LEADS],
    queryFn: fetchSellerLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useListBuyBoxLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchBuyBoxLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    leads: BuyBoxLeadWorkflowItem[];
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listBuyBoxLeads,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListBuyBoxLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listBuyBoxLeadWorkflowItemsByPm) {
      return { nextToken: null, leads: [] };
    }

    const filtered = res.data.listBuyBoxLeadWorkflowItemsByPm.items.filter(Boolean) as BuyBoxLeadWorkflowItem[];

    return {
      nextToken: res.data.listBuyBoxLeadWorkflowItemsByPm.nextToken,
      leads: filtered,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.BUY_BOX_LEADS],
    queryFn: fetchBuyBoxLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useCountBuyBoxLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchBuyBoxLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    count: number;
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listBuyBoxLeadIDs,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListBuyBoxLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listBuyBoxLeadWorkflowItemsByPm) {
      return { nextToken: null, count: 0 };
    }

    return {
      nextToken: res.data.listBuyBoxLeadWorkflowItemsByPm.nextToken,
      count: res.data.listBuyBoxLeadWorkflowItemsByPm.items.length,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.COUNT_BUY_BOX_LEADS],
    queryFn: fetchBuyBoxLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useListOwnerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchOwnerLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    leads: OwnerLeadWorkflowItem[];
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerLeads,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListOwnerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listOwnerLeadWorkflowItemsByPm) {
      return { nextToken: null, leads: [] };
    }

    const filtered = res.data.listOwnerLeadWorkflowItemsByPm.items.filter(Boolean) as OwnerLeadWorkflowItem[];

    return {
      nextToken: res.data.listOwnerLeadWorkflowItemsByPm.nextToken,
      leads: filtered,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.OWNER_LEADS],
    queryFn: fetchOwnerLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useCountOwnerLeads = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const fetchOwnerLeads = async ({ pageParam: nextToken = '' }): Promise<{
    nextToken: string | null | undefined;
    count: number;
  }> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerLeadIDs,
      authToken: token,
      variables: {
        pm: user!.pm,
        nextToken: nextToken || undefined,
      },
    }) as GraphQLResult<ListOwnerLeadWorkflowItemsByPmQuery>;

    if (!res.data?.listOwnerLeadWorkflowItemsByPm) {
      return { nextToken: null, count: 0 };
    }

    return {
      nextToken: res.data.listOwnerLeadWorkflowItemsByPm.nextToken,
      count: res.data.listOwnerLeadWorkflowItemsByPm.items.length,
    };
  };

  const query = useInfiniteQuery({
    queryKey: [QueryKey.COUNT_BUY_BOX_LEADS],
    queryFn: fetchOwnerLeads,
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
    enabled: !!user?.pm,
  });

  return query;
};

export const useMutateBuyerLead = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (lead: { id: string } & Partial<Pick<BuyerLeadWorkflowItem, 'status' | 'revealed'>>) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/buyer-leads/${lead.id}`,
      method: 'PATCH',
      headers: createHeaders(token),
      data: {
        ...lead,
      },
    });
  });
};

export const useMutateSellerLead = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (lead: { id: string } & Partial<Pick<SellerLeadWorkflowItem, 'status' | 'revealed'>>) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/seller-leads/${lead.id}`,
      method: 'PATCH',
      headers: createHeaders(token),
      data: {
        ...lead,
      },
    });
  });
};

export const useMutateBuyBoxLead = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (lead: { id: string } & Partial<Pick<BuyBoxLeadWorkflowItem, 'status' | 'revealed'>>) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/buybox-leads/${lead.id}`,
      method: 'PATCH',
      headers: createHeaders(token),
      data: {
        ...lead,
      },
    });
  });
};

export const useMutateOwnerLead = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (lead: { id: string } & Partial<Pick<OwnerLeadWorkflowItem, 'status'>>) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/owner-leads/${lead.id}`,
      method: 'PATCH',
      headers: createHeaders(token),
      data: {
        ...lead,
      },
    });
  });
};

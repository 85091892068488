import dayjs from 'dayjs';
import {
  GetReportQuery, ListOwnerReportsByOwnerQuery, queryGraphQL, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '../../types/enums';
import { OwnerReport, Report } from '../../types/report';
import { getReport, listOwnerReports, listOwnerReportsIds } from '../graphql/queries';

export const useListOwnerReports = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.REPORTS], async (): Promise<OwnerReport[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerReports,
      authToken: token,
      variables: { owner: user?.id ?? '' },
    }) as GraphQLResult<ListOwnerReportsByOwnerQuery>;

    if (!res.data?.listOwnerReportsByOwner) {
      return [];
    }

    const reports = (res.data.listOwnerReportsByOwner.items as OwnerReport[])
      .sort((a: OwnerReport, b: OwnerReport) => (b.year * 100 + b.month) - (a.year * 100 + a.month)).map((i) => {
        const items = i.reports?.items
          .sort((a: Report, b: Report) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf());
        return {
          ...i,
          reports: {
            items,
          },
        };
      });

    return reports as OwnerReport[];
  }, { enabled: !!user?.id });
};

export const useListOwnerReportsIds = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.REPORT_IDS], async (): Promise<string[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listOwnerReportsIds,
      authToken: token,
      variables: { owner: user?.id ?? '' },
    }) as GraphQLResult<ListOwnerReportsByOwnerQuery>;

    if (!res.data?.listOwnerReportsByOwner) {
      return [];
    }

    return res.data.listOwnerReportsByOwner.items.map((r) => r?.id) as string[];
  }, { enabled: !!user?.id });
};

export const useGetReport = (reportId: string | undefined, enabled: boolean) => {
  const { getAccessTokenSilently } = useAuth();

  const query = useQuery([QueryKey.REPORTS, { reportId }], async (): Promise<Report | null | undefined> => {
    if (!reportId) {
      return null;
    }

    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getReport,
      authToken: token,
      variables: {
        id: reportId,
      },
    }) as GraphQLResult<GetReportQuery>;

    return res.data?.getReport;
  }, {
    enabled,
  });

  return {
    ...query,
    // This is necessary since when enabled is false, isLoading will always be true
    // https://github.com/TanStack/query/issues/3584
    isLoading: query.status === 'loading',
  };
};

type Address = {
  street1: string,
  street2?: string | null,
  city: string,
  state: string,
  zipCode: string,
};
export const createDisplayAddress = (address: Address, short: boolean = false): string => {
  let fullAddress = address.street1;

  if (address.street2) {
    fullAddress += ` ${address.street2}`;
  }

  if (address.city) {
    fullAddress += `, ${address.city}`;
  }

  if (address.state) {
    fullAddress += `, ${address.state}`;
  }

  if (address.zipCode && !short) {
    fullAddress += address.state ? ` ${address.zipCode}` : `, ${address.zipCode}`;
  }

  return fullAddress;
};

import dayjs from 'dayjs';
import { coalesceNegative } from 'lib';

import { PropertyFormValues } from './types';
import { Property } from '../../types';

const getMortgageDueDay = (property: Property): (number | '') => {
  if (property.mortgage?.mortgageDueDay) {
    return property.mortgage?.mortgageDueDay;
  }
  return property.mortgage?.startDate ? dayjs(property.mortgage?.startDate).date() : '';
};

export const createFormDefaultValues = (property: Property): PropertyFormValues => ({
  purchase: {
    homeImprovementCost: coalesceNegative(property.purchaseHistory?.homeImprovementCost, ''),
    closingCost: coalesceNegative(property.purchaseHistory?.closingCost, ''),
    amount: coalesceNegative(property.purchaseHistory?.amount, undefined),
    date: property.purchaseHistory?.date ? dayjs(property.purchaseHistory.date) : null,
  },
  mortgage: {
    mortgageType: property.mortgage?.mortgageType ?? '',
    mortgageDueDay: getMortgageDueDay(property),
    date: property.mortgage?.startDate ? dayjs(property.mortgage?.startDate) : null,
    amount: property.mortgage?.amount,
    loanTerm: (
      property.mortgage?.term && ![120, 180, 240, 360].includes(property.mortgage.term)
        ? 'other'
        : property.mortgage?.term ?? ''
    ),
    otherLoanTerm: property.mortgage?.term,
    interestRate: property.mortgage?.rate,
  },
});

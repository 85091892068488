import {
  BoldTypography, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';

import { DetailRow } from './Row';
import { useProjections } from './useProjections';
import { RowProps } from '../types';

export const TotalExpenses = ({
  form,
}: RowProps) => {
  const l = useLabels();
  const projections = useProjections(form);

  return (
    <DetailRow
      form={form}
      rowID="total-expenses"
      totalRow
      bottomBorder={false}
      columns={[
        {
          id: 'total-expenses',
          content: (
            <SemiBoldTypography variant="body1">
              {l.totalExpenses}
            </SemiBoldTypography>
          ),
        },
        {
          id: 'total-expenses-year-1',
          content: (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.totalExpenses.year1, 0)}
            </BoldTypography>
          ),
        },
        {
          id: 'total-expenses-year-2',
          content: (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.totalExpenses.year2, 0)}
            </BoldTypography>
          ),
        },
        {
          id: 'total-expenses-year-3',
          content: (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.totalExpenses.year3, 0)}
            </BoldTypography>
          ),
        },
      ]}
    />
  );
};

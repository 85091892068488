import { useEffect, useState } from 'react';

import { useKeenSlider } from 'keen-slider/react';
import { generateRandomInt } from 'lib';
import { Box, Typography } from '@mui/material';

export const TextSlider = ({
  textSlides,
  showSteps = false,
  minTimeout = 1000,
  maxTimeout = 1500,
  color = undefined,
}: {
  textSlides: string[],
  showSteps?: boolean,
  minTimeout?: number,
  maxTimeout?: number,
  color?: string,
}) => {
  const [currTextSlideIdx, setCurrTextSlideIdx] = useState<number>(0);
  const [textContainerRef, textInstanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: false,
    drag: false,
  });

  useEffect(() => {
    if (currTextSlideIdx < 4) {
      const timeout = setTimeout(() => {
        setCurrTextSlideIdx((val) => val + 1);
        textInstanceRef.current!.next();
      }, generateRandomInt(minTimeout, maxTimeout));

      return () => clearTimeout(timeout);
    }

    return () => null;
  }, [currTextSlideIdx]);

  return (
    <Box className="keen-slider" ref={textContainerRef}>
      {textSlides.map((text, idx) => (
        <Typography variant="h6" className="keen-slider__slide" sx={{ textAlign: 'center', color }} key={text}>
          {showSteps && (
            <>
              {showSteps && idx + 1}
              {' '}
              /
              {' '}
              {textSlides.length}
              <br />
            </>
          )}
          {text}
        </Typography>
      ))}
    </Box>
  );
};

import { axiosWithPayloadContext, config, useAuth } from 'lib';
import { useMutation } from '@tanstack/react-query';

import { createHeaders } from '../index';

export const useCreateFavorite = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (
    marketplacePropertyId: string,
  ) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/marketplace-favorites`,
      method: 'POST',
      headers: createHeaders(token),
      data: {
        marketplacePropertyId,
      },
    });
  });
};

export const useDeleteFavorite = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (
    favoriteId: string,
  ) => {
    const token = await getAccessTokenSilently();
    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/marketplace-favorites/${favoriteId}`,
      method: 'DELETE',
      headers: createHeaders(token),
    });
  });
};

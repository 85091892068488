import { IoEyeOutline } from 'react-icons/io5';
import { InvertedButton, Spinner, useLabels } from 'ui';
import { Stack } from '@mui/material';

export const RevealOverlayList = ({
  reveal, revealed, isRevealing, disabled,
}: {
  reveal: () => Promise<void>,
  revealed: boolean,
  isRevealing: boolean,
  disabled: boolean,
}) => {
  const l = useLabels();

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  return (
    <Stack
      display={revealed ? 'none' : undefined}
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{
        backdropFilter: 'blur(3px)',
      }}
    >
      <InvertedButton
        disabled={disabled}
        variant="outlined"
        size="small"
        onClick={reveal}
        startIcon={isRevealing ? null : <IoEyeOutline />}
      >
        {isRevealing ? spinner : l.reveal}
      </InvertedButton>
    </Stack>
  );
};

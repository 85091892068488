export const WelcomeCircleCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={57}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={56} height={56} rx={28} fill="#fff" />
    <path
      d="M23.5 34.95L16.55 28l-2.367 2.35 9.317 9.316 20-20-2.35-2.35L23.5 34.95z"
      fill="#16AC52"
    />
  </svg>
);

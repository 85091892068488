import {
  Box, Card, Grid, keyframes, styled,
} from '@mui/material';

export const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100% !important',
  maxWidth: '100% !important',
  [theme.breakpoints.up('sm')]: {
    width: '50% !important',
    maxWidth: '50% !important',
  },
}));

export const ClickableCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    '& .MuiTypography-root': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FooterActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
  bottom: '-1px',
  background: theme.palette.background.paper,
  zIndex: '1600',
  '&.sticky': {
    position: 'sticky',
    animation: `${fadeIn} 0.3s ease-in-out`,
  },
}));

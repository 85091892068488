import { getOwnerFromID, useAnalytics } from 'lib';
import { MdVerified } from 'react-icons/md';
import { InfoTooltip, Spinner, useLabels } from 'ui';
import {
  Box, Stack, Typography, useTheme,
} from '@mui/material';

import { BasicOwnerRisk } from '../../../../api/properties/types';

export const OwnerCell = ({
  ownerRisk,
  activated,
  isLoading,
}: {
  ownerRisk: BasicOwnerRisk,
  activated: boolean,
  isLoading: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const iconSize = 16;

  const owner = getOwnerFromID(ownerRisk.ownerID);

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Typography variant="body1">
        {ownerRisk.ownerDetails.firstName
          ? `${ownerRisk.ownerDetails.firstName} ${ownerRisk.ownerDetails.lastName ?? ''}`
          : owner.email}
      </Typography>
      {isLoading ? (
        <Box position="relative" width={iconSize} height={iconSize}><Spinner size={iconSize} /></Box>
      ) : activated && (
        <InfoTooltip
          title={(
            <Typography variant="body2" sx={{ p: 1 }}>
              {l['retention.tooltip.verifiedOwner']}
            </Typography>
          )}
          arrow
          isLight
          icon={<MdVerified color={theme.palette.primary.dark} size={iconSize} />}
          track={(value) => {
            analytics.track('Tooltip Toggled', {
              value,
              tooltipName: 'Activated Owner Tooltip',
            });
          }}
        />
      )}
    </Stack>
  );
};

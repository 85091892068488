import { ReactNode } from 'react';

import { Typography } from '@mui/material';

import { EmptyFullPageContainer } from '../container/EmptyFullPageContainer';

export const ComingSoonPage = ({ icon }: { icon: ReactNode }) => (
  <EmptyFullPageContainer>
    {icon}
    <Typography variant="h6">Coming soon...</Typography>
  </EmptyFullPageContainer>
);

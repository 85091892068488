import {
  BoldTypography,
  InfoTooltip,
  useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';

import { DetailRow } from './Row';
import { Form } from '../types';

export const Income = ({ form }: { form: Form }) => {
  const l = useLabels();

  return (
    <DetailRow
      form={form}
      rowID="income"
      bottomBorder={false}
      columns={[
        {
          id: 'income',
          content: (
            <Stack direction="row" gap={3}>
              <BoldTypography variant="body1">
                {l.income}
              </BoldTypography>
              <InfoTooltip
                title={(
                  <Typography variant="body2">
                    {l['tooltip.proforma.netOperatingIncome']}
                  </Typography>
                )}
                isLight
                isOutlined
              />
            </Stack>
          ),
        },
        {},
        {},
        {},
      ]}
    />

  );
};

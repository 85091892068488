import { keyframes, styled } from '@mui/material';

const topBarKeyframes = keyframes`
  0% {
    top: 0%;
  } 50% {
    top: 50%;
    transform: rotate(0deg);
  } 75% {
    top: 50%;
    transform: rotate(0deg);
  } 100% {
    top: 50%;
    transform: rotate(45deg);
  }
`;

const topBarReverseKeyframes = keyframes`
  0% {
    top: 50%;
    transform: rotate(45deg);
  } 50% {
    top: 50%;
    transform: rotate(0deg);
  } 75% {
    top: 50%;
    transform: rotate(0deg);
  } 100% {
    top: 0;
  }
`;

const bottomBarKeyframes = keyframes`
  0% {
    top: 100%;
  } 50% {
    top: 50%;
    transform: rotate(0deg);
  } 75% {
    top: 50%;
    transform: rotate(0deg);
  } 100% {
    top: 50%;
    transform: rotate(-45deg);
  }
`;

const bottomBarReverseKeyframes = keyframes`
  0% {
    top: 50%;
    transform: rotate(-45deg);
  } 50% {
    top: 50%;
    transform: rotate(0deg);
  } 75% {
    top: 50%;
    transform: rotate(0deg);
  } 100% {
    top: 100%;
  }
`;

export const HamburgerContainer = styled('button')(({ theme }) => ({
  position: 'relative',
  background: 'transparent',
  border: 'none',
  padding: 0,
  width: 35,
  height: 20,
  marginRight: theme.spacing(4),
  '& .bar:nth-of-type(1)': {
    top: 0,
    '&:not(.silent)': {
      animation: `${topBarReverseKeyframes} 0.25s ease-in-out forwards`,
    },
  },
  '& .bar:nth-of-type(2)': { top: '50%' },
  '& .bar:nth-of-type(3)': {
    top: '100%',
    '&:not(.silent)': {
      animation: `${bottomBarReverseKeyframes} 0.25s ease-in-out forwards`,
    },
  },
  '&.open .bar:nth-of-type(1)': {
    animation: `${topBarKeyframes} 0.25s ease-in-out forwards`,
  },
  '&.open .bar:nth-of-type(2)': {
    opacity: 0,
  },
  '&.open .bar:nth-of-type(3)': {
    animation: `${bottomBarKeyframes} 0.25s ease-in-out forwards`,
  },
}));

export const HamburgerBar = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: 5,
  backgroundColor: theme.palette.text.primary,
  transition: '0.4s',
  borderRadius: 5,
  transformOrigin: 'center center',
}));

import {
  EmptyFullPageContainer,
  PropertyIcon,
  SemiBoldTypography, useLabels,
} from 'ui';
import { Stack } from '@mui/material';

export const Properties = () => {
  const l = useLabels();

  return (
    <EmptyFullPageContainer>
      <Stack alignItems="center" gap={2}>
        <PropertyIcon size={60} />
        <SemiBoldTypography variant="h5" color="text.primary">
          {l['pm-dashboard.properties.comingSoon']}
        </SemiBoldTypography>
      </Stack>
    </EmptyFullPageContainer>
  );
};

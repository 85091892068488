import { lighten } from '@mui/material';

/* eslint-disable max-len */
export const PersonSquareTopLeft = ({ size = 24, color = undefined }: { size?: number, color?: string }) => {
  const gClipPath = `clip-path-${Math.random()}`;
  const gradientClipPath = `clip-path-${Math.random()}`;

  return (
    <svg width={size} height={size} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1.11133" width="39" height="39" rx="3.5" fill="white" />
      <rect x="0.5" y="1.11133" width="39" height="39" rx="3.5" stroke="#D8D8D8" />
      <g clipPath={`url(#${gClipPath})`}>
        <rect width="14" height="14" rx="1.4" transform="matrix(1 0 0 -1 6 20.6113)" fill={`url(#${gradientClipPath})`} />
        <path fill="currentColor" d="M20.0013 13.496C21.3546 13.496 22.4513 14.5926 22.4513 15.946C22.4513 17.2993 21.3546 18.396 20.0013 18.396C18.648 18.396 17.5513 17.2993 17.5513 15.946C17.5513 14.5926 18.648 13.496 20.0013 13.496ZM20.0013 23.996C23.4663 23.996 27.118 25.6993 27.118 26.446V27.7293H12.8846V26.446C12.8846 25.6993 16.5363 23.996 20.0013 23.996ZM20.0013 11.2793C17.423 11.2793 15.3346 13.3676 15.3346 15.946C15.3346 18.5243 17.423 20.6126 20.0013 20.6126C22.5796 20.6126 24.668 18.5243 24.668 15.946C24.668 13.3676 22.5796 11.2793 20.0013 11.2793ZM20.0013 21.7793C16.8863 21.7793 10.668 23.3426 10.668 26.446V29.946H29.3346V26.446C29.3346 23.3426 23.1163 21.7793 20.0013 21.7793Z" />
      </g>
      <defs>
        <linearGradient id={gradientClipPath} x1="0.874999" y1="17" x2="8.40048" y2="1.20799" gradientUnits="userSpaceOnUse">
          <stop stopColor={color ?? '#F24476'} />
          <stop offset="1" stopColor={color ? lighten(color, 0.9) : '#F4E2E7'} stopOpacity="0.35" />
        </linearGradient>
        <clipPath id={gClipPath}>
          <rect width="28" height="28" fill="white" transform="translate(6 6.61133)" />
        </clipPath>
      </defs>
    </svg>

  );
};

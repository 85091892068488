import dayjs from 'dayjs';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { config } from '../../config/config';
import { queryGraphQL } from '../amplify';
import { AppSettings } from '../API';
import { getAppSettings } from '../graphql/queries';

export const useAppSettings = () => useQuery(['appSettings'], async (): Promise<AppSettings> => {
  if (config.devMode) {
    return {
      __typename: 'AppSettings',
      id: window.location.origin,
      color: '#2020ee',
      createdAt: dayjs().toISOString(),
      favicon: 'https://assets.blankethomes.com/pms/portico-production/favicon.png',
      logo: 'https://assets.blankethomes.com/pms/portico-production/blanketlogo.svg',
      pmName: 'Blanket',
      tenant: 'portico',
      title: 'Blanket - Investor Dashboard',
      updatedAt: dayjs().toISOString(),
    };
  }

  const resp = await queryGraphQL({
    query: getAppSettings,
    authMode: 'API_KEY',
    variables: { id: window.location.origin },
  }) as GraphQLResult<{ getAppSettings: AppSettings }>;

  if (resp.errors && resp.errors.length > 0) {
    throw new Error(`failed to get app settings. data: ${JSON.stringify(resp.errors)}`);
  }

  if (typeof resp.data === 'undefined') {
    throw new Error('got empty app settings');
  }

  return {
    ...resp.data.getAppSettings,
  };
}, { staleTime: Infinity });

import { useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import { OwnerRiskStatus, sum, useAnalytics } from 'lib';
import Apexcharts from 'react-apexcharts';
import {
  BoldTypography, formatDecimalToPercentage, InfoTooltip, MissingDataIcon, SpinnerWithLogo, useLabels,
} from 'ui';
import {
  Box,
  Card, CardContent, Stack, Theme, Typography,
  useTheme,
} from '@mui/material';

import { useGetOwnerRisksByPM } from '../../../api/owner-risk';
import { RetainTab } from '../state';

export const expensesWithLegendChartConfig = (theme: Theme, score: number, showScore: boolean): ApexOptions => ({
  chart: {
    type: 'donut',
    parentHeightOffset: 10,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            show: false,
            fontSize: '40px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
          },
          value: {
            show: true,
            fontSize: '14px',
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
          },
          total: {
            show: true,
            label: 'Total',
            fontSize: '32px',
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            formatter: () => (showScore ? formatDecimalToPercentage(score, 0) : ''),
          },
        },
      },
    },
  },
  legend: {
    show: false,
  },
  stroke: {
    show: true,
  },
  dataLabels: {
    enabled: false,
  },
});

type StatusConfig = {
  label: string,
  color: string,
  count: number,
};

export const BlanketScore = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { data, isLoading, isError } = useGetOwnerRisksByPM();

  const ownerRiskCounts: Record<RetainTab, number> = useMemo(() => {
    const allOwnerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];
    const noRisk = allOwnerRisks.filter((ownerRisk) => ownerRisk.isNoRisk);
    const risks = allOwnerRisks.filter((ownerRisk) => !ownerRisk.isNoRisk);

    return {
      new: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.NEW).length,
      inProgress: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.IN_PROGRESS).length,
      resolved: risks.filter((ownerRisk) => ownerRisk.status === OwnerRiskStatus.RESOLVED).length,
      noRisk: noRisk.length,
    };
  }, [data]);

  const ownerRisks = data?.pages.flatMap((page) => page.ownerRisks) ?? [];

  const statusToConfig: Record<OwnerRiskStatus, StatusConfig> = {
    [OwnerRiskStatus.NEW]: {
      label: l['retention.tab.new'], color: theme.palette.info.main, count: ownerRiskCounts.new,
    },
    [OwnerRiskStatus.IN_PROGRESS]: {
      label: l['retention.tab.inProgress'], color: theme.palette.warning.main, count: ownerRiskCounts.inProgress,
    },
    [OwnerRiskStatus.RESOLVED]: {
      label: l['retention.tab.resolved'], color: theme.palette.success.main, count: ownerRiskCounts.resolved,
    },
  };

  const averageScore = sum(ownerRisks.map((ownerRisk) => (ownerRisk.risk ?? 0) / 100)) / ownerRisks.length;

  const options: ApexOptions = {
    labels: [
      statusToConfig[OwnerRiskStatus.NEW].label,
      statusToConfig[OwnerRiskStatus.IN_PROGRESS].label,
      statusToConfig[OwnerRiskStatus.RESOLVED].label,
    ],
    colors: [
      statusToConfig[OwnerRiskStatus.NEW].color,
      statusToConfig[OwnerRiskStatus.IN_PROGRESS].color,
      statusToConfig[OwnerRiskStatus.RESOLVED].color,
    ],
    // TODO: revert once we fix the calculation logic
    ...expensesWithLegendChartConfig(theme, averageScore, false),
  };

  const series: ApexOptions['series'] = [ownerRiskCounts.new, ownerRiskCounts.inProgress, ownerRiskCounts.resolved];

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{
        height: '100%', display: 'flex', flexDirection: 'column',
      }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BoldTypography variant="h6">
            {l['retention.blanketScore']}
          </BoldTypography>
          <InfoTooltip
            arrow
            isLight
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {l['retention.tooltip.blanketScore']}
              </Typography>
            )}
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Blanket Score',
              });
            }}
          />
        </Stack>
        <Stack flexGrow={1} justifyContent="center">
          {isError && (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <MissingDataIcon iconProps={{ size: 36 }} boxProps={{ sx: { borderRadius: '100%' } }} />
            </Stack>
          )}
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" flexGrow={1}>
              <SpinnerWithLogo size={48} />
            </Stack>
          ) : !isError && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 100,
                }}
              >
                <Apexcharts options={options} series={series} type="donut" height={150} width={295} />
              </Stack>
              <Stack>
                {Object.entries(statusToConfig).map(([status, config]) => (
                  <Stack direction="row" alignItems="center" gap={3} key={`blanket-score-label-${status}`}>
                    <Box
                      height={10}
                      width={10}
                      borderRadius={100}
                      sx={{
                        background: config.color,
                      }}
                    />
                    {config.label}
                    {' '}
                    (
                    {statusToConfig[status as OwnerRiskStatus].count}
                    )
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

import {
  createContext, useContext, useMemo, useState,
} from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import { styled, SxProps } from '@mui/material/styles';

import { Navigation } from './Navigation';
import { themeConfig } from '../../configs/themeConfig';
import { useSettings } from '../../hooks/useSettings';
import { LayoutProps } from '../types';

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
});

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
});

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

// eslint-disable-next-line
const LayoutStylesContext = createContext<{ paddingDisabled: boolean, setPadding: (_v: boolean) => void, setMainSxProps: (sx: SxProps) => void }>
  ({ paddingDisabled: false, setPadding: () => {}, setMainSxProps: () => {} });

export const useLayoutStyles = () => {
  const context = useContext(LayoutStylesContext);

  if (context === undefined) {
    throw new Error('useLayoutStyles must be used within a LayoutStylesContext.Provider');
  }

  return context;
};

export const VerticalLayout = (props: LayoutProps) => {
  const { children } = props;
  const { settings } = useSettings();

  const { navHidden, contentWidth } = settings;
  const { navigationSize, collapsedNavigationSize } = themeConfig;
  const navWidth = navigationSize;
  const navigationBorderWidth = 1;
  const collapsedNavWidth = collapsedNavigationSize;

  const [isPaddingDisabled, setPaddingDisabled] = useState<boolean>(false);
  const [mainSxProps, setMainSxProps] = useState<SxProps>({});
  const [navHover, setNavHover] = useState<boolean>(false);
  const [navVisible, setNavVisible] = useState<boolean>(false);

  const toggleNavVisibility = () => { setNavVisible((prevState) => !prevState); };

  const contextProvider = useMemo(() => ({
    paddingDisabled: isPaddingDisabled,
    setPadding: setPaddingDisabled,
    setMainSxProps,
  }), [isPaddingDisabled, setPaddingDisabled, setMainSxProps]);

  return (
    <VerticalLayoutWrapper className="layout-wrapper">
      {navHidden
        && themeConfig.layout === 'vertical'
        && !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <Navigation
            navWidth={navWidth}
            navHover={navHover}
            navVisible={navVisible}
            setNavHover={setNavHover}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            {...props}
          />
        )}
      <LayoutStylesContext.Provider value={contextProvider}>
        <MainContentWrapper className="layout-content-wrapper">
          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
                '@media (min-width:1440px)': { maxWidth: 1440 },
                '@media (min-width:1200px)': { maxWidth: '100%' },
              }),
              padding: isPaddingDisabled ? 0 : 1.6,
              ...mainSxProps,
            }}
          >
            {children}
          </ContentWrapper>
        </MainContentWrapper>
      </LayoutStylesContext.Provider>
    </VerticalLayoutWrapper>
  );
};

import { useMutation } from '@tanstack/react-query';

import { useAuth } from '../../hooks/useAuth';
import { axiosWithPayloadContext, createHeaders } from '../axios';

export const useGetRedirectURL = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    url, params,
  }: {
    url: string,
    params: Record<string, string>
  }): Promise<string | null> => {
    const token = await getAccessTokenSilently();

    const response = await axiosWithPayloadContext<{ url: string }>({
      url,
      method: 'POST',
      params,
      headers: createHeaders(token),
    });

    return response.data.url ?? null;
  });
};

import { hexToRGBA } from 'ui';
import {
  Box, DialogContent, Stack, styled, ToggleButton,
} from '@mui/material';

export const AskingPriceContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 6,
  padding: `${theme.spacing(6)} 0`,
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    marginTop: 0,
  },
}));

export const PropertyImage = styled('img')(({ theme }) => ({
  margin: `${theme.spacing(5)} 0`,
  position: 'relative',
  borderRadius: 6,
}));

export const CtaContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: `0 ${theme.spacing(-3)}`,
  borderTop: `1px solid ${hexToRGBA(theme.palette.text.primary, 0.12)}`,
  position: 'sticky',
  bottom: 0,
  background: theme.palette.background.paper,
  [theme.breakpoints.up('md')]: {
    background: theme.palette.background.default,
    borderTop: 'none',
    position: 'static',
    margin: 0,
    padding: 0,
  },
}));

export const SuccessPopupContent = styled(DialogContent)(({ theme }) => ({
  padding: `${theme.spacing(6)} !important`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&': {
    '&.small': {
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
      fontSize: theme.typography.body2.fontSize,
    },
    '&.secondary': {
      color: theme.palette.text.secondary,
    },
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    background: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    border: `1px solid ${theme.palette.divider}`,
    fontWeight: 800,
  },
  '&.Mui-selected': {
    background: theme.palette.primary.dark,
    color: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

export const ListingDetailsContainer = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey.A100}`,
  borderRadius: '12px',
  padding: theme.spacing(4),
  background: theme.palette.common.white,
}));

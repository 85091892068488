import { ReactElement } from 'react';

import {
  Button, Chip, Stack, useTheme,
} from '@mui/material';

import { MenuItemProps } from './types';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

export const MenuItem = ({
  label,
  onClick,
  icon,
  isActive = false,
  dataTestId = '',
  chipContent = undefined,
}: MenuItemProps): ReactElement => {
  const theme = useTheme();
  return (
    <Stack
      data-testid={dataTestId}
      direction="row"
      justifyContent="space-between"
      component={Button}
      onClick={onClick}
      gap={3}
      py={2.5}
      px={4}
      fullWidth
      sx={{
        color: isActive ? theme.palette.primary.contrastText : theme.palette.text.primary,
        background: isActive ? theme.palette.primary.main : undefined,
        borderRadius: 0,
        '&&&:hover': {
          background: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={3}>
        {icon}
        <SemiBoldTypography
          variant="body2"
          sx={{ textTransform: 'none', color: 'inherit' }}
        >
          {label}
        </SemiBoldTypography>
      </Stack>
      <Chip
        label={chipContent}
        size="small"
        sx={{
          transition: 'scale 0.1s ease-in-out',
          scale: chipContent ? 1 : 0,
          '&.MuiChip-root': {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          },
        }}
      />
    </Stack>
  );
};

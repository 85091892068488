import { MarketplacePMPreferences, parseManagementFee } from 'lib';

import { PMPreferences } from './types';

export const convertPMPreferences = (pmPreferences: MarketplacePMPreferences | null): PMPreferences => {
  const managementFee = parseManagementFee(pmPreferences?.managementFee);

  return {
    managementFee: managementFee.isDollars ? managementFee.value * 12 : managementFee.value,
    isManagementFeeDollars: managementFee.isDollars,
  };
};

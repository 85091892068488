export const PropertyIcon = ({ size = 20 }: { size?: number }) => {
  const clipPathID = `clip-path-${Math.random()}`;

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="11" height="9" rx="1" transform="matrix(1 0 0 -1 0 14)" fill={`url(#${clipPathID})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63399 2L2 9.08871V18.5702H17.268V9.08871L9.63399 2ZM9.63399 4.2L15.5286 9.7V16.9H3.67864V9.7L9.63399 4.2Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id={clipPathID}
          x1="0.733333"
          y1="9"
          x2="6.44937"
          y2="1.08241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F24476" />
          <stop offset="1" stopColor="#E7C8D1" stopOpacity="0.46" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { useCallback, useEffect, useState } from 'react';

import {
  useAnalytics,
} from 'lib';
import {
  Controller, useForm,
} from 'react-hook-form';
import { BsArrowRightCircle } from 'react-icons/bs';
import {
  Reset,
  SemiBoldTypography,
  useLabels,
} from 'ui';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';

import { AskingPrice } from './AskingPrice';
import { CapRate } from './CapRate';
import { CashFlow } from './CashFlow';
import { EstimatedRent } from './EstimatedRent';
import { EstimatedVacancy } from './EstimatedVacancy';
import { ExpectedRent } from './ExpectedRent';
import { Expenses } from './Expenses';
import { GrossYield } from './GrossYield';
import { Income } from './Income';
import { LoanPayments } from './LoanPayments';
import { OperatingIncome } from './OperatingIncome';
import { PurchasePrice } from './PurchasePrice';
import { DetailRow } from './Row';
import { ListingProperty } from '../../../../../types/property';
import { ListingDetailsContainer, StyledToggleButton } from '../../styled';
import {
  CalculationType, EView, Form, PropertyDetailsFormData,
} from '../types';

const tableWidth = 627;

const createInitialFormData = (property: ListingProperty): PropertyDetailsFormData => ({
  view: EView.MONTHLY,
  editMode: false,
  details: {
    purchasePrice: property.askingPrice,
    income: {
      rent: property.rent.value ?? 0,
    },
    estimatedVacancy: {
      value: property.listingItem?.estimatedVacancy ?? 0,
      type: CalculationType.NUMBER,
    },
    expenses: {
      tax: {
        value: property.expenses.tax ?? 0,
        type: CalculationType.NUMBER,
      },
      insurance: {
        value: property.expenses.insurance ?? 0,
        type: CalculationType.NUMBER,
      },
      hoa: {
        value: property.expenses.hoa ?? 0,
        type: CalculationType.NUMBER,
      },
      managementFee: {
        value: property.expenses.managementFee ?? 0,
        type: CalculationType.NUMBER,
      },
      maintenance: {
        value: property.listingItem?.maintenance ?? 0,
        type: CalculationType.NUMBER,
      },
    },
    loanPayments: {
      downpayment: (property.askingPrice ?? 0) * 0.25,
      downpaymentPercent: 25,
      interestRate: 6,
      mortgageAmortizationPeriod: 240,
    },
  },
});

const DetailsTable = ({ form, headerHeight }: { form: Form, headerHeight: number }) => {
  const l = useLabels();
  const theme = useTheme();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [scrollButtonClicked, setScrollButtonClicked] = useState(false);
  const [width, setWidth] = useState(0);
  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContainer(node);
      setWidth(node.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (container) {
        setWidth(container.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [container]);

  const handleClickArrow = () => {
    setScrollButtonClicked(true);
    container?.scrollBy({
      left: tableWidth,
      behavior: 'smooth',
    });
  };

  return (
    <Stack
      ref={ref}
      sx={{
        overflowX: 'scroll',
        overflowY: 'hidden',
        maxWidth: '100%',
      }}
    >
      {width < tableWidth && !scrollButtonClicked && (
        <Stack
          pt={`calc(${headerHeight}px + ${theme.spacing(12)})`}
          pr={4}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            height: '100%',
          }}
        >
          <IconButton
            onClick={handleClickArrow}
            sx={{
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              background: `${theme.palette.background.paper} !important`,
            }}
          >
            <BsArrowRightCircle />
          </IconButton>
        </Stack>
      )}
      <Stack pb={4} minWidth={tableWidth}>
        <DetailRow
          form={form}
          rowID="year-projections"
          bottomBorder={false}
          columns={[
            {},
            {
              id: 'one-year',
              content: (
                <SemiBoldTypography variant="body1">
                  {l.oneYear}
                </SemiBoldTypography>
              ),
            },
            {
              id: 'two-year',
              content: (
                <SemiBoldTypography variant="body1">
                  {l.twoYear}
                </SemiBoldTypography>
              ),
            },
            {
              id: 'three-year',
              content: (
                <SemiBoldTypography variant="body1">
                  {l.threeYear}
                </SemiBoldTypography>
              ),
            },
          ]}
        />
        <PurchasePrice form={form} />
        <AskingPrice form={form} />
        <Income form={form} />
        <EstimatedRent form={form} />
        <EstimatedVacancy form={form} />
        <ExpectedRent form={form} />
        <GrossYield form={form} />
        <Expenses form={form} />
        <OperatingIncome form={form} />
        <CapRate form={form} />
        <LoanPayments form={form} />
        <CashFlow form={form} />
      </Stack>
    </Stack>
  );
};

export const ListingDetails = ({ listing }: { listing: ListingProperty }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();

  const [header, setHeader] = useState<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeader(node);
      setHeaderHeight(node.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [header]);

  const form = useForm<PropertyDetailsFormData>({
    defaultValues: createInitialFormData(listing),
  });
  const {
    control,
    watch,
    setValue,
    getValues,
    reset: resetForm,
  } = form;

  const editMode = watch('editMode');
  const view = watch('view');
  const rent = watch('details.income.rent');

  const handleViewChange = (selectedView: EView | null) => {
    if (!selectedView || view === selectedView) return;

    const fieldNames = [
      'details.expenses.tax',
      'details.expenses.insurance',
      'details.expenses.hoa',
      'details.expenses.managementFee',
      'details.expenses.maintenance',
      'details.estimatedVacancy',
    ] as const;

    if (selectedView === EView.YEARLY) {
      setValue('details.income.rent', rent * 12);
    } else {
      setValue('details.income.rent', rent / 12);
    }

    fieldNames.forEach((fieldName) => {
      const field = getValues(fieldName);

      if (field.type === CalculationType.PERCENTAGE) return;

      if (selectedView === EView.YEARLY) {
        setValue(`${fieldName}.value`, field.value * 12);
      } else {
        setValue(`${fieldName}.value`, field.value / 12);
      }
    });
  };

  return (
    <ListingDetailsContainer sx={{
      px: 0,
      pt: 0,
      position: 'relative',
    }}
    >
      <Grid
        container
        ref={headerRef}
        px={4}
        sx={{
          position: 'sticky !important',
          top: 0,
          py: 4,
          zIndex: 1000,
          background: theme.palette.background.paper,
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}
      >
        <Grid item xs={6} sm={4}>
          <Typography variant="h6">
            {l['listing.propertyDetails']}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={8}>
          <Stack direction="row-reverse" flexWrap="wrap" gap={4}>
            <Controller
              control={control}
              name="view"
              render={({ field }) => (
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  exclusive
                  {...field}
                  onChange={(e, value) => {
                    field.onChange(e, value);
                    handleViewChange(value as EView | null);

                    analytics.track('Switch Toggled', {
                      value,
                      switchName: 'Listing Details - Month/Year Toggle',
                    });
                  }}
                >
                  <StyledToggleButton value={EView.MONTHLY}>{l.month}</StyledToggleButton>
                  <StyledToggleButton value={EView.YEARLY}>{l.year}</StyledToggleButton>
                </ToggleButtonGroup>
              )}
            />
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                form.setValue('editMode', !editMode);

                analytics.track('Button Clicked', {
                  buttonName: `Listing Details - ${editMode ? 'Apply' : 'Edit'} Assumptions`,
                });
              }}
            >
              {editMode ? l.apply : l.editAssumptions}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DetailsTable form={form} headerHeight={headerHeight} />
      <Button
        variant="text"
        onClick={() => {
          resetForm();

          analytics.track('Button Clicked', {
            buttonName: 'Listing Details - Reset to Default',
          });
        }}
        sx={{
          color: theme.palette.primary.dark,
          fontWeight: theme.typography.fontWeightMedium,
          width: 'fit-content',
          mx: 'auto',
          textTransform: 'none',
        }}
      >
        <Stack direction="row" gap={2} alignItems="center">
          <Reset />
          <Box>{l['listing.propertyDetails.resetToDefault']}</Box>
        </Stack>
      </Button>
    </ListingDetailsContainer>
  );
};

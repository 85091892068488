export const sortByDesc = <T>(array: T[], key: keyof T): T[] => array.sort((a, b) => {
  if (a[key] > b[key]) {
    return -1;
  }
  if (a[key] < b[key]) {
    return 1;
  }
  return 0;
});

export const sortByAsc = <T>(array: T[], key: keyof T): T[] => array.sort((a, b) => {
  if (a[key] > b[key]) {
    return 1;
  }
  if (a[key] < b[key]) {
    return -1;
  }
  return 0;
});

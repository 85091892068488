import { useAnalytics } from 'lib';
import { GiHouse } from 'react-icons/gi';
import { Chip, formatNumberToCurrency } from 'ui';
import {
  Box,
  Card,
  CardMedia,
  Stack, Typography, useTheme,
} from '@mui/material';

import { Activity, ActivityStatus } from '../../types/activity';

export type ActivityRowProps = {
  activity: Activity
};

export const ActivityRow = ({ activity }: ActivityRowProps) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  const statuses = {
    [ActivityStatus.PENDING_LISTING]: <Chip
      sx={{
        p: '3px',
      }}
      color="warning"
      label={ActivityStatus.PENDING_LISTING}
      skin="light"
      size="small"
    />,
    [ActivityStatus.ACTIVE]: <Chip
      sx={{
        p: '3px',
      }}
      color="primary"
      label={ActivityStatus.ACTIVE}
      skin="light"
      size="small"
    />,
    [ActivityStatus.UNDER_CONTRACT]: <Chip
      sx={{
        p: '3px',
        color: '#173771',
      }}
      color="secondary"
      label={ActivityStatus.UNDER_CONTRACT}
      skin="light"
      size="small"
    />,
    [ActivityStatus.SOLD]: <Chip
      sx={{
        p: '3px',
      }}
      color="secondary"
      label={ActivityStatus.SOLD}
      skin="light"
      size="small"
    />,
    [ActivityStatus.ESCROW]: <Chip
      sx={{
        p: '3px',
      }}
      color="secondary"
      label={ActivityStatus.ESCROW}
      skin="light"
      size="small"
    />,
    [ActivityStatus.PENDING_SALE]: <Chip
      sx={{
        p: '3px',
      }}
      color="warning"
      label={ActivityStatus.PENDING_SALE}
      skin="light"
      size="small"
    />,
    [ActivityStatus.INVISIBLE]: null,
  };

  return (
    <Card onClick={() => {
      analytics.track('Button Clicked', {
        buttonName: 'Activity',
        activityId: activity.id,
        ...activity,
        offers: activity.offers.map((o) => o.id).join(','),
      });
    }}
    >
      <Stack direction="row" alignItems="center">
        {activity.picture ? (
          <Box
            sx={{
              minWidth: 80,
              width: 80,
              height: 80,
              overflow: 'hidden',
            }}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <CardMedia
              sx={{ minWidth: '100%' }}
              component="img"
              image={activity.picture ?? ''}
            />
          </Box>
        ) : (
          <Stack alignItems="center">
            <GiHouse size={80} />
          </Stack>
        )}
        <Stack direction="column" spacing={2} width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Stack pl={3}>
              {statuses[activity.status]}
            </Stack>
            <Stack pr={3} alignItems="end">
              {formatNumberToCurrency(activity.askingPrice, 0)}
            </Stack>
          </Stack>
          <Stack pl={5} gap={2}>
            <Typography variant="body2" component="span">
              {activity.address}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {activity.offers.length > 0 && (
        <Stack pb={3} borderTop={`1px solid ${theme.palette.divider}`}>
          {(activity.offers ?? []).map((offer) => (
            <Stack key={offer.id} pt={3} direction="row" justifyContent="space-between" width="100%">
              <Stack pl={3}>
                {offer.status}
              </Stack>
              <Stack pr={3} alignItems="end">
                {formatNumberToCurrency(offer.bid, 0)}
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
    </Card>
  );
};

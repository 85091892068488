import { Theme } from '@mui/material/styles';

export const overrideRating = (theme: Theme) => ({
  MuiRating: {
    styleOverrides: {
      root: {
        color: theme.palette.warning.main,
      },
      iconEmpty: {
        color: `rgba(${theme.palette.customColors.main}, 0.22)`,
      },
    },
  },
});

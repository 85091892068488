import {
  BoldTypography, InfoTooltip, useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';

import { DetailRow } from './Row';
import { RowProps } from '../types';

export const PurchasePrice = ({ form }: RowProps) => {
  const l = useLabels();

  return (
    <DetailRow
      form={form}
      rowID="purchase-price"
      bottomBorder={false}
      columns={[
        {
          content: (
            <Stack direction="row" gap={3}>
              <BoldTypography variant="body1">
                {l.homePrice}
              </BoldTypography>
              <InfoTooltip
                title={(
                  <Typography variant="body2">
                    {l['tooltip.proforma.homePrice']}
                  </Typography>
                )}
                isLight
                isOutlined
              />
            </Stack>
          ),
          id: 'purchase-price',
        },
        {},
        {},
        {},
      ]}
    />
  );
};

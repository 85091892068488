import { useEffect } from 'react';
import {
  Location, NavigateFunction, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';

import {
  checkIsFirstLogin, Claims,
  firstLoad, impersonationToken, useAuth,
  useIsOldNavigationEnabled,
  usePermissions,
  User,
} from 'lib';

import { analytics } from '../lib/3rd-parties/analytics';

const isFirstLogin = (claims: Claims | undefined): boolean => !!claims?.isFirstLogin
 && !checkIsFirstLogin();

const handlePMDashboardRedirect = (navigate: NavigateFunction, location: Location, isOldNavigationEnabled: boolean) => {
  if (location.pathname === '/') {
    navigate(isOldNavigationEnabled ? '/admin' : '/pm');
  }
};

const handleDashboardRedirect = (claims: Claims | undefined, navigate: NavigateFunction, location: Location) => {
  if (location.pathname === '/' && isFirstLogin(claims)) {
    localStorage.setItem('firstLoginDetected', '.');
    navigate('/onboarding', { state: { firstLogin: true } });
  }
};

const handleMarketplaceRedirect = (navigate: NavigateFunction, location: Location) => {
  if (location.pathname === '/') {
    navigate('/marketplace');
  }
};

const handleLeadsRedirect = (navigate: NavigateFunction, location: Location, isOldNavigationEnabled: boolean) => {
  if (location.pathname === '/') {
    navigate(isOldNavigationEnabled ? '/admin/deals' : '/pm/leads');
  }
};

const handleReferralPartnerRedirect = (navigate: NavigateFunction, location: Location) => {
  if (location.pathname === '/') {
    navigate('/referral-partners');
  }
};

const setIdForThirdParties = (claims: Claims | undefined, user: User | undefined) => {
  if (claims && user) {
    analytics.identify(user.realID, {
      pm: user.pm,
      given_name: user.givenName,
      family: user.familyName,
      email: user.email,
      name: user.name,
      picture: user.picture,
      isActAs: claims.isActAs,
    });
    analytics.register({ isActAs: claims.isActAs });
  }
};

export const usePostLogin = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: isOldNavigationEnabled, loading: loadingIsOldNavigationEnabled } = useIsOldNavigationEnabled();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    user,
    isAuthenticated,
    getIdTokenClaims,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (searchParams.get(impersonationToken)) {
      sessionStorage.setItem(impersonationToken, searchParams.get(impersonationToken) ?? '');
      setSearchParams('');
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    analytics.page();
  }, [location.pathname, isAuthenticated]);

  useEffect(() => {
    if (firstLoad.value && isAuthenticated && user && !isLoadingPermissions && !loadingIsOldNavigationEnabled) {
      analytics.enrich();

      getIdTokenClaims().then((claims) => {
        firstLoad.value = false;

        setIdForThirdParties(claims, user);

        if (permissions?.viewPMDashboard) {
          return handlePMDashboardRedirect(navigate, location, isOldNavigationEnabled);
        }

        if (permissions?.viewLeads) {
          return handleLeadsRedirect(navigate, location, isOldNavigationEnabled);
        }

        if (permissions?.viewDashboard) {
          return handleDashboardRedirect(claims, navigate, location);
        }

        if (permissions?.viewReferralPartner) {
          return handleReferralPartnerRedirect(navigate, location);
        }

        if (permissions?.viewMarketplace) {
          return handleMarketplaceRedirect(navigate, location);
        }

        return null;
      }).catch(console.error);

      if (searchParams.get('fromEmail')) {
        analytics.track('Link Clicked', {
          linkName: 'Email LInk',
          subject: searchParams.get('fromEmail'),
        });
        setSearchParams('');
      }

      if (searchParams.get('s')) {
        analytics.register({
          source: searchParams.get('s'),
        });
        setSearchParams('');
      }
    }
  }, [
    isAuthenticated,
    user,
    isLoadingPermissions,
    location,
    firstLoad,
    isOldNavigationEnabled,
    loadingIsOldNavigationEnabled,
    permissions,
  ]);

  useEffect(() => {
    // @ts-ignore
    window.Intercom?.('onShow', () => {
      analytics.track('Intercom Toggled', {
        value: true,
      });
    });

    // @ts-ignore
    window.Intercom?.('onHide', () => {
      analytics.track('Intercom Toggled', {
        value: false,
      });
    });
  }, []);

  useEffect(() => {
    if (user) {
      import('@sentry/react').then(async (Sentry) => {
        Sentry.setContext('user', user);
      });
    }
  }, [user?.email]);
};

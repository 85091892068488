import { useLabels } from 'ui';
import { Stack } from '@mui/material';

import { BedsBathsFilterChip } from './styled';

export const bedsBathsOptions = [1, 2, 3, 4, 5];

type BedsBathsSelectorProps = {
  value: number
  onClick: (n: number) => void
  keyPrefix: string
  isExactMatch: boolean
};

export const BedsBathsSelector = ({
  value, onClick, keyPrefix, isExactMatch,
}: BedsBathsSelectorProps) => {
  const l = useLabels();

  return (
    <Stack direction="row" alignItems="center" gap={0.5} justifyContent="space-between">
      <BedsBathsFilterChip
        onClick={() => onClick(0)}
        label={l.any}
        className={value === 0 ? 'active' : ''}
        sx={{ flexGrow: 1 }}
      />
      {bedsBathsOptions.map((option) => (
        <BedsBathsFilterChip
          key={`${keyPrefix}-${option}`}
          onClick={() => onClick(option)}
          label={`${option}${isExactMatch ? '' : '+'}`}
          className={value === option ? 'active' : ''}
          sx={{ flexGrow: 1 }}
        />
      ))}
    </Stack>
  );
};

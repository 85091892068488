import { useEffect, useState } from 'react';

import {
  Activity, SuggestedPartnerStatus, useAnalytics,
} from 'lib';
import { MdFormatListBulleted, MdPersonAddAlt } from 'react-icons/md';
import {
  IconSelector, InvertedButton, UpDownArrows, useLabels,
} from 'ui';
import {
  Button, ButtonGroup, Card, CardContent, IconButton, MenuItem, Stack, TextField, useTheme,
} from '@mui/material';

import { partnerStatusState } from './state';
import { useGetGrowthPolicy } from '../../api/growth-policy';
import { useGetSuggestedPartners } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { AddPartnerDialog } from '../../components/add-partner/AddPartnerDialog';
import { GrowthPolicyDialog } from '../../templates/growth-policy/GrowthPolicyDialog';
import { growthPolicyDialogState } from '../../templates/growth-policy/state';

export const PartnersHeader = ({
  search,
  setSearch,
  sortOption,
  setSortOption,
}: {
  search: string,
  setSearch: (search: string) => void,
  sortOption: SortOption,
  setSortOption: (sortOption: SortOption) => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [growthPolicyDialogOpen, setGrowthPolicyDialogOpen] = useState(false);
  const { data: growthPolicy } = useGetGrowthPolicy();
  const {
    data: suggestedPartnerPages,
    isLoading: isLoadingSuggestedPartners,
    isFetchingNextPage: isFetchingNextPageSuggestedPartners,
    hasNextPage: hasNextPageSuggestedPartners,
  } = useGetSuggestedPartners();

  const isLoading = isLoadingSuggestedPartners || isFetchingNextPageSuggestedPartners || hasNextPageSuggestedPartners;
  const suggestedPartners = suggestedPartnerPages?.pages.flatMap((page) => page.partners) ?? [];

  const inProgressPartners = suggestedPartners.filter((partner) => partner.status === SuggestedPartnerStatus.IN_PROGRESS);
  const lostPartners = suggestedPartners.filter((partner) => partner.status === SuggestedPartnerStatus.LOST);

  const setPartnerStatus = (status: SuggestedPartnerStatus) => {
    partnerStatusState.value = status;
  };

  const handleClickWizard = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Wizard',
      partnerStatus: partnerStatusState.value,
    });

    setGrowthPolicyDialogOpen(true);
  };

  const handleSelectSortOption = (value: SortOption) => {
    analytics.track('Selector Changed', {
      selectorName: 'Sort',
      value,
    });

    setSortOption(value);
  };

  const handleClickAdd = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Add',
      partnerStatus: partnerStatusState.value,
    });

    setAddDialogOpen(true);
  };

  useEffect(() => {
    growthPolicyDialogState.value = {
      open: growthPolicyDialogOpen,
      openDialog: () => setGrowthPolicyDialogOpen(true),
    };

    return () => {
      growthPolicyDialogState.value = {
        open: false,
        openDialog: () => { },
      };
    };
  }, [growthPolicyDialogOpen, setGrowthPolicyDialogOpen]);

  return (
    <>
      <Card
        sx={{
          overflowY: 'visible',
          overflowX: 'clip',
          border: `1px solid ${theme.palette.divider}`,
        }}
        elevation={0}
      >
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
            <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
              <TextField
                size="small"
                label={l['partners.searchPartner']}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconSelector<SortOption>
                inputProps={{ IconComponent: () => null }}
                value={sortOption}
                onChange={(e) => handleSelectSortOption(e.target.value as SortOption)}
                renderValue={() => (
                  <IconButton size="medium" sx={{ maxWidth: 40 }}>
                    <UpDownArrows
                      height={24}
                      width={24}
                      fill={theme.palette.text.primary}
                    />
                  </IconButton>
                )}
              >
                <MenuItem value="" disabled>{l.sortBy}</MenuItem>
                <MenuItem value={SortOption.TOP_PICKS}>{SortOption.TOP_PICKS}</MenuItem>
                <MenuItem value={SortOption.ACTIVITY_LEVEL}>{SortOption.ACTIVITY_LEVEL}</MenuItem>
                <MenuItem value={SortOption.YEARS_OF_EXPERIENCE}>{SortOption.YEARS_OF_EXPERIENCE}</MenuItem>
                <MenuItem value={SortOption.A_TO_Z}>{SortOption.A_TO_Z}</MenuItem>
                <MenuItem value={SortOption.Z_TO_A}>{SortOption.Z_TO_A}</MenuItem>
              </IconSelector>
            </Stack>
            <ButtonGroup>
              <Button
                sx={{ '&:hover': { background: theme.palette.primary.main } }}
                variant={partnerStatusState.value === SuggestedPartnerStatus.NEW ? 'contained' : 'outlined'}
                onClick={() => setPartnerStatus(SuggestedPartnerStatus.NEW)}
              >
                {l['partners.status.suggested']}
              </Button>
              <Button
                sx={{ '&:hover': { background: theme.palette.primary.main } }}
                variant={partnerStatusState.value === SuggestedPartnerStatus.IN_PROGRESS ? 'contained' : 'outlined'}
                onClick={() => setPartnerStatus(SuggestedPartnerStatus.IN_PROGRESS)}
              >
                {l['partners.status.inProgress']}
                {!isLoading && (
                  <>
                    {': '}
                    {inProgressPartners.length}
                  </>
                )}
              </Button>
              <Button
                sx={{ '&:hover': { background: theme.palette.primary.main } }}
                variant={partnerStatusState.value === SuggestedPartnerStatus.LOST ? 'contained' : 'outlined'}
                onClick={() => setPartnerStatus(SuggestedPartnerStatus.LOST)}
              >
                {l['partners.status.notRelevant']}
                {!isLoading && (
                  <>
                    {': '}
                    {lostPartners.length}
                  </>
                )}
              </Button>
            </ButtonGroup>
            <Stack direction="row" alignItems="center" gap={3}>
              <Button variant="outlined" onClick={handleClickWizard} startIcon={<MdFormatListBulleted />}>
                {l['partners.wizard']}
              </Button>
              <InvertedButton
                variant="outlined"
                onClick={handleClickAdd}
                startIcon={<MdPersonAddAlt />}
                aria-hidden={addDialogOpen}
              >
                {l.invite}
              </InvertedButton>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <AddPartnerDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        defaultReferralAmount={growthPolicy?.pmReferralsFeeAmount ?? 0}
      />
      <GrowthPolicyDialog open={growthPolicyDialogOpen} onClose={() => setGrowthPolicyDialogOpen(false)} />
    </>
  );
};

const sortByTopPicks = (a: SuggestedPartner, b: SuggestedPartner) => {
  // Handle null or undefined relevancy values, treat nulls as larger so they appear last
  if (a.relevancy === null || a.relevancy === undefined) return 1;
  if (b.relevancy === null || b.relevancy === undefined) return -1;

  // ascending relevancy
  if (a.relevancy !== b.relevancy) {
    return a.relevancy - b.relevancy;
  }

  const levelSort = sortByActivityLevel(a, b);
  if (levelSort !== 0) return levelSort;

  // descending years of experience
  return (b.experienceInYears ?? 0) - (a.experienceInYears ?? 0);
};

const sortByActivityLevel = (a: SuggestedPartner, b: SuggestedPartner) => {
  const activities = [Activity.LOW, Activity.MEDIUM, Activity.HIGH];
  const aIdx = a.activity ? activities.indexOf(a.activity) : -1;
  const bIdx = b.activity ? activities.indexOf(b.activity) : -1;

  if (aIdx !== bIdx) return bIdx - aIdx;

  return 0;
};

export enum SortOption {
  TOP_PICKS = 'Top Picks',
  ACTIVITY_LEVEL = 'Activity Level',
  YEARS_OF_EXPERIENCE = 'Years of Experience',
  A_TO_Z = 'A to Z',
  Z_TO_A = 'Z to A',
}

export const sortOptionToFunction: Record<SortOption, (a: SuggestedPartner, b: SuggestedPartner) => number> = {
  [SortOption.TOP_PICKS]: sortByTopPicks,
  [SortOption.ACTIVITY_LEVEL]: sortByActivityLevel,
  [SortOption.YEARS_OF_EXPERIENCE]: (a, b) => (b.experienceInYears ?? 0) - (a.experienceInYears ?? 0),
  [SortOption.A_TO_Z]: (a, b) => a.name.localeCompare(b.name),
  [SortOption.Z_TO_A]: (a, b) => b.name.localeCompare(a.name),
};

import { useMemo, useState } from 'react';

import { buildPartnerReferralLink, OwnerLeadWorkflowItem } from 'lib';
import {
  AdminView,
  BoldTypography,
  FallbackSpinner,
  formatPotentialTodayDate,
  FullScreenBlur,
  InvertedButton,
  useEnableScroll,
  useLabels,
} from 'ui';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';

import { AddLeadDialog } from './components/AddLeadDialog';
import { CopyLinkButton } from './components/CopyLinkButton';
import { LeadStatusChip } from './components/LeadStatusChip';
import { SuccessEmailSentDialog } from './components/SuccessEmailSentDialog';
import { useGetOwnerLeadsReferralLink, useListOwnerLeads } from '../../api/partners';
import { QueryKey } from '../../types/enums';

export const PartnerDashboard = () => {
  const l = useLabels();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEmailSendModalOpen, setIsEmailSendModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: ownerLeads,
    isLoading: isOwnerLeadsLoading,
  } = useListOwnerLeads();

  const isEmptyState = (ownerLeads?.length === 0) || isOwnerLeadsLoading;

  useEnableScroll(!isEmptyState);

  const {
    data: ownerLeadsReferralLink,
    isLoading: isOwnerLeadReferralLinkLoading,
  } = useGetOwnerLeadsReferralLink();

  const columns = useMemo(() => {
    const cols: (ColumnDef<OwnerLeadWorkflowItem, string> | null)[] = [
      {
        id: 'header-lead',
        header: l['referral-partners.table.lead'],
        enableSorting: true,
        accessorFn: (row) => `${row.ownerLead?.firstName ?? ''} ${row.ownerLead?.lastName ?? ''}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.ownerLead?.firstName}
            {' '}
            {info.row.original.ownerLead?.lastName}
          </Typography>
        ),
      },
      {
        id: 'header-email',
        header: l['referral-partners.table.email'],
        enableSorting: true,
        accessorFn: (row) => `${row.ownerLead?.email ?? ''}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.ownerLead?.email}
          </Typography>
        ),
      },
      {
        id: 'header-status',
        header: l['referral-partners.table.status'],
        enableSorting: false,
        accessorFn: (row) => row.status,
        minSize: 160,
        size: 160,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <LeadStatusChip status={info.row.original.status} />,
      },
      {
        id: 'header-last-updated',
        header: l['referral-partners.table.lastUpdated'],
        enableSorting: false,
        accessorFn: (row) => row.updatedTime,
        minSize: 160,
        size: 160,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {formatPotentialTodayDate(info.row.original.updatedTime)}
          </Typography>
        ),
      },
    ];

    return cols;
  }, []);

  const filterLeads = (rows: OwnerLeadWorkflowItem[], search: string) => rows.filter((lead) => {
    const searchable = `${lead.ownerLead?.firstName ?? ''} ${lead.ownerLead?.lastName ?? ''}`;

    return searchable.toLowerCase().includes(search.toLowerCase());
  });

  if (isOwnerLeadsLoading || isOwnerLeadReferralLinkLoading) {
    return <FallbackSpinner />;
  }

  return (
    <Stack position="relative" height="100%">
      <FullScreenBlur
        shown={isEmptyState}
      >
        <Stack direction="column" spacing={4} alignItems="center">
          <BoldTypography variant="body1">
            {l['referral-partners.noDealsYet']}
          </BoldTypography>
          <Typography variant="body2">
            {l['referral-partners.inviteDealsText']}
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={4}>
              <CopyLinkButton link={ownerLeadsReferralLink ? buildPartnerReferralLink(ownerLeadsReferralLink.id) : ''} />
              <InvertedButton
                variant="contained"
                onClick={() => {
                  setIsAddModalOpen(true);
                }}
              >
                {l.invite}
              </InvertedButton>
            </Stack>
          </Stack>
        </Stack>
      </FullScreenBlur>
      <AdminView
        title={l.leads}
        searchPlaceholder="Search Leads"
        data={ownerLeads || []}
        columns={columns}
        filter={filterLeads}
        tableHeader={(
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={4}>
              <CopyLinkButton link={ownerLeadsReferralLink ? buildPartnerReferralLink(ownerLeadsReferralLink.id) : ''} />
              <InvertedButton
                variant="contained"
                onClick={() => {
                  setIsAddModalOpen(true);
                }}
              >
                {l.referLead}
              </InvertedButton>
            </Stack>
          </Stack>
        )}
      />
      <AddLeadDialog
        open={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
        }}
        onSuccess={() => {
          setIsEmailSendModalOpen(true);
          setTimeout(() => {
            queryClient.invalidateQueries([QueryKey.OWNER_LEADS]);
          }, 3000);
        }}
      />
      <SuccessEmailSentDialog
        open={isEmailSendModalOpen}
        onClose={() => {
          setIsEmailSendModalOpen(false);
        }}
      />
    </Stack>

  );
};

import { useAnalytics } from 'lib';
import { MdFilterList } from 'react-icons/md';
import { RiCloseCircleLine } from 'react-icons/ri';
import { useLabels } from 'ui';
import {
  Box, Zoom,
} from '@mui/material';

import { ClearFiltersButton, FilterButton as ActualFilterButton, FilterButtonContainer } from '../styled';

export const FilterButton = ({
  toggleFiltersOpen, activeFilterCount, resetFilters,
}: {
  toggleFiltersOpen: (open: boolean, buttonName: string) => void,
  activeFilterCount: number,
  resetFilters: () => void,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();

  const activeFilters = activeFilterCount > 0;

  return (
    <Box width={activeFilters ? 155 : 100} sx={{ transition: '0.3s' }}>
      <FilterButtonContainer direction="row" sx={{ width: activeFilters ? '100%' : 100 }}>
        <ActualFilterButton
          variant="outlined"
          onClick={() => {
            analytics.track('Button Clicked', {
              buttonName: 'Filter',
            });

            toggleFiltersOpen(true, 'Filter');
          }}
          endIcon={<MdFilterList size={18} />}
        >
          {l.filter}
          <Box component="span" display={activeFilters ? 'inline-block' : 'none'} ml={1}>
            (
            {activeFilterCount}
            )
          </Box>
        </ActualFilterButton>
        <Zoom in={activeFilters} unmountOnExit>
          <ClearFiltersButton
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();

              analytics.track('Button Clicked', {
                buttonName: 'Filter - X',
              });

              resetFilters();
            }}
          >
            <RiCloseCircleLine size={22} />
          </ClearFiltersButton>
        </Zoom>
      </FilterButtonContainer>
    </Box>
  );
};

import { ReactElement } from 'react';

import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermsAndConditions } from './components/TermsAndConditions';
import { App } from './App';

export const BaseApp = () => {
  type Paths = {
    [path: string]: () => ReactElement
  };

  const paths: Paths = {
    '/terms': TermsAndConditions,
    '/privacy-policy': PrivacyPolicy,
  };

  const Component = paths[window.location.pathname] || App;

  return <Component />;
};

import { calculateMortgage, sum } from 'lib';
import {
  UseFormReturn,
} from 'react-hook-form';

import { PORTFOLIO } from './state';
import { Property } from '../../types';

const loanTerms = [120, 180, 240, 360, 'other'] as const;
export const metricRelatedExpenses = ['managementFee', 'maintenance', 'capEx', 'estimatedVacancy'] as const;
export type MetricRelatedExpense = typeof metricRelatedExpenses[number];

type Loan = null | {
  purchasePrice: number,
  amount: number,
  interestRate: number,
  term: typeof loanTerms[number],
  termOther: number,
};

export type PropertyProformaData = {
  askingPrice: number,
  metrics: {
    annualHomeAppreciation: number,
    annualRentGrowth: number,
    estimatedVacancy: number,
    inflationRate: number,
    managementFee: number,
    maintenance: number,
    capEx: number,
  },
  income: {
    rent: number,
  },
  expenses: {
    estimatedVacancy: number,
    tax: number,
    insurance: number,
    hoa: number,
    managementFee: number,
    maintenance: number,
    capEx: number,
  },
  loan: Loan,
};

const getRent = (properties: Property[]): number => (
  sum(properties.map((property) => property.metrics?.annualMetrics?.income ?? 0))
);

const getValuation = (properties: Property[]): number => (
  sum(properties.map((property) => property.metrics?.valuation ?? property.latestValuation?.priceMean ?? 0))
);

const getTax = (properties: Property[]): number => (
  sum(properties.map((property) => property.metrics?.annualMetrics?.propertyTax ?? 0))
);

const getInsurance = (properties: Property[]): number => (
  sum(properties.map((property) => property.metrics?.annualMetrics?.propertyInsurance ?? 0))
);

const getHoa = (properties: Property[]): number => (
  sum(properties.map((property) => property.metrics?.annualMetrics?.propertyHoa ?? 0))
);

export const getLoan = (property: Property): Loan => {
  if (!property.mortgage) return null;

  const term = property.mortgage.term as typeof loanTerms[number];

  return {
    purchasePrice: property.purchaseHistory?.amount ?? 0,
    amount: property.mortgage.amount,
    interestRate: property.mortgage.rate,
    term: loanTerms.includes(term) ? term : 'other',
    termOther: loanTerms.includes(term) ? 0 : term as number,
  };
};

const defaultMetrics = {
  annualHomeAppreciation: 3,
  annualRentGrowth: 3,
  estimatedVacancy: 5,
  inflationRate: 3,
  managementFee: 8,
  maintenance: 6,
  capEx: 3,
};

const createInitialPropertyData = (property: Property): PropertyProformaData => {
  const rent = getRent([property]);
  const loan = getLoan(property);

  const metrics = { ...defaultMetrics };

  return {
    askingPrice: getValuation([property]),
    metrics,
    loan,
    income: {
      rent,
    },
    expenses: {
      estimatedVacancy: rent * (metrics.estimatedVacancy / 100),
      tax: getTax([property]),
      insurance: getInsurance([property]),
      hoa: getHoa([property]),
      managementFee: rent * (metrics.managementFee / 100),
      maintenance: rent * (metrics.maintenance / 100),
      capEx: rent * (metrics.capEx / 100),
    },
  };
};

const createInitialPortfolioData = (properties: Property[]): PropertyProformaData => {
  const rent = getRent(properties);

  const metrics = { ...defaultMetrics };

  return {
    askingPrice: getValuation(properties),
    metrics,
    loan: null,
    income: {
      rent,
    },
    expenses: {
      estimatedVacancy: rent * (metrics.estimatedVacancy / 100),
      tax: getTax(properties),
      insurance: getInsurance(properties),
      hoa: getHoa(properties),
      managementFee: rent * (metrics.managementFee / 100),
      maintenance: rent * (metrics.maintenance / 100),
      capEx: rent * (metrics.capEx / 100),
    },
  };
};

export const createInitialData = (properties: Property[]): PropertyProformaFormData => {
  const initialData: PropertyProformaFormData = {
    portfolio: createInitialPortfolioData(properties),
  };

  properties.forEach((property) => {
    initialData[property.id] = createInitialPropertyData(property);
  });

  return initialData;
};

export type PropertyProformaFormData = Record<string, PropertyProformaData>;

export type Form = UseFormReturn<PropertyProformaFormData>;

export type RowProps = {
  form: Form;
};

export const getCalculatedMortgage = (data: PropertyProformaFormData, propertyID: string) => {
  if (propertyID !== PORTFOLIO) {
    if (!data[propertyID] || !data[propertyID].loan) return 0;

    const loan = data[propertyID].loan!;

    return calculateMortgage({
      loanAmount: loan.amount,
      interestRate: loan.interestRate,
      loanTerm: loan.term === 'other' ? loan.termOther : loan.term,
    });
  }

  const calculatedMortgage = sum(Object.entries(data).map(([key, formData]) => (
    key !== 'portfolio' && formData.loan ? calculateMortgage({
      loanAmount: formData.loan.amount,
      interestRate: formData.loan.interestRate,
      loanTerm: formData.loan.term === 'other' ? formData.loan.termOther : formData.loan.term,
    }) : 0
  )));

  return calculatedMortgage;
};

import {
  useEffect, useMemo, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { APIUser, pmState, useGetUsers } from 'lib';
import { toast } from 'react-toastify';
import {
  ActivatedOwner, AdminView, DetailedMetric, formatDecimalToPercentage, InvitedOwner, PercentRedBig, useLabels,
} from 'ui';
import {
  Button,
  Stack,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import {
  ColumnDef,
  createColumnHelper,
  Row,
} from '@tanstack/react-table';

import {
  ImpersonateOwnerButton, InviteUserButton, RowButtons,
} from './buttons';
import { ImportProspectsDialog } from './ImportProspectsDialog';
import { useGetNotificationTemplate } from '../../api/notification-templates';

const sortByActivated = (a: APIUser, b: APIUser) => {
  if (a.isActivated && !b.isActivated) {
    return -1;
  }

  if (!a.isActivated && b.isActivated) {
    return 1;
  }

  return 0;
};

const sortColumnByActivated = (a: Row<APIUser>, b: Row<APIUser>) => sortByActivated(a.original, b.original);

export const Prospects = ({ renderedOnRoot = true }: { renderedOnRoot?: boolean }) => {
  const theme = useTheme();
  const l = useLabels();
  const [openImportProspectsDialog, setOpenImportProspectsDialog] = useState(false);
  const [searchParams] = useSearchParams();

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data,
    isLoading,
    isError,
  } = useGetUsers(['prospect_user']);

  const {
    data: inviteNotificationTemplate,
  } = useGetNotificationTemplate('invitation_user');

  const getSMSText = (user: APIUser) => {
    if (!inviteNotificationTemplate?.template) return '';

    return inviteNotificationTemplate.template
      .replace('{{ .PMCompanyName }}', pmState.value?.name || '')
      .replace('{{ .Name }}', user.name)
      .replace(
        '{{ .Link }}', '<a href="#">https://api.blankethomes.com/link</a>',
      );
  };

  const activatedProspects = data?.filter((owner) => owner.isActivated) || [];

  const columnHelper = createColumnHelper<APIUser>();
  const columns = useMemo(() => {
    const cols: ColumnDef<APIUser, string>[] = [
      columnHelper.accessor('name', {
        header: l['pm-dashboard.owner.name'],
        enableSorting: true,
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('email', {
        header: l['pm-dashboard.owner.email'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
      columnHelper.accessor('phoneNumber', {
        header: l['pm-dashboard.owner.phone'],
        cell: (info) => info.getValue(),
        meta: { shownAsText: true },
      }),
    ];

    cols.push({
      header: 'Activation',
      accessorKey: 'login-as',
      size: 100,
      maxSize: 210,
      enableSorting: true,
      sortingFn: sortColumnByActivated,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Typography variant="body2">{info.row.original.isActivated ? 'Yes' : 'No'}</Typography>
      ),
    });

    if (isLowerThanMd) {
      cols.push({
        header: ' ',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <RowButtons info={info} smsText={getSMSText(info.row.original)} />,
        maxSize: 50,
        meta: { sticky: true },
      });
    } else {
      cols.push({
        header: 'Actions',
        accessorKey: 'actions',
        size: 100,
        maxSize: 140,
        enableSorting: true,
        sortingFn: sortColumnByActivated,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Stack spacing={2} direction="row">
            {info.row.original.isActivated ? (
              <ImpersonateOwnerButton
                user={info.row.original}
              />

            ) : (
              <InviteUserButton
                user={info.row.original}
                smsText={getSMSText(info.row.original)}
              />
            )}
          </Stack>
        ),
      });
    }

    return cols;
  }, [isLowerThanMd, inviteNotificationTemplate]);

  useEffect(() => {
    if (isError) {
      toast.error(l['error.unknownError']);
    }
  }, [isError]);

  const prospects = useMemo(() => {
    if (data && inviteNotificationTemplate) {
      return data?.sort(sortByActivated) as APIUser[];
    }

    return [];
  }, [data, inviteNotificationTemplate]);

  return (
    <>
      <ImportProspectsDialog
        open={openImportProspectsDialog}
        closeDialog={() => setOpenImportProspectsDialog(false)}
      />
      <AdminView
        tableHeader={(
          <Button
            variant="contained"
            type="button"
            onClick={() => setOpenImportProspectsDialog(true)}
          >
            {isLowerThanMd ? l['pm-dashboard.prospect.invite'] : l['pm-dashboard.prospect.inviteProspects']}
          </Button>
        )}
        initSearch={searchParams.get('search') ?? ''}
        title={renderedOnRoot ? l['menu-prospects'] : undefined}
        searchPlaceholder={l['pm-dashboard.prospect.search']}
        data={isLoading ? [] : prospects}
        columns={columns}
        filter={(currProperties, search) => currProperties.filter(
          (p) => (p.email + p.name).toLowerCase().includes(search.toLowerCase()),
        )}
        pageHeader={(
          <DetailedMetric
            title=""
            onClick={() => { }}
            metrics={[
              {
                id: 'signed-up-prospects',
                value: activatedProspects.length,
                label: l.activated,
                icon: <ActivatedOwner />,
              },
              {
                id: 'invited-prospects',
                value: prospects?.length,
                label: l.invited,
                icon: <InvitedOwner />,
              },
              {
                id: 'prospects-conversion-rate',
                value: formatDecimalToPercentage(prospects?.length ? activatedProspects.length / prospects.length : 0, 2),
                label: l['pm-dashboard.owner.conversion-rate'],
                icon: <PercentRedBig />,
              },
            ]}
          />
        )}
      />
    </>

  );
};

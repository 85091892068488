export const parseManagementFee = (rawManagementFee: string | null | undefined): { value: number, isDollars: boolean } => {
  if (rawManagementFee?.includes('%')) {
    return {
      value: parseFloat(rawManagementFee.replace('%', '')) || 0,
      isDollars: false,
    };
  }

  if (rawManagementFee?.includes('$')) {
    return {
      value: (parseFloat(rawManagementFee.replace('$', '')) || 0),
      isDollars: true,
    };
  }

  return {
    value: 0,
    isDollars: false,
  };
};

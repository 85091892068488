/* eslint-disable max-len */

export const PriceTagIcon = ({
  size = 24,
  color = undefined,
  svgProps = undefined,
}: {
  size?: number,
  color?: string,
  svgProps?: React.SVGProps<SVGSVGElement>
}) => {
  const clipPathID = `clip-path-${Math.random()}`;
  const clipPathID2 = `clip-path-${Math.random()}`;

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <g clipPath={`url(#${clipPathID})`}>
        <rect
          width="8"
          height="10.6841"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 3 14.1924)"
          fill={`url(#${clipPathID2})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9838 0.866158L9.98753 0.894797L1.16179 9.72054L9.12945 17.6882L17.9552 8.86246L17.9838 0.866158ZM16.2161 2.63392L16.293 8.06481L9.08055 15.2773L3.52977 9.72651L10.7423 2.51402L16.2161 2.63392Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <linearGradient
          id={clipPathID2}
          x1="0.533333"
          y1="10.6841"
          x2="7.58703"
          y2="4.69832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F24476" />
          <stop offset="1" stopColor="#E7C8D1" stopOpacity="0.46" />
        </linearGradient>
        <clipPath id={clipPathID}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

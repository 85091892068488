import {
  convertArrayToFirstElement,
  createDisplayAddress,
  MarketplaceProperty,
  MarketPlacePropertyStatus,
  Offer,
  OfferStatus,
  PropertyExtendedAddress,
} from 'lib';

import { Activity, ActivityOfferStatus, ActivityStatus } from '../../types/activity';
import {
  ListingOffer,
  ListingPropertyShort,
} from '../../types/property';

export const convertProperty = (marketplaceProperty: MarketplaceProperty): ListingPropertyShort => {
  const extendedAddress = convertArrayToFirstElement<PropertyExtendedAddress>(marketplaceProperty.property.extendedAddress);

  const sortedPictures = marketplaceProperty.listingPictures?.items.sort((a, b) => (a?.rank ?? 99) - (b?.rank ?? 99));
  const pictures = sortedPictures?.map((p) => p?.url ?? '') ?? [];

  const primaryPicture = pictures[0] ?? null;

  const { address } = marketplaceProperty;

  const originOffers = marketplaceProperty.offers ? marketplaceProperty.offers.items : [];

  const offers: ListingOffer[] = originOffers.filter(Boolean).map((o) => ({
    buyer: o?.buyer ?? '',
    bid: o?.bid ?? 0,
    seller: o?.seller ?? '',
    status: o?.status,
    id: o?.id ?? '',
  }));

  return {
    primaryPicture,
    offers,
    status: marketplaceProperty.status,
    id: marketplaceProperty.id,
    address: {
      street1: address?.street1,
      street2: address?.street2,
      city: address?.city,
      state: address?.state,
      zipCode: address?.zipCode,
      lat: extendedAddress?.lat,
      lon: extendedAddress?.lon,
      fullAddress: createDisplayAddress(address),
    },
    askingPrice: marketplaceProperty.askingPrice,
    propertyID: marketplaceProperty.propertyMarketplacePropertyId,
    owner: marketplaceProperty.owner,
  };
};

export const convertOffer = (offer: Offer): ListingPropertyShort => {
  const extendedAddress = convertArrayToFirstElement<PropertyExtendedAddress>(offer.property.property.extendedAddress);

  const pictures = offer.property.listingPictures?.items
    ?.map((p) => p?.url)
    .filter(Boolean) ?? [];

  const primaryPicture = pictures.map((p) => p)[0] ?? null;
  const { address } = offer.property;

  const offers: ListingOffer[] = [{
    buyer: offer.buyer,
    seller: offer.seller,
    bid: offer.bid,
    status: offer.status,
    id: offer.id,
  }];

  return {
    primaryPicture,
    offers,
    status: offer.property.status,
    id: offer.property.id,
    address: {
      street1: address?.street1,
      street2: address?.street2,
      city: address?.city,
      state: address?.state,
      zipCode: address?.zipCode,
      lat: extendedAddress?.lat,
      lon: extendedAddress?.lon,
      fullAddress: createDisplayAddress(address),
    },
    askingPrice: offer.property.askingPrice,
    propertyID: offer.property.propertyMarketplacePropertyId,
    owner: offer.property.owner,
  };
};

const mapOfferStatusToActivitiesStatus = {
  [OfferStatus.psaSigned]: ActivityStatus.UNDER_CONTRACT,
  [OfferStatus.depositSubmitted]: ActivityStatus.ESCROW,
  [OfferStatus.won]: ActivityStatus.PENDING_SALE,
  [OfferStatus.rejected]: ActivityStatus.ACTIVE,
  [OfferStatus.offerMade]: ActivityStatus.ACTIVE,
  [OfferStatus.accepted]: ActivityStatus.ACTIVE,
  [OfferStatus.newLead]: ActivityStatus.ACTIVE,
};

const mapListingStatusToActivitiesStatus = {
  [MarketPlacePropertyStatus.canceled]: (): ActivityStatus => ActivityStatus.INVISIBLE,
  [MarketPlacePropertyStatus.newLead]: (): ActivityStatus => ActivityStatus.INVISIBLE,
  [MarketPlacePropertyStatus.pendingListing]: (): ActivityStatus => ActivityStatus.PENDING_LISTING,
  [MarketPlacePropertyStatus.active]: (offers: ListingOffer[]): ActivityStatus => {
    const offer: ListingOffer | undefined = offers
      .find((o) => o.status && [OfferStatus.depositSubmitted, OfferStatus.psaSigned, OfferStatus.won].includes(o.status));
    if (!offer) {
      return ActivityStatus.ACTIVE;
    }
    return mapOfferStatusToActivitiesStatus[offer.status!];
  },
  [MarketPlacePropertyStatus.pendingSaleApproval]: (offers: ListingOffer[]): ActivityStatus => {
    const offer: ListingOffer | undefined = offers
      .find((o) => o.status && [OfferStatus.depositSubmitted, OfferStatus.psaSigned, OfferStatus.won].includes(o.status));
    if (!offer) {
      return ActivityStatus.ACTIVE;
    }
    return ActivityStatus.PENDING_SALE;
  },
  [MarketPlacePropertyStatus.sold]: (): ActivityStatus => ActivityStatus.SOLD,

};

const getOfferStatus = (offerStatus: OfferStatus | undefined): ActivityOfferStatus => {
  switch (offerStatus) {
    case OfferStatus.offerMade: {
      return ActivityOfferStatus.NEW_OFFER;
    }
    case OfferStatus.accepted: {
      return ActivityOfferStatus.OFFER_ACCEPTED;
    }
    case OfferStatus.rejected: {
      return ActivityOfferStatus.OFFER_REJECTED;
    }
    default: {
      return ActivityOfferStatus.EMPTY;
    }
  }
};

const convertListingToActivity = (listing: ListingPropertyShort) : Activity => {
  const relevantOffers = listing.offers
    ? listing.offers.filter((offer) => offer.status !== OfferStatus.newLead) : [];
  const status = mapListingStatusToActivitiesStatus[listing.status](relevantOffers);

  const offers = status === ActivityStatus.ACTIVE ? relevantOffers.map((offer) => ({
    status: getOfferStatus(offer.status),
    bid: offer.bid ?? 0,
    id: offer.id,
  })) : [];

  return {
    id: listing.id,
    picture: listing.primaryPicture,
    status,
    address: listing.address.fullAddress ?? '',
    askingPrice: listing.askingPrice,
    offers,
  };
};

export const convertActivities = (
  listings: ListingPropertyShort[],
): Activity[] => listings.map(convertListingToActivity).filter((a) => a.status !== ActivityStatus.INVISIBLE);

import {
  DialogContent, styled,
} from '@mui/material';

export const SuccessPopupContent = styled(DialogContent)(({ theme }) => ({
  padding: `${theme.spacing(6)} !important`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

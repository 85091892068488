import { Theme } from '@mui/material/styles';

import { Skin } from '../../layouts/types';

export const overridePopover = (theme: Theme, skin: Skin) => ({
  MuiPopover: {
    styleOverrides: {
      root: {
        zIndex: 1700,
        '& .MuiPopover-paper': {
          boxShadow: theme.shadows[skin === 'bordered' ? 0 : 6],
          ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}` }),
        },
      },
    },
  },
});

import { signal } from '@preact/signals-react';

export enum RetainTab {
  NEW = 'new',
  IN_PROGRESS = 'inProgress',
  RESOLVED = 'resolved',
  NO_RISK = 'noRisk',
}

export const currentRetainTab = signal<RetainTab>(RetainTab.NEW);

// eslint-disable-next-line no-spaced-func
export const retentionPolicyDialogState = signal<{ open: boolean, openDialog: () => void }>({
  open: false,
  openDialog: () => { },
});

export const showOwnerRiskMovedToInProgressToast = signal(false);

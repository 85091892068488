type CalculateMortgageFields = {
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
};
/**
 * [Logic for Mortgage Calculation](https://www.mymove.com/mortgage/mortgage-calculation)
 */
export const calculateMortgage = ({
  loanAmount,
  interestRate,
  loanTerm,
}: CalculateMortgageFields): number => {
  if (![loanAmount, interestRate, loanTerm].every(Boolean)) {
    return 0;
  }

  const monthlyInterestRate = (interestRate || 0) / 100 / 12;

  return loanAmount * (
    (monthlyInterestRate * (1 + monthlyInterestRate) ** loanTerm) / ((1 + monthlyInterestRate) ** loanTerm - 1)
  );
};

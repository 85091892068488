import { Theme } from '@mui/material/styles';

import { overrideAccordion } from './accordion';
import { overrideAlert } from './alert';
import { overrideAutocomplete } from './autocomplete';
import { overrideAvatar } from './avatars';
import { overrideBackdrop } from './backdrop';
import { overrideBreadcrumbs } from './breadcrumbs';
import { overrideButton } from './button';
import { overrideButtonGroup } from './buttonGroup';
import { overrideCard } from './card';
import { overrideChip } from './chip';
import { overrideDataGrid } from './dataGrid';
import { overrideDateTimePicker } from './dateTimePicker';
import { overrideDialog } from './dialog';
import { overrideDivider } from './divider';
import { overrideFab } from './fab';
import { overrideInput } from './input';
import { overrideLink } from './link';
import { overrideList } from './list';
import { overrideMenu } from './menu';
import { overridePagination } from './pagination';
import { overridePaper } from './paper';
import { overridePopover } from './popover';
import { overrideProgress } from './progress';
import { overrideRating } from './rating';
import { overrideSelect } from './select';
import { overrideSnackbar } from './snackbar';
import { overrideSwitches } from './switches';
import { overrideTable } from './table';
import { overrideTabs } from './tabs';
import { overrideTimeline } from './timeline';
import { overrideToggleButton } from './toggleButton';
import { overrideTooltip } from './tooltip';
import { overrideTypography } from './typography';
import { Settings } from '../../context/settingsContext';

export const overrides = (theme: Theme, settings: Settings) => {
  const { skin } = settings;

  const fab = overrideFab(theme);
  const chip = overrideChip(theme);
  const list = overrideList(theme);
  const tabs = overrideTabs(theme);
  const input = overrideInput(theme);
  const tables = overrideTable(theme);
  const alerts = overrideAlert(theme);
  const button = overrideButton(theme);
  const rating = overrideRating(theme);
  const select = overrideSelect(theme);
  const avatars = overrideAvatar(theme);
  const progress = overrideProgress(theme);
  const divider = overrideDivider(theme);
  const menu = overrideMenu(theme, skin);
  const tooltip = overrideTooltip(theme);
  const cards = overrideCard(theme, skin);
  const backdrop = overrideBackdrop(theme);
  const dataGrid = overrideDataGrid(theme);
  const switches = overrideSwitches(theme);
  const timeline = overrideTimeline(theme);
  const accordion = overrideAccordion(theme);
  const dialog = overrideDialog(theme, skin);
  const pagination = overridePagination(theme);
  const popover = overridePopover(theme, skin);
  const snackbar = overrideSnackbar(theme, skin);
  const dateTimePicker = overrideDateTimePicker(theme);
  const autocomplete = overrideAutocomplete(theme, skin);

  return Object.assign(
    fab,
    chip,
    list,
    menu,
    tabs,
    cards,
    input,
    select,
    alerts,
    button,
    dialog,
    rating,
    tables,
    avatars,
    divider,
    popover,
    tooltip,
    overrideLink,
    backdrop,
    dataGrid,
    overridePaper,
    progress,
    snackbar,
    switches,
    timeline,
    accordion,
    pagination,
    autocomplete,
    overrideTypography,
    dateTimePicker,
    overrideBreadcrumbs,
    overrideButtonGroup,
    overrideToggleButton,
  );
};

import { forwardRef, Ref } from 'react';

import MuiChip from '@mui/material/Chip';
import { lighten, useTheme } from '@mui/material/styles';

import { CustomChipProps } from './types';
import { useBgColor, UseBgColorType } from '../hooks/useBgColor';
import { ThemeColor } from '../layouts/types';

export const Chip = forwardRef((props: CustomChipProps, ref: Ref<any>) => {
  const {
    sx, skin, color,
  } = props;

  const theme = useTheme();
  const bgColors: UseBgColorType = useBgColor();

  const getChipStyles = (skinColor: ThemeColor) => {
    let chipStyles = { ...bgColors[`${skinColor}Filled`] };
    switch (skin) {
      case 'light':
        chipStyles = { ...bgColors[`${skinColor}Light`] };
        break;
      case 'light-static':
        chipStyles = {
          color: bgColors[`${skinColor}Light`].color,
          backgroundColor: lighten(theme.palette[skinColor].main, 0.88),
        };
        break;
      default:
        break;
    }

    return chipStyles;
  };

  const colors: UseBgColorType = {
    primary: getChipStyles('primary'),
    secondary: getChipStyles('secondary'),
    success: getChipStyles('success'),
    error: getChipStyles('error'),
    warning: getChipStyles('warning'),
    info: getChipStyles('info'),
  };

  return <MuiChip ref={ref} {...props} sx={skin && color ? Object.assign(colors[color], sx) : sx} />;
});

Chip.defaultProps = {
  skin: 'filled',
  color: 'primary',
};

import { useAnalytics } from 'lib';
import { FieldPath } from 'react-hook-form';
import { BiDollar } from 'react-icons/bi';
import { MdPercent } from 'react-icons/md';
import {
  BoldTypography, ControlledFormattedNumberField, formatDecimalToPercentage, formatNumberToCurrency,
  SemiBoldTypography,
} from 'ui';
import { IconButton, InputAdornment } from '@mui/material';

import { DetailRow } from './Row';
import { useProjections } from './useProjections';
import {
  CalculationType, Form, PropertyDetailsFormData, RowProps,
} from '../types';

type ExpenseProps = {
  form: Form,
  name: FieldPath<PropertyDetailsFormData>
  calculationType: CalculationType
  value: number
  label: string
  onClickDollar: () => void
  onClickPercentage: () => void
  showAsNegative?: boolean
};

export const Expense = ({
  form, calculationType, value, label, name, onClickDollar, onClickPercentage, showAsNegative = false,
}: ExpenseProps) => {
  const analytics = useAnalytics();
  const editMode = form.watch('editMode');

  const { control } = form;

  if (editMode) {
    return (
      <ControlledFormattedNumberField
        control={control}
        name={name}
        label={label}
        prefix=""
        suffix=""
        size="small"
        decimalScale={calculationType === CalculationType.PERCENTAGE ? 2 : 0}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                edge="end"
                onClick={() => {
                  if (calculationType === CalculationType.NUMBER) {
                    onClickPercentage();
                  } else {
                    onClickDollar();
                  }
                }}
              >
                {calculationType === CalculationType.NUMBER ? <BiDollar /> : <MdPercent />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onBlur={() => {
          analytics.track('Input Changed', {
            value,
            calculationType,
            inputName: `Listing Details - ${label}`,
          });
        }}
      />
    );
  }

  return (
    <BoldTypography variant="body1">
      {showAsNegative && value !== 0 && '-'}
      {calculationType === CalculationType.NUMBER ? (
        formatNumberToCurrency(value, 0)
      ) : (
        formatDecimalToPercentage(value / 100, 2)
      )}
    </BoldTypography>
  );
};

export const ExpenseRow = ({
  form,
  projectionKey,
  label,
  dataKey,
}: RowProps & {
  projectionKey: keyof PropertyDetailsFormData['details']['expenses'],
  label: string,
  dataKey: `details.expenses.${keyof PropertyDetailsFormData['details']['expenses']}`,
}) => {
  const rent = form.watch('details.income.rent');
  const projections = useProjections(form);
  const projectionData = projections[projectionKey];
  const valuePath = `${dataKey}.value` as const;
  const calculationTypePath = `${dataKey}.type` as const;
  const value = form.watch(valuePath);
  const calculationType = form.watch(calculationTypePath);

  return (
    <DetailRow
      form={form}
      rowID={projectionKey}
      columns={[
        {
          id: projectionKey,
          content: (
            <SemiBoldTypography variant="body1">
              {label}
            </SemiBoldTypography>
          ),
        },
        {
          id: `${projectionKey}-year-1`,
          content: (
            <Expense
              form={form}
              name={valuePath}
              calculationType={form.watch(calculationTypePath)}
              value={value}
              label={label}
              onClickDollar={() => {
                form.setValue(calculationTypePath, CalculationType.NUMBER);
                form.setValue(valuePath, Math.round(rent * (value / 100)));
              }}
              onClickPercentage={() => {
                form.setValue(calculationTypePath, CalculationType.PERCENTAGE);
                form.setValue(valuePath, (value / rent) * 100);
              }}
            />
          ),
        },
        {
          id: `${projectionKey}-year-2`,
          content: (
            <BoldTypography variant="body1">
              {calculationType === CalculationType.NUMBER ? (
                formatNumberToCurrency(projectionData.year2, 0)
              ) : (
                formatDecimalToPercentage(value / 100, 2)
              )}
            </BoldTypography>
          ),
        },
        {
          id: `${projectionKey}-year-3`,
          content: (
            <BoldTypography variant="body1">
              {calculationType === CalculationType.NUMBER ? (
                formatNumberToCurrency(projectionData.year3, 0)
              ) : (
                formatDecimalToPercentage(value / 100, 2)
              )}
            </BoldTypography>
          ),
        },
      ]}
    />
  );
};

import { Question } from '../../api/jotform/types';

export const shouldSkipQuestion = (questionId: string,
  questionValues: { [key: string]: Question }, skipsQuestions: string[] = []): boolean => {
  const question = questionValues[questionId];

  if (question.conditions.length === 0) {
    return false;
  }

  const { link } = question.conditions[0];

  const conditionResult: boolean[] = [];

  question.conditions.forEach((condition) => {
    const conditionQuestion = questionValues[condition.field];

    if (skipsQuestions.filter((q) => q === condition.field).length > 0) {
      return;
    }

    const answer = conditionQuestion.answer ?? '';

    if (condition.visibility === 'Show') {
      if (condition.operator === 'equals' && conditionQuestion.answer === condition.value) {
        conditionResult.push(true);
        return;
      }

      if (condition.operator === 'isEmpty' && answer.trim() === '') {
        conditionResult.push(true);
        return;
      }

      if (condition.operator === 'isFilled' && answer.trim() !== '') {
        conditionResult.push(true);
        return;
      }
    }

    conditionResult.push(false);
  });

  if (link === 'Any') {
    return !conditionResult.some((res) => res);
  }

  // ALL
  return !conditionResult.reduce((acc, currentValue) => acc && currentValue,
    conditionResult.length === question.conditions.length);
};

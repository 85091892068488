import {
  Address as APIAddress,
  DashboardProperty as APIDashboardProperty, DashboardPropertyMetric,
  DashboardPropertyPurchaseHistory,
  DashboardPropertyValuation,
  Lease as APILease,
  Mortgage as APIMortgage,
  Property as APIProperty,
  PropertyAmortizationTable,
  Unit as APIUnit,
} from 'lib';

import { Category, GroupedTransaction, Transaction } from './transaction';

type OmittedAPIDashboardPropertyFields = (
    | '__typename'
    | 'address'
    | 'transactions'
    | 'amortizationTable'
    | 'purchaseHistory'
    | 'mortgage'
    | 'property'
    | 'leases'
    | 'metrics'
    );

type OmittedAPIPropertyFields = (
    | '__typename'
    | 'purchaseHistory'
    | 'address'
    | 'pictures'
    | 'propertyValuation'
    | 'units'
  );

export enum MortgageType {
  PURCHASE = 'purchase',
  REFINANCE = 'refinance',
}

export type Address = Pick<APIAddress, 'street1' | 'street2' | 'city' | 'state' | 'zipCode'>;
export type AmortizationTable = Omit<PropertyAmortizationTable, 'property'>;
export type Mortgage = Pick<
APIMortgage,
'amount' | 'id' | 'startDate' | 'rate' | 'term' | 'dashboardPropertyMortgageId' | 'mortgageDueDay'
> & { mortgageType: MortgageType };
// eslint-disable-next-line max-len
export type Valuation = Pick<DashboardPropertyValuation, 'priceMean' | 'provider' | 'date' | 'dashboardPropertyPropertyValuationId'>;
export type PurchaseHistory = Pick<
DashboardPropertyPurchaseHistory,
'date' | 'amount' | 'closingCost' | 'homeImprovementCost' | 'id' | 'dashboardPropertyPurchaseHistoryId'
>;
export type Lease = Pick<APILease, | 'startDate' | 'endDate' | 'rent'>;
export type Unit = Pick<APIUnit, | 'id' | 'isVacant'>;
type Metrics = Omit<DashboardPropertyMetric, '__typename' | 'property'>;

export type Property = Omit<APIDashboardProperty, OmittedAPIDashboardPropertyFields> & {
  groupTransactions: GroupedTransaction[],
  displayName: string;
  transactions: Transaction[],
  address: Address & { fullAddress: string, shortAddress: string },
  amortizationTable: AmortizationTable | null,
  mortgage: Mortgage | null,
  valuations: Valuation[],
  latestValuation: Valuation | null,
  purchaseHistory: PurchaseHistory | null,
  metrics: Metrics | null,
  leases: Lease[],
  units: Unit[],
} & Omit<APIProperty, OmittedAPIPropertyFields>;

export type PropertyWithAlerts = Property & {
  missingExpensesCategories: Category[];
};

export type PropertyForUpdate = Omit<APIDashboardProperty, OmittedAPIDashboardPropertyFields> & {
  displayName: string;
  address: Address & { fullAddress: string },
};

import { useEffect, useRef } from 'react';

import { useAnalytics } from 'lib';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import Carousel from 'react-multi-carousel';
import { BoldTypography, useLabels } from 'ui';
import {
  Box, Button, Collapse, Grid, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { carouselResponsiveConfig } from './carousel';
import { ListingCard } from './ListingCard';
import { ListingSkeletons } from './ListingSkeletons';
import { ListingProperty } from '../../types/property';

export type ExpandableListingProps = {
  label: string,
  showHeader: boolean,
  properties: ListingProperty[],
  isLoading: boolean,
  isFetchingNextPage: boolean,
  fetchNextPage: () => void,
  hasNextPage?: boolean,
  forceLoading: boolean,
  changeExpandedCategory?: () => void,
  expanded: boolean,
  showExpandButton: boolean,
};

export const ExpandableListings = ({
  label,
  showHeader,
  properties,
  isLoading,
  forceLoading,
  isFetchingNextPage,
  fetchNextPage,
  changeExpandedCategory = undefined,
  showExpandButton,
  expanded,
  hasNextPage = false,
}: ExpandableListingProps) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const gridRef = useRef(null);

  const handleScroll = () => {
    if (!expanded) return;
    if (!gridRef.current) return;

    const offset = 150; // safe margin
    const { offsetHeight: elementHeight } = gridRef.current;

    if (hasNextPage
      && !isFetchingNextPage
      && window.innerHeight + window.scrollY >= elementHeight - offset
    ) {
      analytics.track('Page Bottom Reached', {
        pageName: 'Marketplace',
      });

      fetchNextPage();
    }
  };

  useEffect(() => {
    if (expanded) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, expanded]);

  return (
    <Collapse
      in={expanded}
      timeout={300}
      collapsedSize={0}
      sx={{
        width: '100%', maxWidth: '100%',
      }}
    >
      <Grid ref={gridRef} container item xs={12} rowGap={4} sx={{ transition: 'all 0.3s ease-in-out !important' }}>
        {showHeader && (
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" height={36}>
              <BoldTypography variant="body1">
                {label}
              </BoldTypography>
              {showExpandButton && (
                <Button
                  variant="text"
                  onClick={() => changeExpandedCategory?.()}
                  endIcon={!expanded && <RiArrowRightLine size={18} />}
                  startIcon={expanded && <RiArrowLeftLine size={18} />}
                >
                  {expanded ? l.seeLess : l.seeAll}
                </Button>
              )}
            </Stack>
          </Grid>
        )}
        <Grid container item xs={12} rowGap={expanded ? 3 : 20} spacing={3} pb={3} flexGrow={1}>
          {!isLoading && isLowerThanMd && !expanded && (
            <Grid item xs={12} flexGrow={1} sx={{ pr: 3, minHeight: 420 }}>
              <Box height="100%">
                <Carousel
                  containerClass="properties-carousel-container"
                  partialVisible
                  arrows={false}
                  responsive={carouselResponsiveConfig}
                >
                  {properties.slice(0, 3).map((listing, index) => (
                    <Box key={listing.id}>
                      <ListingCard listingIndex={index} listingItem={listing} />
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          )}
          {!isLoading && (!isLowerThanMd || expanded) && properties.map((listing, index) => (
            <Grid item xs={12} md={4} key={listing.id}>
              <ListingCard listingIndex={index} listingItem={listing} />
            </Grid>
          ))}
          {(forceLoading || isLoading || isFetchingNextPage) && (
            <ListingSkeletons avoidCarousel={expanded} />
          )}
        </Grid>
      </Grid>
    </Collapse>
  );
};

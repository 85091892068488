import { MdClose } from 'react-icons/md';
import {
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

export const Progress = ({
  cancel, amount, total, show, title,
}: {
  amount: number, total: number, show: boolean, title: string, cancel: () => void;
}) => {
  const theme = useTheme();

  return (
    <Stack position="sticky" top="0" sx={{ background: theme.palette.background.paper, zIndex: 1600 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 3, backgroundColor: theme.palette.common.white }}
      >
        <Typography variant="h5">
          {title}
        </Typography>
        <IconButton onClick={cancel}>
          <MdClose />
        </IconButton>
      </Stack>

      { show && (
        <LinearProgress
          variant="determinate"
          value={(amount / total) * 100}
          data-testid="buy-box-progress"
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: 0,
            [`& .${linearProgressClasses.bar1Determinate}`]: {
              borderRadius: 0,
            },
            [`& .${linearProgressClasses.bar}`]: {
              transition: 'transform 0.6s linear',
            },
          }}
        />
      ) }

    </Stack>
  );
};

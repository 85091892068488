import { MouseEvent, useState } from 'react';

import { useAnalytics } from 'lib';
import {
  MdCheckCircleOutline, MdClose, MdContentCopy, MdShare,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { SemiBoldTypography, Spacer, useLabels } from 'ui';
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';

const ShareDialog = ({ url, open, onClose }: { url: string, open: boolean, onClose: () => void }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [copied, setCopied] = useState(false);
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1600 }}
      onClick={(e) => e.stopPropagation()}
      fullScreen={isLowerThanMd}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
          <Typography variant="h6">{l.share}</Typography>
          <IconButton onClick={onClose}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: { md: theme.breakpoints.values.sm, xs: 'unset' } }}>
        <SemiBoldTypography variant="body1">{l.shareListingWithFriends}</SemiBoldTypography>
        <Spacer spacing={2} />
        <TextField
          fullWidth
          disabled
          value={url}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                onClick={() => {
                  analytics.track('Button Clicked', {
                    url,
                    buttonName: 'Copy Link',
                  });

                  navigator.clipboard.writeText(url);

                  toast.success(l.linkCopied, {
                    toastId: url,
                  });

                  setCopied(true);

                  setTimeout(() => {
                    setCopied(false);
                  }, 5000);
                }}
              >
                {copied ? <MdCheckCircleOutline /> : <MdContentCopy />}
              </IconButton>
            ),
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
export const ShareButton = ({
  listingID,
  showAsButton = false,
}: {
  listingID: string,
  showAsButton?: boolean
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [dialogOpen, setDialogOpen] = useState(false);

  const shareURL = `${window.location.origin}/marketplace/${listingID}?utm_source=share&register=true`;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    analytics.track('Button Clicked', {
      url: shareURL,
      listingId: listingID,
      buttonName: 'Share Listing',
    });

    if (window.navigator.share) {
      window.navigator.share({
        title: l.shareThisListing,
        text: l.shareListingText,
        url: shareURL,
      });
    } else {
      setDialogOpen(true);
    }
  };

  if (showAsButton) {
    return (
      <>
        <Button
          variant="outlined"
          startIcon={<MdShare />}
          onClick={handleClick}
        >
          {l.share}
        </Button>
        <ShareDialog open={dialogOpen} onClose={() => setDialogOpen(false)} url={shareURL} />
      </>
    );
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          background: 'white',
          position: 'absolute',
          top: 8,
          right: 64,
          zIndex: 1,
          '& > svg': {
            transition: '0.3s',
          },
          '&:active,&:focus,&:hover,&:disabled': {
            background: theme.palette.background.paper,
          },
          '&:hover > svg': {
            fill: theme.palette.primary.dark,
          },
        }}
      >
        <MdShare />
      </IconButton>
      <ShareDialog open={dialogOpen} onClose={() => setDialogOpen(false)} url={shareURL} />
    </>
  );
};

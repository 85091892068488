import { useCallback, useState } from 'react';

import { useAnalytics } from 'lib';
import {
  closestCorners, defaultDropAnimation, DndContext, DragEndEvent, DragOverEvent,
  DragOverlay, DragStartEvent, KeyboardSensor, PointerSensor, useDroppable, useSensor, useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Box, Card, CardContent, Stack, Typography, useTheme,
} from '@mui/material';

import {
  CardComponentProps, Column, ColumnCard,
  KanbanBoardProps,
} from './types';
import { getCardAndColumnID } from './utils';
import { InfoTooltip } from '../tooltip/InfoTooltip';
import { BoldTypography } from '../typography/BoldTypography';

const SortableColCard = <Data extends ColumnCard>({
  card,
  CardComponent,
}: {
  card: Data;
  CardComponent: CardComponentProps<Data>,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: card.id, disabled: card.disabled || card.disableStatusChange });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <CardComponent card={card} />
    </div>
  );
};

const Col = <Data extends ColumnCard>({
  column,
  CardComponent,
  width = 220,
  topContentHeight = 0,
}: {
  column: Column<Data>,
  CardComponent: CardComponentProps<Data>,
  width?: number,
  topContentHeight?: number,
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  const [top, setTop] = useState<number>(0);
  const containerRef = useCallback((node: Element) => {
    if (node === null) {
      return;
    }

    setTop(node.getBoundingClientRect().top + 18);
  }, [setTop]);

  const { setNodeRef } = useDroppable({
    id: column.id,
  });

  return (
    <Stack flexGrow={1} width={width} minWidth={width} gap={4}>
      <Card elevation={0} sx={{ borderRadius: '6px', border: `1px solid ${theme.palette.divider}` }}>
        <CardContent sx={{ p: 4, py: `${theme.spacing(3)} !important` }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexGrow={1}
            width="100%"
          >
            <Stack direction="row" alignItems="center" gap={3}>
              {column.icon}
              <Stack gap={1}>
                <BoldTypography variant="h6">{column.cards.length}</BoldTypography>
                <Typography variant="caption" color="secondary">{column.title}</Typography>
              </Stack>
            </Stack>
            {column.tooltip && (
              <InfoTooltip
                isSmall
                isLight
                isOutlined
                title={(
                  <Typography variant="body2" sx={{ p: 1 }}>
                    {column.tooltip}
                  </Typography>
                )}
                arrow
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: column.title,
                  });
                }}
              />
            )}
          </Stack>
        </CardContent>
      </Card>
      <Box ref={containerRef}>
        <Stack
          gap={3}
          pb={6}
          pt={2}
          ref={setNodeRef}
          sx={{ overflowY: 'scroll', maxHeight: `calc(100vh - ${top + topContentHeight}px)` }}
        >
          {column.cards.map((card) => (
            <SortableColCard key={card.id} card={card} CardComponent={CardComponent} />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export const KanbanBoard = <Data extends ColumnCard>({
  columnsSignal,
  orderedColumnIDs,
  CardComponent,
  onColumnChange,
  colWidth = 264,
  topContentHeight = 0,
}: KanbanBoardProps<Data>) => {
  const [activeCardID, setActiveCardID] = useState<string | undefined>();

  const columns = columnsSignal.value;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const findColumnIDByCardID = useCallback((
    cardOrColumnID: string,
  ): string | undefined => {
    if (cardOrColumnID in columns) {
      return cardOrColumnID;
    }

    return (
      Object.keys(columns) as string[]
    ).find((key) => columns[key].cards.find((card) => card.id === cardOrColumnID));
  }, [columns]);

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveCardID(active?.id as string);
  };

  const handleDragEnd = async ({ active }: DragEndEvent) => {
    const card = getCardAndColumnID(Object.values(columns), active?.id as string);

    if (!card) {
      return;
    }

    if (card.columnID === card.card.originalColumnID) {
      return;
    }

    await onColumnChange(card.card, card.columnID);

    setActiveCardID(undefined);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const activeColumnID = findColumnIDByCardID(
      active.id as string,
    );

    const overColumnID = over?.id && findColumnIDByCardID(
      over.id as string,
    );

    if (
      !activeColumnID
      || !overColumnID
      || activeColumnID === overColumnID
    ) {
      return;
    }

    const newColumns = { ...columns };
    const activeColumn = columns[activeColumnID];

    const activeIndex = activeColumn.cards.findIndex(
      (item) => item.id === active.id,
    );

    const poppedCard = activeColumn.cards[activeIndex];

    newColumns[activeColumnID].cards = newColumns[activeColumnID].cards.filter(
      (item) => item.id !== active.id,
    );

    newColumns[overColumnID].cards.unshift(poppedCard);

    // eslint-disable-next-line no-param-reassign
    columnsSignal.value = newColumns;
  };

  const activeCard = activeCardID ? getCardAndColumnID(Object.values(columns), activeCardID) : null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Stack
        direction="row"
        flexGrow="1"
        spacing={4}
        sx={{
          overflowX: 'scroll', overflowY: 'hidden', maxHeight: '100%', position: 'relative',
        }}
      >
        {orderedColumnIDs.map((columnID) => (
          columns[columnID] && (
            <Col
              key={columnID}
              width={colWidth}
              column={columns[columnID]}
              CardComponent={CardComponent}
              topContentHeight={topContentHeight}
            />
          )
        ))}
      </Stack>
      <DragOverlay dropAnimation={defaultDropAnimation}>
        {activeCard ? <CardComponent isOverlay card={activeCard.card} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

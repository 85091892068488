import {
  Badge, Box, IconButton, styled,
} from '@mui/material';

export const SliderButton = styled(IconButton)(({ theme }) => ({
  height: 36,
  width: 36,
  background: theme.palette.background.paper,
  borderRadius: '50%',
  border: `1px solid ${theme.palette.text.disabled}`,
  '&:hover': {
    background: theme.palette.background.default,
  },
}));

export const SliderButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `0 ${theme.spacing(1)}`,
}));

export const SliderContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  position: 'relative',
  borderRadius: 6,
}));

export const SliderImage = styled('img')({
  width: '100%',
  height: '100%',
});

export const SliderBadge = styled(Badge)(({ theme }) => ({
  '&.active .MuiBadge-badge': {
    backgroundColor: theme.palette.text.primary,
  },
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.text.disabled,
    position: 'relative',
  },
  '&:hover': {
    cursor: 'pointer',
  },
}));

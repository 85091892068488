import { useLocation, useNavigate } from 'react-router-dom';

import {
  OwnerRiskStatus,
  useIsGrowProductEnabled,
  useIsPMDashboardEnabled,
  useIsPMDashboardLeadsEnabled,
  useIsProspectsUsersViewEnabled,
  useIsRetainProductEnabled,
  useIsTeamMembersViewEnabled,
  usePermissions,
} from 'lib';
import {
  Admin, CollapsibleNavMenu, CollapsibleNavMenuItem,
  GrowIcon, GrowLeadsIcon, Home, MenuItem, OpportunitiesIcon,
  OwnerIcon, PartnersIcon, PropertyIcon, RetainIcon, Team, useLabels,
} from 'ui';
import {
  Stack,
} from '@mui/material';

import { useGetOwnerRisksByPM } from '../../api/owner-risk';
import { useGetNewSuggestedPartnerCount } from '../../api/suggested-partners';

const oldPaths = {
  properties: '/admin/properties',
  owners: '/admin/owners',
  prospects: '/admin/prospects',
  blanketOverview: '/admin/blanket-overview',
  leads: '/admin/deals',
  team: '/admin/team',
  retain: '/admin/retain',
  retainAwards: '/admin/retain/awards',
  grow: '/admin/grow',
  growPartners: '/admin/grow/partners',
  growNetwork: '/admin/grow/network',
  dataProperties: '/admin/data/properties',
} as const;

const menuIconSize = 18;
const subMenuIconSize = 16;

export const DrawerMenuItems = ({ onNavigate = () => { } }: { onNavigate: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: pmDashboardEnabled, loading: loadingPMDashboardEnabledFF } = useIsPMDashboardEnabled();
  const { value: prospectsUsersViewEnabled, loading: loadingIsProspectsUsersViewEnabledFF } = useIsProspectsUsersViewEnabled();
  const { value: teamMembersViewEnabled, loading: loadingIsTeamMembersViewEnabled } = useIsTeamMembersViewEnabled();
  const { value: isRetainProductEnabled, loading: loadingIsRetainProductEnabled } = useIsRetainProductEnabled();
  const { value: leadsEnabled, loading: loadingIsLeadsEnabled } = useIsPMDashboardEnabled();

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  if (loadingPMDashboardEnabledFF
    || isLoadingPermissions
    || loadingIsProspectsUsersViewEnabledFF
    || loadingIsTeamMembersViewEnabled
    || loadingIsRetainProductEnabled
    || loadingIsLeadsEnabled
  ) return null;

  return (
    <Stack>
      {pmDashboardEnabled && permissions?.viewPMDashboard && (
        <MenuItem
          icon={<Home height={menuIconSize} width={menuIconSize} />}
          label={l['menu-blanket-overview']}
          onClick={() => handleNavigate(oldPaths.blanketOverview)}
          isActive={location.pathname === oldPaths.blanketOverview}
        />
      )}
      {pmDashboardEnabled && permissions?.viewPMDashboard && (
        <CollapsibleNavMenu
          initialOpen={[oldPaths.team, oldPaths.owners].includes(location.pathname as any)}
          icon={<Admin height={menuIconSize} width={menuIconSize} />}
          label={l['menu-pm']}
        >
          <CollapsibleNavMenuItem
            icon={<OwnerIcon size={subMenuIconSize} />}
            text={l['menu-owners']}
            onNavigate={() => handleNavigate(oldPaths.owners)}
            isActive={location.pathname === oldPaths.owners}
          />
          {prospectsUsersViewEnabled && (
            <CollapsibleNavMenuItem
              icon={<OwnerIcon size={subMenuIconSize} />}
              text={l['menu-prospects']}
              onNavigate={() => handleNavigate(oldPaths.prospects)}
              isActive={location.pathname === oldPaths.prospects}
            />
          )}
          {teamMembersViewEnabled && (
            <CollapsibleNavMenuItem
              icon={<Team height={subMenuIconSize} width={subMenuIconSize} />}
              text={l['menu-team']}
              onNavigate={() => handleNavigate(oldPaths.team)}
              isActive={location.pathname === oldPaths.team}
            />
          )}
          <CollapsibleNavMenuItem
            icon={<PropertyIcon size={subMenuIconSize} />}
            text={l['menu-properties']}
            onNavigate={() => handleNavigate(oldPaths.properties)}
            isActive={location.pathname === oldPaths.properties}
          />
        </CollapsibleNavMenu>
      )}
      {permissions?.viewLeads && leadsEnabled && (
        <MenuItem
          label={l.deals}
          isActive={location.pathname === oldPaths.leads}
          onClick={() => handleNavigate(oldPaths.leads)}
          icon={(
            <GrowLeadsIcon
              color={location.pathname === oldPaths.leads ? 'primary.dark' : 'text.primary'}
              height={menuIconSize}
              width={menuIconSize}
            />
          )}
        />
      )}
      {pmDashboardEnabled && permissions?.viewPMDashboard && isRetainProductEnabled && (
        <MenuItem
          label={l.retain}
          isActive={location.pathname === oldPaths.retain}
          onClick={() => handleNavigate(oldPaths.retain)}
          icon={(
            <RetainIcon
              color={location.pathname === oldPaths.retain ? 'primary.dark' : 'text.primary'}
              height={menuIconSize}
              width={menuIconSize}
            />
          )}
        />
      )}
    </Stack>
  );
};

const paths = {
  blanketOverview: '/pm',
  properties: '/pm/properties',
  contacts: '/pm/contacts',
  leads: '/pm/leads',
  retain: '/pm/retain',
  grow: '/pm/grow/opportunities',
  growNetwork: '/pm/grow/network',
};

const maxSuggestedPartnerCount = 50;

export const MenuItems = ({ onNavigate = () => { } }: { onNavigate: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: pmDashboardEnabled, loading: loadingPMDashboardEnabledFF } = useIsPMDashboardEnabled();
  const { value: isRetainProductEnabled, loading: loadingIsRetainProductEnabled } = useIsRetainProductEnabled();
  const { value: isGrowProductEnabled, loading: loadingIsGrowProductEnabled } = useIsGrowProductEnabled();
  const {
    data: suggestedPartnerCount,
    isLoading: isLoadingSuggestedPartnerCount,
  } = useGetNewSuggestedPartnerCount();
  const {
    data: ownerRisksPages,
    isLoading: isLoadingOwnerRisks,
    hasNextPage: hasNextPageOwnerRisks,
  } = useGetOwnerRisksByPM();

  const ownerRiskCount = ownerRisksPages?.pages.flatMap((page) => page.ownerRisks)
    .filter((p) => p.status === OwnerRiskStatus.NEW && !p.isNoRisk).length ?? 0;

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  const growActive = [
    location.pathname.startsWith(paths.grow),
    location.pathname.startsWith(paths.growNetwork),
  ].includes(true);

  if (loadingPMDashboardEnabledFF
    || isLoadingPermissions
    || loadingIsRetainProductEnabled
    || loadingIsGrowProductEnabled
  ) return null;

  return (
    <>
      {pmDashboardEnabled && permissions?.viewPMDashboard && (
        <MenuItem
          icon={<Home height={menuIconSize} width={menuIconSize} />}
          label={l['menu-blanket-overview']}
          onClick={() => handleNavigate(paths.blanketOverview)}
          isActive={location.pathname === paths.blanketOverview}
        />
      )}
      {isRetainProductEnabled && permissions?.viewRetain && (
        <MenuItem
          icon={<RetainIcon height={subMenuIconSize} width={subMenuIconSize} />}
          label={l.retain}
          onClick={() => handleNavigate(paths.retain)}
          isActive={location.pathname === paths.retain}
          chipContent={!isLoadingOwnerRisks && !hasNextPageOwnerRisks && (ownerRiskCount || undefined)}
        />
      )}
      {isGrowProductEnabled && permissions?.viewGrow && (
        <CollapsibleNavMenu
          initialOpen={growActive}
          icon={<GrowIcon height={menuIconSize} width={menuIconSize} />}
          label={l.grow}
          isActive={growActive}
        >
          <CollapsibleNavMenuItem
            icon={<OpportunitiesIcon height={menuIconSize} width={menuIconSize} />}
            text={l.opportunities}
            onNavigate={() => handleNavigate(paths.grow)}
            isActive={location.pathname === paths.grow}
            chipContent={!isLoadingSuggestedPartnerCount && (
              (suggestedPartnerCount ?? 0) > maxSuggestedPartnerCount
                ? `${maxSuggestedPartnerCount}+`
                : suggestedPartnerCount || undefined
            )}
          />
          <CollapsibleNavMenuItem
            icon={<PartnersIcon height={subMenuIconSize} width={subMenuIconSize} />}
            text={l.network}
            onNavigate={() => handleNavigate(paths.growNetwork)}
            isActive={location.pathname === paths.growNetwork}
          />
        </CollapsibleNavMenu>
      )}
    </>
  );
};

export const ContactsMenuItem = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: permissions } = usePermissions();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  return permissions?.viewPMDashboard && (
    <MenuItem
      icon={<OwnerIcon size={menuIconSize} />}
      label={l['menu-contacts']}
      onClick={() => handleNavigate(paths.contacts)}
      isActive={location.pathname.startsWith(paths.contacts)}
    />
  );
};

export const PropertiesMenuItem = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const { data: permissions } = usePermissions();
  const { value: isRetainProductEnabled } = useIsRetainProductEnabled();
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  return isRetainProductEnabled && permissions?.viewRetain && (
    <MenuItem
      icon={<PropertyIcon size={subMenuIconSize} />}
      label={l['menu-properties']}
      onClick={() => handleNavigate(paths.properties)}
      isActive={location.pathname === paths.properties}
    />
  );
};

export const LeadsMenuItem = ({ onNavigate = () => { } }: { onNavigate?: () => void }) => {
  const l = useLabels();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: permissions } = usePermissions();
  const { value: leadsEnabled } = useIsPMDashboardLeadsEnabled();

  const handleNavigate = (path: string) => {
    onNavigate?.();
    navigate(path);
  };

  return permissions?.viewLeads && leadsEnabled && (
    <MenuItem
      isActive={location.pathname === paths.leads}
      label={l.leads}
      onClick={() => handleNavigate(paths.leads)}
      icon={<GrowLeadsIcon height={menuIconSize} width={menuIconSize} />}
    />
  );
};

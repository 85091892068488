import dayjs from 'dayjs';
import { ExpenseCategory } from 'lib';
import { Control, Controller, useForm } from 'react-hook-form';
import { ControlledFormattedNumberField, useLabels } from 'ui';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem,
  Radio, RadioGroup, Select, Stack, TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { ITransactionForm } from './types';
import { useListOwnerProperties } from '../../api/properties';

const dateFormat = 'MM/DD/YYYY';

const txnTypeEnum = z.enum(['one_time', 'recurring', 'bi_annual']);

const dateField = z.string().pipe(z.coerce.date());

const biAnnualDatesSchema = z.object({
  type: z.literal(txnTypeEnum.Enum.bi_annual),
  dates: z.object({
    firstPaymentDate: dateField,
    secondPaymentDate: dateField,
  }),
});

const recurringDateSchema = z.object({
  type: z.literal(txnTypeEnum.Enum.recurring),
  dates: z.object({
    date: dateField,
  }),
});

const oneTimeDateSchema = z.object({
  type: z.literal(txnTypeEnum.Enum.one_time),
  dates: z.object({
    date: dateField,
  }),
});

const schemaDateCond = z.discriminatedUnion('type', [biAnnualDatesSchema, recurringDateSchema, oneTimeDateSchema]);

const baseSchema = z.object({
  id: z.string().optional(),
  type: txnTypeEnum,
  propertyId: z.string(),
  category: z.string(),
  amount: z.number().min(0),
});

const schema = z.intersection(baseSchema, schemaDateCond);

type DateFieldProps = {
  control: Control<ITransactionForm>,
  label: string,
  name: 'dates.date' | 'dates.firstPaymentDate' | 'dates.secondPaymentDate',
};

const DateField = ({ control, label, name }: DateFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <DesktopDatePicker
        inputFormat={dateFormat}
        label={label}
        {...field}
        onChange={(date) => {
          field.onChange(dayjs(date).format(dateFormat));
        }}
        renderInput={(params) => <TextField sx={{ width: '100%', mt: 5 }} {...params} />}
      />
    )}
  />
);

export const TransactionForm = ({
  hideOptions = false, onSubmit, toggle, initialValues = null, isLoading = false,
} : {
  hideOptions?: boolean,
  onSubmit: (data: ITransactionForm) => Promise<void>,
  toggle: () => void,
  initialValues?: ITransactionForm | null,
  isLoading?: boolean
}) => {
  const l = useLabels();
  const { data: ownerProperties } = useListOwnerProperties();
  const properties = ownerProperties?.properties ?? [];

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<ITransactionForm>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: initialValues ?? {
      type: 'one_time',
      propertyId: properties?.[0].id,
      category: ExpenseCategory.property_hoa,
      dates: {
        date: dayjs().format(dateFormat),
        firstPaymentDate: dayjs().format(dateFormat),
        secondPaymentDate: dayjs().format(dateFormat),
      },
    },
  });

  const [
    type,
  ] = watch([
    'type',
  ]);

  const categories = [
    {
      label: l.hoa,
      value: 'property_hoa',
    },
    {
      label: l.insurance,
      value: 'property_insurance',
    },
    {
      label: l.propertyTax,
      value: 'property_tax',
    },
    {
      label: l.autoAndTravel,
      value: 'auto_and_travel',
    },
    {
      label: l.profFees,
      value: 'legal_and_professional_fees',
    },
    {
      label: l.leasingAndMarketing,
      value: 'leasing_and_marketing',
    },
    {
      label: l.other,
      value: 'other',
    },
  ];

  return (
    <Box component="form" gap={3} onSubmit={handleSubmit(onSubmit)}>
      {!hideOptions && (
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>{l.paymentType}</FormLabel>
              <RadioGroup {...field}>
                <FormControlLabel value="one_time" control={<Radio />} label={l.oneTime} />
                <FormControlLabel value="recurring" control={<Radio />} label={l.monthlyRecurring} />
                <FormControlLabel value="bi_annual" control={<Radio />} label={l.biAnnual} />
              </RadioGroup>
            </FormControl>
          )}
        />
      )}
      <Controller
        name="propertyId"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel>{l.property}</InputLabel>
            <Select label={l.property} {...field}>
              {properties?.map((p) => (
                <MenuItem value={p.id} key={p.id}>
                  {p.address.fullAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel>{l.category}</InputLabel>
            <Select label={l.category} {...field}>
              {categories?.map((c) => (
                <MenuItem value={c.value} key={c.value}>
                  {c.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <ControlledFormattedNumberField
        name="amount"
        label={l.expenseAmount}
        control={control}
        sx={{ mt: 5 }}
        data-testid="transaction-amount"
        fullWidth
      />
      {type === 'bi_annual' ? (
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <DateField control={control} name="dates.firstPaymentDate" label={l.firstPaymentDate} />
          <DateField control={control} name="dates.secondPaymentDate" label={l.secondPaymentDate} />
        </Stack>
      ) : (
        <DateField control={control} name="dates.date" label={type === 'one_time' ? l.date : l.startDate} />
      )}
      <Stack direction="row" gap={3} mt={8}>
        <Button variant="outlined" onClick={toggle} style={{ flexGrow: 1 }}>
          {l.cancel}
        </Button>
        <Button disabled={isLoading || !isValid} variant="contained" type="submit" style={{ flexGrow: 1 }}>
          {isLoading ? l.loading : (
            <span>
              {initialValues?.id ? l.edit : l.add}
            </span>
          )}
        </Button>
      </Stack>
    </Box>
  );
};

import { sum } from './math';
import { calculateCapRate } from './measures';
import { DashboardPropertyMetric } from '../../api/API';

type PropertyMetrics = (Omit<DashboardPropertyMetric, '__typename' | 'property'> | null)[];
type NumeralMetricKey = 'cashflow' | 'noi' | 'totalExpenses' | 'noiExpenses' | 'income';

export const getActualAnnualIncome = (metrics: PropertyMetrics) => getActualMetricData(metrics, 'income');

export const getAllExpenses = (metrics: PropertyMetrics) => getActualMetricData(metrics, 'totalExpenses');

export const getActualCashflow = (metrics: PropertyMetrics) => getActualMetricData(metrics, 'cashflow');

const getEstimatedCashFlow = (metrics: PropertyMetrics) => sum(metrics.map((metric) => metric?.cashFlow ?? 0));

export const getActualNOI = (metrics: PropertyMetrics) => getActualMetricData(metrics, 'noi');

export const getEstimatedNOI = (metrics: PropertyMetrics) => sum(metrics.map((metric) => metric?.NOI ?? 0));

export const getValuation = (metrics: PropertyMetrics) => {
  if (metrics.some((metric) => !metric?.valuation)) {
    return 0;
  }

  return sum(metrics.map((metric) => metric?.valuation ?? 0));
};

export const getCashOnCash = (metrics: PropertyMetrics) => {
  const cashFlow = getEstimatedCashFlow(metrics);
  const cashInvested = getCashInvested(metrics);

  return cashInvested === 0 ? 0 : cashFlow / cashInvested;
};

export const getCapRate = (metrics: PropertyMetrics) => {
  const noi = getEstimatedNOI(metrics);
  const valuation = getValuation(metrics);

  const allHaveValuation = metrics.every((metric) => !!metric?.valuation);

  if (!allHaveValuation) {
    return 0;
  }

  return calculateCapRate(noi / 12, valuation);
};

const getCashInvested = (metrics: PropertyMetrics) => sum(metrics.map((metric) => metric?.cashInvested ?? 0));

const getActualMetricData = (metrics: PropertyMetrics, key: NumeralMetricKey) => {
  const amounts: number[] = [];

  metrics.forEach((metric) => {
    const quarters = [
      metric?.trailingQ1?.[key] ?? 0,
      metric?.trailingQ2?.[key] ?? 0,
      metric?.trailingQ3?.[key] ?? 0,
      metric?.trailingQ4?.[key] ?? 0,
    ];

    amounts.push(sum(quarters));
  });

  return sum(amounts);
};

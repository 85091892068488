import { Stack, styled } from '@mui/material';

import { hexToRGBA } from '../lib/hexToRGBA';

export const MissingDataContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: hexToRGBA(theme.palette.info.main, 0.08),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  margin: theme.spacing(5),
  borderRadius: 4,
}));

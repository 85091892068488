import { calculateMortgage } from 'lib';
import {
  BoldTypography, formatDecimalToPercentage, formatNumberToCurrency, InfoTooltip, useLabels,
} from 'ui';
import { Stack, Typography } from '@mui/material';

import { CalculatedRow, DetailRow } from './Row';
import { useProjections } from './useProjections';
import { Form } from '../types';

export const CashFlow = ({ form }: { form: Form }) => {
  const l = useLabels();
  const projections = useProjections(form);

  const purchasePrice = form.watch('details.purchasePrice');
  const downpayment = form.watch('details.loanPayments.downpayment');
  const downpaymentPercent = form.watch('details.loanPayments.downpaymentPercent');
  const interestRate = form.watch('details.loanPayments.interestRate');
  const mortgageAmortizationPeriod = form.watch('details.loanPayments.mortgageAmortizationPeriod');

  const calculatedMortgage = calculateMortgage({
    interestRate,
    loanAmount: purchasePrice - downpayment,
    loanTerm: mortgageAmortizationPeriod,
  });

  const calculateCashOnCash = (noi: number) => (noi * 12) / (purchasePrice * (downpaymentPercent / 100));

  const year1NetCashFlow = projections.expectedRent.year1 - calculatedMortgage - projections.totalExpenses.year1;
  const year2NetCashFlow = projections.expectedRent.year2 - calculatedMortgage - projections.totalExpenses.year2;
  const year3NetCashFlow = projections.expectedRent.year3 - calculatedMortgage - projections.totalExpenses.year3;

  const year1CashOnCash = calculateCashOnCash(year1NetCashFlow);
  const year2CashOnCash = calculateCashOnCash(year2NetCashFlow);
  const year3CashOnCash = calculateCashOnCash(year3NetCashFlow);

  return (
    <>
      <DetailRow
        form={form}
        rowID="cash-flow"
        bottomBorder={false}
        columns={[
          {
            id: 'cash-flow"',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.cashFlow}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.netCashFlow']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          {}, {}, {},
        ]}
      />
      <CalculatedRow
        totalRow
        form={form}
        label={l.netCashFlow}
        year1={formatNumberToCurrency(year1NetCashFlow || 0, 0)}
        year2={formatNumberToCurrency(year2NetCashFlow || 0, 0)}
        year3={formatNumberToCurrency(year3NetCashFlow || 0, 0)}
      />
      <CalculatedRow
        form={form}
        label={l.cashOnCash}
        bottomBorder={false}
        year1={formatDecimalToPercentage(year1CashOnCash || 0, 2)}
        year2={formatDecimalToPercentage(year2CashOnCash || 0, 2)}
        year3={formatDecimalToPercentage(year3CashOnCash || 0, 2)}
      />
    </>
  );
};

import { ReactNode, useState } from 'react';

import { useAnalytics } from 'lib';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  Collapse, IconButton, Stack, Typography, useTheme,
} from '@mui/material';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';

import { AdminView } from '../admin/AdminView';
import { InfoTooltip } from '../tooltip/InfoTooltip';
import { BoldTypography } from '../typography/BoldTypography';

// eslint-disable-next-line @typescript-eslint/comma-dangle
export type RowGroupConfig<Data,> = {
  rows: Data[],
  columnDefs: (ColumnDef<Data, string> | null)[],
  id: string,
  color: string,
  icon: ReactNode,
  title: string,
  tooltip?: ReactNode,
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const RowGroupData = <Data,>({
  rowGroup,
  columnSort,
}: {
  rowGroup: RowGroupConfig<Data>,
  columnSort: ColumnSort[],
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();
  const [expanded, setExpanded] = useState(false);

  const handleClickExpand = () => {
    setExpanded((currExpanded) => !currExpanded);

    analytics.track('Button Clicked', {
      buttonName: 'Expand Column',
      columnName: rowGroup.title,
    });
  };

  const shouldExpand = expanded && !!rowGroup.rows.length;

  return (
    <Stack>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        flexGrow={1}
        width="100%"
        position="sticky"
        top={0}
        zIndex={100}
        sx={{ background: theme.palette.background.paper }}
        pb={3}
      >
        <IconButton onClick={handleClickExpand} size="small" color="primary" disabled={rowGroup.rows.length === 0}>
          <MdKeyboardArrowRight
            style={{
              transform: shouldExpand ? 'rotate(90deg)' : 'none',
              transition: 'transform 0.3s',
            }}
          />
        </IconButton>
        <Stack direction="row" alignItems="center" gap={3}>
          {rowGroup.icon}
          <Typography variant="caption" color="secondary">{rowGroup.title}</Typography>
          <BoldTypography variant="h6">{rowGroup.rows.length}</BoldTypography>
        </Stack>
        {rowGroup.tooltip && (
          <InfoTooltip
            isSmall
            isLight
            isOutlined
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {rowGroup.tooltip}
              </Typography>
            )}
            arrow
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: rowGroup.title,
              });
            }}
          />
        )}
      </Stack>
      <Collapse in={shouldExpand} sx={{ pl: 10 }} unmountOnExit>
        <AdminView
          minimal
          data={rowGroup.rows}
          columns={rowGroup.columnDefs}
          filter={() => rowGroup.rows}
          allowSearch={false}
          usePadding={false}
          initialState={columnSort}
        />
      </Collapse>
    </Stack>
  );
};

export type RowGroup<Data> = RowGroupConfig<Data>;

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const ListBoard = <Data,>({ rowGroups, columnSort }: { rowGroups: RowGroup<Data>[], columnSort: ColumnSort[] }) => (
  <Stack
    flexGrow="1"
    pb={2}
    gap={4}
    sx={{
      overflowY: 'scroll',
      overflowX: 'hidden',
      maxHeight: 'calc(100vh - 175px)',
    }}
  >
    {rowGroups.map((rowGroup) => (
      <RowGroupData
        key={rowGroup.id}
        rowGroup={rowGroup}
        columnSort={columnSort}
      />
    ))}
  </Stack>
);

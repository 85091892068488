import {
  OwnerLeadsWorkflowStatus
  ,
} from 'lib';

import { OwnerLeadsKanban } from './kanban/OwnerLeadsKanban';
import { OwnerLeadsList } from './list/OwnerLeadsList';

const orderedStatuses = [
  OwnerLeadsWorkflowStatus.NEW,
  OwnerLeadsWorkflowStatus.IN_PROGRESS,
  OwnerLeadsWorkflowStatus.WON,
  OwnerLeadsWorkflowStatus.LOST,
];

export const OwnerLeads = ({ isKanbanViewEnabled }: { isKanbanViewEnabled: boolean }) => (
  isKanbanViewEnabled
    ? <OwnerLeadsKanban orderedStatuses={orderedStatuses} />
    : <OwnerLeadsList orderedStatuses={orderedStatuses} />
);

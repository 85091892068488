import { useState } from 'react';

import {
  Activity,
  GrowthPolicy, SuggestedPartnerStatus, useAnalytics,
} from 'lib';
import {
  MdKeyboardArrowDown, MdLocationPin, MdPhone,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, FireIcon, formatNumberToCurrency, GreyFireIcon,
  hexToRGBA, InvertedButton, Spinner, useLabels,
} from 'ui';
import {
  Box, ButtonGroup, Card, darken, Grid, Link, Menu, MenuItem, Stack, Typography, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { AddToNetworkDialog } from './AddToNetworkDialog';
import { ContactDialog } from './ContactDialog';
import { useUpdateSuggestedPartner } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { PartnerEmail } from '../../components/partners/PartnerEmail';
import { QueryKey } from '../../types/enums';

type Props = {
  img: string;
  style?: React.CSSProperties;
};

const LazyImage = ({ img, style = undefined }: Props) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        filter: loaded ? 'none' : 'blur(5px)',
        transition: 'filter 0.5s',
        ...style,
      }}
    >
      <img src={img} alt={img} onLoad={handleLoad} style={{ display: 'none' }} />
    </Box>
  );
};

const ActivityLevel = ({ activity }: { activity: SuggestedPartner['activity'] }) => (
  <Stack direction="row" alignItems="center" gap={1}>
    {activity ? (
      <>
        <FireIcon />
        {[Activity.MEDIUM, Activity.HIGH].includes(activity) ? <FireIcon /> : <GreyFireIcon />}
        {activity === Activity.HIGH ? <FireIcon /> : <GreyFireIcon />}
      </>
    ) : (
      <>
        <GreyFireIcon />
        <GreyFireIcon />
        <GreyFireIcon />
      </>
    )}
  </Stack>
);

export const SuggestedPartnerCard = ({
  partner,
  growthPolicy = undefined,
}: {
  partner: SuggestedPartner,
  growthPolicy?: GrowthPolicy | null,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [addToNetworkDialogOpen, setAddToNetworkDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const queryClient = useQueryClient();
  const {
    mutateAsync: updateSuggestedPartner,
    isLoading: isUpdatingSuggestedPartner,
  } = useUpdateSuggestedPartner();

  const isLoading = isUpdatingSuggestedPartner || invalidatingQueries;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    analytics.track('Button Clicked', {
      buttonName: 'Suggested Partner - Open Menu',
      id: partner.id,
      email: partner.email,
      name: partner.name,
    });

    setAnchorEl(e.currentTarget);
  };

  const handleClickContact = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Suggested Partner - Contact',
      id: partner.id,
      status: partner.status,
      email: partner.email,
    });

    setContactDialogOpen(true);
    handleCloseMenu();
  };

  const handleClickAddToNetwork = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Suggested Partner - Add to network',
      id: partner.id,
      status: partner.status,
      email: partner.email,
    });

    setAddToNetworkDialogOpen(true);
    handleCloseMenu();
  };

  const handleClickChangeStatus = async (status: SuggestedPartnerStatus) => {
    analytics.track('Button Clicked', {
      buttonName: 'Partners - Change Status',
      id: partner.id,
      email: partner.email,
      name: partner.name,
      status,
    });

    try {
      await updateSuggestedPartner({
        id: partner.id,
        status,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.SUGGESTED_PARTNERS]);
      setInvalidatingQueries(false);
      handleCloseMenu();
      toast.success(l['partners.successStatusChange']);
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
    }
  };

  const [minPrice, maxPrice] = [
    formatNumberToCurrency(partner.minPrice ?? 0, 0, { notation: 'compact' }),
    formatNumberToCurrency(partner.maxPrice ?? 0, 0, { notation: 'compact' }),
  ];

  const metrics = [
    {
      label: l['partners.card.activityLevel'],
      value: <ActivityLevel activity={partner.activity} />,
    },
    {
      label: l['partners.card.yearsOfExperience'],
      value: partner.experienceInYears || l.na,
    },
    {
      label: l['partners.card.priceRange'],
      value: `${minPrice}-${maxPrice}`,
    },
    {
      label: l['partners.card.midRange'],
      value: formatNumberToCurrency(((partner.minPrice ?? 0) + (partner.maxPrice ?? 0)) / 2, 0, { notation: 'compact' }),
    },
  ];

  const spinner = (
    <Stack justifyContent="center" alignItems="center" width="100%" minWidth={120}>
      &nbsp;
      <Spinner size={20} />
    </Stack>
  );

  return (
    <>
      <Card
        elevation={0}
        component={Stack}
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '10px',
          background: theme.palette.background.paper,
          height: '100%',
        }}
      >
        <Stack p={3} gap={4} flexGrow={1} justifyContent="space-between">
          <Stack direction="row" gap={2}>
            <LazyImage
              img={partner.avatar ? partner.avatar : `https://ui-avatars.com/api/?name=${partner.firstName}+${partner.lastName}`}
              style={{
                minHeight: 52,
                minWidth: 52,
                height: 52,
                width: 52,
                borderRadius: '50%',
              }}
            />
            <Stack direction="row" justifyContent="space-between" flexGrow={1} gap={2}>
              <Stack gap={2}>
                <BoldTypography variant="body1">
                  {partner.name}
                </BoldTypography>
                {partner.website && (
                  <Link
                    href={partner.website}
                    component="a"
                    variant="body2"
                    target="_blank"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {partner.officeName}
                  </Link>
                )}
              </Stack>
              {partner.status !== SuggestedPartnerStatus.LOST && (
                <Stack>
                  <ButtonGroup>
                    {partner.status !== SuggestedPartnerStatus.NEW && (
                      <InvertedButton
                        variant="contained"
                        size="small"
                        disabled={isLoading}
                        onClick={() => handleClickAddToNetwork()}
                      >
                        {isLoading ? spinner : l['partners.addToMyNetwork']}
                      </InvertedButton>
                    )}
                    {partner.status === SuggestedPartnerStatus.NEW && (
                      <InvertedButton
                        variant="contained"
                        size="small"
                        onClick={() => handleClickContact()}
                      >
                        {l['partners.contact']}
                      </InvertedButton>
                    )}
                    <InvertedButton
                      variant="contained"
                      size="small"
                      onClick={(e) => handleClickOpenMenu(e)}
                      sx={{
                        p: '0 !important',
                        borderLeft: `1px solid ${darken(theme.palette.primary.dark, 0.1)} !important`,
                      }}
                    >
                      <MdKeyboardArrowDown size={24} />
                    </InvertedButton>
                  </ButtonGroup>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => setAnchorEl(null)}
                  >
                    {partner.status === SuggestedPartnerStatus.NEW && (
                      <MenuItem onClick={() => handleClickAddToNetwork()} disabled={isLoading}>
                        {isLoading ? spinner : l['partners.addToMyNetwork']}
                      </MenuItem>
                    )}
                    {partner.status !== SuggestedPartnerStatus.NEW && (
                      <MenuItem onClick={() => handleClickContact()}>
                        {l['partners.contact']}
                      </MenuItem>
                    )}
                    {partner.status !== SuggestedPartnerStatus.IN_PROGRESS && (
                      <MenuItem onClick={() => handleClickChangeStatus(SuggestedPartnerStatus.IN_PROGRESS)} disabled={isLoading}>
                        {isLoading ? spinner : l['partners.moveToInProgress']}
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => handleClickChangeStatus(SuggestedPartnerStatus.LOST)} disabled={isLoading}>
                      {isLoading ? spinner : l['partners.moveToNotRelevant']}
                    </MenuItem>
                  </Menu>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack gap={2} pb={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} flexWrap="wrap">
              {partner.phone?.trim() && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <MdPhone size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
                  <Typography variant="body2" color="secondary">
                    {partner.phone}
                  </Typography>
                </Stack>
              )}
              <PartnerEmail email={partner.email} />
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <MdLocationPin size={20} color={hexToRGBA(theme.palette.primary.main, 0.8)} />
              <Typography variant="body2" color="secondary">
                {partner.address}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Grid container spacing={3} p={3} sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
          {metrics.map((metric) => (
            <Grid item xs={6} key={`${partner.email}-${metric.label}`}>
              <Stack height="100%" justifyContent="space-between">
                {typeof metric.value === 'string' ? (
                  <BoldTypography variant="body1">
                    {metric.value}
                  </BoldTypography>
                ) : metric.value}
                <Typography variant="body2">
                  {metric.label}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Card>
      <ContactDialog partner={partner} open={contactDialogOpen} onClose={() => setContactDialogOpen(false)} />
      <AddToNetworkDialog
        partner={partner}
        open={addToNetworkDialogOpen}
        onClose={() => setAddToNetworkDialogOpen(false)}
        defaultReferralAmount={growthPolicy?.pmReferralsFeeAmount ?? 0}
      />
    </>
  );
};

import {
  useState,
} from 'react';

import {
  config, Question, shouldSkipQuestion, SubmissionRequest, useAnalytics,
  useCreateSubmission, useListPropertyIDs, useListSellableProperties,
} from 'lib';
import { toast } from 'react-toastify';
import {
  Calendly, Progress, QuestionControlRadio, SuccessDialog, useLabels,
} from 'ui';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Fade,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { SellPropertyContents } from './SellPropertyContents';
import { FooterActions } from './styled';
import { PurchaseQuestionnaireDialogProps } from './types';

export type PurchaseQuestionnaireFormValues = {
  [key: string]: Question
};

export const DialogPurchaseQuestionnaire = ({ open, closeDialog, questions }: PurchaseQuestionnaireDialogProps) => {
  const theme = useTheme();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const [questionSlide, setQuestionSlide] = useState<number>(0);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [displayView, setDisplayView] = useState<'questions' | 'sell' | 'calendly'>('questions');

  const createSubmission = useCreateSubmission(config.purchaseQuestionnaireFormId);
  const l = useLabels();
  const analytics = useAnalytics();

  const [questionsForm, setQuestionsForm] = useState<PurchaseQuestionnaireFormValues>(questions.reduce((acc, q) => ({
    ...acc,
    [q.id]: q,
  }), {} as PurchaseQuestionnaireFormValues));

  const orderFormValues = Object.values(questionsForm)
    .sort((a, b) => Number(a.order) - Number(b.order));

  const orderFormKeys = Object.keys(questionsForm)
    .sort((a, b) => Number(questionsForm[a].order)
      - Number(questionsForm[b].order));

  const {
    data: sellableProperties,
    isLoading: isSellablePropertiesLoading,
  } = useListSellableProperties();
  const { data: propertyIDs, isLoading: propertyIDsLoading } = useListPropertyIDs();

  if (!sellableProperties || isSellablePropertiesLoading || !propertyIDs || propertyIDsLoading) {
    return null;
  }

  const closeQuestionnaireDialog = () => {
    setDisplayView('questions');
    setQuestionSlide(0);
    setSuccessPopupOpen(true);
    closeDialog();
  };

  const cancelQuestionnaireDialog = () => {
    setDisplayView('questions');
    setQuestionSlide(0);
    closeDialog();
  };

  const onFormSubmitted = () => {
    if (!shouldSkipQuestion(config.purchaseQuestionnaireInvestmentPlansQuestion, questionsForm)
      && questionsForm[config.purchaseQuestionnaireInvestmentPlansQuestion].answer
      === config.purchaseQuestionnaireInvestmentPlansSellAnswer) {
      setDisplayView('sell');
      return;
    }

    if (!shouldSkipQuestion(config.purchaseQuestionnaireConsultQuestion, questionsForm)
      && questionsForm[config.purchaseQuestionnaireConsultQuestion].answer === config.purchaseQuestionnaireConsulYesAnswer) {
      setDisplayView('calendly');
      return;
    }

    closeQuestionnaireDialog();
  };

  const submitForm = async () => {
    const requestData = Object.keys(questionsForm).map((key): SubmissionRequest => ({
      id: key,
      answer: questionsForm[key].answer,
    }));

    analytics.track('Form Submitted', {
      formName: 'Buy Box',
      ...requestData.reduce((acc, q) => ({
        ...acc,
        [q.id]: q.answer,
      }), {}),
    });

    onFormSubmitted();

    try {
      await createSubmission.mutateAsync(requestData);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleLastQuestionIsNext = () => {
    const question = orderFormValues[questions.length - 1];

    const skip = shouldSkipQuestion(question.id, questionsForm);

    if (skip) {
      submitForm();
    }
  };

  const handleNext = (idx: number) => () => {
    let nextQuestionIndex = idx;

    let question = null;
    let skip = nextQuestionIndex !== (questions!.length - 1);

    while (skip) {
      nextQuestionIndex += 1;
      if (nextQuestionIndex === (questions!.length - 1)) {
        skip = false;
        break;
      }
      question = orderFormValues[nextQuestionIndex];
      skip = shouldSkipQuestion(question.id, questionsForm);
    }

    setQuestionSlide(nextQuestionIndex);

    if (nextQuestionIndex === (questions.length - 1)) {
      handleLastQuestionIsNext();
    }
  };

  return (
    <>
      <Dialog
        fullScreen={isLowerThanMd}
        open={open}
        maxWidth="sm"
        sx={{ zIndex: 1600 }}
      >
        <DialogContent sx={{ p: 0 }}>
          {displayView === 'questions' && (
            <Box
              component="form"
              height={isLowerThanMd ? '100%' : undefined}
            >
              {(
                questions && questions.length > 1) ? (
                  <Progress
                    title={l['listings.marketplace.preferences']}
                    show={questionSlide === 0
                    || questionsForm[config.purchaseQuestionnaireInvestmentPlansQuestion].answer
                    === config.purchaseQuestionnaireInvestmentPlansBuyerAnswer}
                    cancel={() => {
                      setQuestionSlide(0);
                      closeDialog();
                      analytics.track('Button Clicked', {
                        buttonName: 'Buy Box - Cancel',
                      });
                    }}
                    amount={questionSlide + 1}
                    total={[...Object.keys(questionsForm)].length}
                  />
                ) : null}
              <Box
                position="relative"
                flexGrow="1"
                minHeight={isLowerThanMd ? '100%' : '400px'}
                width={isLowerThanMd ? undefined : theme.breakpoints.values.sm}
              >
                {orderFormKeys
                  .map((id, idx) => (
                    <Fade
                      key={`${id}-slide`}
                      in={idx === questionSlide}
                      timeout={600}
                    >
                      <Box position="absolute" top="0" left="0" height="100%" width="100%">
                        <QuestionControlRadio
                          onChange={(selectedProviderID: string, answer) => {
                            setQuestionsForm((currentQuestions) => ({
                              ...currentQuestions,
                              [selectedProviderID]: {
                                ...currentQuestions[selectedProviderID],
                                answer,
                              },
                            }));
                          }}
                          id={id}
                          question={questionsForm[id]}
                        />
                      </Box>
                    </Fade>
                  ))}
              </Box>
              <FooterActions className="sticky">
                <Button
                  type="button"
                  variant="text"
                  onClick={() => {
                    setQuestionSlide(0);
                    closeDialog();
                  }}
                  sx={{
                    borderColor: theme.palette.grey[500],
                    color: theme.palette.text.primary,
                  }}
                >
                  {l.cancel}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  data-testid="click-next-buy-box-button"
                  sx={{
                    borderColor: theme.palette.grey[500],
                    display: (questionSlide !== questions.length - 1) ? 'block' : 'none',
                  }}
                  onClick={handleNext(questionSlide)}
                >
                  {l.next}
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  data-testid="click-save-buy-box-button"
                  sx={{
                    borderColor: theme.palette.grey[500],
                    display: (questionSlide === questions.length - 1) ? 'block' : 'none',
                  }}
                  onClick={submitForm}
                >
                  {l.save}
                </Button>

              </FooterActions>
            </Box>
          )}
          {displayView === 'sell' && (
            <SellPropertyContents
              closeDialog={closeQuestionnaireDialog}
              cancelDialog={cancelQuestionnaireDialog}
              title={l['listings.marketplace.preferences']}
              sellableProperties={sellableProperties}
              propertyIDs={propertyIDs}
            />
          )}
          {displayView === 'calendly' && (
            <Calendly
              title={l['listings.marketplace.preferences']}
              onClose={closeQuestionnaireDialog}
            />
          )}
        </DialogContent>
      </Dialog>
      <SuccessDialog
        open={successPopupOpen}
        title={l.excitedSuccess}
        description={l['listings.marketplace.preferences.successConfirm']}
        btnText={l.gotIt}
        closeDialog={() => setSuccessPopupOpen(false)}
      />
    </>
  );
};

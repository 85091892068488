import { ReactNode } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
  createTheme, Theme, ThemeProvider,
} from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { globalStyles } from './globalStyles';
import { overrides } from './overrides';
import { themeOptions } from './themeOptions';
import { typography } from './typography';
import { Settings } from '../context/settingsContext';

/**
 * to expand variants of MUI - check out this stackoverflow
 * https://stackoverflow.com/questions/71797203/how-to-use-material-ui-custom-variants-in-react-with-typescript
 */

interface ExtendedTypographyOptions extends TypographyOptions {
  body1bold: React.CSSProperties;
  body2bold: React.CSSProperties;
}

interface Props {
  settings: Settings
  children: ReactNode
}

export const ThemeComponent = (props: Props) => {
  const { settings, children } = props;

  const coreThemeConfig = themeOptions(settings);

  let coreTheme = createTheme(coreThemeConfig);

  const mergeComponentOverrides = (theme: Theme) => overrides(theme, settings);

  const mergeTypography = (theme: Theme) => typography(theme);

  coreTheme = createTheme(coreTheme, {
    components: {
      ...mergeComponentOverrides(coreTheme),
      MuiPickersPopper: {
        styleOverrides: {
          root: {
            zIndex: 1700,
          },
        },
      },
    },
    typography: { ...mergeTypography(coreTheme) } as ExtendedTypographyOptions,
  });

  return (
    <ThemeProvider theme={coreTheme}>
      <CssBaseline />
      <GlobalStyles styles={() => globalStyles(coreTheme, settings) as any} />
      {children}
    </ThemeProvider>
  );
};

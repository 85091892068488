import { useMemo, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { Stack, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { MobileHeader } from './MobileHeader';

export const MobileLayout = () => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigationRef = useRef<HTMLDivElement | null>(null);
  const navigationPadding = useMemo<string>(() => {
    if (navigationRef.current) {
      // give a little buffer of 10px on top of the navigation bar height
      return `${navigationRef.current.clientHeight + 10}px`;
    }
    return '0px';
  }, [navigationRef.current, isSm]);

  return (
    <Stack pb={navigationPadding} height="100%">
      <MobileHeader />
      <Outlet />
    </Stack>
  );
};

import { useEffect, useRef } from 'react';

import {
  createEmbeddingContext,
  EmbeddingContext,
} from 'amazon-quicksight-embedding-sdk';
import {
  Avatar, EmptyFullPageContainer, MissingDataIcon, useLabels,
} from 'ui';
import { Box, Typography } from '@mui/material';

import { useGeneratePmPropertiesDashboard } from '../../api/dashboard';
import { EmbedDashboardResponse } from '../../api/dashboard/types';

export const PmProperties = () => {
  const {
    data,
    isLoading,
    isError,
  } = useGeneratePmPropertiesDashboard();
  const l = useLabels();
  const divRef = useRef<HTMLInputElement>(null);
  const divId = 'properties-container';

  useEffect(() => {
    const embedUrl = data as EmbedDashboardResponse | undefined;

    if (!isLoading && embedUrl && embedUrl?.url) {
      const frameOptions = {
        url: embedUrl?.url ?? '',
        container: `#${divId}`,
        width: '100%',
      };

      createEmbeddingContext()
        .then((embedDashboard: EmbeddingContext) => {
          embedDashboard.embedDashboard(frameOptions, {});

          const iframe = divRef.current?.querySelector('iframe');
          if (iframe) {
            iframe.setAttribute('data-hj-allow-iframe', '');
          }
        });
    }

    return () => {
      if (divRef.current) {
        divRef.current.innerHTML = '';
      }
    };
  }, [isLoading, data]);

  if (isLoading || !data) {
    return null;
  }

  if (isError) {
    return (
      <EmptyFullPageContainer>
        <Avatar
          skin="light"
          variant="circular"
          color="primary"
          sx={{
            mr: 3, mb: 3, width: 72, height: 72,
          }}
        >
          <MissingDataIcon iconProps={{ size: 40, color: 'text.primary' }} />
        </Avatar>
        <Typography variant="h6">
          {l.errorGettingProperties}
        </Typography>
        <Typography variant="body2">
          {l.errorGettingPropertiesDescription}
        </Typography>
      </EmptyFullPageContainer>
    );
  }

  return (
    <Box height="100%" ref={divRef} id={divId} />
  );
};

import { useState } from 'react';

import { MdArrowDropDown } from 'react-icons/md';
import { Spinner, useLabels } from 'ui';
import {
  Button,
  lighten,
  Menu, MenuItem, Stack, Typography,
  useTheme,
} from '@mui/material';

import { CardDetail } from '../kanban/ColCard';
import {
  StatusConfig,
} from '../WorkflowStatus';

export const StatusChanger = <TStatus extends string>({
  id,
  status,
  statuses,
  changeStatus,
  isLoading,
}: {
  id: string,
  status: TStatus,
  statuses: Record<TStatus, StatusConfig>,
  changeStatus: (status: TStatus) => void,
  isLoading: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  const buttonColor = lighten(statuses[status].color, 0.7);
  const buttonTextColor = theme.palette.getContrastText(buttonColor);

  return (
    <>
      <Stack width="fit-content" direction="row" alignItems="center" gap={2}>
        <CardDetail
          label={null}
          content={(
            <Button
              variant="contained"
              size="small"
              disabled={isLoading}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              sx={{
                background: buttonColor,
                color: buttonTextColor,
                transition: 'opacity 0.3s',
                textWrap: 'nowrap',
                '&:hover': {
                  opacity: 0.8,
                  background: buttonColor,
                  color: buttonTextColor,
                },
              }}
              endIcon={isLoading ? null : <MdArrowDropDown />}
            >
              {isLoading && spinner}
              {!isLoading && statuses[status].displayValue}
            </Button>
          )}
        />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{ '& .MuiList-root': { p: 0 } }}
      >
        <MenuItem disabled>
          {l['leads.selectStatus']}
        </MenuItem>
        {(Object.keys(statuses) as TStatus[]).map((currStatus) => (
          <MenuItem
            key={`${id}-${currStatus}-status`}
            onClick={() => {
              setAnchorEl(null);
              changeStatus(currStatus);
            }}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              {statuses[currStatus].smallIcon}
              <Typography variant="body2">
                {statuses[currStatus].displayValue}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

import { useState } from 'react';

import { useHideChat, useListPropertyIDs, useListSellableProperties } from 'lib';
import {
  FallbackSpinner,
} from 'ui';

import { GetStarted } from './GetStarted';
import { Sell } from './Sell';
import { Success } from './Success';

export const SellProperty = () => {
  const [sellDialogOpen, setSellDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const {
    data: sellableProperties,
    isLoading: isSellablePropertiesLoading,
  } = useListSellableProperties();
  const { data: propertyIDs, isLoading: propertyIDsLoading } = useListPropertyIDs();
  useHideChat(true);

  if (!sellableProperties || isSellablePropertiesLoading || !propertyIDs || propertyIDsLoading) {
    return (
      <FallbackSpinner />
    );
  }

  return (
    <>
      <GetStarted open openNextDialog={() => setSellDialogOpen(true)} />
      <Sell
        open={sellDialogOpen}
        openNextDialog={() => setSuccessDialogOpen(true)}
        sellableProperties={sellableProperties.filter((sp) => !propertyIDs.includes(sp.id))}
        propertyIDs={propertyIDs}
      />
      <Success open={successDialogOpen} />
    </>
  );
};

import { ContactDetails } from 'lib';
import { LightTypography } from 'ui';
import { Stack } from '@mui/material';

type ContactData = Pick<ContactDetails, 'firstName' | 'lastName' | 'email'> | null | undefined;

export const Contact = ({ contact }: { contact: ContactData }) => contact && (
  <Stack gap={1}>
    {(contact?.firstName || contact?.lastName) && (
      <LightTypography variant="body1" sx={{ textWrap: 'nowrap' }}>
        {`${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`}
      </LightTypography>
    )}
    <LightTypography variant="body2" color="secondary" sx={{ textWrap: 'nowrap' }}>
      {contact?.email}
    </LightTypography>
  </Stack>
);

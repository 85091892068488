import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { HeaderLink } from '../../link/styled';
import { ReactComponent as DefaultLogo } from '../logo.svg';
import { HeaderLogo } from '../types';

const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: theme.spacing(4),
  justifyContent: 'space-between',
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight,
}));

export const VerticalNavHeader = ({ logo }: { logo: HeaderLogo }) => (
  <MenuHeaderWrapper className="nav-header" sx={{ pl: 6, pt: 2 }}>
    {logo ? (
      <HeaderLink to="/" style={{ backgroundImage: `url(${logo})` }} />
    ) : (
      <HeaderLink to="/">
        <DefaultLogo height="24" title="Portico" />
      </HeaderLink>
    )}
  </MenuHeaderWrapper>
);

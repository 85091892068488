import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useIsProspectsUsersViewEnabled } from 'lib';
import { MdPersonOutline } from 'react-icons/md';
import {
  PageTitle, Spacer, Tabs,
  useLabels,
} from 'ui';
import { Divider, Paper, Stack } from '@mui/material';

import { Owners } from '../owners';
import { Prospects } from '../prospects';

const contactTypes = ['owners', 'prospects'] as const;

type ContactType = typeof contactTypes[number];

export const Contacts = () => {
  const l = useLabels();
  const navigate = useNavigate();
  const { contactType } = useParams<{ contactType: ContactType }>();
  const [tab, setTab] = useState<ContactType>(
    contactTypes.includes(contactType as ContactType) ? contactType as ContactType : 'owners',
  );
  const {
    value: prospectsUsersViewEnabled,
    loading: loadingIsProspectsUsersViewEnabled,
  } = useIsProspectsUsersViewEnabled();

  const isLoading = loadingIsProspectsUsersViewEnabled;

  const navigateToTab = (type: ContactType) => {
    setTab(type);
    navigate(`/pm/contacts/${type}`);
  };

  useEffect(() => {
    if (loadingIsProspectsUsersViewEnabled) return;

    if (!contactTypes.includes(contactType as ContactType) || (!prospectsUsersViewEnabled && contactType === 'prospects')) {
      navigateToTab('owners');
    }
  }, [contactType, loadingIsProspectsUsersViewEnabled]);

  return (
    <Stack height="100%" p={3}>
      <PageTitle title={l['menu-contacts']} />
      <Spacer spacing={2} />
      <Paper elevation={4} sx={{ flexGrow: 1 }}>
        <Tabs
          tabs={[
            {
              label: l['menu-owners'],
              value: 'owners',
              icon: <MdPersonOutline />,
            },
            prospectsUsersViewEnabled && {
              label: l['menu-prospects'],
              value: 'prospects',
              icon: <MdPersonOutline />,
            },
          ]}
          activeTab={tab}
          setTab={navigateToTab}
          isLoading={isLoading}
        />
        <Divider sx={{ mt: 0 }} />
        {tab === 'owners' && (
          <Stack height="100%"><Owners renderedOnRoot={false} /></Stack>
        )}
        {tab === 'prospects' && prospectsUsersViewEnabled && (
          <Stack height="100%"><Prospects renderedOnRoot={false} /></Stack>
        )}
      </Paper>
    </Stack>
  );
};

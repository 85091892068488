import { Theme } from '@mui/material/styles';

import { hexToRGBA } from '../../lib/hexToRGBA';

export const overrideTooltip = (theme: Theme) => ({
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        borderRadius: 6,
        lineHeight: 1.455,
        backgroundColor: hexToRGBA(theme.palette.customColors.tooltipBg, 0.9),
        maxWidth: '450px',
      },
      arrow: {
        color: hexToRGBA(theme.palette.customColors.tooltipBg, 0.9),
      },
    },
  },
});

import {
  calculateCapRate,
  calculateGrossYield,
  calculateNOI,
  calculateTotalExpenses,
  convertArrayToFirstElement,
  createDisplayAddress,
  FavoriteMarketplaceProperty,
  filterNulls, MarketplaceProperty,
  MarketplacePropertyListingItem,
  MarketplacePropertyRental,
  parseManagementFee,
  PropertyExtendedAddress,
  Unit as APIUnit,
} from 'lib';

import {
  Crime, ListingProperty, Unit,
} from '../../types/property';

const replaceURLToCDN: (imageUrl: string) => string = (imageUrl) => {
  const oldUrlStart = 'https://s3.amazonaws.com/marketplace-property-images/';
  const newUrlStart = 'https://assets.blankethomes.com/';

  if (imageUrl.startsWith(oldUrlStart)) {
    return imageUrl.replace(oldUrlStart, newUrlStart);
  }

  return imageUrl;
};

export const convertProperty = (
  marketplaceProperty: MarketplaceProperty,
  listingItem: Omit<MarketplacePropertyListingItem, 'property'>,
  userId: string,
): ListingProperty => {
  const extendedAddress = convertArrayToFirstElement<PropertyExtendedAddress>(marketplaceProperty.property.extendedAddress);
  const propertyRental = convertArrayToFirstElement<MarketplacePropertyRental>(marketplaceProperty.propertyRental);
  const crime = convertArrayToFirstElement<Crime>(marketplaceProperty.crime);

  const pmMgmtFee = listingItem?.managementFee ?? '';

  if (!pmMgmtFee) console.error('No management fee found for listing item', listingItem.id);

  const statesInsuranceFactor = (listingItem?.stateInsuranceFactor ?? 0) / 100;

  if (!statesInsuranceFactor) console.error('No state insurance factor found for listing item', listingItem.id);

  const measures = {
    capRate: (listingItem?.capRate ?? 0) / 100,
    grossYield: (listingItem?.grossYield ?? 0) / 100,
    noi: listingItem?.noi ?? 0,
  };

  const rent: ListingProperty['rent'] = {
    actual: marketplaceProperty.rent,
    estimated: propertyRental?.priceMean ?? null,
    provider: marketplaceProperty.propertyRental?.items[0]?.provider,
    value: marketplaceProperty.rent || (propertyRental?.priceMean ?? null),
  };

  const rentAmount = rent.value ?? 0;
  const parsedManagementFee = parseManagementFee(pmMgmtFee);
  const managementFee = (
    parsedManagementFee.isDollars ? parsedManagementFee.value : (parsedManagementFee.value / 100) * rentAmount
  );

  const insurance = listingItem.insurance !== 0
    ? (listingItem.insurance ?? 0)
    : marketplaceProperty.expenses?.insurance ?? 0;

  const { expenses } = marketplaceProperty;

  const totalExpenses = calculateTotalExpenses({
    hoa: expenses?.hoa ?? 0,
    insurance,
    managementFee,
    tax: expenses?.tax ?? 0,
    maintenance: listingItem.maintenance ?? 0,
  });

  const noi = calculateNOI(rentAmount, totalExpenses);

  const propertyMeasures = {
    capRate: measures?.capRate ?? calculateCapRate(noi, marketplaceProperty.askingPrice),
    grossYield: measures?.grossYield ?? calculateGrossYield(rentAmount ?? 0, marketplaceProperty.askingPrice),
    noi: measures?.noi ?? noi,
  };

  const sortedPictures = marketplaceProperty.listingPictures?.items.sort((a, b) => (a?.rank ?? 99) - (b?.rank ?? 99));
  const pictures = sortedPictures?.map((p) => replaceURLToCDN(p?.url ?? '')) ?? [];

  const primaryPicture = pictures[0] ?? null;
  const { address: regularAddress } = marketplaceProperty;
  const address = (
    extendedAddress?.city && extendedAddress?.state
      && extendedAddress?.zipCode && extendedAddress?.street1 ? extendedAddress : regularAddress
  );

  const units: Unit[] = filterNulls<APIUnit>(marketplaceProperty.property.units?.items);

  const favorites = marketplaceProperty.favorites?.items.filter((f) => f?.owner === userId) ?? [];

  return {
    id: marketplaceProperty.id,
    owner: marketplaceProperty.owner,
    status: marketplaceProperty.status,
    address: {
      street1: address?.street1 ?? '',
      street2: address?.street2,
      city: address?.city ?? '',
      state: address?.state ?? '',
      zipCode: address?.zipCode ?? '',
      lat: extendedAddress?.lat,
      lon: extendedAddress?.lon,
      fullAddress: createDisplayAddress({
        street1: address?.street1 ?? '',
        street2: address?.street2 ?? '',
        city: address?.city ?? '',
        state: address?.state ?? '',
        zipCode: address?.zipCode ?? '',
      }),
    },
    measures: propertyMeasures,
    askingPrice: marketplaceProperty.askingPrice,
    description: marketplaceProperty.description,
    rent,
    propertyID: marketplaceProperty.propertyMarketplacePropertyId,
    expenses: {
      hoa: marketplaceProperty.expenses?.hoa,
      insurance,
      managementFee,
      tax: marketplaceProperty.expenses?.tax,
      total: totalExpenses,
    },
    pictures,
    primaryPicture,
    property: {
      yearBuilt: marketplaceProperty.property.yearBuilt,
      sqft: marketplaceProperty.property.sqft,
      bathrooms: marketplaceProperty.property.bathrooms,
      bedrooms: marketplaceProperty.property.bedrooms,
      isVacant: marketplaceProperty.property.isVacant,
      garages: marketplaceProperty.property.garages,
      lotSize: marketplaceProperty.property.lotSize,
      totalUnits: units.length,
      totalVacantUnits: units.filter((u) => u.isVacant).length,
    },
    crime,
    favoriteId: convertArrayToFirstElement<FavoriteMarketplaceProperty>({ items: favorites })?.id ?? null,
    units,
    listingItem,
  };
};

import { useState } from 'react';

import { MdInfo, MdInfoOutline } from 'react-icons/md';
import {
  IconButton, styled, Theme, Tooltip, tooltipClasses,
  TooltipProps,
  useMediaQuery,
} from '@mui/material';

import { IntoTooltipProps } from './types';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: `1px solid ${theme.palette.divider}`,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 11,
  },
}));

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`&&& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`&&&.align-right .${tooltipClasses.arrow}`]: {
    transform: 'translate3d(1900%, 0px, 0px) !important',
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    border: `1px solid ${theme.palette.divider}`,
  },
  [`&&&.align-right .${tooltipClasses.tooltip}`]: {
    right: '8%',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    fontSize: 11,
  },
}));

export const InfoTooltip = ({
  title,
  isOutlined = false,
  arrow = false,
  color,
  isLight = false,
  track,
  icon = null,
  buttonSx = {},
  isSmall = false,
  iconProps = {},
}: IntoTooltipProps) => {
  const [open, setOpen] = useState(false);

  const TooltipComponent = isLight ? LightTooltip : Tooltip;
  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));

  return (
    <TooltipComponent
      onClose={() => setOpen(false)}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow={arrow}
      title={title}
      PopperProps={{ sx: { zIndex: 2000 } }}
    >
      <IconButton
        size={isSmall ? 'small' : undefined}
        sx={{ p: 0, color, ...buttonSx }}
        onClick={(e) => {
          e.stopPropagation();
          track?.(!open);
          setOpen((currOpen) => !currOpen);
        }}
        onBlur={() => setOpen(false)}
        {...(isDesktop ? {
          onMouseEnter: () => {
            setOpen(true);
            track?.(true);
          },
          onMouseLeave: () => setOpen(false),
        } : {})}
        {...iconProps}
      >
        {icon}
        {!icon && isOutlined
          ? <MdInfoOutline />
          : !icon && <MdInfo />}
      </IconButton>
    </TooltipComponent>
  );
};

import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { SellerLeadsWorkflowStatus, useAnalytics } from 'lib';
import { FaDollarSign } from 'react-icons/fa';
import { MdHome, MdLocationPin, MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  ColumnCard, Columns, FallbackSpinner, formatNumberToCurrency, InvertedButton, KanbanBoard, useLabels,
} from 'ui';
import { Stack, useTheme } from '@mui/material';
import { signal } from '@preact/signals-react';

import { CardDetail, ColCard } from './ColCard';
import { useMutatePhoneCall } from '../../../api/calls';
import { useListSellerLeads, useMutateSellerLead } from '../../../api/workflows';
import { SellerLead } from '../../../api/workflows/converters';
import { LeadCardHeader } from '../components/LeadCardHeader';
import { StatusChanger } from '../components/StatusChanger';
import { moveCardToColumn, updateCard } from '../utils';
import { useSellerLeadStatuses } from '../WorkflowStatus';

const columnsSignal = signal<Columns<SellerLeadCard>>({});

export const SellerLeadsKanban = ({ orderedStatuses }: { orderedStatuses: SellerLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { getStatus } = useSellerLeadStatuses();
  const { data, isLoading, isError } = useListSellerLeads();
  const { mutateAsync } = useMutateSellerLead();

  useEffect(() => {
    if (data) {
      const cols: Columns<SellerLeadCard> = {};

      orderedStatuses.forEach((columnID) => {
        const status = getStatus(columnID);

        cols[columnID] = {
          id: columnID,
          icon: status.icon,
          title: status.displayValue,
          color: status.color,
          tooltip: status.tooltip,
          cards: [],
        };
      });

      data.pages.forEach((page) => {
        page.leads.forEach((lead) => {
          const columnID = lead.status;

          cols[columnID].cards.push({
            ...lead,
            originalColumnID: columnID,
            disabled: false,
            disableStatusChange: true,
          });
        });
      });

      Object.keys(cols).forEach((columnID) => {
        cols[columnID].cards.sort((a, b) => dayjs(b.updatedTime).diff(dayjs(a.updatedTime)));
      });

      columnsSignal.value = cols;
    }

    if (isError) toast.error(l['error.unknownError']);
  }, [data, isLoading, isError, columnsSignal]);

  const handleColumnChange = async (lead: SellerLeadCard, newStatus: string) => {
    analytics.track('Card Moved', {
      cardName: 'Seller Lead',
      from: lead.status,
      to: newStatus,
      id: lead.id,
    });

    const oldStatus = lead.status;

    columnsSignal.value = updateCard(columnsSignal.value, {
      ...lead,
      disabled: true,
    });

    try {
      await mutateAsync({
        id: lead.id,
        status: newStatus as SellerLeadsWorkflowStatus,
      });
      columnsSignal.value = updateCard(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: newStatus as SellerLeadsWorkflowStatus,
        originalColumnID: newStatus as SellerLeadsWorkflowStatus,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);

      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: oldStatus,
        originalColumnID: oldStatus,
      });
    }
  };

  useEffect(() => {
    document.querySelector('html')?.classList.add('full-height');

    return () => {
      document.querySelector('html')?.classList.remove('full-height');
    };
  }, []);

  if (isLoading) {
    return <FallbackSpinner />;
  }

  return (
    <Stack flexGrow={1} height="100%">
      <KanbanBoard
        orderedColumnIDs={orderedStatuses}
        CardComponent={SellerLeadColCard}
        columnsSignal={columnsSignal}
        onColumnChange={handleColumnChange}
      />
    </Stack>
  );
};

type SellerLeadCard = SellerLead & ColumnCard;

const SellerLeadColCard = ({
  card,
  isOverlay = false,
}: {
  card: SellerLeadCard,
  isOverlay?: boolean,
}) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useSellerLeadStatuses();
  const { mutateAsync: reveal, isLoading: isRevealing } = useMutateSellerLead();
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateSellerLead();
  const { mutateAsync: sendPhoneCallRequest, isLoading: isCalling } = useMutatePhoneCall();
  const disabled = isRevealing || isCalling || card.disabled;

  const revealLead = async () => {
    await reveal({
      id: card.id,
      revealed: true,
    });
    columnsSignal.value = updateCard(columnsSignal.value, {
      ...card,
      revealed: true,
    });
  };

  const call = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Call',
      called: 'Seller',
      id: card.id,
    });

    if (!card.sellerContact?.phoneNumber) {
      toast.error(l['error.missingContactPhone']);
      return;
    }

    try {
      await sendPhoneCallRequest(card.sellerContact?.id);
      toast.success(l['leads.call.success']);
    } catch (err) {
      console.error(err);
      toast.error(l['error.unknownError']);
    }
  };

  const handleStatusChange = async (status: SellerLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Seller Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: card.id,
        status,
      });
      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...card,
        disabled: false,
        status,
        originalColumnID: status,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  // split card.name on first comma
  const firstCommaIndex = card.name.indexOf(',');
  const name = firstCommaIndex > -1 ? card.name.slice(0, firstCommaIndex) : card.name;
  const location = firstCommaIndex > -1 ? card.name.slice(firstCommaIndex + 1) : undefined;

  return (
    <ColCard
      card={card}
      revealCard={revealLead}
      revealed={!!card.revealed}
      isRevealing={isRevealing}
      createdTime={card.createdTime}
      updatedTime={card.updatedTime}
      isOverlay={isOverlay}
      footer={card.sellerContact?.phoneNumber && (
        <InvertedButton fullWidth disabled={disabled} color="primary" variant="contained" onClick={call}>
          <Stack direction="row" gap={1} alignItems="center">
            <MdPhone />
            {l.owner}
          </Stack>
        </InvertedButton>
      )}
      afterContent={(
        <StatusChanger
          id={card.id}
          status={card.status}
          statuses={statuses}
          changeStatus={handleStatusChange}
          isLoading={isUpdatingStatus}
        />
      )}
    >
      {card.sellerContact && (
        <LeadCardHeader
          firstName={card.sellerContact.firstName}
          lastName={card.sellerContact.lastName}
          email={card.sellerContact.email}
        />
      )}
      <CardDetail
        label={<MdHome />}
        content={card.propertyId && card.isPropertyVisible ? (
          <Link
            to={`/marketplace/${card.propertyId}`}
            target="_blank"
            style={{ color: theme.palette.info.main, textDecoration: 'none' }}
          >
            {name}
          </Link>
        ) : name}
      />
      {location && <CardDetail label={<MdLocationPin />} content={location} />}
      <CardDetail
        label={<FaDollarSign />}
        content={card.askingPrice ? formatNumberToCurrency(card.askingPrice, 0) : l.toDiscuss}
      />
    </ColCard>
  );
};

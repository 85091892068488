import { forwardRef, Ref } from 'react';

import MuiAvatar from '@mui/material/Avatar';
import { lighten, useTheme } from '@mui/material/styles';

import { CustomAvatarProps } from './types';
import { useBgColor, UseBgColorType } from '../hooks/useBgColor';
import { ThemeColor } from '../layouts/types';

export const Avatar = forwardRef((props: CustomAvatarProps, ref: Ref<any>) => {
  const {
    sx, src, skin = 'filled', color = 'primary',
  } = props;

  const theme = useTheme();
  const bgColors: UseBgColorType = useBgColor();

  const getAvatarStyles = (skinColor: ThemeColor) => {
    let avatarStyles = { ...bgColors[`${skinColor}Filled`] };
    switch (skin) {
      case 'light':
        avatarStyles = { ...bgColors[`${skinColor}Light`] };
        break;
      case 'light-static':
        avatarStyles = {
          color: bgColors[`${skinColor}Light`].color,
          backgroundColor: lighten(theme.palette[skinColor].main, 0.88),
        };
        break;
      default:
        break;
    }

    return avatarStyles;
  };

  const colors: UseBgColorType = {
    primary: getAvatarStyles('primary'),
    secondary: getAvatarStyles('secondary'),
    success: getAvatarStyles('success'),
    error: getAvatarStyles('error'),
    warning: getAvatarStyles('warning'),
    info: getAvatarStyles('info'),
  };

  return <MuiAvatar ref={ref} {...props} sx={!src && skin && color ? Object.assign(colors[color], sx) : sx} />;
});

import {
  Box, keyframes, styled,
} from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FooterActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
  bottom: '-1px',
  background: theme.palette.background.paper,
  zIndex: 1600,
  '&.sticky': {
    position: 'sticky',
    animation: `${fadeIn} 0.3s ease-in-out`,
  },
}));

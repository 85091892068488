import { RightTransition } from 'ui';
import { Dialog } from '@mui/material';

import { MobileMenuProps } from './types';
import { NavigationMenu } from '../navigation/NavigationMenu';

export const MobileMenu = ({ open, onClose }: MobileMenuProps): React.ReactElement => (
  <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={RightTransition}
    sx={{
      zIndex: 'tooltip', width: '75%', maxWidth: 350,
    }}
  >
    <NavigationMenu onNavigate={onClose} />
  </Dialog>
);

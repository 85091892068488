import { FormEvent, useMemo, useState } from 'react';

import { Analytics, useAnalytics } from 'lib';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  BoldTypography,
  InfoTooltip, Spinner, useLabels,
} from 'ui';
import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { PropertyHeader } from './PropertyHeader';
import { PropertyFooter } from './styled';
import { PropertyProps } from './types';
import {
  createFormDefaultValues, PropertyFormValues, PropertyMortgageData, PurchaseData,
  useMortgageMutation,
  usePurchaseHistoryMutation,
} from '../../components/update-properties';
import { Property } from '../../types';
import { QueryKey } from '../../types/enums';

const trackSubmit = (analytics: Analytics, data: PropertyFormValues, property: Property) => {
  analytics.track('Form Submitted', {
    formName: 'Onboarding Property',
    propertyID: property.id,
    propertyAddress: property.address.fullAddress,
    homeImprovementCost: data.purchase.homeImprovementCost,
    closingCost: data.purchase.closingCost,
    purchaseAmount: data.purchase.amount,
    purchaseDate: data.purchase.date && data.purchase.date.format('YYYY-MM-DD'),
    mortgageType: data.mortgage.mortgageType,
    mortgageDueDay: data.mortgage.mortgageDueDay,
    mortgageAmount: data.mortgage.amount,
    mortgageLoanTerm: data.mortgage.loanTerm,
    mortgageOtherLoanTerm: data.mortgage.otherLoanTerm,
    mortgageInterestRate: data.mortgage.interestRate,
    mortgageDate: data.mortgage.date && data.mortgage.date.format('YYYY-MM-DD'),
  });
};

export const OnboardingProperty = ({
  property,
  nextCallback,
  prevCallback,
  skipCallback,
  showNextButton,
  showSaveAndExitButton,
  showPrevButton,
  isLastProperty,
}: PropertyProps) => {
  const theme = useTheme();
  const analytics = useAnalytics();
  const l = useLabels();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
  } = useForm<PropertyFormValues>({
    defaultValues: createFormDefaultValues(property),
  });
  const queryClient = useQueryClient();
  const purchaseHistoryMutation = usePurchaseHistoryMutation();
  const mortgageMutation = useMortgageMutation();
  const [forceLoading, setForceLoading] = useState(false);

  const mutationLoading = useMemo<boolean>(() => [
    purchaseHistoryMutation.isLoading,
    mortgageMutation.isLoading,
  ].some(Boolean), [
    purchaseHistoryMutation.isLoading,
    mortgageMutation.isLoading,
  ]);
  const submitLoading = mutationLoading || forceLoading;

  const buildSubmitForm = (postActionFunction: () => void) => async (data: PropertyFormValues) => {
    trackSubmit(analytics, data, property);

    try {
      setForceLoading(true);
      await Promise.all([
        purchaseHistoryMutation.mutate(property, data),
        mortgageMutation.mutate(property, data),
      ]);
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      postActionFunction();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    } finally {
      setForceLoading(false);
    }
  };

  const submitFormWrapper = (postActionFunction: () => void) => async (event: FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    event.preventDefault();
    await handleSubmit(buildSubmitForm(postActionFunction))(event);
  };

  const formIsInvalid = (): boolean => !!errors.purchase || !!errors.mortgage || submitLoading;

  return (
    <Stack
      justifyContent="space-between"
      pt={3}
      sx={{
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
      }}
      component="form"
      onSubmit={submitFormWrapper(nextCallback)}
    >
      <Stack>
        <PropertyHeader property={property} />
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          direction="row"
          px={5}
          py={4}
          sx={{
            backgroundColor: 'rgba(127, 179, 255, 0.12)',
            marginTop: theme.spacing(3),
          }}
        >
          <InfoTooltip
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {l['updateProperties.whereDidWeGetThisInfo.tooltip']}
              </Typography>
            )}
            color={theme.palette.primary.main}
            isOutlined
            isLight
            buttonSx={{
              marginRight: theme.spacing(1),
            }}
            arrow
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Where did we get this info?',
              });
            }}
          />
          {l['updateProperties.whereDidWeGetThisInfo.label']}
        </Stack>
        <Box px={3}>
          <Stack direction="row" gap={1} mt={5} justifyContent="space-between">
            <BoldTypography variant="body1">
              {l.purchase}
            </BoldTypography>
            <InfoTooltip
              arrow
              isLight
              title={(
                <Typography variant="body2" sx={{ p: 1 }}>
                  {l['tooltip.purchase']}
                </Typography>
              )}
              isOutlined
              track={(value) => {
                analytics.track('Tooltip Toggled', {
                  value,
                  tooltipName: 'Purchase',
                });
              }}
            />
          </Stack>
          <Typography variant="body1">
            {l['purchase-description']}
          </Typography>
          <PurchaseData
            useSubKey={false}
            errors={errors}
            control={control}
            watch={watch}
            revalidate={() => trigger()}
            showDays
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={5}>
            <BoldTypography variant="body1">
              {l.loan}
            </BoldTypography>
            <InfoTooltip
              arrow
              isLight
              title={(
                <Typography variant="body2" sx={{ p: 1 }}>
                  {l['tooltip.loan']}
                </Typography>
              )}
              isOutlined
              track={(value) => {
                analytics.track('Tooltip Toggled', {
                  value,
                  tooltipName: 'Loan',
                });
              }}
            />
          </Stack>
          <Typography variant="body1">
            {l['loan-description']}
          </Typography>
          <PropertyMortgageData useSubKey={false} errors={errors} control={control} watch={watch} revalidate={() => trigger()} />
        </Box>
      </Stack>
      <PropertyFooter className="sticky" height="65px">
        {showPrevButton ? (
          <Button
            type="button"
            variant="outlined"
            onClick={prevCallback}
            sx={{
              borderColor: theme.palette.grey[500],
              color: theme.palette.text.primary,
              width: 85,
            }}
          >
            {l.prev}
          </Button>
        ) : <div />}
        <Stack direction="row" gap={3}>
          {showNextButton ? (
            <Button
              variant="contained"
              type="submit"
              disabled={formIsInvalid()}
              data-testid="next-button"
              sx={{ width: 85 }}
            >
              {submitLoading ? <Spinner size={30} /> : l.next}
            </Button>
          ) : (<div />)}
          {showSaveAndExitButton ? (
            <Button
              variant={isLastProperty ? 'contained' : 'outlined'}
              type="button"
              disabled={formIsInvalid()}
              data-testid="save-and-exit-button"
              sx={{
                borderColor: theme.palette.grey[500],
                ...(!isLastProperty && { color: theme.palette.text.primary }),
                width: 85,
              }}
              onClick={submitFormWrapper(skipCallback)}
            >
              {submitLoading ? <Spinner size={30} /> : l.done}
            </Button>
          ) : (<div />)}
        </Stack>
      </PropertyFooter>
    </Stack>
  );
};

import { useNavigate } from 'react-router-dom';

import { sum, useAnalytics, useGetUsers } from 'lib';
import {
  ActivatedOwner,
  BasicMetric, BoldTypography,
  BuyerLeads,
  DetailedMetric, DollarCircle,
  formatDecimalToPercentage,
  formatNumberToCurrency,
  InfoTooltip,
  InvitedOwner,
  PageTitle,
  PercentRedBig,
  SellerLeads,
  Spacer,
  useLabels,
} from 'ui';
import {
  Grid, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { useListPropertyValuations } from '../../api/properties';
import { useCountBuyerLeads, useCountSellerLeads } from '../../api/workflows';

export const BlanketOverview = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const { data: owners, isLoading: isLoadingOwners } = useGetUsers(['owner']);
  const { data: prospects, isLoading: isLoadingProspects } = useGetUsers(['prospect_user']);
  const { data: buyerLeadCountPages, isLoading: isLoadingBuyerLeadCount } = useCountBuyerLeads();
  const { data: sellerLeadCountPages, isLoading: isLoadingSellerLeadCount } = useCountSellerLeads();
  const { data: valuations, isLoading: isLoadingValuations } = useListPropertyValuations();

  const activatedProspects = prospects?.filter((prospect) => prospect.isActivated) || [];
  const activatedOwners = owners?.filter((owner) => owner.isActivated) || [];

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  if (
    isLoadingOwners
    || isLoadingBuyerLeadCount
    || isLoadingSellerLeadCount
    || isLoadingValuations
    || isLoadingProspects
  ) return null;

  const buyerLeadCount = buyerLeadCountPages?.pages.reduce((acc, page) => acc + page.count, 0);
  const sellerLeadCount = sellerLeadCountPages?.pages.reduce((acc, page) => acc + page.count, 0);
  const portfolioValue = sum(valuations?.pages.map((page) => sum(page.valuations)) ?? []);

  return (
    <Stack p={3}>
      <PageTitle title={l['menu-blanket-overview']} />
      <Spacer spacing={3} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack
            sx={{
              background: theme.palette.primary.main,
              borderRadius: '6px',
              p: 4,
              height: '100%',
            }}
            gap={5}
          >
            <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
              <BoldTypography variant="h6" color="primary.contrastText">
                {l.portfolioValue}
              </BoldTypography>
              <InfoTooltip
                title={l['pm-dashboard.blanket-overview.portfolioValue.tooltip']}
                arrow
                isLight
                isOutlined
                buttonSx={{ color: theme.palette.primary.contrastText }}
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Portfolio Value Tooltip',
                  });
                }}
              />
            </Stack>
            <Stack direction="row" gap={2} alignItems="center" color="primary.contrastText">
              <DollarCircle />
              <BoldTypography variant="h6" color="primary.contrastText">
                {formatNumberToCurrency(portfolioValue, 0)}
              </BoldTypography>
            </Stack>
          </Stack>
        </Grid>
        {isLowerThanMd ? (
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={6}>
              <BasicMetric label={l.buyerLeads} value={buyerLeadCount} icon={<BuyerLeads />} />
            </Grid>
            <Grid item xs={6}>
              <BasicMetric label={l.sellerLeads} value={sellerLeadCount} icon={<SellerLeads />} />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={4}>
              <BasicMetric label={l.buyerLeads} value={buyerLeadCount} icon={<BuyerLeads />} />
            </Grid>
            <Grid item xs={12} md={4}>
              <BasicMetric label={l.sellerLeads} value={sellerLeadCount} icon={<SellerLeads />} />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <DetailedMetric
            title={l.numberOfOwners}
            tooltipContent={l['pm-dashboard.blanket-overview.owners.tooltip']}
            onClick={() => navigate('/admin/owners')}
            metrics={[
              {
                id: 'signed-up-owners',
                value: activatedOwners.length,
                label: l.activated,
                icon: <ActivatedOwner />,
              },
              {
                id: 'invited-owners',
                value: owners?.length,
                label: l.invited,
                icon: <InvitedOwner />,
              },
              {
                id: 'owners-conversion-rate',
                value: formatDecimalToPercentage(owners?.length ? activatedOwners.length / owners.length : 0, 2),
                label: l['pm-dashboard.owner.conversion-rate'],
                icon: <PercentRedBig />,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <DetailedMetric
            title={l.numberOfProspects}
            tooltipContent={l['pm-dashboard.blanket-overview.prospects.tooltip']}
            onClick={() => navigate('/admin/prospects')}
            metrics={[
              {
                id: 'signed-up-prospects',
                value: activatedProspects.length,
                label: l.activated,
                icon: <ActivatedOwner />,
              },
              {
                id: 'invited-prospects',
                value: prospects?.length,
                label: l.invited,
                icon: <InvitedOwner />,
              },
              {
                id: 'prospects-conversion-rate',
                value: formatDecimalToPercentage(prospects?.length ? activatedProspects.length / prospects.length : 0, 2),
                label: l['pm-dashboard.owner.conversion-rate'],
                icon: <PercentRedBig />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

import { ReactNode, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { useAnalytics } from 'lib';
import {
  MdOutlineTimer,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  ColumnCard,
  InfoTooltip,
  LightTooltip, SemiBoldTypography, useConfetti, useLabels,
} from 'ui';
import {
  Card, Divider, Stack, Typography,
  useTheme,
} from '@mui/material';

import { RevealOverlayKanban } from './RevealOverlayKanban';

dayjs.extend(relativeTime);
dayjs.extend(timezone);

const datetimeFormat = 'MM/DD/YYYY hh:mm A';

export const CardDetail = ({
  label,
  content,
  tooltipContent = undefined,
  tooltipName = undefined,
}: {
  label: ReactNode,
  content: ReactNode,
  tooltipContent?: ReactNode,
  tooltipName?: string,
}) => {
  const analytics = useAnalytics();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {label}
      <Stack direction="row" alignItems="center" justifyContent="space-between" flexGrow={1} gap={2}>
        {typeof content === 'string' ? (
          <SemiBoldTypography variant="body2" sx={{ textWrap: 'nowrap' }}>{content}</SemiBoldTypography>
        ) : content}
        {tooltipContent && (
          <InfoTooltip
            title={tooltipContent}
            isOutlined
            arrow
            isSmall
            isLight
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: tooltipName ?? (content?.toString() ?? ''),
              });
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export const ColCard = <Data extends ColumnCard>({
  card,
  revealCard = undefined,
  children,
  revealed,
  isRevealing = false,
  createdTime,
  updatedTime,
  isOverlay = false,
  footer,
  afterContent = undefined,
}: {
  card: Data,
  revealCard?: () => Promise<void>,
  children: ReactNode,
  revealed: boolean,
  isRevealing?: boolean,
  createdTime: string,
  updatedTime: string,
  isOverlay?: boolean,
  footer: ReactNode,
  afterContent?: ReactNode,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const confetti = useConfetti();
  const [cursor, setCursor] = useState<'grab' | 'grabbing'>('grab');

  const disabled = isRevealing || card.disabled;

  const reveal = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Reveal Lead',
      id: card.id,
    });

    try {
      await revealCard?.();
      confetti.trigger();
    } catch (err) {
      console.error('Failed to reveal card');

      toast.error(l['error.unknownError']);
    }
  };

  return (
    <Card
      elevation={isOverlay ? 4 : 0}
      onMouseDown={() => setCursor('grabbing')}
      onMouseUp={() => setCursor('grab')}
      sx={{
        p: 3,
        position: 'relative',
        opacity: card.disabled ? 0.5 : undefined,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '6px',
        // eslint-disable-next-line no-nested-ternary
        cursor: card.disableStatusChange ? 'default' : isOverlay ? 'grabbing' : cursor,
      }}
    >
      <Stack gap={3}>
        {children}
        <Stack direction="row" gap={2} alignItems="center">
          <LightTooltip
            arrow
            title={(
              <Stack gap={1} p={1}>
                <CardDetail
                  label={(
                    <Typography variant="body2" color="textSecondary">
                      {l.created}
                      :
                    </Typography>
                  )}
                  content={dayjs(createdTime).tz(dayjs.tz.guess()).format(datetimeFormat)}
                />
                <CardDetail
                  label={(
                    <Typography variant="body2" color="textSecondary">
                      {l.updated}
                      :
                    </Typography>
                  )}
                  content={dayjs(updatedTime).tz(dayjs.tz.guess()).format(datetimeFormat)}
                />
              </Stack>
            )}
            placement="top"
          >
            <Stack justifyContent="center"><MdOutlineTimer /></Stack>
          </LightTooltip>
          <Typography variant="body2">
            {dayjs(updatedTime).fromNow()}
          </Typography>
        </Stack>
        {afterContent}
      </Stack>
      {footer && (
        <>
          <Divider sx={{ mx: -4, mb: 0, mt: 4 }} />
          <Stack direction="row" justifyContent="space-between" alignItems="center" pt={4}>
            {footer}
          </Stack>
        </>
      )}
      <RevealOverlayKanban
        reveal={reveal}
        revealed={revealed}
        isRevealing={isRevealing}
        disabled={disabled}
      />
    </Card>
  );
};

import { useEffect, useState } from 'react';

import { useAnalytics } from 'lib';
import CountUp from 'react-countup';
import {
  DollarRect, formatNumberToCurrency, InfoTooltip, PropertyIcon, useLabels,
} from 'ui';
import {
  Card, Divider, Stack, Typography,
  useTheme,
} from '@mui/material';

import { useGetOwnerRisksByPM } from '../../../api/owner-risk';
import { useGetDashboardPropertyIDs } from '../../../api/properties';

export const ValueAndProperties = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const [lastPropertyIDsCount, setLastPropertyIDsCount] = useState(0);
  const [lastClientValueSum, setLastClientValueSum] = useState(0);

  const { data: ownerRisks } = useGetOwnerRisksByPM();
  const { data: propertyIdPages } = useGetDashboardPropertyIDs();

  const getNumOfPropertyIDs = () => propertyIdPages?.pages.flatMap((page) => {
    const ids: string[] = [];

    page.propertyIDs.forEach((id) => ids.push(id));

    return ids;
  }).length ?? 0;
  const clientValueSum = ownerRisks?.pages.flatMap((page) => page.ownerRisks).reduce(
    (acc, risk) => acc + risk.clientValue, 0,
  ) ?? 0;

  useEffect(() => {
    if (!ownerRisks && !propertyIdPages) return;

    setLastPropertyIDsCount(getNumOfPropertyIDs());
    setLastClientValueSum(ownerRisks?.pages.flatMap((page) => page.ownerRisks).reduce(
      (acc, risk) => acc + risk.clientValue, 0,
    ) ?? 0);
  }, [ownerRisks, propertyIdPages]);

  return (
    <Card sx={{
      height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly',
    }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={4} py={{ xs: 4, sm: 0 }} flexGrow={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Stack
            justifyContent="center"
            alignItems="center"
            p={1}
            border={`1px solid ${theme.palette.grey.A100}`}
            borderRadius="4px"
            height={40}
            width={40}
          >
            <DollarRect />
          </Stack>
          <Stack>
            <Typography variant="h6">
              <CountUp
                start={lastClientValueSum}
                end={clientValueSum}
                duration={2}
                formattingFn={(value) => formatNumberToCurrency(value, 0)}
              />
            </Typography>
            <Typography variant="caption">
              {l['retention.totalClientValue']}
            </Typography>
          </Stack>
        </Stack>
        <InfoTooltip
          arrow
          isLight
          title={(
            <Typography variant="body2" sx={{ p: 1 }}>
              {l['retention.tooltip.totalClientValue']}
            </Typography>
          )}
          isOutlined
          track={(value) => {
            analytics.track('Tooltip Toggled', {
              value,
              tooltipName: 'Total Client Value',
            });
          }}
        />
      </Stack>
      <Divider sx={{ m: 0 }} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={4} py={{ xs: 4, sm: 0 }} flexGrow={1}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Stack
            justifyContent="center"
            alignItems="center"
            p={1}
            border={`1px solid ${theme.palette.grey.A100}`}
            borderRadius="4px"
            height={40}
            width={40}
          >
            <PropertyIcon size={28} />
          </Stack>
          <Stack>
            <Typography variant="h6">
              <CountUp start={lastPropertyIDsCount} end={getNumOfPropertyIDs()} duration={2} />
            </Typography>
            <Typography variant="caption">
              {l['retention.monitoredProperties']}
            </Typography>
          </Stack>
        </Stack>
        <InfoTooltip
          arrow
          isLight
          isOutlined
          title={(
            <Typography variant="body2" sx={{ p: 1 }}>
              {l['retention.tooltip.monitoredProperties']}
            </Typography>
          )}
          track={(value) => {
            analytics.track('Tooltip Toggled', {
              value,
              tooltipName: 'Monitored Properties',
            });
          }}
        />
      </Stack>
    </Card>
  );
};

import { useLabels } from 'ui';
import {
  Stack, Typography,
} from '@mui/material';

import { TransactionCard } from './TransactionCard';
import { TableItem } from './types';

export const List = ({
  rows,
}: {
  rows: TableItem[],
}) => {
  const l = useLabels();

  return (
    <Stack gap={2}>
      {rows.length === 0 && (
        <Stack direction="column" alignItems="center" justifyContent="space-between" mt={10}>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            {l['transactions.no-results']}
            <br />
            {l['transactions.no-results.try-again']}
          </Typography>
        </Stack>
      )}
      {rows.filter((row) => row.amount !== 0)
        .map((row) => <TransactionCard state={row} key={row.id} />)}
    </Stack>
  );
};

import {
  Content, PropertiesResponse, Question, QuestionCondition, QuestionsResponse, ResponseAction,
  ResponseTerm,
  SubmissionsResponse,
} from './types';
import { sortByDesc } from '../../lib/sort';

const filteredQuestionNames = ['pm_user_id', 'owner', 'owner_email'];

export const convertPropertiesResponseToQuestionConditionMap = (
  properties: PropertiesResponse,
): Map<string, QuestionCondition[]> => {
  const questionsConditionMap = new Map<string, QuestionCondition[]>();

  if (!properties.content.conditions) {
    return questionsConditionMap;
  }

  properties.content.conditions.forEach((condition) => {
    const actions = JSON.parse(condition.action) as ResponseAction[];
    const terms = JSON.parse(condition.terms) as ResponseTerm[];

    actions.forEach((action) => {
      const questionConditions = terms.map((term:ResponseTerm) => ({
        operator: term.operator,
        field: term.field,
        value: term.value,
        visibility: action.visibility,
        link: condition.link,
      }));

      const questionsConditionValue = questionsConditionMap.get(action.field) ?? [];
      questionsConditionMap.set(action.field, questionsConditionValue.concat(questionConditions));
    });
  });

  return questionsConditionMap;
};

export const convertQuestionsAndSubmissionResponseToQuestions = (
  response: SubmissionsResponse,
  questionsData: QuestionsResponse,
  properties: PropertiesResponse,
): Question[] => {
  const content = sortByDesc<Content>(
    response.content,
    'created_at',
  );

  const questionsConditionMap = convertPropertiesResponseToQuestionConditionMap(properties);

  const res = Object.keys(questionsData.content)
    .filter((key) => !filteredQuestionNames.includes(questionsData.content[key].name))
    .map((key) : Question => ({
      id: key,
      name: questionsData.content[key].text,
      order: questionsData.content[key].order,
      description: questionsData.content[key].description,
      answer: content[0] ? content[0].answers[key].answer : '',
      options: questionsData.content[key].options ? String(questionsData.content[key].options).split('|') : [],
      type: questionsData.content[key].type,
      conditions: questionsConditionMap.get(key) ?? [],
    }));

  return res;
};

import { useEffect, useState } from 'react';

import {
  AppSettings, SuggestedPartnerStatus, useAnalytics, useAppSettings, useAuth, User,
} from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, copyHtmlToRTF, InvertedButton, RichEditor, Spinner, useLabels,
} from 'ui';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useUpdateSuggestedPartner } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { QueryKey } from '../../types/enums';

const getInitialHtmlContent = (partner: SuggestedPartner, user: User, appSettings: AppSettings): string => `
  <p>Hi ${partner.name},</p>
  <p>I hope this email finds you well.</p>
  <p>
    I am ${user.name || '[Your Name]'}, from ${appSettings.pmName}.
    We are currently expanding our referral program and believe that a partnership with you could be mutually beneficial.
    Our referral program is designed to provide significant value to our partners.
  </p>
  <p>
    I would love the opportunity to discuss this further and explore how we can work together to achieve our common goals.
    Are you available for a meeting sometime next week? Please let me know your availability,
    and I will be happy to coordinate a convenient time.
  </p>
  <p>Looking forward to the possibility of working together.</p>
  <p>Best regards,</p>
  <p>${user.name || '[Your Name]'}</p>
  <p>${appSettings.pmName}</p>
  <p>${user.effectivePhone || '[Your Phone Number]'}</p>
`;

export const ContactDialog = ({
  partner,
  open,
  onClose,
}: {
  partner: SuggestedPartner,
  open: boolean,
  onClose: () => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const queryClient = useQueryClient();
  const { data: appSettings } = useAppSettings();
  const { user } = useAuth();
  const { mutateAsync: updateSuggestedPartner, isLoading: isUpdatingSuggestedPartner } = useUpdateSuggestedPartner();
  const [invalidatingQuery, setInvalidatingQuery] = useState(false);

  const [emailContent, setEmailContent] = useState({
    html: '',
    text: '',
  });

  const isLoading = isUpdatingSuggestedPartner || invalidatingQuery;

  const handleClickCopyAndClose = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Copy and save',
      id: partner.id,
      email: partner.email,
      status: partner.status,
    });

    await copyHtmlToRTF(emailContent.html, emailContent.text);
    await updateSuggestedPartner({
      id: partner.id,
      status: SuggestedPartnerStatus.IN_PROGRESS,
    });
    setInvalidatingQuery(true);
    await queryClient.invalidateQueries([QueryKey.SUGGESTED_PARTNERS]);
    setInvalidatingQuery(false);
    toast.success(l['partners.contact.copyAndClose.success']);

    handleClickClose(null, 'buttonClick');
  };

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  useEffect(() => {
    if (open && appSettings && user) {
      setEmailContent({
        html: getInitialHtmlContent(partner, user, appSettings),
        text: '',
      });
    }
  }, [open, appSettings, user]);

  useEffect(() => {
    if (!open) setEmailContent({ html: getInitialHtmlContent(partner, user!, appSettings!), text: '' });
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={false} fullWidth maxWidth="md" disableEscapeKeyDown>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['partners.contact']}
          </BoldTypography>
          <IconButton onClick={() => handleClickClose(null, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack mt={3} gap={4}>
          <RichEditor
            initialContent={emailContent.html}
            onContentChange={(html, text) => setEmailContent({ html, text })}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" pt={3}>
          <Button variant="text" onClick={(e) => handleClickClose(e, 'buttonClick')} size="small">
            {l.cancel}
          </Button>
          <InvertedButton
            disabled={isLoading}
            onClick={handleClickCopyAndClose}
            size="small"
          >
            {isLoading ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l['partners.contact.copyAndClose']}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

import { ReactNode } from 'react';

import { GiBrickWall } from 'react-icons/gi';
import { MdBed, MdOutlineBathtub, MdSquareFoot } from 'react-icons/md';
import {
  BoldTypography,
  formatNumber, GarageBlank, SquareIcon, useLabels,
} from 'ui';
import {
  Grid, Stack, Typography, useTheme,
} from '@mui/material';

import { ListingProperty } from '../../../types/property';

const MAX_LOT_SIZE = 100_000;

const PhysicalDetail = ({ icon, value, title }: { icon: ReactNode, value: string | number, title: string }) => (
  <Stack direction="row" gap={1.5} alignItems="center">
    {icon}
    <Stack>
      <BoldTypography variant="body1">
        {value}
      </BoldTypography>
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
    </Stack>
  </Stack>
);

export const PhysicalDetails = ({ property }: { property: ListingProperty }) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Grid container rowGap={2}>
      <Grid item xs={4} sm={3}>
        <PhysicalDetail
          icon={<MdBed size={25} color={theme.palette.text.primary} />}
          value={property.property.bedrooms ?? 0}
          title={l.beds}
        />
      </Grid>
      <Grid item xs={4} sm={3}>
        <PhysicalDetail icon={<MdOutlineBathtub size={25} />} value={property.property.bathrooms ?? 0} title={l.baths} />
      </Grid>
      {property.property.sqft ? (
        <Grid item xs={4} sm={3}>
          <PhysicalDetail icon={<MdSquareFoot size={25} />} value={formatNumber(property.property.sqft, 0)} title={l.sqft} />
        </Grid>
      ) : null}
      {property.property.yearBuilt ? (
        <Grid item xs={4} sm={3}>
          <PhysicalDetail icon={<GiBrickWall size={25} />} value={property.property.yearBuilt} title={l.yearBuilt} />
        </Grid>
      ) : null}
      {property.property.lotSize && property.property.lotSize <= MAX_LOT_SIZE ? (
        <Grid item xs={4} sm={3}>
          <PhysicalDetail
            icon={<SquareIcon height={25} width={25} />}
            value={formatNumber(property.property.lotSize, 0)}
            title={l.lotSize}
          />
        </Grid>
      ) : null}
      {property.property.garages ? (
        <Grid item xs={4} sm={3}>
          <PhysicalDetail icon={<GarageBlank height={25} width={25} />} value={property.property.garages} title={l.garages} />
        </Grid>
      ) : null}
    </Grid>
  );
};

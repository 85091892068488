import { useAnalytics } from 'lib';
import {
  formatDecimalToPercentage, formatNumberToCurrency, InfoTooltip, useLabels,
} from 'ui';
import {
  Box, Skeleton, Stack, Typography, useTheme,
} from '@mui/material';

import { Expenses } from '../../../types/report';

type WithSkeleton = {
  showSkeleton?: boolean;
};

export const OperatingIncome = ({
  value,
  totalOtherIncome,
  showSkeleton = false,
}: WithSkeleton & { value: number, totalOtherIncome: number | null | undefined }) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Stack width="100%" p={4} gap={4} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
      <Typography variant="h6">
        {l.operatingIncome}
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">
          {l.rent}
        </Typography>
        <Typography variant="caption">
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatNumberToCurrency(value, 0)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">
          {l.totalOtherIncome}
        </Typography>
        <Typography variant="caption">
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatNumberToCurrency(totalOtherIncome ?? 0, 0)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const LoanPayments = ({ value, showSkeleton = false }: WithSkeleton & { value: number }) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Stack width="100%" p={4} gap={4} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {l['reports.widgets.loanPayments']}
        </Typography>
        <InfoTooltip
          title={(
            <Typography variant="body2">
              {l['reports.widgets.loanPayments.tooltip']}
            </Typography>
          )}
          isOutlined
          arrow
          isLight
          track={(v) => {
            analytics.track('Tooltip Toggled', {
              value: v,
              tooltipName: 'Loan Payments Tooltip',
            });
          }}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">
          {l.mortgage}
        </Typography>
        <Typography variant="caption">
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatNumberToCurrency(value, 0)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const Expense = ({
  value, dataTestId, title, showSkeleton = false,
}: WithSkeleton & { value: number | undefined | null, dataTestId: string, title: string }) => {
  if (showSkeleton) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : title}
        </Typography>
        <Typography variant="caption" data-testid={dataTestId}>
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatNumberToCurrency(value ?? 0, 0)}
        </Typography>
      </Stack>
    );
  }

  if (value !== 0) {
    return (
      <span>
        {
          value !== 0 ? (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption">
                {title}
              </Typography>
              <Typography variant="caption" data-testid={dataTestId}>
                {formatNumberToCurrency(value ?? 0, 0)}
              </Typography>
            </Stack>
          ) : null
        }
      </span>
    );
  }

  return null;
};

export const OperatingExpense = ({
  expenses,
  hideBorder = false,
  showSkeleton = false,
}: WithSkeleton & { expenses: Expenses | null, hideBorder?: boolean }) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Stack width="100%" p={4} gap={4} sx={{ borderBottom: hideBorder ? 'none' : `1px solid ${theme.palette.divider}` }}>
      <Typography variant="h6">
        {l.operatingExpenses}
      </Typography>
      <Expense
        value={expenses?.tax}
        dataTestId="dashboard.report.propertyTaxValue"
        title={l.propertyTax}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.insurance}
        dataTestId="dashboard.report.insurance"
        title={l.insurance}
        showSkeleton={showSkeleton}
      />
      <Expense value={expenses?.hoa} dataTestId="dashboard.report.hoaFees" title={l.hoaFees} showSkeleton={showSkeleton} />
      <Expense
        value={expenses?.managementFee}
        dataTestId="dashboard.report.managementFees"
        title={l.managementFees}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.autoAndTravel}
        dataTestId="dashboard.report.autoAndTravel"
        title={l.autoAndTravel}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.utilities}
        dataTestId="dashboard.report.utilities"
        title={l.utilities}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.legalAndProfessionalFees}
        dataTestId="dashboard.report.legalAndProfessionalFees"
        title={l.legalAndProfessionalFees}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.rentalRegistration}
        dataTestId="dashboard.report.rentalRegistration"
        title={l.rentalRegistration}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.supplies}
        dataTestId="dashboard.report.supplies"
        title={l.supplies}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.maintenance}
        dataTestId="dashboard.report.maintenance"
        title={l.maintenanceReserve}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.leasingAndMarketing}
        dataTestId="dashboard.report.leasingAndMarketing"
        title={l.leasingAndMarketing}
        showSkeleton={showSkeleton}
      />
      <Expense
        value={expenses?.other}
        dataTestId="dashboard.report.otherExpenses"
        title={l.otherExpenses}
        showSkeleton={showSkeleton}
      />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1bold">
          {l.totalOperatingExpenses}
        </Typography>
        <Typography variant="body1bold" data-testid="dashboard.report.totalOperatingExpenses">
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatNumberToCurrency(expenses?.totalExpense ?? 0, 0)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const NetOperatingIncome = ({
  value,
  capRate,
  showSkeleton = false,
}: WithSkeleton & { value: number, capRate: number }) => {
  const theme = useTheme();
  const l = useLabels();
  return (
    <Stack width="100%" p={4} gap={4} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
      <Typography variant="h6">
        {l.operatingIncome}
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">
          {l.netOperatingIncome}
        </Typography>
        <Typography variant="caption" data-testid="dashboard.report.netOperating">
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatNumberToCurrency(value, 0)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1bold">
          {l.capRate}
        </Typography>
        <Typography variant="body1bold" data-testid="dashboard.report.capRate">
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatDecimalToPercentage(capRate, 2)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const CashFlow = ({
  netCashFlow,
  cashOnCash,
  showSkeleton = false,
}: WithSkeleton & { netCashFlow: number, cashOnCash: number }) => {
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Stack width="100%" p={4} gap={4}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {l.cashFlow}
        </Typography>
        <InfoTooltip
          title={(
            <Typography variant="body2">
              {l['reports.widgets.cashFlow.tooltip']}
            </Typography>
          )}
          isOutlined
          arrow
          isLight
          track={(value) => {
            analytics.track('Tooltip Toggled', {
              value,
              tooltipName: 'Report Cash Flow Tooltip',
            });
          }}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="caption">
            {l.netCashFlow}
          </Typography>
          <InfoTooltip
            title={(
              <Typography variant="body2">
                {l['reports.widgets.cashOnCashReturn.tooltip']}
              </Typography>
            )}
            isOutlined
            arrow
            isLight
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Report Cash On Cash Return Tooltip',
              });
            }}
          />
        </Box>
        <Typography
          data-testid="dashboard.report.netCashFlow"
          variant="caption"
        >
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatNumberToCurrency(netCashFlow, 0)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1bold">
          {l.cashOnCashReturn}
        </Typography>
        <Typography
          data-testid="dashboard.report.cashOnCash"
          variant="body1bold"
        >
          {showSkeleton ? <Skeleton animation="wave" width="100px" /> : formatDecimalToPercentage(cashOnCash, 2)}
        </Typography>
      </Stack>
    </Stack>
  );
};

import {
  SellerLeadsWorkflowStatus,
} from 'lib';

import { SellerLeadsKanban } from './kanban/SellerLeadsKanban';
import { SellerLeadsList } from './list/SellerLeadsList';

const orderedStatuses = [
  SellerLeadsWorkflowStatus.new,
  SellerLeadsWorkflowStatus.attemptedContact,
  SellerLeadsWorkflowStatus.spokeWithLead,
  SellerLeadsWorkflowStatus.appointmentSet,
  SellerLeadsWorkflowStatus.metWithLead,
  SellerLeadsWorkflowStatus.activeListing,
  SellerLeadsWorkflowStatus.offerAccepted,
  SellerLeadsWorkflowStatus.underContract,
  SellerLeadsWorkflowStatus.sold,
  SellerLeadsWorkflowStatus.rejected,
];

export const SellerLeads = ({ isKanbanViewEnabled }: { isKanbanViewEnabled: boolean }) => (
  isKanbanViewEnabled
    ? <SellerLeadsKanban orderedStatuses={orderedStatuses} />
    : <SellerLeadsList orderedStatuses={orderedStatuses} />
);

import { SuggestedPartner as APISuggestedPartner } from 'lib';

import { SuggestedPartner } from './types';

export const convertSuggestedPartner = (partner: APISuggestedPartner): SuggestedPartner => ({
  id: partner.id,
  name: `${partner.firstName} ${partner.lastName}`,
  firstName: partner.firstName,
  lastName: partner.lastName,
  email: partner.email,
  phone: partner.phone,
  avatar: partner.avatar,
  rating: partner.rating,
  officeName: partner.officeName,
  address: partner.address,
  website: partner.website,
  last12MonthsSales: partner.last12MonthsSales,
  totalSales: partner.totalSales,
  minPrice: partner.minPrice,
  maxPrice: partner.maxPrice,
  status: partner.status,
  activity: partner.activity,
  relevancy: partner.relevancy,
  experienceInYears: partner.experienceInYears,
});

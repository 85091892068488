export const calculateGrossYield = (rent: number, price: number) => {
  if (rent <= 0 || price <= 0) {
    return 0;
  }

  return price <= 0 ? 0 : (rent * 12) / price;
};

export const calculateTotalExpenses = (expenses: {
  hoa?: number;
  insurance?: number;
  managementFee?: number;
  tax?: number;
  maintenance?: number;
}) => (expenses?.hoa || 0)
+ (expenses.insurance || 0)
+ (expenses.managementFee || 0)
+ (expenses?.tax || 0)
+ (expenses?.maintenance || 0);

export const calculateNOI = (
  rent: number,
  totalExpenses: number,
) => (rent > 0 ? rent - totalExpenses : 0);

export const calculateCapRate = (noi: number, price: number) => {
  if (price <= 0) {
    return 0;
  }

  return (noi * 12) / price;
};

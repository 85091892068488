import { ApexOptions } from 'apexcharts';
import { OwnerRiskSeverity, OwnerRiskStatus, useAnalytics } from 'lib';
import Apexcharts from 'react-apexcharts';
import {
  BoldTypography, capitalize, InfoTooltip, MissingDataIcon, SpinnerWithLogo, useLabels,
} from 'ui';
import {
  Box,
  Card, CardContent, Stack, Typography,
  useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';

import { useGetOwnerRisksByPM } from '../../../api/owner-risk';

type SeverityConfig = {
  label: string,
  color: string,
  count: number,
};

export const SeverityGauge = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { data, isLoading, isError } = useGetOwnerRisksByPM();

  const currentDisplayedSeverity = signal(0);

  const ownerRisks = data?.pages.flatMap((page) => page.ownerRisks).filter(
    (ownerRisk) => ownerRisk.status !== OwnerRiskStatus.RESOLVED,
  ) ?? [];

  const lowCount = ownerRisks.filter((ownerRisk) => ownerRisk.severity === OwnerRiskSeverity.LOW).length ?? 0;
  const mediumCount = ownerRisks.filter((ownerRisk) => ownerRisk.severity === OwnerRiskSeverity.MEDIUM).length ?? 0;
  const highCount = ownerRisks.filter((ownerRisk) => ownerRisk.severity === OwnerRiskSeverity.HIGH).length ?? 0;

  const series: ApexOptions['series'] = [lowCount, mediumCount, highCount];

  const severityToConfig: Record<OwnerRiskSeverity, SeverityConfig> = {
    [OwnerRiskSeverity.NO_RISK]: {
      label: capitalize(OwnerRiskSeverity.NO_RISK),
      color: theme.palette.grey.A100,
      count: 0,
    },
    [OwnerRiskSeverity.LOW]: {
      label: capitalize(OwnerRiskSeverity.LOW),
      color: lowCount > 0 ? theme.palette.success.main : theme.palette.grey.A100,
      count: lowCount,
    },
    [OwnerRiskSeverity.MEDIUM]: {
      label: capitalize(OwnerRiskSeverity.MEDIUM),
      color: mediumCount > 0 ? theme.palette.warning.main : theme.palette.grey.A100,
      count: mediumCount,
    },
    [OwnerRiskSeverity.HIGH]: {
      label: capitalize(OwnerRiskSeverity.HIGH),
      color: highCount > 0 ? theme.palette.error.main : theme.palette.grey.A100,
      count: highCount,
    },
  };

  const labelToCount = {
    [severityToConfig[OwnerRiskSeverity.LOW].label]: lowCount,
    [severityToConfig[OwnerRiskSeverity.MEDIUM].label]: mediumCount,
    [severityToConfig[OwnerRiskSeverity.HIGH].label]: highCount,
  };

  const options: ApexOptions = {
    labels: [
      severityToConfig[OwnerRiskSeverity.LOW].label,
      severityToConfig[OwnerRiskSeverity.MEDIUM].label,
      severityToConfig[OwnerRiskSeverity.HIGH].label,
    ],
    colors: [
      severityToConfig[OwnerRiskSeverity.LOW].color,
      severityToConfig[OwnerRiskSeverity.MEDIUM].color,
      severityToConfig[OwnerRiskSeverity.HIGH].color,
    ],
    chart: {
      type: 'donut',
      events: {
        dataPointMouseEnter: (_, __, config) => {
          const label = config.w.config.labels[config.dataPointIndex];

          currentDisplayedSeverity.value = labelToCount[label];
        },
        dataPointMouseLeave: (_, __, config) => {
          const label = config.w.config.labels[config.dataPointIndex];

          currentDisplayedSeverity.value = labelToCount[label];
        },
        dataPointSelection: (_, __, config) => {
          const label = config.w.config.labels[config.dataPointIndex];

          currentDisplayedSeverity.value = labelToCount[label];
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetX: -10,
        donut: {
          labels: {
            name: {
              offsetY: -30,
            },
            value: {
              offsetY: -20,
              formatter: () => currentDisplayedSeverity.value.toString(),
            },
            show: true,
          },
        },
      },
    },
    grid: {
      padding: {
        bottom: -80,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
    },
    legend: {
      show: false,
    },
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BoldTypography variant="h6">
            {l['retention.table.priority']}
          </BoldTypography>
          <InfoTooltip
            arrow
            isLight
            title={(
              <Typography variant="body2" sx={{ p: 1 }}>
                {l['retention.tooltip.priority']}
              </Typography>
            )}
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Severity Gauge',
              });
            }}
          />
        </Stack>
        {isError && (
          <Stack alignItems="center" justifyContent="center" flexGrow={1}>
            <MissingDataIcon iconProps={{ size: 36 }} boxProps={{ sx: { borderRadius: '100%' } }} />
          </Stack>
        )}
        {isLoading ? (
          <Stack alignItems="center" justifyContent="center" flexGrow={1}>
            <SpinnerWithLogo size={48} />
          </Stack>
        ) : !isError && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: 170,
              }}
            >
              <Apexcharts options={options} series={series} type="donut" height={150} width={295} />
            </Stack>
            <Stack>
              {Object.entries(severityToConfig)
                .filter(([severity]) => severity !== OwnerRiskSeverity.NO_RISK)
                .map(([severity, config]) => (
                  <Stack direction="row" alignItems="center" gap={3} key={`expense-chart-label-${severity}`}>
                    <Box
                      height={10}
                      width={10}
                      borderRadius={100}
                      sx={{
                        background: config.color,
                      }}
                    />
                    {config.label}
                    {' '}
                    (
                    {severityToConfig[severity as OwnerRiskSeverity].count}
                    )
                  </Stack>
                ))}
            </Stack>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

import {
  BuyerLeadWorkflowItem, createDisplayAddress, SellerLeadWorkflowItem,
} from 'lib';

export type SellerLead = SellerLeadWorkflowItem & { name: string };
export type BuyerLead = BuyerLeadWorkflowItem & { name: string };

export const convertSellerLead = (lead: SellerLeadWorkflowItem): SellerLead => ({
  ...lead,
  name: createDisplayAddress(lead.address),
});

export const convertBuyerLead = (lead: BuyerLeadWorkflowItem): BuyerLead => ({
  ...lead,
  name: createDisplayAddress(lead.address),
});

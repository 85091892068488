import { ReactNode } from 'react';

import { Stack, useTheme } from '@mui/material';

export const FullScreenBlur = ({
  shown,
  children,
}: {
  shown: boolean,
  children: ReactNode,
}) => {
  const theme = useTheme();

  return (
    <Stack
      display={shown ? undefined : 'none'}
      position="absolute"
      zIndex={2}
      top="0"
      left="0"
      height={`calc(100vh - ${theme.spacing(2)})`}
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{
        backdropFilter: 'blur(3px)',
      }}
    >
      {children}
    </Stack>
  );
};

import { useState } from 'react';

import { useAppSettings } from 'lib';
import { HeaderLink, PorticoLogo } from 'ui';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Hamburger } from './Hamburger';
import { MobileMenu } from './MobileMenu';

export const MobileHeader = () => {
  const theme = useTheme();
  const { data: appSettings, isLoading } = useAppSettings();
  const [menuOpen, setMenuOpen] = useState(false);

  if (!appSettings || isLoading) return null;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        component="header"
        sx={{
          backgroundColor: theme.palette.background.paper,
          height: '60px important',
          p: 4,
          zIndex: 1200,
          position: 'relative',
        }}
      >
        {appSettings.logo ? (
          <HeaderLink to="/" style={{ backgroundImage: `url(${appSettings.logo})` }} />
        ) : (
          <HeaderLink to="/">
            <PorticoLogo height="24" title="Portico" />
          </HeaderLink>
        )}
        <Hamburger open={menuOpen} onClick={() => setMenuOpen(true)} />
      </Stack>
      <MobileMenu open={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
};

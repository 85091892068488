import {
  BoldTypography, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';

import { DetailRow } from './Row';
import { useProjections } from './useProjections';
import { RowProps } from '../types';

export const ExpectedRent = ({ form }: RowProps) => {
  const l = useLabels();

  const projections = useProjections(form);

  return (
    <DetailRow
      form={form}
      rowID="expected-rent"
      totalRow
      columns={[
        {
          id: 'expected-rent',
          content: (
            <SemiBoldTypography variant="body1">
              {l.expectedRent}
            </SemiBoldTypography>
          ),
        },
        {
          id: 'expected-rent-year-1',
          content: (
            <BoldTypography variant="body1" color="success.main">
              {formatNumberToCurrency(projections.expectedRent.year1 || 0, 0)}
            </BoldTypography>
          ),
        },
        {
          id: 'expected-rent-year-2',
          content: (
            <BoldTypography variant="body1" color="success.main">
              {formatNumberToCurrency(projections.expectedRent.year2 || 0, 0)}
            </BoldTypography>
          ),
        },
        {
          id: 'expected-rent-year-3',
          content: (
            <BoldTypography variant="body1" color="success.main">
              {formatNumberToCurrency(projections.expectedRent.year3 || 0, 0)}
            </BoldTypography>
          ),
        },
      ]}
    />
  );
};

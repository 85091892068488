import {
  ListMarketplacePropertiesQuery,
  ListOffersQuery,
  MarketplaceProperty,
  Offer,
  queryGraphQL, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useInfiniteQuery } from '@tanstack/react-query';

import { convertActivities, convertOffer, convertProperty } from './converters';
import { Activity } from '../../types/activity';
import { QueryKey } from '../../types/enums';
import {
  listMarketplacePropertiesForActivities,
} from '../graphql/queries';

export const useListActivities = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const query = useInfiniteQuery({
    enabled: !!user?.id,
    queryKey: [QueryKey.MARKETPLACE_PROPERTIES_FOR_ACTIVITIES],
    queryFn: async ({ pageParam: nextToken = '' }): Promise<{
      nextToken: string | null | undefined;
      activities: Activity[];
    }> => {
      const token = await getAccessTokenSilently();

      const res = await queryGraphQL({
        query: listMarketplacePropertiesForActivities,
        authToken: token,
        variables: { owner: user?.id, nextToken: nextToken || undefined },
      }) as GraphQLResult<ListMarketplacePropertiesQuery & ListOffersQuery>;

      if (!res.data) {
        return { nextToken: null, activities: [] };
      }

      const filteredProperties = (
        res.data.listMarketplaceProperties
          ? res.data.listMarketplaceProperties.items.filter(Boolean) as MarketplaceProperty[] : []
      );
      const filteredOffers = res.data.listOffers ? res.data.listOffers.items.filter(Boolean) as Offer[] : [];

      const listings = filteredProperties.map(convertProperty).concat(filteredOffers.map(convertOffer));

      return {
        nextToken: res.data.listMarketplaceProperties?.nextToken,
        activities: convertActivities(listings),
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};

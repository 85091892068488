import { useEffect, useState } from 'react';

import {
  PartnerType, SuggestedPartnerStatus, useAnalytics,
} from 'lib';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, ControlledFormattedNumberField, InvertedButton, Spinner, useLabels,
} from 'ui';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
  Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useCreatePartner } from '../../api/partners';
import { useUpdateSuggestedPartner } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { QueryKey } from '../../types/enums';

const addToNetworkSchema = z.object({
  referralAmount: z.number().min(1),
});

type AddToNetworkFormData = z.infer<typeof addToNetworkSchema>;

export const AddToNetworkDialog = ({
  partner,
  open,
  onClose,
  defaultReferralAmount,
}: {
  partner: SuggestedPartner,
  open: boolean,
  onClose: () => void,
  defaultReferralAmount: number,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const queryClient = useQueryClient();
  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const { mutateAsync: updateSuggestedPartner, isLoading: isUpdatingSuggestedPartner } = useUpdateSuggestedPartner();
  const { mutateAsync: createPartner, isLoading: isCreatingPartner } = useCreatePartner();
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<AddToNetworkFormData>({
    resolver: zodResolver(addToNetworkSchema),
    defaultValues: {
      referralAmount: defaultReferralAmount,
    },
  });

  const isLoading = isUpdatingSuggestedPartner || isCreatingPartner || invalidatingQueries;

  const addToNetwork = async () => {
    try {
      await Promise.all([
        updateSuggestedPartner({
          id: partner.id,
          status: SuggestedPartnerStatus.WON,
        }),
        createPartner({
          email: partner.email,
          firstName: partner.firstName,
          lastName: partner.lastName,
          partnerType: PartnerType.AGENT,
          referralAmount: getValues('referralAmount'),
          suggestedPartnerId: partner.id,
        }),
      ]);
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.SUGGESTED_PARTNERS]);
      setInvalidatingQueries(false);
      toast.success(l['pm-dashboard.network.messageSuccessInviteNewPartner']);
    } catch (e) {
      console.error(e);

      toast.error(l['error.unknownError']);
    }
  };

  const handleClickAdd = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Add to Network Popup - Add',
      id: partner.id,
      status: partner.status,
      email: partner.email,
    });

    handleSubmit(addToNetwork)();
  };

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    onClose();
  };

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  useEffect(() => {
    if (defaultReferralAmount) reset({ ...getValues(), referralAmount: defaultReferralAmount });
  }, [defaultReferralAmount]);

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={isLowerThanMd} fullWidth maxWidth="xs" disableEscapeKeyDown>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['partners.add.title']}
          </BoldTypography>
          <IconButton onClick={() => handleClickClose({}, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: `${theme.spacing(4)} !important` }}>
        <Stack gap={4}>
          <Typography variant="body1">
            {l['partners.add.referralAmount']}
          </Typography>
          <ControlledFormattedNumberField
            rules={{ required: getValues('referralAmount') > 0 }}
            error={!!errors.referralAmount}
            helperText={errors.referralAmount ? l['partners.add.referralAmount.errorText'] : ''}
            name="referralAmount"
            control={control}
            fullWidth
            onBlur={() => {
              analytics.track('Input Changed', {
                inputName: 'Referral Amount',
                value: getValues('referralAmount'),
              });
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={3} gap={2}>
          <Button
            size="small"
            variant="text"
            onClick={() => handleClickClose({}, 'buttonClick')}
          >
            {l.cancel}
          </Button>
          <InvertedButton
            size="small"
            onClick={handleClickAdd}
            disabled={isLoading}
          >
            {(isLoading) ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l.add}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

import { useState } from 'react';

import {
  SellableProperty,
  useAnalytics,
  useAuth,
  useCreateExternalMarketplaceProperty,
  useCreateMarketplaceProperty,
  useIsUserPhoneInputEnabled,
} from 'lib';
import { toast } from 'react-toastify';
import {
  SellProperty,
  SellSelector,
  useLabels,
} from 'ui';

import { SellPropertyDialog } from './SellPropertyDialog';
import { useUpdateUser } from '../../api/users';
import { UserPhoneInput } from '../../components/phone-number/UserPhoneInput';

export const Sell = ({
  open, openNextDialog, sellableProperties, propertyIDs,
}: { open: boolean, openNextDialog: () => void, sellableProperties: SellableProperty[], propertyIDs: string[]
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [sellProperty, setSellProperty] = useState<SellProperty>();
  const [submitting, setSubmitting] = useState(false);

  const { user, refreshUser } = useAuth();
  const updateUser = useUpdateUser();

  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsConsent, setSmsConsent] = useState(false);

  const {
    value: userPhoneInputEnabled,
  } = useIsUserPhoneInputEnabled();

  const showPhoneInput = (user?.userProvidedPhone === '' && userPhoneInputEnabled);

  const nextButtonDisabled = (submitting || !sellProperty?.id
      || (sellProperty.hasPriceInMind && !sellProperty.price) || (showPhoneInput && !isPhoneValid))
      && !sellProperty?.externalPropertyAddress;

  const createMarketplacePropertyMutation = useCreateMarketplaceProperty();
  const createExternalListingMutation = useCreateExternalMarketplaceProperty();

  const nextButtonText = submitting ? l.pleaseWait : l.continue;

  const handleSubmit = async () => {
    const originalPrice = sellableProperties.find((property) => property.id === sellProperty?.id)?.prices?.priceMean || '';

    analytics.track('Form Submitted', {
      formName: 'Sell Property - Sell',
      propertyId: sellProperty?.id,
      price: sellProperty?.price,
      originalPrice,
      idkPrice: sellProperty?.hasPriceInMind,
    });

    if (!sellProperty?.address && !sellProperty?.isOtherOptionSelected) return;
    setSubmitting(true);
    try {
      if (sellProperty.isOtherOptionSelected) {
        await createExternalListingMutation.mutateAsync({
          fullAddress: sellProperty.externalPropertyAddress,
          askingPrice: !sellProperty.price || !sellProperty.hasPriceInMind ? 0 : sellProperty.price,
        });
      } else if (sellProperty.address) {
        await createMarketplacePropertyMutation.mutateAsync({
          propertyId: sellProperty.id,
          address: sellProperty.address,
          askingPrice: !sellProperty.price || !sellProperty.hasPriceInMind ? 0 : sellProperty.price,
          category: 'unknown',
        });
      }

      if (showPhoneInput && userPhoneInputEnabled) {
        await updateUser.mutateAsync({ phoneNumber, smsConsent });
        await refreshUser();
      }

      openNextDialog();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SellPropertyDialog
      open={open}
      openNextDialog={handleSubmit}
      nextButtonText={nextButtonText}
      showActions
      nextButtonDisabled={nextButtonDisabled}
    >
      <SellSelector
        onSellPropertyChanged={(sp:SellProperty) => setSellProperty(sp)}
        sellableProperties={sellableProperties.filter((sp) => !propertyIDs.includes(sp.id))}
      />

      {showPhoneInput && (
        <UserPhoneInput
          smsConsentDefaultValue={user?.smsConsent === true}
          onValidityChange={setIsPhoneValid}
          onPhoneNumberChange={setPhoneNumber}
          onSmsConsentChange={setSmsConsent}
        />
      )}
    </SellPropertyDialog>
  );
};

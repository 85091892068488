import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useAnalytics, useDebounce } from 'lib';
import { MdSearch } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import {
  Chip, hexToRGBA, useLabels,
} from 'ui';
import { TextField, useMediaQuery, useTheme } from '@mui/material';

import { Autocomplete } from './styled';
import { useGetAutocompleteSearchOptions } from '../../../api/properties';

export const PropertyAutocomplete = ({
  selectedOptions,
  setSelectedOptions,
  fullWidth = false,
}: {
  fullWidth?: boolean,
  selectedOptions: string[],
  setSelectedOptions: (opt: string[]) => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLowerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const inputWidth = useMemo(() => {
    if (isLowerThanSm) return 120;

    return 250;
  }, [isLowerThanSm, isLowerThanMd]);

  const [input, setInput] = useState('');
  const [, debouncedSearch] = useDebounce(input, 500);
  const [showOptions, setShowOptions] = useState(false);

  const { data: options, isLoading } = useGetAutocompleteSearchOptions(debouncedSearch);

  useEffect(() => {
    setShowOptions(input.trim().length > 1);
  }, [input]);

  return (
    <Autocomplete<string, true>
      multiple
      limitTags={2}
      fullWidth={fullWidth}
      size="small"
      open={showOptions}
      componentsProps={{
        popper: { sx: { width: '250px !important', ml: { xs: `${theme.spacing(3)} !important`, sm: '0 !important' } } },
      }}
      loading={!!input.trim() && isLoading}
      onOpen={() => { setShowOptions(!!input.trim()); }}
      onClose={() => setShowOptions(false)}
      onInputChange={(_, newValue) => {
        setInput(newValue);
        if (!newValue.trim()) setShowOptions(false);
      }}
      filterSelectedOptions
      value={selectedOptions}
      getLimitTagsText={(more) => `+${more} ${l.more}`}
      onChange={(_, newValues) => {
        analytics.track('Autocomplete Options Changed', {
          autocompleteName: 'Property',
          value: newValues.join(';'),
        });

        setSelectedOptions(newValues);
      }}
      options={options ?? []}
      popupIcon={<MdSearch fill={theme.palette.text.primary} />}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          label={option}
          key={option}
          deleteIcon={<TiDelete color={theme.palette.grey[400]} />}
          sx={{
            backgroundColor: hexToRGBA(theme.palette.grey.A100, 0.3),
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
          }}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          label={l.addressPlaceholder}
          sx={{ minWidth: inputWidth, maxWidth: 400 }}
          InputProps={{ sx: { borderRadius: '4px' }, ...params.InputProps }}
        />
      )}
    />
  );
};

import { List, Typography } from '@mui/material';

type TBullet = {
  bold?: string;
  label: string
};

export const TooltipContent = ({ bullets }: { bullets: TBullet[] }) => (
  <List sx={{
    listStyleType: 'disc',
    pl: 4,
  }}
  >
    {
      bullets.map((b) => (
        <li key={b.label}>
          <Typography variant="body2">
            {b.bold && <b>{b.bold}</b>}
            {' '}
            {b.label}
          </Typography>
        </li>
      ))
    }
  </List>
);

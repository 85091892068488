import { MdCheckCircle } from 'react-icons/md';
import { Avatar, BoldTypography, useLabels } from 'ui';
import {
  Button, Stack, Typography, useTheme,
} from '@mui/material';

import { SellPropertyDialog } from './SellPropertyDialog';

export const Success = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <SellPropertyDialog open={open}>
      <Stack height="100%" justifyContent="center" alignItems="center" p={4}>
        <Avatar skin="light" variant="circular" color="info" sx={{ height: 96, width: 96, mb: 3 }}>
          <MdCheckCircle size={40} color={theme.palette.primary.main} />
        </Avatar>
        <BoldTypography variant="h5" sx={{ mb: 3 }}>
          {l.thatIsIt}
        </BoldTypography>
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mb: 6 }}>
          {l['sellProperty.listingSubmitted']}
        </Typography>
        <Button
          onClick={() => window.history.back()}
          variant="text"
          sx={{ color: theme.palette.text.primary }}
        >
          {l.gotIt}
        </Button>
      </Stack>
    </SellPropertyDialog>
  );
};

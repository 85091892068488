export const getAppSettings = /* GraphQL */ `
    query GetAppSettings($id: ID!) {
        getAppSettings(id: $id) {
            id
            iamDomain
            iamClientId
            iamAudience
            title
            pmName
            tenant
            color
            logo
            favicon
            createdAt
            updatedAt
        }
    }
`;

export const listPropertyIDs = /* GraphQL */`
  query ListPropertyIDs {
    listMarketplaceProperties(limit: 5000) {
      items {
        property {
          id
        }
      }
    }
  }
`;

export const listSellableProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelDashboardPropertyFilterInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $owner: String!
  ) {
    listDashboardPropertyByOwner(
      owner: $owner
      filter: $filter
      limit: 5000
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      nextToken
      items {
        id
        name
        address {
          street1
          street2
          city
          state
          zipCode
        }
        property {
          id
        }
        propertyValuation {
          items {
            priceMean
            priceUpper
            priceLower
          }
        }
      }
    }
  }
`;

export const getUserActions = /* GraphQL */ `
    query GetUserActions($owner: ID!) {
        getUserActions(owner: $owner) {
            owner
            viewedWelcomeToMarketplaceScreen
            viewedCashOutTooltip
        }
    }
`;

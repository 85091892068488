export const SuccessCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={16} height={16} rx={8} fill="#16AC52" />
    <path
      d="M6.929 10.184L4.744 8 4 8.738l2.929 2.928 6.285-6.285-.738-.739-5.547 5.542z"
      fill="#173771"
      fillOpacity={0.5}
    />
  </svg>
);

import { ReactNode } from 'react';

import { InfoTooltip } from 'ui';
import {
  Box, Card, CardContent, CardHeader, Grid, Typography, useTheme,
} from '@mui/material';

export const Widget = ({
  title = '',
  children,
  isDark = false,
  tooltip = '',
  tooltipTrack = () => {},
}: { title?: string, children: ReactNode, isDark?: boolean, tooltip?: string, tooltipTrack?: (value: boolean) => void }) => {
  const theme = useTheme();

  const [backgroundColor, color, iconColor] = isDark
    ? [theme.palette.primary.main, theme.palette.primary.contrastText, theme.palette.primary.contrastText]
    : [theme.palette.background.paper, theme.palette.primary.main, theme.palette.secondary.main];

  return (
    <Card sx={{ height: '100%', boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.06)' }}>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          backgroundColor,
          borderRadius: '6px',
          justifyContent: 'space-between',
        }}
      >
        {title && (
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: 'h6', sx: { color } }}
            action={(
              tooltip && (
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {tooltip}
                    </Typography>
                  )}
                  isOutlined
                  arrow
                  isLight
                  color={iconColor}
                  track={tooltipTrack}
                />
              )
            )}
          />
        )}
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              {children}
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
};

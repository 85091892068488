import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { TextField } from '@mui/material';

import { ControlledFormattedNumberFieldProps } from './types';

export const ControlledFormattedNumberField = ({
  rules,
  helperText = '',
  control,
  name,
  prefix = '$',
  suffix = '',
  decimalScale = undefined,
  allowNegative = false,
  showZero = true,
  onValueChange = undefined,
  externalValue = undefined,
  useExternalValue = false,
  ...textFieldProps
}: ControlledFormattedNumberFieldProps) => (
  <Controller
    rules={rules}
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => {
      const internalValue = showZero ? value : value || undefined;

      return (
        <NumberFormat
          helperText={helperText}
          prefix={prefix}
          suffix={suffix}
          data-hj-allow
          customInput={TextField}
          thousandSeparator
          allowNegative={allowNegative}
          {...textFieldProps}
          autoComplete="off"
          onValueChange={(v) => {
            onChange(useExternalValue ? v.floatValue : v.floatValue || '');
            onValueChange?.(v.floatValue);
          }}
          value={useExternalValue ? externalValue : internalValue}
          decimalScale={decimalScale}
        />
      );
    }}
  />
);

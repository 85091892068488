import {
  GetGrowthPolicyQuery,
  GrowthPolicy,
  queryGraphQL, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '../../types/enums';
import { getGrowthPolicy } from '../graphql/queries';

export const useGetB2CIncentive = (enabled: boolean) => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.GROWTH_POLICY], async (): Promise<GrowthPolicy | null> => {
    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getGrowthPolicy,
      variables: { pm },
      authToken: token,
    }) as GraphQLResult<GetGrowthPolicyQuery>;

    return res.data?.getGrowthPolicy ?? null;
  }, { enabled });
};

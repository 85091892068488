import { OwnerRiskSeverity } from 'lib';
import { capitalize, Chip, replaceUnderscoreWithSpace } from 'ui';

import { BasicOwnerRisk } from '../../../../api/properties/types';

export const Priority = ({ ownerRisk }: { ownerRisk: BasicOwnerRisk }) => {
  const colorToPriority = {
    [OwnerRiskSeverity.NO_RISK]: 'success',
    [OwnerRiskSeverity.LOW]: 'success',
    [OwnerRiskSeverity.MEDIUM]: 'warning',
    [OwnerRiskSeverity.HIGH]: 'error',
  } as const;

  return (
    <Chip
      color={colorToPriority[ownerRisk.severity]}
      label={capitalize(replaceUnderscoreWithSpace(ownerRisk.severity))}
      size="small"
    />
  );
};

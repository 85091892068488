import {
  Navigate, Route, Routes, useLocation,
} from 'react-router-dom';

import {
  useIsGrowProductEnabled,
  useIsOldNavigationEnabled,
  useIsPMDashboardEnabled, useIsPMDashboardLeadsEnabled,
  useIsPropertiesDashboardEnabled, useIsProspectsUsersViewEnabled,
  useIsRetainProductEnabled,
  usePermissions,
} from 'lib';
import {
  ComingSoonPage, GrowIcon, OpportunitiesIcon, PropertyIcon,
  RetainIcon,
  RiskIcon,
} from 'ui';

import { BlanketOverview } from './views/blanket-overview';
import { Contacts } from './views/contacts';
import { Leads } from './views/leads';
import { Network } from './views/network';
import { Partners } from './views/opportunities';
import { Owners } from './views/owners';
import { PmProperties } from './views/pm-properties';
import { Properties } from './views/properties';
import { Prospects } from './views/prospects';
import { Retain } from './views/retain';

export const App = () => {
  const { value: isOldNavigationEnabled, loading: loadingIsOldNavigationEnabled } = useIsOldNavigationEnabled();

  if (loadingIsOldNavigationEnabled) return null;

  return isOldNavigationEnabled ? <OldApp /> : <NewApp />;
};

export const OldApp = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: pmDashboardEnabled, loading: loadingPMDashboardEnabledFF } = useIsPMDashboardEnabled();
  const { value: leadsEnabled, loading: loadingLeadsEnabledFF } = useIsPMDashboardLeadsEnabled();
  const { value: isRetainProductEnabled, loading: loadingIsRetainProductEnabled } = useIsRetainProductEnabled();
  const { value: isOldNavigationEnabled, loading: loadingIsOldNavigationEnabled } = useIsOldNavigationEnabled();

  const {
    value: propertiesDashboardEnabled,
    loading: loadingPropertiesDashboardEnabledFF,
  } = useIsPropertiesDashboardEnabled();

  const {
    value: prospectsUsersViewEnabled,
    loading: loadingIsProspectsUsersViewEnabledFF,
  } = useIsProspectsUsersViewEnabled();

  if ([
    loadingPMDashboardEnabledFF,
    loadingPropertiesDashboardEnabledFF,
    isLoadingPermissions,
    loadingLeadsEnabledFF,
    loadingIsProspectsUsersViewEnabledFF,
    loadingIsRetainProductEnabled,
    loadingIsOldNavigationEnabled,
  ].some(Boolean)) return null;

  return (
    <Routes>
      {pmDashboardEnabled && permissions?.viewPMDashboard && (
        <>
          {propertiesDashboardEnabled ? (
            <Route path="/properties" element={<PmProperties />} />
          ) : (
            <Route path="/properties" element={<Properties />} />
          )}
          {prospectsUsersViewEnabled && (
            <Route path="/prospects" element={<Prospects />} />
          )}
          <Route path="/owners" element={<Owners />} />
          <Route path="/blanket-overview" element={<BlanketOverview />} />
          {isRetainProductEnabled && (
            <Route path="/retain">
              <Route index element={<Retain />} />
              {!isOldNavigationEnabled && (
                <>
                  <Route path="risks" element={<ComingSoonPage icon={<RiskIcon height={40} width={40} />} />} />
                  <Route path="awards" element={<ComingSoonPage icon={<RetainIcon height={40} width={40} />} />} />
                  <Route path="properties" element={<ComingSoonPage icon={<PropertyIcon size={40} />} />} />
                </>
              )}
            </Route>
          )}
          {!isOldNavigationEnabled && (
            <Route path="/grow">
              <Route index element={<ComingSoonPage icon={<GrowIcon height={40} width={40} />} />} />
              <Route path="opportunities" element={<ComingSoonPage icon={<OpportunitiesIcon height={40} width={40} />} />} />
              <Route path="network" element={<Network />} />
              <Route path="partners" element={<Partners />} />
            </Route>
          )}
          <Route path="/data/properties" element={<ComingSoonPage icon={<PropertyIcon />} />} />
        </>
      )}
      {leadsEnabled && permissions?.viewLeads && <Route path="/deals" element={<Leads />} />}
      <Route
        path="*"
        element={
          <Navigate to={pmDashboardEnabled ? '/admin/blanket-overview' : '/'} />
        }
      />
    </Routes>
  );
};

export const NewApp = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();
  const { value: pmDashboardEnabled, loading: loadingPMDashboardEnabledFF } = useIsPMDashboardEnabled();
  const { value: leadsEnabled, loading: loadingLeadsEnabledFF } = useIsPMDashboardLeadsEnabled();
  const { value: isRetainProductEnabled, loading: loadingIsRetainProductEnabled } = useIsRetainProductEnabled();
  const { value: isGrowProductEnabled, loading: loadingIsGrowProductEnabled } = useIsGrowProductEnabled();

  if ([
    loadingPMDashboardEnabledFF,
    isLoadingPermissions,
    loadingLeadsEnabledFF,
    loadingIsRetainProductEnabled,
    loadingIsGrowProductEnabled,
  ].some(Boolean)) return null;

  return (
    <Routes>
      {pmDashboardEnabled && permissions?.viewPMDashboard && (
        <>
          <Route index element={<BlanketOverview />} />
          <Route path="/contacts" element={<Navigate to="/pm/contacts/owners" />} />
          <Route path="/contacts/:contactType" element={<Contacts />} />
          {isRetainProductEnabled && permissions?.viewRetain && (
            <>
              <Route path="/retain" element={<Retain />} />
              <Route path="/properties" element={<PmProperties />} />
            </>
          )}
          {isGrowProductEnabled && permissions?.viewGrow && (
            <>
              <Route path="/grow/opportunities" element={<Partners />} />
              <Route path="/grow/network" element={<Network />} />
            </>
          )}
        </>
      )}
      {leadsEnabled && permissions?.viewLeads && <Route path="/leads" element={<Leads />} />}
      <Route
        path="*"
        element={
          <Navigate to={pmDashboardEnabled ? '/pm' : '/'} />
        }
      />
    </Routes>
  );
};

export const RedirectsFromOldPMDashboard = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/owners" element={<Navigate to={`/pm/contacts/owners${location.search}`} />} />
      <Route path="/prospects" element={<Navigate to={`/pm/contacts/prospects${location.search}`} />} />
      <Route path="/deals" element={<Navigate to={`/pm/leads${location.search}`} />} />
      <Route path="/properties" element={<Navigate to={`/pm/properties${location.search}`} />} />
      <Route path="/blanket-overview" element={<Navigate to={`/pm${location.search}`} />} />
    </Routes>
  );
};

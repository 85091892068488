import {
  axiosWithPayloadContext,
  config,
  createHeaders,
  GetGrowthPolicyQuery,
  GrowthPolicy,
  queryGraphQL, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery } from '@tanstack/react-query';

import { QueryKey } from '../../types/enums';
import { getGrowthPolicy } from '../graphql/queries';
import { GrowthPolicyData } from '../suggested-partners/forms';

export const useGetGrowthPolicy = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.GROWTH_POLICY], async (): Promise<GrowthPolicy | null> => {
    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getGrowthPolicy,
      variables: { pm },
      authToken: token,
    }) as GraphQLResult<GetGrowthPolicyQuery>;

    return res.data?.getGrowthPolicy ?? null;
  });
};

export const useCreateGrowthPolicy = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (data: GrowthPolicyData) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/growth-policy`,
      method: 'POST',
      headers: createHeaders(token),
      data,
    });
  });
};

export const useUpdateGrowthPolicy = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async ({
    id,
    ...data
  }: {
    id: string,
  } & GrowthPolicyData) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/growth-policy/${id}`,
      method: 'PUT',
      headers: createHeaders(token),
      data,
    });
  });
};

export const useUploadGrowthPolicyPmAvatar = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (file: File): Promise<string> => {
    const token = await getAccessTokenSilently();

    const formData = new FormData();
    formData.append('image', file);

    formData.append('type', 'pm-avatar');

    try {
      const res = await axiosWithPayloadContext({
        url: `${config.apiBaseURL}/image-upload`,
        method: 'POST',
        headers: {
          ...createHeaders(token),
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return res.data.url;
    } catch (e) {
      console.error(e);
      throw e;
    }
  });
};

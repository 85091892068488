import { useAuth, useIsObpVendorEnabled } from 'lib';
import { MdCheckCircleOutline } from 'react-icons/md';
import {
  useLabels,
} from 'ui';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import { VendorDataProps } from './types';

export const Vendor = ({
  vendorData, onClick,
}: VendorDataProps) => {
  const l = useLabels();
  const { user } = useAuth();
  const { value: isObpVendorEnabled } = useIsObpVendorEnabled(vendorData.name);

  if (!user?.id || !isObpVendorEnabled) {
    return null;
  }

  return (
    <Grid item md={3} sx={{ flexGrow: 1 }}>
      <Card sx={{ height: '100%' }}>
        <CardContent
          component={Stack}
          sx={{
            p: 0,
            pb: '0 !important',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Stack py={2} height={100} borderBottom="1px solid rgba(23, 55, 113, 0.12)">
              <Stack alignItems="center" justifyContent="center" flexGrow={1}>
                <img
                  style={{
                    textAlign: 'center',
                    maxWidth: '180px',
                    maxHeight: 40,
                  }}
                  alt="The house from the offer."
                  src={vendorData.logo}
                />
              </Stack>
            </Stack>
            <Stack pb={1} pt={3} px={3}>
              <Box>
                <Typography variant="h6">
                  {vendorData.name}
                </Typography>
                <Typography variant="body1" fontSize={14} pb={4}>
                  {vendorData.description}
                </Typography>
              </Box>
              <List disablePadding>
                {vendorData.features.map((f) => (
                  <ListItem key={f} sx={{ padding: 0 }}>
                    <ListItemAvatar>
                      <MdCheckCircleOutline color="#16AC52" size={20} />
                    </ListItemAvatar>
                    <ListItemText primary={f} />
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Box>
          <Stack p={2}>
            <Button
              sx={{
                textAlign: 'center', mt: 'auto',
              }}
              onClick={() => { onClick(vendorData.cta); }}
              variant="contained"
            >
              {l['obp.get.started']}
            </Button>
            {!!vendorData.readMore && (
              <Button
                sx={{ marginTop: 2 }}
                onClick={() => { onClick(vendorData.readMore); }}
                variant="text"
              >
                {l['obp.read-more']}
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

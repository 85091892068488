import { Box, Stack, styled } from '@mui/material';

export const GraphContainer = styled(Box)({
  height: '100%',
  width: '100%',
  background: '#E2EAF6',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  zIndex: 2,
  position: 'relative',
  borderBottom: '1px solid #CAD4E6',
});

export const GraphClipPath = styled(Box)({
  top: '-1px',
  position: 'absolute',
  height: '100%',
  width: '100%',
  zIndex: 1,
  clipPath: 'polygon(0% -1%, 0% 50%, 100% 0%)',
});

export const BarContainer = styled(Stack)({
  width: '60%',
  height: '99%',
  background: '#C0CDE1',
  position: 'absolute',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

export const BarValue = styled(Box)(({ theme }) => ({
  height: '100%',
  borderLeft: `2px dashed ${theme.palette.primary.main}`,
  position: 'absolute',
  top: '-10%',
  transition: '0.3s',
}));

import { ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { breakpoints } from './breakpoints';
import { palette } from './palette';
import { shadows } from './shadows';
import { spacing } from './spacing';
import { Settings } from '../context/settingsContext';

export const themeOptions = (settings: Settings): ThemeOptions => {
  const {
    mode, direction, themeColor,
  } = settings;

  const mergedThemeConfig = {
    direction,
    palette: palette(mode),
    typography: {
      fontFamily: [
        'Nunito Sans',
        'sans-serif',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    shadows: shadows(mode),
    ...spacing,
    breakpoints: breakpoints(),
    shape: {
      borderRadius: 6,
    },
    mixins: {
      toolbar: {
        minHeight: 64,
      },
    },
  };

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        ...mergedThemeConfig.palette[themeColor],
      },
    },
  });
};

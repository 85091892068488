import { useState } from 'react';

import {
  config, useGetRedirectURL, useIsPayingPM, usePermissions,
} from 'lib';
import { MdOutlineCreditCard } from 'react-icons/md';
import { TbBriefcase2 } from 'react-icons/tb';
import { toast } from 'react-toastify';
import {
  PageTitle, Spacer, Tabs, useLabels,
} from 'ui';
import {
  Divider,
  Paper, Stack,
} from '@mui/material';

import { Team } from './components/Team';

type TabName = 'team' | 'billing';

export const Settings = () => {
  const l = useLabels();
  const [tab, setTab] = useState<TabName>('team');
  const { value: isPayingPM, loading: loadingIsPayingPM } = useIsPayingPM();
  const { mutateAsync: getRedirectURL, isLoading: isLoadingRedirectURL } = useGetRedirectURL();
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();

  const handleClickBilling = async () => {
    let redirectURL;

    try {
      const url = `${config.apiBaseURL}/view-billing`;
      redirectURL = await getRedirectURL({
        url,
        params: {
          return_url: window.location.origin,
        },
      });
    } catch (e) {
      console.error(e);
    }

    if (redirectURL) {
      window.open(redirectURL, '_blank');
    } else {
      toast.error(l['error.unknownError']);
    }
  };

  return (
    <Stack height="100%" p={3}>
      <PageTitle title={l.settings} />
      <Spacer spacing={2} />
      <Paper elevation={4} sx={{ flexGrow: 1 }}>
        <Tabs
          tabs={[
            {
              label: l['menu-team'],
              value: 'team',
              icon: <TbBriefcase2 />,
            },
            isPayingPM && permissions?.viewBilling && {
              label: l['menu-billing'],
              value: 'billing',
              icon: <MdOutlineCreditCard size={16} />,
              onClick: () => {
                handleClickBilling();
              },
              isLoading: isLoadingRedirectURL,
            },
          ]}
          activeTab={tab}
          setTab={setTab}
          isLoading={loadingIsPayingPM || isLoadingPermissions}
        />
        <Divider sx={{ mt: 0 }} />
        {tab === 'team' && (
          <Stack><Team /></Stack>
        )}
      </Paper>
    </Stack>
  );
};

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAnalytics } from 'lib';
import { BoldTypography, FallbackSpinner, useLabels } from 'ui';
import {
  Button, MenuItem, Select, Stack,
} from '@mui/material';

import { useListOwnerProperties } from '../../api/properties';
import { PropertyProforma } from '../../templates/property-proforma/PropertyProforma';
import { editModeSignal } from '../../templates/property-proforma/state';

export const Proforma = () => {
  const l = useLabels();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { propertyID } = useParams();
  const editMode = editModeSignal.value;
  const { data: ownerProperties, isLoading } = useListOwnerProperties();
  const properties = ownerProperties?.properties ?? [];

  const handleSelectProperty = (selected: string) => {
    analytics.track('Selector Changed', {
      selectorName: 'Property Proforma',
      value: selected || 'portfolio',
    });

    navigate(selected ? `/proforma/${selected}` : '/proforma');
  };

  const handleClickEditMode = () => {
    const newMode = !editModeSignal.value;

    analytics.track('Button Clicked', {
      buttonName: 'Edit Mode',
      editMode: newMode,
    });

    editModeSignal.value = newMode;
  };

  const foundPropertyByID = !propertyID || !!properties?.find((p) => p.id === propertyID);

  useEffect(() => {
    if (!properties || !propertyID) return;

    const property = properties.find((p) => p.id === propertyID);

    if (!property) {
      navigate('/proforma');
    }
  }, [propertyID, properties]);

  if (isLoading || !properties) {
    return <FallbackSpinner />;
  }

  return (
    <Stack height="100%" p={3} gap={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3} flexWrap="wrap">
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={{ xs: 3, md: 12 }}>
          <BoldTypography variant="h6">
            {l.propertyDetails}
          </BoldTypography>
          <Select
            size="small"
            value={propertyID ?? ''}
            onChange={(e) => handleSelectProperty(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              {l.portfolio}
            </MenuItem>
            {properties.map((property) => (
              <MenuItem key={property.id} value={property.id}>
                {property.displayName}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Button variant="outlined" size="small" onClick={handleClickEditMode}>
          {editMode ? l.save : l.edit}
        </Button>
      </Stack>
      {foundPropertyByID && properties && <PropertyProforma properties={properties} />}
    </Stack>
  );
};

import { useEffect } from 'react';

import { useAnalytics, useHideChat } from 'lib';
import { Controller, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  AutocompletePrediction,
  BoldTypography,
  ControlledFormattedNumberField,
  GoogleAutocomplete,
  InvertedButton, PlusSign,
  Spinner,
  StyledToggleButton,
  useConfetti,
  useLabels,
} from 'ui';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { AddLeadData, addLeadFormSchema, useCreateLead } from '../../../api/partners';

export const AddLeadDialog = ({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean,
  onClose: () => void,
  onSuccess: () => void,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const confetti = useConfetti();

  useHideChat(open && isLowerThanMd);

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    watch,
    setValue,
  } = useForm<AddLeadData>({
    resolver: zodResolver(addLeadFormSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      addresses: [undefined],
      requestRentalAnalysis: false,
      numOfProperties: 0,
    },
  });

  const addresses = watch('addresses');

  const { mutateAsync: createLead, isLoading: isCreatingLead } = useCreateLead();

  const handleClickClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick') => {
    if (reason === 'backdropClick') return;

    analytics.track('Button Clicked', {
      buttonName: 'Close',
    });

    onClose();
  };

  const addLead = async (data: AddLeadData) => {
    try {
      await createLead(data);
      onSuccess();
      confetti.trigger();
      onClose();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
      onClose();
    }
  };

  const handleClickAdd = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Add Lead',
    });

    handleSubmit(addLead)();
  };

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClickClose} fullScreen={isLowerThanMd} fullWidth maxWidth="md" disableEscapeKeyDown>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['referral-partners.add.title']}
          </BoldTypography>
          <IconButton onClick={() => handleClickClose({}, 'buttonClick')}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: `${theme.spacing(4)} !important` }}>
        <Stack gap={4}>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.firstName']}
              </BoldTypography>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={l['referral-partners.add.firstName']}
                    variant="outlined"
                    value={field.value}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Stack>
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.lastName']}
              </BoldTypography>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (

                  <TextField
                    label={l['referral-partners.add.lastName']}
                    variant="outlined"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    fullWidth
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.phoneNumber']}
              </BoldTypography>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (

                  <TextField
                    label={l['referral-partners.add.phoneNumber']}
                    variant="outlined"
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                  />
                )}
              />
            </Stack>
            <Stack direction="column" width="100%">
              <BoldTypography variant="body1" pb={4}>
                {l['referral-partners.add.email']}
              </BoldTypography>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (

                  <TextField
                    label={l['referral-partners.add.email']}
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />

                )}
              />
            </Stack>

          </Stack>
          <Stack direction="column" width="100%">
            <BoldTypography variant="body1" pb={4}>
              {l['referral-partners.add.propertyAddress']}
            </BoldTypography>
            {addresses.map((address, index) => (
              <Stack pb={4}>
                <GoogleAutocomplete
                  key={address?.description || 'test'}
                  selectedOption={address || null}
                  setSelectedOption={(googleAddress: AutocompletePrediction | null) => {
                    setValue('addresses', [...addresses.slice(0, index), googleAddress, ...addresses.slice(index + 1)]);
                  }}
                  placeHolderLabel={l['referral-partners.add.propertyAddress']}
                  autoCompleteTrackingName="Address Autocomplete Create Lead popup"
                />
              </Stack>
            ))}
          </Stack>

          <Button
            sx={{
              color: 'grey',
            }}
            size="small"
            variant="outlined"
            onClick={() => { setValue('addresses', [...addresses, undefined]); }}
            startIcon={<PlusSign />}
          >
            {l.addAddress}
          </Button>
          <Stack direction="column" width="100%" spacing={4}>
            <BoldTypography variant="body1">
              {l['referral-partners.add.numOfProperties']}
            </BoldTypography>
            <ControlledFormattedNumberField
              rules={{ required: true }}
              error={!!errors.numOfProperties}
              helperText={errors.numOfProperties ? l['referral-partners.add.numOfProperties.errorText'] : ''}
              name="numOfProperties"
              control={control}
              fullWidth
              prefix=""
              onBlur={() => {
                analytics.track('Input Changed', {
                  inputName: 'How Many Properties To Manage',
                  value: getValues('numOfProperties'),
                });
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={4}>
            <Typography variant="body1" color={theme.palette.primary.dark}>
              {l['referral-partners.add.wouldYouLikeARentalAnalysis']}
            </Typography>
            <Controller
              name="requestRentalAnalysis"
              control={control}
              render={({ field }) => (
                <ToggleButtonGroup
                  color="primary"
                  size="small"
                  exclusive
                  value={field.value}
                  onChange={(_, value) => {
                    setValue('requestRentalAnalysis', value);

                    analytics.track('Switch Toggled', {
                      value,
                      switchName: 'Do You Want A Rental Analysis Switch',
                    });
                  }}
                >
                  <StyledToggleButton value>{l.yes}</StyledToggleButton>
                  <StyledToggleButton value={false}>{l.no}</StyledToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Stack>

        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={3} gap={2}>
          <Button
            size="small"
            variant="text"
            onClick={() => handleClickClose({}, 'buttonClick')}
          >
            {l.cancel}
          </Button>
          <InvertedButton
            size="small"
            onClick={handleClickAdd}
            disabled={isCreatingLead}
          >
            {(isCreatingLead) ? (
              <>
                &nbsp;
                <Spinner size={20} />
              </>
            ) : l.invite}
          </InvertedButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

import { useState } from 'react';

import { useKeenSlider } from 'keen-slider/react';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { Stack, useTheme } from '@mui/material';

import {
  SliderBadge, SliderButton, SliderButtonContainer, SliderContainer, SliderImage,
} from './styled';

type Props = {
  images: string[];
  maxHeight?: string;
};

export const ImageCarousel = ({ images, maxHeight = undefined }: Props) => {
  const theme = useTheme();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideContainerRef, slideRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slideChanged: (slider) => {
      setCurrentSlide(slider.track.details.rel);
    },
    created: (slider) => {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  return (
    <>
      <SliderContainer ref={slideContainerRef} className="keen-slider">
        {images.map((image) => (
          <SliderImage
            key={`${image}-image`}
            src={image}
            className="keen-slider__slide"
            style={{ maxHeight: maxHeight ?? '100%' }}
          />
        ))}
        <SliderButtonContainer>
          <SliderButton onClick={() => slideRef.current?.prev()}>
            <MdArrowBackIosNew size={12} color={theme.palette.secondary.main} />
          </SliderButton>
          <SliderButton onClick={() => slideRef.current?.next()}>
            <MdArrowForwardIos size={12} color={theme.palette.secondary.main} />
          </SliderButton>
        </SliderButtonContainer>
      </SliderContainer>
      <Stack direction="row" justifyContent="center" py={3} spacing={2}>
        {images.map((image, idx) => (
          <SliderBadge
            onClick={() => slideRef.current?.moveToIdx(idx)}
            key={`${image}-badge`}
            className={currentSlide === idx ? 'active' : ''}
            variant="dot"
          />
        ))}
      </Stack>
    </>
  );
};

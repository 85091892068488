export enum QueryKey {
  SELLABLE_PROPERTIES = 'marketplace_sellableProperties',
  PROPERTY_IDS = 'marketplace_propertyIds',
  MARKETPLACE_PROPERTIES = 'marketplace_marketplaceProperties',
  MARKETPLACE_PROPERTY = 'marketplace_marketplaceProperty',
  RECOMMENDED_LISTINGS = 'marketplace_recommendedListings',
  AUTOCOMPLETE_SEARCH_OPTIONS = 'marketplace_autocompleteSearchOptions',
  STATES = 'states',
  MARKETPLACE_PROPERTIES_FOR_ACTIVITIES = 'marketplace_listMarketplacePropertiesForActivities',
  PURCHASE_QUESTIONNAIRE_SUBMISSIONS = 'marketplace_purchaseQuestionnaireQuestions',
  USER_ACTIONS = 'marketplace_userActions',
  GROWTH_POLICY = 'marketplace_growthPolicy',
}

import Carousel from 'react-multi-carousel';
import {
  Box, Card, CardContent, CardMedia, Grid, Skeleton, useMediaQuery,
  useTheme,
} from '@mui/material';

import { carouselResponsiveConfig } from './carousel';

export const ListingSkeletons = ({ avoidCarousel = false }: { avoidCarousel?: boolean }) => {
  const theme = useTheme();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const skeletons = Array(isLowerThanMd ? 2 : 3).fill(0).map((_, i) => i);

  if (isLowerThanMd && !avoidCarousel) {
    return (
      <Grid item xs={12}>
        <Carousel
          containerClass="properties-carousel-container"
          partialVisible
          arrows={false}
          responsive={carouselResponsiveConfig}
        >
          {skeletons.map(() => (
            <Box>
              <Card>
                <CardMedia>
                  <Skeleton animation="wave" variant="rounded" sx={{ height: 200, borderRadius: 0 }} />
                </CardMedia>
                <CardContent sx={{ height: 167 }}>
                  <Skeleton animation="wave" variant="text" width="30%" height={30} />
                  <Skeleton animation="wave" variant="text" width="70%" height={30} />
                  <Skeleton animation="wave" variant="text" width="50%" height={30} />
                </CardContent>
              </Card>
            </Box>
          ))}
        </Carousel>
      </Grid>
    );
  }

  return (
    <>
      {skeletons.map((i) => (
        <Grid item xs={12} md={4} key={i}>
          <Card>
            <CardMedia>
              <Skeleton animation="wave" variant="rounded" sx={{ height: 200, borderRadius: 0 }} />
            </CardMedia>
            <CardContent sx={{ height: 167 }}>
              <Skeleton animation="wave" variant="text" width="30%" height={30} />
              <Skeleton animation="wave" variant="text" width="70%" height={30} />
              <Skeleton animation="wave" variant="text" width="50%" height={30} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

import { ReactNode } from 'react';

import { Avatar, BoldTypography } from 'ui';
import { Stack, Typography } from '@mui/material';

export const GetStartedStep = ({ icon, title, description }: { icon: ReactNode, title: string, description: string }) => (
  <Stack direction="row" alignItems="center" mb={6}>
    <Avatar skin="light" variant="circular" color="info" sx={{ height: 72, width: 72, mr: 3 }}>
      {icon}
    </Avatar>
    <Stack>
      <BoldTypography variant="body1" sx={{ mb: 1 }}>{title}</BoldTypography>
      <Typography variant="body2" color="text.secondary">{description}</Typography>
    </Stack>
  </Stack>
);

import { ReactNode, useState } from 'react';

import {
  Button, Chip, Collapse, lighten, Stack, SxProps, useTheme,
} from '@mui/material';

import { ArrowToggleOpen } from '../icons/ArrowOpenToggle';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

export const CollapsibleNavMenuItem = ({
  text,
  textSx = undefined,
  onNavigate = undefined,
  icon,
  chipContent = undefined,
  isActive = false,
}: {
  text: ReactNode
  icon: ReactNode
  onNavigate?: () => void
  isActive?: boolean
  chipContent?: ReactNode
  textSx?: SxProps,
}) => {
  const theme = useTheme();

  return (
    <Stack
      onClick={onNavigate}
      direction="row"
      py={2.5}
      px={4}
      mb={0}
      gap={3}
      justifyContent="space-between"
      pl={10.5}
      alignItems="center"
      sx={{
        borderRadius: 0,
        color: isActive ? theme.palette.primary.contrastText : theme.palette.text.primary,
        background: isActive ? `${theme.palette.primary.main} !important` : undefined,
      }}
      {...(onNavigate ? {
        component: Button,
        fullWidth: true,
      } : {})}
    >
      <Stack direction="row" alignItems="center" gap={3}>
        {icon}
        <SemiBoldTypography
          variant="body2"
          sx={{
            textTransform: 'none',
            color: isActive ? theme.palette.primary.contrastText : theme.palette.text.primary,
            textWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...textSx,
          }}
        >
          {text}
        </SemiBoldTypography>
      </Stack>
      <Chip
        label={chipContent}
        size="small"
        sx={{
          transition: 'scale 0.1s ease-in-out',
          scale: chipContent ? 1 : 0,
          '&.MuiChip-root': {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          },
        }}
      />
    </Stack>
  );
};

export const CollapsibleNavMenu = ({
  icon,
  label,
  children,
  initialOpen,
  onClick = undefined,
  isActive = false,
  itemPaddingLeft = 0,
}: {
  icon: ReactNode,
  label: string,
  children: ReactNode,
  initialOpen: boolean,
  onClick?: () => void,
  isActive?: boolean
  itemPaddingLeft?: number
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(initialOpen);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        component={Button}
        onClick={() => {
          setOpen((o) => !o);
          onClick?.();
        }}
        gap={3}
        py={2.5}
        px={4}
        mb={0}
        fullWidth
        sx={{
          borderRadius: 0,
          color: theme.palette.text.primary,
          backgroundColor: isActive ? lighten(theme.palette.primary.dark, 0.88) : undefined,
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap={3}
          p={0}
          m={0}
          pl={itemPaddingLeft}
        >
          {icon}
          <SemiBoldTypography variant="body2" sx={{ textTransform: 'none' }}>
            {label}
          </SemiBoldTypography>
        </Stack>
        <ArrowToggleOpen size={22} className={open ? 'open' : ''} />
      </Stack>
      <Collapse in={open}>
        {children}
      </Collapse>
    </>
  );
};

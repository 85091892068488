import { ApexOptions } from 'apexcharts';
import { sum } from 'lib';
import { Theme } from '@mui/material/styles';

import { formatNumberToCurrency } from '../lib/formatFuncs';

export const expensesWithLegendChartConfig = (theme: Theme): ApexOptions => ({
  chart: {
    type: 'donut',
    parentHeightOffset: 10,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        labels: {
          show: true,
          name: {
            show: false,
            fontSize: '40px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
          },
          value: {
            show: true,
            fontSize: '14px',
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            formatter(val: string): string {
              return formatNumberToCurrency(+val, 1, {
                notation: 'compact',
                compactDisplay: 'short',
              });
            },
          },
          total: {
            show: true,
            label: 'Total',
            fontSize: '32px',
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            formatter: (val: any) => formatNumberToCurrency(sum(val.config.series), 1, {
              notation: 'compact',
              compactDisplay: 'short',
            }),
          },
        },
      },
    },
  },
  legend: {
    show: false,
    position: 'right',
    fontSize: '16px',
    horizontalAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    labels: {
      colors: theme.palette.text.primary,
    },
    markers: {
      offsetX: -5,
    },
    itemMargin: {
      vertical: 4,
      horizontal: 100,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
  },
  stroke: {
    show: true,
  },
  tooltip: {
    y: {
      formatter(val: number): string {
        return formatNumberToCurrency(+val, 2, {
          notation: 'compact',
          compactDisplay: 'short',
        });
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
});

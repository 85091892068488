import { useEffect } from 'react';

const hubSpotChatContainerID = 'hubspot-messages-iframe-container';

export const useHideChat = (hide: boolean) => {
  useEffect(() => {
    const chatContainer = document.getElementById(hubSpotChatContainerID);
    if (chatContainer) {
      chatContainer.classList.toggle('hidden', hide);
    }

    // @ts-ignore window has no property "Intercom"
    window.Intercom?.('update', {
      hide_default_launcher: hide,
    });

    return () => {
      // @ts-ignore window has no property "Intercom"
      window.Intercom?.('update', {
        hide_default_launcher: false,
      });

      if (chatContainer) {
        chatContainer.classList.toggle('hidden', false);
      }
    };
  }, [hide]);

  return null;
};

export const toggleChatOpen = (open: boolean) => {
  if (open) {
    // @ts-ignore
    window.Intercom?.('show');
    // @ts-ignore
    window.HubSpotConversations?.widget?.open();
  } else {
    // @ts-ignore
    window.Intercom?.('hide');
    // @ts-ignore
    window.HubSpotConversations?.widget?.close();
  }
};

import { useAnalytics } from 'lib';
import { IconSelector, UpDownArrows, useLabels } from 'ui';
import {
  IconButton, MenuItem, useTheme,
} from '@mui/material';

import { SortOption, sortOptions } from '../../api/properties/searchable';

export const SortingOptionsSelect = ({ setSortOption, sortOption, hasSelection }: {
  setSortOption: (value: SortOption) => void,
  sortOption: SortOption,
  hasSelection: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const sortOptionLabels: Record<SortOption, string> = {
    'top-picks': l['listings.marketplace.sortOption.topPicks'],
    'cap-rate-desc': l['listings.marketplace.sortOption.capRate'],
    'gross-yield-desc': l['listings.marketplace.sortOption.grossYield'],
    'asking-price-desc': l['listings.marketplace.sortOption.askingPriceHtL'],
    'asking-price-asc': l['listings.marketplace.sortOption.askingPriceLtH'],
  };

  return (
    <IconSelector<SortOption>
      inputProps={{ IconComponent: () => null }}
      value={sortOption}
      onChange={(e) => {
        analytics.track('Selector Changed', {
          selectorName: 'Sort Properties',
          value: e.target.value,
          previousValue: sortOption,
        });

        setSortOption(e.target.value as SortOption);
      }}
      renderValue={() => (
        <IconButton size="medium" sx={{ maxWidth: 40 }}>
          <UpDownArrows height={24} width={24} fill={hasSelection ? theme.palette.primary.dark : theme.palette.text.primary} />
        </IconButton>
      )}
    >
      {sortOptions.map((option) => (
        <MenuItem value={option} key={option}>
          {sortOptionLabels[option]}
        </MenuItem>
      ))}
    </IconSelector>
  );
};

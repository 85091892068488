import { MdMapsHomeWork } from 'react-icons/md';
import {
  Avatar, BoldTypography, formatNumber, SemiBoldTypography,
} from 'ui';
import {
  lighten, Stack, useTheme,
} from '@mui/material';

import { Property } from '../../types';

export const PropertyHeader = ({ property }: { property: Property }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" gap={2} px={3}>
      <Avatar skin="light" variant="rounded" color="primary">
        <MdMapsHomeWork size={24} />
      </Avatar>
      <Stack>
        <BoldTypography variant="h6">
          {property.address.shortAddress}
        </BoldTypography>
        <Stack direction="row" gap={3}>
          <SemiBoldTypography variant="body2" sx={{ color: lighten(theme.palette.text.primary, 0.2) }}>
            {property.bedrooms ? `${property.bedrooms} Beds` : ''}
          </SemiBoldTypography>
          <SemiBoldTypography variant="body2" sx={{ color: lighten(theme.palette.text.primary, 0.2) }}>
            {property.bathrooms ? `${property.bathrooms} Baths` : ''}
          </SemiBoldTypography>
          <SemiBoldTypography variant="body2" sx={{ color: lighten(theme.palette.text.primary, 0.2) }}>
            {property.sqft ? `${formatNumber(property.sqft, 0)} sq. ft.` : ''}
          </SemiBoldTypography>
        </Stack>
      </Stack>
    </Stack>
  );
};

import dayjs from 'dayjs';

export const formatNumberToCurrency = (
  number: number,
  maximumFractionDigits: number,
  options?: Intl.NumberFormatOptions,
): string => (
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits,
    ...(options || {}),
  }).format(number)
);

export const formatNumber = (
  number: number,
  maximumFractionDigits: number,
  options?: Intl.NumberFormatOptions,
): string => (
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits,
    ...(options || {}),
  }).format(number)
);

export const formatDecimalToPercentage = (
  number: number,
  maximumFractionDigits: number,
  options?: Intl.NumberFormatOptions,
): string => (
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits,
    ...(options || {}),
  }).format(number)
);

export const formatDate = (date: dayjs.Dayjs, format: string = 'YYYY-MM-DD'): string => date.format(format);

import { hexToRGBA } from 'ui';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

export const InsightTypography = ({ sx, ...props }: TypographyProps) => (
  <Typography
    {...props}
    sx={{
      background: (theme) => (hexToRGBA(theme.palette.info.main, 0.08)),
      padding: 3,
      borderRadius: 0.666,
    }}
  />
);

import { useState } from 'react';

import {
  SuggestedPartnerStatus, useAnalytics,
  useTrackPageBottom,
} from 'lib';
import {
  FullScreenBlur, PartnersIcon, SpinnerWithLogo, useEnableScroll, useLabels,
} from 'ui';
import {
  Alert,
  Button,
  Grid,
  Stack, Typography,
  useTheme,
} from '@mui/material';

import { PartnersHeader, SortOption, sortOptionToFunction } from './PartnersHeader';
import { partnerStatusState } from './state';
import { SuggestedPartnerCard } from './SuggestedPartnerCard';
import { useGetGrowthPolicy } from '../../api/growth-policy';
import { useGetSuggestedPartners } from '../../api/suggested-partners';
import { SuggestedPartner } from '../../api/suggested-partners/types';
import { growthPolicyDialogState } from '../../templates/growth-policy/state';

export const Partners = () => {
  const partnersPerPage = 24;
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [search, setSearch] = useState('');
  const [maxPartnersToShow, setMaxPartnersToShow] = useState<Record<SuggestedPartnerStatus, number>>({
    [SuggestedPartnerStatus.NEW]: partnersPerPage,
    [SuggestedPartnerStatus.IN_PROGRESS]: 0,
    [SuggestedPartnerStatus.WON]: 0,
    [SuggestedPartnerStatus.LOST]: 0,
  });
  const {
    data: suggestedPartnerPages,
    isLoading: isLoadingSuggestedPartners,
    isFetchingNextPage: isFetchingNextPageSuggestedPartners,
    hasNextPage: hasNextPageSuggestedPartners,
  } = useGetSuggestedPartners();
  const { data: growthPolicy, isLoading: isLoadingGrowthPolicy } = useGetGrowthPolicy();
  const [sortOption, setSortOption] = useState<SortOption>(SortOption.TOP_PICKS);

  const shouldShowBlurAlert = !growthPolicy && !isLoadingGrowthPolicy;

  useEnableScroll(!shouldShowBlurAlert && !growthPolicyDialogState.value.open);

  const suggestedPartners = (
    suggestedPartnerPages?.pages.flatMap((page) => page.partners).sort(sortOptionToFunction[sortOption]) ?? []
  );

  const statusToPartners: Record<SuggestedPartnerStatus, SuggestedPartner[]> = {
    [SuggestedPartnerStatus.NEW]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.NEW),
    [SuggestedPartnerStatus.IN_PROGRESS]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.IN_PROGRESS),
    [SuggestedPartnerStatus.WON]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.WON),
    [SuggestedPartnerStatus.LOST]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.LOST),
  };

  const partnerSearchFilter = (partner: SuggestedPartner) => (
    partner.name.toLowerCase().includes(search.toLowerCase())
      || partner.email.toLowerCase().includes(search.toLowerCase())
  );

  const partners = statusToPartners[partnerStatusState.value].filter(partnerSearchFilter);

  const noData = !isLoadingSuggestedPartners && !hasNextPageSuggestedPartners && !partners.length;
  const isLoading = (
    isLoadingSuggestedPartners || isFetchingNextPageSuggestedPartners || hasNextPageSuggestedPartners || isLoadingGrowthPolicy
  );

  useTrackPageBottom(() => {
    analytics.track('Page Bottom Reached', {
      pageName: 'Suggested Partners',
      partnerStatus: partnerStatusState.value,
      maxPartnersToShow: maxPartnersToShow[partnerStatusState.value],
    });

    setMaxPartnersToShow((prevMaxPartnersToShow) => ({
      ...prevMaxPartnersToShow,
      [partnerStatusState.value]: prevMaxPartnersToShow[partnerStatusState.value] + partnersPerPage,
    }));
  });

  return (
    <Stack position="relative" p={{ xs: 3, md: 6 }} gap={3} height="100%">
      <FullScreenBlur
        shown={shouldShowBlurAlert}
      >
        <Alert
          variant="filled"
          severity="error"
          icon={<PartnersIcon height={18} width={18} />}
          sx={{ color: theme.palette.primary.contrastText, alignItems: 'center' }}
          action={(
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={() => growthPolicyDialogState.value.openDialog()}
            >
              {l.setup}
            </Button>
          )}
        >
          {l['partners.setupGrowthPolicy']}
        </Alert>
      </FullScreenBlur>
      <Typography variant="h6">
        {l.opportunities}
      </Typography>
      <PartnersHeader
        search={search}
        setSearch={setSearch}
        setSortOption={setSortOption}
        sortOption={sortOption}
      />
      {isLoading && (
        <Stack flexGrow={1} justifyContent="center" alignItems="center">
          <SpinnerWithLogo />
        </Stack>
      )}
      {!isLoading && !noData && (
        <Grid container spacing={3}>
          {partners.slice(0, maxPartnersToShow[partnerStatusState.value] || partners.length).map((partner) => (
            <Grid item xs={12} sm={6} md={4} key={partner.email}>
              <SuggestedPartnerCard partner={partner} growthPolicy={growthPolicy} />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoading && noData && (
        <Stack flexGrow={1} justifyContent="center" alignItems="center">
          <PartnersIcon height={40} width={40} />
          <Typography variant="h6">
            {l['partners.noPartnersFound']}
          </Typography>
        </Stack>
      )}
      {!isLoading && maxPartnersToShow[partnerStatusState.value] >= partners.length && (
        <Typography variant="body2" color="secondary" sx={{ textAlign: 'center' }}>
          {l['partners.noMorePartners']}
        </Typography>
      )}
    </Stack>
  );
};

export const ArrowCircleLeft = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_11086_73219)">
      <path
        d="M19.172 18l-4.95-4.95 1.414-1.414L22 18l-6.364 6.364-1.414-1.414 4.95-4.95z"
        fill="#6D788D"
      />
    </g>
    <rect
      x={0.5}
      y={0.5}
      width={35}
      height={35}
      rx={17.5}
      stroke="#173771"
      strokeOpacity={0.22}
    />
    <defs>
      <clipPath id="prefix__clip0_11086_73219">
        <path fill="#fff" transform="translate(6 6)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

import {
  axiosWithPayloadContext, config, createHeaders, useAuth,
} from 'lib';
import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';

export const createOwnerLeadSchema = z.object({
  email: z.string().email('This is not a valid email').min(1),
  firstName: z.string().trim().min(1, 'First name is required'),
  lastName: z.string().trim().min(1, 'Last name is required'),
});

export type CreateOwnerLeadData = z.infer<typeof createOwnerLeadSchema>;

export const useCreateOwnerLead = () => {
  const { getAccessTokenSilently, user } = useAuth();

  return useMutation(async (data: CreateOwnerLeadData & {
    referralFreeMonths?: number,
  }) => {
    const token = await getAccessTokenSilently();
    const dataToSend = { ...data, pm: user?.pm ?? '' };

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/owner-lead`,
      method: 'POST',
      headers: createHeaders(token),
      data: dataToSend,
    });
  });
};

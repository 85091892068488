import {
  BuyerLeadsWorkflowStatus,
} from 'lib';

import { BuyerLeadsKanban } from './kanban/BuyerLeadsKanban';
import { BuyerLeadsList } from './list/BuyerLeadsList';

const orderedStatuses = [
  BuyerLeadsWorkflowStatus.new,
  BuyerLeadsWorkflowStatus.attemptedContact,
  BuyerLeadsWorkflowStatus.spokeWithLead,
  BuyerLeadsWorkflowStatus.appointmentSet,
  BuyerLeadsWorkflowStatus.metWithLead,
  BuyerLeadsWorkflowStatus.offerSubmitted,
  BuyerLeadsWorkflowStatus.underContract,
  BuyerLeadsWorkflowStatus.sold,
  BuyerLeadsWorkflowStatus.rejected,
];

export const BuyerLeads = ({ isKanbanViewEnabled }: { isKanbanViewEnabled: boolean }) => (
  isKanbanViewEnabled
    ? <BuyerLeadsKanban orderedStatuses={orderedStatuses} />
    : <BuyerLeadsList orderedStatuses={orderedStatuses} />
);

import { IconBaseProps } from 'react-icons';
import { MdWarningAmber } from 'react-icons/md';
import { Box, BoxProps, useTheme } from '@mui/material';

export const MissingDataIcon = ({ iconProps = {}, boxProps = {} }: { iconProps?: IconBaseProps, boxProps?: BoxProps }) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{ display: 'inline-flex', alignItem: 'center', ...(boxProps.sx ?? {}) }}
      {...boxProps}
      data-testid="missing-data-icon"
    >
      <MdWarningAmber size={16} color={theme.palette.secondary.main} {...iconProps} />
    </Box>
  );
};

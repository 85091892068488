import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { getActualAnnualIncome, useAnalytics, useIsDashboardPropertyProformaEnabled } from 'lib';
import {
  BoldTypography, DollarCircle, DollarRect, ErrorCircle,
  formatDecimalToPercentage, formatNumberToCurrency, InfoTooltip, LightButton, Person, Spacer, SuccessCircle, useLabels,
} from 'ui';
import {
  Box, CardContent, CardHeader, Grid, Stack, Typography,
} from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';

import { TooltipContent } from './TooltipContent';
import { PortfolioSummaryProps } from './types';
import { sumIncomeTransactions } from '../../lib/calc';

export const PortfolioSummary = ({ properties, inSelectedProperty = false }: PortfolioSummaryProps) => {
  const today = dayjs();
  const singleProperty = properties.length === 1;
  const singlePropertyIsVacant = () => {
    if (singleProperty) {
      if (properties[0].units.length === 1) {
        return properties[0].units[0].isVacant;
      }
      return properties[0].isVacant;
    }
    return false;
  };

  const { value: isPropertyProformaEnabled } = useIsDashboardPropertyProformaEnabled();

  const allMetrics = properties.map((p) => p.metrics);

  const summary = {
    lastYearRentIncome: getActualAnnualIncome(allMetrics),
    lastMonthRentIncome: sumIncomeTransactions(properties,
      today.subtract(1, 'months').startOf('month'),
      today.subtract(1, 'months').endOf('month')),
    numOfUnits: properties.reduce((acc, p) => acc + (p.units.length || 1), 0),
    numOfOccupancyUnits: properties.reduce((acc, p) => acc + p.units.filter((u) => !u.isVacant).length, 0),
  };

  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();
  const navigate = useNavigate();

  const tooltipContent = [
    {
      bold: l['tooltip.total-income.title'],
      label: l['tooltip.total-income.desc'],
    },
    {
      bold: l['tooltip.last_month_income.title'],
      label: l['tooltip.last_month_income.desc'],
    },
    {
      bold: l['tooltip.occupancy.title'],
      label: l['tooltip.occupancy.desc'],
    },
  ];

  const handleClickProforma = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Proforma',
      propertyId: singleProperty ? properties[0].id : 'portfolio',
    });

    if (singleProperty) {
      navigate(`/proforma/${properties[0].id}`);
    } else {
      navigate('/proforma');
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '6px',

        ...(singleProperty && !inSelectedProperty && { borderTopLeftRadius: 0, borderTopRightRadius: 0 }),
      }}
    >
      <CardHeader
        title={singleProperty ? l.summary : l.portfolioSummary}
        titleTypographyProps={{ variant: 'h6', sx: { color: theme.palette.primary.contrastText } }}
        sx={{ p: 4, pb: 2 }}
        action={(
          <InfoTooltip
            title={<TooltipContent bullets={tooltipContent} />}
            isOutlined
            arrow
            isLight
            color={theme.palette.primary.contrastText}
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName: 'Portfolio Summary Tooltip',
              });
            }}
          />
        )}
      />
      <CardContent>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.1)}`,
              paddingBottom: 3,
              marginBottom: 3,
            }}
          >
            <Box mr={5} color={theme.palette.primary.contrastText}>
              <DollarCircle />
            </Box>
            <Box sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <BoldTypography sx={{ color: theme.palette.primary.contrastText }} variant="h6">
                {formatNumberToCurrency(summary.lastYearRentIncome, 0)}
              </BoldTypography>
              <Typography variant="body2" sx={{ color: theme.palette.primary.contrastText }}>
                {l.grossRent}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              borderBottom: `1px solid ${lighten(theme.palette.primary.main, 0.1)}`,
              paddingBottom: 3,
              marginBottom: 3,
            }}
          >
            <Box mr={5} color={theme.palette.primary.contrastText}>
              <DollarRect />
            </Box>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1 }}>
              <Typography sx={{ mb: 0.5, color: theme.palette.primary.contrastText }} variant="body2">
                {l.lastMonthIncome}
              </Typography>
              <BoldTypography sx={{ color: theme.palette.primary.contrastText }}>
                {formatNumberToCurrency(summary.lastMonthRentIncome, 1, {
                  notation: 'compact',
                  compactDisplay: 'short',
                })}
              </BoldTypography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Box mr={5} color={theme.palette.primary.contrastText}>
              <Person />
            </Box>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1 }}>
              <Typography sx={{ mb: 0.5, color: theme.palette.primary.contrastText }} variant="body2">
                {l.occupied}
              </Typography>
              <BoldTypography sx={{ color: theme.palette.primary.contrastText }}>
                {singleProperty && summary.numOfUnits === 1 ? (
                  <Stack component="span" direction="row" gap={1} alignItems="center">
                    {singlePropertyIsVacant() ? <ErrorCircle /> : <SuccessCircle />}
                    {singlePropertyIsVacant() ? 'No' : 'Yes'}
                  </Stack>
                ) : (
                  <>
                    {summary.numOfOccupancyUnits}
                    /
                    {summary.numOfUnits}
                    (
                    {formatDecimalToPercentage(summary.numOfOccupancyUnits / summary.numOfUnits, 0)}
                    )
                  </>
                )}
              </BoldTypography>
            </Stack>
          </Grid>
        </Grid>
        {isPropertyProformaEnabled && (
          <>
            <Spacer spacing={2} />
            <LightButton onClick={handleClickProforma} fullWidth disabled={singleProperty && !!properties[0].deleted}>
              {l.proforma}
            </LightButton>
          </>
        )}
      </CardContent>
    </Box>
  );
};

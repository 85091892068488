import { useState } from 'react';

import dayjs from 'dayjs';
import {
  ExpenseCategory, Label, useAnalytics, useHideChat,
} from 'lib';
import {
  MdArrowBackIosNew, MdDelete, MdOutlineEdit,
} from 'react-icons/md';
import {
  BoldTypography, GraphTrendDown, GraphTrendUp, IconButton, useLabels,
} from 'ui';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Drawer,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { DeleteRecurringTransactionsModal } from './DeleteRecurringTransaction';
import { DeleteTransactionsModal } from './DeleteTransaction';
import { EditRecurringTransactionsModal } from './EditRecurringTransaction';
import { EditTransactionsModal } from './EditTransaction';
import { SumLabel } from './SumLabel';
import { TableItem } from './types';
import { useToggle } from '../../hooks/useToggle';
import { convertExpenseCategoryToCategory } from '../../lib/calc';
import { Transaction } from '../../types';

const StyledCard = styled(Card)(({ theme }) => ({
  transition: '0.3s',
  '&:hover': {
    cursor: 'pointer',
    background: theme.palette.background.default,
  },
}));

export const TransactionCard = ({ state }: { state: TableItem }) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, toggleOpen] = useToggle();
  const [isEditOpen, toggleEditOpen] = useToggle();
  const [isRecurringEditOpen, toggleRecurringEditOpen] = useToggle();
  const [isDeleteOpen, toggleDeleteOpen] = useToggle();
  const [isDeleteRecurringOpen, toggleDeleteRecurringOpen] = useToggle();
  useHideChat(isOpen || isEditOpen || isRecurringEditOpen || isDeleteOpen || isDeleteRecurringOpen);

  const [transactionSelected, setTransactionSelected] = useState<Transaction | null>(null);

  const allowEditAndDelete = state.rawLabel === Label.manual || state.rawLabel === Label.manual_recurring || state.isEditable;

  return (
    <>
      <StyledCard onClick={() => {
        toggleOpen();
        analytics.track('Button Clicked', {
          buttonName: 'Expand Transaction',
          transactionId: state.id,
        });
      }}
      >
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <BoldTypography variant="body1">{state.propertyName}</BoldTypography>
              <Stack direction="row" alignItems="center">
                {state.label && <Box component="span" mr={2}>{state.label}</Box>}
                <Typography variant="body2" component="span">
                  {state.category}
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ alignItems: 'end' }}>
              <SumLabel value={state.amount} />
              <Typography variant="body2">
                {dayjs(state.date).format('MMM, YYYY')}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </StyledCard>
      <Drawer
        open={isOpen}
        onClose={toggleOpen}
        sx={{ zIndex: 1600 }}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : undefined,
          },
        }}
        anchor="right"
      >
        <Box width={isMobile ? undefined : '375px'}>
          <Stack justifyContent="space-between" direction="row" m={3}>
            <IconButton Icon={MdArrowBackIosNew} onClick={toggleOpen} actionName="back" />
          </Stack>
          <Stack m={3} gap={3} direction="row">
            {state.amount > 0 ? <GraphTrendUp height={40} width={40} /> : <GraphTrendDown height={40} width={40} />}
            <Stack>
              <Typography variant="body1bold">{state.propertyName}</Typography>
              <Typography data-testid="transaction-type" variant="caption" sx={{ textTransform: 'capitalize' }}>
                {state.amount > 0 ? l.income : l.expense}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack>
            <Stack direction="row" m={4}>
              <Typography variant="body1" color="secondary" sx={{ width: '25%' }}>
                {l.date}
              </Typography>
              <Typography variant="body1bold">
                {dayjs(state.date).format('MMM, YYYY')}
              </Typography>
            </Stack>
            <Stack direction="row" m={4}>
              <Typography variant="body1" color="secondary" sx={{ width: '25%' }}>
                {l.category}
              </Typography>
              <Typography variant="body1bold">
                {state.label}
                {' '}
                {state.category}
              </Typography>
            </Stack>
            {state.originalCategory && state.originalCategory.length > 0 && (
              <Stack direction="row" m={4}>
                <Typography variant="body1" color="secondary" sx={{ width: '25%' }}>
                  {l.description}
                </Typography>
                <Typography variant="body1bold">
                  {state.originalCategory}
                </Typography>
              </Stack>
            )}
            <Stack direction="row" m={4}>
              <Typography variant="body1" color="secondary" sx={{ width: '25%' }}>
                {l.address}
              </Typography>
              <Typography variant="body1bold" sx={{ width: '75%' }}>
                {state.fullAddress}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems={state.transactions.length === 1 ? 'center' : 'flex-start'} m={4}>
              <Typography variant="body1" color="secondary" sx={{ width: '25%' }}>
                {l.amount}
              </Typography>
              {state.rawLabel && state.rawLabel !== Label.pm ? (
                <Box sx={{ width: '75%' }} m={1}>
                  {state.transactions.map((transaction) => (
                    <Stack
                      key={transaction.id}
                      direction="row"
                      justifyContent="space-between"
                      alignItems={state.transactions.length === 1 ? 'center' : 'flex-start'}
                    >
                      <Stack direction="row" alignItems="center">
                        {allowEditAndDelete && (
                          <Stack direction="row" alignItems="center" sx={{ mr: 2 }}>
                            <IconButton
                              Icon={MdOutlineEdit}
                              onClick={() => {
                                setTransactionSelected(transaction);
                                if (state.rawLabel === Label.manual_recurring) {
                                  toggleRecurringEditOpen();
                                } else {
                                  toggleEditOpen();
                                }
                              }}
                              disabledTooltip={l['tooltip.edit-action.disabled']}
                              actionName="edit"
                              buttonSx={{
                                p: 0, mr: 2, display: 'flex', alignItems: 'center',
                              }}
                            />
                            <IconButton
                              Icon={MdDelete}
                              onClick={() => {
                                setTransactionSelected(transaction);
                                if (state.rawLabel === Label.manual_recurring) {
                                  toggleDeleteRecurringOpen();
                                } else {
                                  toggleDeleteOpen();
                                }
                              }}
                              disabledTooltip={l['tooltip.delete-action.disabled']}
                              actionName="delete"
                              buttonSx={{
                                p: 0, mr: 2, display: 'flex', alignItems: 'center',
                              }}
                            />
                          </Stack>
                        )}
                        <Typography variant="body2">
                          {dayjs(transaction.transactionDate).format('MMM D, YYYY')}
                        </Typography>
                      </Stack>
                      <Box>
                        <Typography align="right">
                          <SumLabel value={transaction.amount} overrideDesktopFont />
                        </Typography>
                      </Box>
                    </Stack>
                  ))}
                  {state.transactions.length > 1 && (
                    <>
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2" sx={{ ml: state.rawLabel === Label.manual ? 22 : 0 }}>
                          {l.total}
                        </Typography>
                        <Typography align="right">
                          <SumLabel value={state.amount} overrideDesktopFont />
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Box>
              ) : (
                <Typography align="right">
                  <SumLabel value={state.amount} overrideDesktopFont />
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      </Drawer>
      {transactionSelected?.id && (
        <>
          <EditTransactionsModal
            isOpen={isEditOpen}
            toggle={toggleEditOpen}
            initialValues={{
              id: transactionSelected?.id,
              type: 'one_time',
              propertyId: state.propertyId,
              category: transactionSelected ? convertExpenseCategoryToCategory(transactionSelected.category)
                : ExpenseCategory.other,
              dates: {
                date: transactionSelected?.transactionDate,
              },
              amount: Math.abs(transactionSelected?.amount ?? 0),
            }}
          />
          <EditRecurringTransactionsModal
            isOpen={isRecurringEditOpen}
            toggle={toggleRecurringEditOpen}
            initialValues={{
              id: transactionSelected.id,
              type: 'recurring',
              propertyId: state.propertyId,
              category: transactionSelected ? convertExpenseCategoryToCategory(transactionSelected.category)
                : ExpenseCategory.other,
              dates: {
                date: transactionSelected.transactionDate,
              },
              amount: Math.abs(transactionSelected?.amount ?? 0),
            }}
          />
          <DeleteRecurringTransactionsModal
            isOpen={isDeleteRecurringOpen}
            toggle={toggleDeleteRecurringOpen}
            id={transactionSelected.id}
          />
          <DeleteTransactionsModal
            isOpen={isDeleteOpen}
            toggle={toggleDeleteOpen}
            id={transactionSelected.id}
          />
        </>
      )}
    </>
  );
};

import { useEffect, useState } from 'react';

import {
  config,
  Question, shouldSkipQuestion, SubmissionRequest, useCreateSubmission, useGetQuestions,
} from 'lib';
import { toast } from 'react-toastify';
import {
  Progress,
  QuestionControlRadio,
  QuestionTextArea,
  useLabels,
} from 'ui';
import {
  Box,
  Button,
  Dialog, DialogContent, Fade, useMediaQuery, useTheme,
} from '@mui/material';

import { FooterActions } from './styled';

export const DialogOwnerSurvey = ({
  open, closeDialog, formId, satisfiedAnswer, ownerEmail,
}:
{ open: boolean, closeDialog: () => void, formId: string, satisfiedAnswer: string, ownerEmail: string }) => {
  const l = useLabels();
  const theme = useTheme();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const [questionSlide, setQuestionSlide] = useState<number>(0);
  const [skippedQuestions, setSkippedQuestions] = useState<string[]>([]);
  const createSubmission = useCreateSubmission(formId);

  const {
    data: questions,
    isLoading: questionsLoading,
    isError: questionsError,
  } = useGetQuestions({
    formID: config.jotFormOwnerSurveyFormId,
    ownerEmail: ownerEmail ?? '',
    enabled: true,
  });

  const [questionsForm, setQuestionsForm] = useState<{ [key: string]: Question }>((questions ?? []).reduce((acc, q) => ({
    ...acc,
    [q.id]: q,
  }), {} as { [key: string]: Question }));

  const updateAnswer = (selectedId: string, answer: string) => {
    setQuestionsForm((currentQuestions) => ({
      ...currentQuestions,
      [selectedId]: {
        ...currentQuestions[selectedId],
        answer,
      },
    }));
  };

  const orderFormValues = Object.values(questionsForm)
    .sort((a, b) => Number(a.order) - Number(b.order));

  const orderFormKeys = Object.keys(questionsForm)
    .sort((a, b) => Number(questionsForm[a].order)
          - Number(questionsForm[b].order));

  const submitForm = async () => {
    const requestData = Object.keys(questionsForm).map((key): SubmissionRequest => ({
      id: key,
      answer: questionsForm[key].answer,
    }));
    try {
      await createSubmission.mutateAsync(requestData);
      closeDialog();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  const handleLastQuestionIsNext = () => {
    const question = orderFormValues[(questions ?? []).length - 1];
    const skip = shouldSkipQuestion(question?.id, questionsForm);
    if (skip) {
      submitForm();
    }
  };

  const handleNext = (idx: number) => () => {
    if (!questions || questions.length === 0) {
      return;
    }
    let nextQuestionIndex = idx;

    let skip = nextQuestionIndex !== (questions!.length - 1);

    const skippedQuestionsIds = skippedQuestions;

    while (skip) {
      nextQuestionIndex += 1;
      if (nextQuestionIndex === (questions!.length - 1)) {
        skip = false;
        break;
      }
      const question = orderFormValues[nextQuestionIndex];
      skip = shouldSkipQuestion(question.id, questionsForm, skippedQuestionsIds);
      if (skip) {
        skippedQuestionsIds.push(question.id);
      }
    }

    setSkippedQuestions(skippedQuestionsIds);
    setQuestionSlide(nextQuestionIndex);

    if (nextQuestionIndex === ((questions ?? []).length - 1)) {
      handleLastQuestionIsNext();
    }
  };

  useEffect(() => {
    setQuestionsForm((questions ?? []).reduce((acc, q) => ({
      ...acc,
      [q.id]: q,
    }), {} as { [key: string]: Question }));

    updateAnswer('2', satisfiedAnswer);
    handleNext(questionSlide)();
  }, [questions, satisfiedAnswer]);

  if (questionsLoading || questionsError || !questions || questions.length === 0) {
    return null;
  }

  return (
    <Dialog
      fullScreen={isLowerThanMd}
      open={open}
      maxWidth="sm"
      sx={{ zIndex: 1600 }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box
          component="form"
          width={600}
          height={isLowerThanMd ? '100%' : 400}
        >
          <Box position="absolute" top="0" left="0" height="100%" width="100%">
            {(
              questions && questions.length > 1) ? (
                <Progress
                  title={l['dashboard.ownerSurvey']}
                  show
                  cancel={() => {
                    setQuestionSlide(0);
                    submitForm();
                  }}
                  amount={questionSlide + 1}
                  total={[...Object.keys(questionsForm)].length}
                />
              ) : null}
            <Box
              position="relative"
              flexGrow="1"
              minHeight={isLowerThanMd ? '100%' : '400px'}
              width={isLowerThanMd ? undefined : theme.breakpoints.values.sm}
            >
              {orderFormKeys
                .map((id, idx) => (
                  <Fade
                    key={`${id}-slide`}
                    in={idx === questionSlide}
                    timeout={600}
                  >
                    <Box position="absolute" top="0" left="0" height="100%" width="100%">
                      {questionsForm[id].type === 'control_radio' && (
                        <QuestionControlRadio
                          onChange={updateAnswer}
                          id={id}
                          question={questionsForm[id]}
                        />
                      )}
                      {questionsForm[id].type === 'control_textarea' && (
                        <QuestionTextArea
                          onChange={updateAnswer}
                          id={id}
                          question={questionsForm[id]}
                        />
                      )}
                    </Box>
                  </Fade>
                ))}
            </Box>
          </Box>
        </Box>
        <FooterActions className="sticky">
          <Button
            type="button"
            variant="text"
            sx={{
              borderColor: theme.palette.grey[500],
              color: theme.palette.text.primary,
            }}
            onClick={() => {
              submitForm();
            }}
          >
            {l.cancel}
          </Button>
          <Button
            variant="contained"
            type="button"
            data-testid="click-save-owner-survey-button"
            onClick={(questionSlide !== (questions ?? []).length - 1) ? handleNext(questionSlide) : submitForm}
            sx={{
              borderColor: theme.palette.grey[500],
            }}
          >
            {l.next}
          </Button>
        </FooterActions>
      </DialogContent>
    </Dialog>
  );
};

import { Spinner, useLabels } from 'ui';
import {
  alpha, Button, Stack, SxProps, useTheme,
} from '@mui/material';

export const RevealOverlayKanban = ({
  reveal, revealed, isRevealing, disabled, cover = true,
}: {
  reveal: () => Promise<void>,
  revealed: boolean,
  isRevealing: boolean,
  disabled: boolean,
  cover?: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  const coverSx: SxProps = {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
  };

  return (
    <Stack
      display={revealed ? 'none' : undefined}
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{
        backdropFilter: 'blur(3px)',
        ...cover && coverSx,
      }}
    >
      <Button
        sx={{
          '&.Mui-disabled': {
            backgroundColor: alpha(theme.palette.success.main, 0.7),
          },
        }}
        disabled={disabled}
        color="success"
        variant="contained"
        onClick={reveal}
      >
        {isRevealing ? spinner : l.revealLead}
      </Button>
    </Stack>
  );
};

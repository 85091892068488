import {
  axiosWithPayloadContext, config, createHeaders, useAuth,
} from 'lib';
import { useQuery } from '@tanstack/react-query';

import { EmbedDashboardResponse } from './types';
import { QueryKey } from '../../types/enums';

export const useGeneratePmPropertiesDashboard = () => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.PM_PROPERTIES_DASHBOARD_URL], async (): Promise<EmbedDashboardResponse> => {
    const token = await getAccessTokenSilently();

    const { data } = await axiosWithPayloadContext<EmbedDashboardResponse>({
      headers: createHeaders(token),
      url: `${config.apiBaseURL}/embed-dashboard`,
      method: 'POST',
      data: { dashboardName: 'pm_dashboard' },
    });

    return data;
  }, { enabled: true, refetchOnWindowFocus: false });
};

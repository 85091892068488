import { FormEvent, Fragment, useState } from 'react';

import { useAnalytics, useHideChat } from 'lib';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  BoldTypography, GrowTransition, InfoTooltip, useLabels,
} from 'ui';
import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useMortgageMutation, usePurchaseHistoryMutation } from './apiHelpers';
import { PropertyMortgageData } from './PropertyMortgageData';
import { PurchaseData } from './PurchaseData';
import { PropertyFormValues } from './types';
import { createFormDefaultValues } from './utils';
import { Property } from '../../types';
import { QueryKey } from '../../types/enums';

type Props = {
  open: boolean;
  onClose: () => void;
  properties: Property[];
  showPurchase?: boolean;
  showMortgage?: boolean;
  propertyID?: string;
};

export const UpdateProperties = ({
  properties, propertyID = '', open, onClose, showMortgage = false, showPurchase = false,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const lowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const [selectedPropertyID, setSelectedPropertyID] = useState<string>(propertyID ?? properties[0]?.id ?? '');
  const queryClient = useQueryClient();
  const purchaseHistoryMutation = usePurchaseHistoryMutation();
  const mortgageMutation = useMortgageMutation();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm<{
    [key: string]: PropertyFormValues
  }>({
    defaultValues: properties.reduce((acc, p) => ({ ...acc, [p.id]: createFormDefaultValues(p) }), {}),
  });

  useHideChat(lowerThanMd);

  const saveProperties = async (data: { [key: string]: PropertyFormValues }) => {
    setLoading(true);
    const promises: Promise<any>[] = [];
    properties.forEach((property) => {
      analytics.track('Entity Edited', {
        entityName: 'Property',
        propertyID: property.id,
        propertyAddress: property.address.fullAddress,
        updatedMortgage: showMortgage,
        updatedPurchase: showPurchase,
      });

      const propertyData = data[property.id];
      if (showPurchase && propertyData.purchase.date && propertyData.purchase.amount) {
        promises.push(purchaseHistoryMutation.mutate(property, propertyData));
      }
      if (showMortgage) {
        promises.push(mortgageMutation.mutate(property, propertyData));
      }
    });
    try {
      await Promise.all(promises);
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      onClose();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForm = async (event: FormEvent<HTMLFormElement | HTMLButtonElement>) => {
    event.preventDefault();
    await handleSubmit(saveProperties)(event);
  };

  const hasErrors = loading || !!errors[selectedPropertyID]?.purchase || !!errors[selectedPropertyID]?.mortgage;

  return (
    <Dialog
      open={open}
      fullScreen={lowerThanMd}
      onClose={onClose}
      TransitionComponent={GrowTransition}
      sx={{ zIndex: 1600 }}
      maxWidth="xs"
    >
      <DialogTitle component={Stack} alignItems="flex-end" sx={{ p: 3 }}>
        <IconButton aria-label="Close" onClick={onClose} size="medium" color="secondary">
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ transition: 'height 0.3s' }}>
        <Box component="form" onSubmit={handleSubmitForm}>
          <FormControl sx={{ mt: 2, mb: 6 }} fullWidth>
            <InputLabel id="missing-data-select-property">{l.property}</InputLabel>
            <Select
              data-testid="missing-data-select-property"
              labelId="missing-data-select-property"
              value={selectedPropertyID}
              label={l.property}
              onChange={(e) => {
                setSelectedPropertyID(e.target.value);
              }}
            >
              {properties.map((property) => (
                <MenuItem key={`${property.id}-select`} value={property.id}>
                  {property.displayName}
                </MenuItem>
              )) }
            </Select>
          </FormControl>
          {properties.map((property) => property.id === selectedPropertyID && (
            <Fragment key={`${property.id}-form`}>
              {showPurchase && (
                <>
                  <Stack direction="row" gap={1} justifyContent="space-between">
                    <BoldTypography variant="body1">
                      {l.purchase}
                    </BoldTypography>
                    <InfoTooltip
                      arrow
                      isLight
                      title={(
                        <Typography variant="body2" sx={{ p: 1 }}>
                          {l['tooltip.purchase']}
                        </Typography>
                      )}
                      isOutlined
                      track={(value) => {
                        analytics.track('Tooltip Toggled', {
                          value,
                          tooltipName: 'Purchase Info Tooltip',
                        });
                      }}
                    />
                  </Stack>
                  <PurchaseData
                    useSubKey
                    errors={errors}
                    control={control}
                    watch={watch}
                    formSubKey={property.id}
                    revalidate={() => trigger()}
                    showDays
                    sx={{ mt: 5 }}
                  />
                </>
              )}
              {showMortgage && (
                <>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3}>
                    <Stack direction="row" gap={1}>
                      <BoldTypography variant="body1">
                        {l.loan}
                      </BoldTypography>
                      <InfoTooltip
                        arrow
                        isLight
                        title={(
                          <Typography variant="body2" sx={{ whiteSpace: 'pre-line', p: 1 }}>
                            {l['tooltip.loan']}
                          </Typography>
                        )}
                        isOutlined
                        track={(value) => {
                          analytics.track('Tooltip Toggled', {
                            value,
                            tooltipName: 'Loan Info Tooltip',
                          });
                        }}
                      />
                    </Stack>
                  </Stack>
                  <PropertyMortgageData
                    useSubKey
                    errors={errors}
                    control={control}
                    watch={watch}
                    revalidate={() => trigger()}
                    formSubKey={property.id}
                  />
                </>
              )}
            </Fragment>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" width="100%" justifyContent="space-between" gap={2}>
          <Button
            onClick={onClose}
            variant="outlined"
            fullWidth
            sx={{
              py: 3,
              color: theme.palette.text.primary,
              border: `1px solid ${theme.palette.text.disabled}`,
            }}
          >
            {l.cancel}
          </Button>
          <Button
            disabled={hasErrors}
            onClick={handleSubmitForm}
            fullWidth
            variant="contained"
            sx={{ py: 3 }}
          >
            {loading ? l.pleaseWait : l.save}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const ErrorCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} width={16} height={16} rx={8} fill="#FD646F" />
    <path
      // eslint-disable-next-line max-len
      d="M12.167 5.072l-.739-.739L8.5 7.261 5.572 4.333l-.739.739L7.761 8l-2.928 2.928.739.739L8.5 8.738l2.928 2.929.739-.739L9.239 8l2.928-2.928z"
      fill="#fff"
    />
  </svg>
);

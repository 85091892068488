import { useLocation, useNavigate } from 'react-router-dom';

import {
  usePermissions,
} from 'lib';
import {
  FallbackSpinner,
  Home, MenuItem, useLabels,
} from 'ui';
import {
  Stack,
} from '@mui/material';

const paths = {
  dashboard: '/referral-partners',
};

const menuIconSize = 18;

export const DrawerMenuItems = ({ onNavigate = () => { } }: { onNavigate: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  if (isLoadingPermissions) return <FallbackSpinner />;

  return permissions?.viewReferralPartner && (
    <Stack>
      <MenuItem
        icon={<Home height={menuIconSize} width={menuIconSize} />}
        label={l.referrals}
        onClick={() => handleNavigate(paths.dashboard)}
        isActive={location.pathname === paths.dashboard}
      />
    </Stack>
  );
};

export const ProductMenuItems = ({ onNavigate = () => { } }: { onNavigate: () => void }) => {
  const l = useLabels();
  const location = useLocation();
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();

  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    onNavigate();
    navigate(path);
  };

  if (isLoadingPermissions) return null;

  return permissions?.viewReferralPartner && (
    <MenuItem
      icon={<Home height={menuIconSize} width={menuIconSize} />}
      label={l.referrals}
      onClick={() => handleNavigate(paths.dashboard)}
      isActive={location.pathname === paths.dashboard}
    />
  );
};

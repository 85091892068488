import {
  axiosWithPayloadContext, config, createHeaders, useAuth,
} from 'lib';
import { useMutation } from '@tanstack/react-query';

export const useUpdateUser = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (
    { phoneNumber, smsConsent }: { phoneNumber: string, smsConsent: boolean },
  ) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/users`,
      method: 'PATCH',
      headers: createHeaders(token),
      data: { phoneNumber, smsConsent },
    });
  });
};

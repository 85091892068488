import { useState } from 'react';

import { ArrowToggleOpenBottom, formatNumberToCurrency } from 'ui';
import { MenuItem } from '@mui/material';

import { FilterMenu, FilterSelector } from './styled';

export const priceOptions = [
  50_000,
  100_000,
  150_000,
  200_000,
  250_000,
  300_000,
  350_000,
  400_000,
  450_000,
  500_000,
];

type PriceSelectorProps = {
  value: number
  onChange: (n: number) => void
  zeroLabel: string
  idPrefix: string
  isRelevantSelection: (n: number) => boolean
};

export const PriceSelector = ({
  value,
  onChange,
  zeroLabel,
  idPrefix,
  isRelevantSelection,
}: PriceSelectorProps) => {
  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const open = !!menu;
  const menuID = `${idPrefix}-price-selector-menu`;
  const buttonID = `${idPrefix}-price-selector-button`;

  const handleChange = (n: number) => {
    onChange(n);
    setMenu(null);
  };

  return (
    <>
      <FilterSelector
        sx={{ flexGrow: 1 }}
        className={open ? 'open' : ''}
        id={buttonID}
        aria-controls={open ? menuID : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={(e) => setMenu(e.currentTarget)}
        endIcon={<ArrowToggleOpenBottom className={open ? 'open' : ''} />}
      >
        {value === 0 ? zeroLabel : formatNumberToCurrency(value, 0, { notation: 'compact' })}
      </FilterSelector>
      <FilterMenu
        id="price-range-menu"
        MenuListProps={{
          'aria-labelledby': 'price-range-button',
        }}
        anchorEl={menu}
        open={open}
        onClose={() => setMenu(null)}
      >
        <MenuItem value={0} onClick={() => handleChange(0)}>
          {zeroLabel}
        </MenuItem>
        {priceOptions.map((price) => isRelevantSelection(price) && (
          <MenuItem value={price} key={`${idPrefix}-${price}`} onClick={() => handleChange(price)}>
            {formatNumberToCurrency(price, 0, { notation: 'compact' })}
          </MenuItem>
        ))}
      </FilterMenu>
    </>
  );
};

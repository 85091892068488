import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { signal } from '@preact/signals-react';

export const history = signal<string[]>([]);

export const useTrackHistory = () => {
  const location = useLocation();

  useEffect(() => {
    history.value = [...history.value, location.pathname];
  }, [location.pathname]);

  useEffect(() => () => {
    history.value = [];
  }, []);
};

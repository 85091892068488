import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  history, useAnalytics, useHideChat,
} from 'lib';
import { GiHouse } from 'react-icons/gi';
import {
  MdArrowBackIosNew,
} from 'react-icons/md';
import Carousel from 'react-multi-carousel';
import { toast } from 'react-toastify';
import {
  BoldTypography, FallbackSpinner,
  formatNumberToCurrency,
  ImageCarousel,
  InvertedButton,
  LightTypography,
  Spacer,
  useLabels,
} from 'ui';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { ListingDetails } from './details/listing-details';
import { CrimeDetails } from './CrimeDetails';
import { DesktopImageList } from './DesktopImageList';
import { InterestedPopup } from './InterestedPopup';
import { PhysicalDetails } from './PhysicalDetails';
import {
  AskingPriceContainer, CtaContainer,
  ListingDetailsContainer,
} from './styled';
import { useGetProperty, useGetRecommendedListings } from '../../../api/properties';
import { GoogleMap } from '../../../components/map/GoogleMap';
import { carouselResponsiveConfig } from '../carousel';
import { FavoriteButton } from '../FavoriteButton';
import { ListingCard } from '../ListingCard';
import { ListingSkeletons } from '../ListingSkeletons';
import { ShareButton } from '../ShareButton';

const RecommendedListings = () => {
  const l = useLabels();
  const theme = useTheme();
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const { propertyID } = useParams();

  const { data: listings, isLoading } = useGetRecommendedListings();

  const listingsToRender = (listings ?? []).filter((listing) => listing.id !== propertyID).slice(0, 3);

  if (!listingsToRender.length) return null;

  const renderedListings = isLowerThanMd ? (
    <Grid item xs={12}>
      <Carousel
        containerClass="properties-carousel-container"
        partialVisible
        arrows={false}
        responsive={carouselResponsiveConfig}
      >
        {listingsToRender.map((listing, i) => (
          <Box key={listing.id}>
            <ListingCard listingIndex={i} listingItem={listing} isRecommendedListing />
          </Box>
        ))}
      </Carousel>
    </Grid>
  ) : (
    listingsToRender.map((listing, i) => (
      <Grid item xs={12} md={4} key={listing.id}>
        <ListingCard listingItem={listing} listingIndex={i} isRecommendedListing />
      </Grid>
    ))
  );

  return (
    <Grid item container xs={12} spacing={4} rowGap={2} sx={{ '&&&': { px: { xs: 0, md: 10 } } }}>
      <Grid item xs={12}>
        <BoldTypography variant="h6">
          {l.recommendedListings}
        </BoldTypography>
      </Grid>
      {isLoading || !listings ? (
        <ListingSkeletons />
      ) : renderedListings}
    </Grid>
  );
};

export const DetailedListing = () => {
  const theme = useTheme();
  const analytics = useAnalytics();
  const l = useLabels();
  const navigate = useNavigate();
  const [interestedPopupOpen, setInterestedPopupOpen] = useState(false);
  const { propertyID } = useParams();
  const { data: property, isLoading, isError } = useGetProperty(propertyID);

  useHideChat(true);

  const handleBackButton = () => {
    if (history.value.length > 1) {
      window.history.back();
    } else {
      navigate('/marketplace');
    }
  };

  useEffect(() => {
    if (property) {
      analytics.register({
        listingId: property.id,
        propertyId: property.propertyID,
      });
    }
  }, [property]);

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  if (isLoading) return <FallbackSpinner />;

  if (!property || isError) {
    toast.error(l.listingNotFound);
    navigate('/marketplace');

    return null;
  }

  let pictures = null;

  if (property.pictures?.length) {
    pictures = (
      isLowerThanMd
        ? <ImageCarousel images={property.pictures} maxHeight="50vh" />
        : <DesktopImageList images={property.pictures} address={property.address.fullAddress} />
    );
  }

  return (
    <Container
      sx={{
        pt: 5, px: 3, flexGrow: 1,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Button
          variant="text"
          sx={{ px: 2, ml: -2 }}
          onClick={handleBackButton}
          size="medium"
          startIcon={<MdArrowBackIosNew color={theme.palette.secondary.main} />}
        >
          {l.back}
        </Button>
        <Stack direction="row" alignItems="center" gap={2}>
          <FavoriteButton
            favoriteId={property.favoriteId}
            marketplacePropertyId={property.id}
            showAsButton
          />
          <ShareButton listingID={property.id} showAsButton />
        </Stack>
      </Stack>
      <Box position="relative">
        {property.pictures?.length ? pictures : (
          <Stack alignItems="center" mb={{ xs: 3, md: 5 }}>
            <GiHouse size={200} />
          </Stack>
        )}
      </Box>
      <Grid
        container
        spacing={{ xs: 6, md: 4, xl: 0 }}
        justifyContent={{ xs: 'center', md: 'space-around' }}
        alignItems={{ xs: 'center', md: 'flex-start' }}
        direction={isLowerThanMd ? 'column-reverse' : 'row'}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            '&&&': {
              maxWidth: { xs: 'unset', md: theme.breakpoints.values.sm + 48 },
              width: { xs: '100%', md: 'unset' },
            },
          }}
        >
          <Stack gap={6}>
            <ListingDetailsContainer>
              <Typography variant="h6">
                {property.address.fullAddress}
              </Typography>
              <Spacer spacing={2} />
              <PhysicalDetails property={property} />
              <Typography variant="h6" sx={{ my: 4 }}>
                {l['listing.aboutTheProperty']}
              </Typography>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {property.description}
              </Typography>
            </ListingDetailsContainer>
            <ListingDetails listing={property} />
            {property.address.lat && property.address.lon ? (
              <ListingDetailsContainer>
                <BoldTypography variant="h6" sx={{ mb: 3 }}>
                  {l.location}
                </BoldTypography>
                <GoogleMap mapName="Listing Map" location={{ lat: property.address.lat, lng: property.address.lon }} />
              </ListingDetailsContainer>
            ) : null}
            <CrimeDetails property={property} />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            '&&&': {
              maxWidth: { xs: 'unset', md: 375 },
              width: { xs: '100%', md: 'unset' },
              position: { xs: 'static', md: 'sticky' },
              top: theme.spacing(2),
            },
          }}
        >
          <ListingDetailsContainer>
            <AskingPriceContainer alignItems="center" sx={{ mb: 5 }}>
              <BoldTypography variant="body2" color="primary.contrastText" sx={{ mb: 2 }}>
                {l.askingPrice}
              </BoldTypography>
              <BoldTypography variant="h4" color="primary.contrastText">
                {formatNumberToCurrency(property.askingPrice, 0)}
              </BoldTypography>
            </AskingPriceContainer>
            <CtaContainer>
              <InvertedButton
                variant="contained"
                fullWidth
                onClick={() => {
                  setInterestedPopupOpen(true);

                  analytics.track('Button Clicked', {
                    buttonName: 'I Am Interested',
                    propertyId: property.id,
                    propertyAddress: property.address.fullAddress,
                  });
                }}
              >
                {l.iAmInterested}
              </InvertedButton>
            </CtaContainer>
          </ListingDetailsContainer>
        </Grid>
      </Grid>
      <Spacer spacing={14} />
      <RecommendedListings />
      <Stack px={10} my={7}>
        <LightTypography
          variant="caption"
          sx={{
            fontSize: '0.8125rem',
            textAlign: 'center',
          }}
        >
          {l['listing.disclaimer']}
        </LightTypography>
      </Stack>
      <InterestedPopup
        propertyID={property.id}
        open={interestedPopupOpen}
        onClose={() => {
          setInterestedPopupOpen(false);
        }}
      />
    </Container>
  );
};

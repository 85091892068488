import { ComponentPropsWithRef } from 'react';

import { Chip, hexToRGBA } from 'ui';
import {
  Autocomplete as MuiAutocomplete,
  Button, Checkbox, Menu, Paper,
  styled,
} from '@mui/material';

export const PredefinedFilterChip = styled(Chip)(({ theme }) => ({
  borderRadius: 4,
  width: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    '&:not(.active)': {
      backgroundColor: theme.palette.grey.A100,
    },
  },
  '&:not(.active)': {
    backgroundColor: hexToRGBA(theme.palette.grey.A100, 0.3),
    color: theme.palette.text.primary,
  },
}));

export const FilterSelector = styled(Button)(({ theme }) => ({
  background: 'transparent',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.grey.A100}`,
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  padding: theme.spacing(2, 4),
  paddingRight: theme.spacing(5),
  height: 40,
  '&.open': {
    border: `1px solid ${theme.palette.text.primary}`,
  },
  '& .MuiButton-endIcon > svg': {
    fill: theme.palette.text.primary,
  },
  '&:hover': {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& .MuiButton-endIcon > svg': {
      fill: theme.palette.primary.contrastText,
    },
  },
}));

export const FilterMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
  },
}));

export const BedsUseExactMatchCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey.A100,
}));

export const BedsBathsFilterChip = styled(Chip)(({ theme }) => ({
  borderRadius: 4,
  background: 'transparent',
  transition: 'background-color 0.2s ease-in-out',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    '& .MuiChip-label': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  border: `1px solid ${theme.palette.grey.A100}`,
  color: theme.palette.text.primary,
  '&:hover, &.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '&.Mui-expanded .MuiAutocomplete-popupIndicator': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAutocomplete-endAdornment': {
    height: '100%',
    display: 'flex',
    paddingRight: theme.spacing(2),
    alignItems: 'center',
  },
  '&&& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.grey.A100} !important`,
  },
  '&&&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
  '& .MuiChip-root.Mui-focusVisible': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
// This is sadly necessary in order to inject the original value type to the un-styled component.
})) as <T = unknown, Multiple extends boolean = false>(
  props: ComponentPropsWithRef<typeof MuiAutocomplete<T, Multiple>>
) => ReturnType<typeof MuiAutocomplete>;

// create slow animation that turns the display to none at the end
const animation = `
  @keyframes fadeOut {
    0% {
      display: block;
    }
    99% {
      display: block;
    }
    100% {
      display: none;
    }
  }
`;

export const CategoryListingsContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 100,
  maxHeight: '100vh',
  height: 'calc(100vh - 155px)',
  width: '100%',
  overflowY: 'scroll',
  background: theme.palette.background.paper,
  transition: 'all 0.3s ease-in-out !important',
  top: 0,
  '&:not(.open)': {
    animation: `${animation} 0.3s ease-in-out`,
  },
  [theme.breakpoints.down('lg')]: {
    padding: `0 ${theme.spacing(2)}`,
    maxHeight: 'calc(100vh - 52px)',
    height: 'calc(100vh - 200px)',
  },
  '&.scrolled': {
    top: -155,
    height: '100vh',
    [theme.breakpoints.down('lg')]: {
      top: -165,
      height: 'calc(100vh - 52px)',
    },
    [theme.breakpoints.down('md')]: {
      top: -300,
    },
  },
}));

export const CategoryListingsHeader = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'sticky',
  top: -1,
  zIndex: 101,
  background: theme.palette.background.paper,
}));

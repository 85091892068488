import {
  GetMarketplacePMPreferencesQuery, queryGraphQL, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { convertPMPreferences } from './converters';
import { PMPreferences } from './types';
import { QueryKey } from '../../types/enums';
import { getPMPreferencesQuery } from '../graphql/queries';

export const useGetPMPreferences = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.PM_PREFERENCES, pm], async (): Promise<PMPreferences | null> => {
    if (!pm) return null;

    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getPMPreferencesQuery,
      variables: { pm },
      authToken: token,
    }) as GraphQLResult<GetMarketplacePMPreferencesQuery>;

    if (!res.data?.getMarketplacePMPreferences) {
      return null;
    }

    return convertPMPreferences(res.data.getMarketplacePMPreferences);
  });
};

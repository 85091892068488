import { useState } from 'react';

import { useAuth, useImportUsers, UserImportRequest } from 'lib';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  BoldTypography,
  useLabels,
} from 'ui';
import {
  Box, Button,
  Dialog,
  DialogContent,
  keyframes, Stack, styled, Typography,
  useMediaQuery, useTheme,
} from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export type UserImport = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export type ValidateUserImport = {
  isValid: boolean;
  message: string;
  user: UserImport
};

export const FooterActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
  bottom: '-1px',
  background: theme.palette.background.paper,
  zIndex: '1600',
  '&.sticky': {
    position: 'sticky',
    animation: `${fadeIn} 0.3s ease-in-out`,
  },
}));

export const ImportProspectsDialog = ({ open, closeDialog }:
{ open: boolean, closeDialog: () => void, }) => {
  const theme = useTheme();
  const l = useLabels();
  const { user } = useAuth();
  const { mutateAsync } = useImportUsers();

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const [prospects, setProspects] = useState<ValidateUserImport[]>([]);

  const handleDownload = () => {
    const csvContent = 'email,firstName,lastName,phoneNumber';
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.csv';
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const verifyUser = (importUser: UserImport): ValidateUserImport => {
    if (importUser.email === '') {
      return {
        message: 'email is empty',
        isValid: false,
        user: importUser,
      };
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(importUser.email);

    if (!isEmailValid) {
      return {
        message: 'email is not valid',
        isValid: false,
        user: importUser,
      };
    }

    return {
      isValid: true,
      message: '',
      user: importUser,
    };
  };

  const uploadFiles = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target?.result as string;
        const parsedData = Papa.parse<UserImport>(csvData, { header: true });
        const usersToImport: UserImport[] = parsedData.data;
        const verifyUsers = usersToImport.map((u) => verifyUser(u));
        setProspects(verifyUsers);
      };
      reader.readAsText(file);
    }
  };

  const save = async () => {
    const prospectsToImport = prospects.filter((v) => v.isValid).map((p) => ({
      email: p.user.email,
      firstName: p.user.firstName,
      lastName: p.user.lastName,
      phoneNumber: p.user.phoneNumber,
      propertyManager: user?.pm ?? '',
      userType: 'prospect',
    })) as UserImportRequest[];

    mutateAsync({ users: prospectsToImport })
      .then(() => {
        toast.success(l['pm-dashboard.prospect.messageSuccess']);
      })
      .catch(() => {
        toast.error(l['error.unknownError']);
      });

    closeDialog();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: uploadFiles, multiple: false });

  return (
    <Dialog
      fullScreen={isLowerThanMd}
      open={open}
      maxWidth="sm"
      sx={{ zIndex: 1600 }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box
          p={4}
          position="relative"
          flexGrow="1"
          minHeight={isLowerThanMd ? '100%' : '400px'}
          width={isLowerThanMd ? undefined : theme.breakpoints.values.sm}
        >
          <Stack spacing={4}>
            <Stack
              justifyContent="flex-start"
              alignItems="left"
              spacing={1}
            >
              <Stack>
                <BoldTypography>
                  {l['pm-dashboard.prospects.inviteProspects']}
                </BoldTypography>
              </Stack>
              <Stack>
                <Typography>
                  {l['pm-dashboard.prospects.inviteDescription']}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                pt={4}
                justifyContent="flex-start"
              >
                <Button
                  component="a"
                  sx={{ padding: 0, color: 'blue' }}
                  onClick={handleDownload}
                >
                  {l['pm-dashboard.prospects.downloadTemplate']}
                </Button>
              </Stack>
            </Stack>
            <Stack
              height="150px"
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Stack
                width="100%"
                height="100%"
                {...getRootProps()}
                sx={{
                  p: 2,
                  border: '2px dashed',
                  borderRadius: 1,
                  borderColor: isDragActive ? 'primary.main' : 'text.disabled',
                  textAlign: 'center',
                  cursor: 'pointer',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <input {...getInputProps()} />
                  <Typography>
                    {l['pm-dashboard.prospects.dragAndDrop']}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Stack direction="column">
                {prospects.length > 0 && prospects.filter((v) => !v.isValid).length === 0 && (
                  <Stack>
                    {l['pm-dashboard.prospects.totalValidProspectsPart1']}
                    {prospects.filter((v) => v.isValid).length}
                    {l['pm-dashboard.prospects.totalValidProspectsPart2']}
                  </Stack>
                )}
                {prospects.filter((v) => !v.isValid).length > 0 && (
                  <>
                    <Stack>
                      {l['pm-dashboard.prospects.invalidProspectsPart1']}
                      {prospects.filter((v) => !v.isValid).length}
                      {l['pm-dashboard.prospects.invalidProspectsPart2']}
                    </Stack>
                    {prospects.filter((v) => !v.isValid).map((inValidProspect) => (
                      <Stack direction="row" key={inValidProspect.user.email}>
                        <Stack>
                          {' '}
                          Email :
                          {inValidProspect.user.email}
                        </Stack>
                        <Stack pl={4}>{inValidProspect.message}</Stack>
                      </Stack>
                    ))}
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <FooterActions className="sticky">
          <Button
            onClick={closeDialog}
            variant="text"
            sx={{ py: 3, color: theme.palette.text.primary }}
          >
            {l.cancel}
          </Button>
          <Button
            variant="contained"
            disabled={prospects.filter((v) => !v.isValid).length > 0 || prospects.length === 0}
            sx={{ py: 3 }}
            onClick={save}
          >
            {l.import}
          </Button>
        </FooterActions>
      </DialogContent>
    </Dialog>
  );
};

import { FieldPath, FieldPathValue } from 'react-hook-form';

import { PropertyProformaData } from './types';

const FORM_KEY = 'blanket-proforma-form';

export type StorageData = Record<string, Partial<{
  [K in FieldPath<PropertyProformaData>]: FieldPathValue<PropertyProformaData, K>
}>>;

export const saveFieldToSessionStorage = (data: StorageData) => {
  const flatData = loadFieldsFromSessionStorage() ?? {};

  Object.entries(data).forEach(([propertyID, formData]) => {
    flatData[propertyID] = { ...flatData[propertyID], ...formData };
  });

  sessionStorage.setItem(FORM_KEY, JSON.stringify(flatData));
};

export const loadFieldsFromSessionStorage = () => {
  const data = sessionStorage.getItem(FORM_KEY);

  if (!data) return null;

  return JSON.parse(data) as StorageData;
};

import {
  ReactNode,
} from 'react';

import { Box, useTheme } from '@mui/material';

import { ReactComponent as Logo } from '../icons/svg/logo.svg';

import styles from './spinner.module.css';

export const SpinnerIcon = ({ size }: { size: number }) => {
  const theme = useTheme();

  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={`
            M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96
            48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z
          `}
        fill={theme.palette.grey[300]}
      />
      <path
        className={styles['spinner-path']}
        d={`
            M93 48C94.6569 48 96.0097 49.3468 95.9078 51.0005C94.3591 76.1136 73.5033 96 48 96C21.489 96 0 74.511 0 48C0
            22.4967 19.8864 1.64094 44.9995 0.0922454C46.6532 -0.00973724 48 1.34315 48 3C48 4.65685 46.6523 5.98886 44.9996
            6.10552C23.2039 7.64396 6 25.8128 6 48C6 71.196 24.804 90 48 90C70.1872 90 88.356 72.7961 89.8945 51.0004C90.0111
            49.3477 91.3431 48 93 48Z
          `}
        fill={theme.palette.primary.dark}
      />
    </svg>
  );
};

export const Spinner = ({ size = 125 }: { size?: number }) => (
  <div className={styles['spinner-without-logo-container']}>
    <SpinnerIcon size={size} />
  </div>
);

export const SpinnerWithLogo = ({ size = 96 }: { size?: number }) => {
  const theme = useTheme();
  const logoSize = Math.abs(size * 0.4);

  return (
    <Box position="relative">
      <Box className={styles['spinner-logo-container']}><Logo height={logoSize} width={logoSize} /></Box>
      <svg width={size} height={size} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={`
            M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96
            48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z
          `}
          fill={theme.palette.grey[300]}
        />
        <path
          className={styles['spinner-path']}
          d={`
            M93 48C94.6569 48 96.0097 49.3468 95.9078 51.0005C94.3591 76.1136 73.5033 96 48 96C21.489 96 0 74.511 0 48C0
            22.4967 19.8864 1.64094 44.9995 0.0922454C46.6532 -0.00973724 48 1.34315 48 3C48 4.65685 46.6523 5.98886 44.9996
            6.10552C23.2039 7.64396 6 25.8128 6 48C6 71.196 24.804 90 48 90C70.1872 90 88.356 72.7961 89.8945 51.0004C90.0111
            49.3477 91.3431 48 93 48Z
          `}
          fill={theme.palette.primary.dark}
        />
      </svg>
    </Box>
  );
};

export const FallbackSpinner = ({ children = null }: { children?: ReactNode }) => (
  <Box
    sx={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <SpinnerWithLogo />
    {children}
  </Box>
);

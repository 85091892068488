import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { SellerLeadsWorkflowStatus, useAnalytics } from 'lib';
import { MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  FallbackSpinner, formatNumberToCurrency, formatPotentialTodayDate, InvertedButton,
  LightTypography, ListBoard, RowGroup, RowGroupConfig, Spinner, useLabels,
} from 'ui';
import { Stack, Typography, useTheme } from '@mui/material';
import { signal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';

import { useMutatePhoneCall } from '../../../api/calls';
import { useListSellerLeads, useMutateSellerLead } from '../../../api/workflows';
import { SellerLead } from '../../../api/workflows/converters';
import { QueryKey } from '../../../types/enums';
import { Contact } from '../components/Contact';
import { LeadReveal, LeadType } from '../components/LeadReveal';
import { StatusChanger } from '../components/StatusChanger';
import { markListItemAsRevealed, sortContacts } from '../utils';
import { useSellerLeadStatuses } from '../WorkflowStatus';

const rowGroupsSignal = signal<RowGroup<SellerLead>[]>([]);

export const SellerLeadsList = ({ orderedStatuses }: { orderedStatuses: SellerLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const theme = useTheme();
  const { getStatus } = useSellerLeadStatuses();
  const { data, isLoading } = useListSellerLeads();

  useEffect(() => {
    if (!data) return;

    const columns: ColumnDef<SellerLead, string>[] = [
      {
        header: l.status,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <SellerLeadStatusChanger item={info.row.original} />
        ),
        meta: { sticky: true, blurred: (row) => !row.original.revealed },
      },
      {
        header: l.name,
        id: l.name,
        accessorKey: 'sellerContact.email',
        sortingFn: (a, b) => sortContacts(a.original.sellerContact, b.original.sellerContact),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <Contact contact={info.row.original.sellerContact} />,
        meta: { sticky: true, blurred: (row) => !row.original.revealed },
      },
      {
        header: l.property,
        id: l.property,
        accessorKey: 'name',
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => {
          const lead = info.row.original;
          const firstCommaIndex = lead.name.indexOf(',');
          const name = firstCommaIndex > -1 ? lead.name.slice(0, firstCommaIndex) : lead.name;
          const location = firstCommaIndex > -1 ? lead.name.slice(firstCommaIndex + 1) : '';

          return (
            <Stack gap={1}>
              {lead.propertyId && lead.isPropertyVisible ? (
                <Link
                  to={`/marketplace/${lead.propertyId}`}
                  target="_blank"
                  style={{ color: theme.palette.info.main, textDecoration: 'none' }}
                >
                  <LightTypography variant="body1" sx={{ textWrap: 'nowrap' }}>
                    {name}
                  </LightTypography>
                </Link>
              ) : name}
              <LightTypography variant="body2" color="secondary" sx={{ textWrap: 'nowrap' }}>
                {location}
              </LightTypography>
            </Stack>
          );
        },
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        header: l.askingPrice,
        id: l.askingPrice,
        accessorKey: 'askingPrice',
        enableSorting: true,
        minSize: 125,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.askingPrice ? formatNumberToCurrency(info.row.original.askingPrice, 0) : l.toDiscuss}
          </Typography>
        ),
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        id: l.updated,
        header: l.updated,
        accessorKey: 'updatedTime',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => formatPotentialTodayDate(info.row.original.updatedTime),
        meta: { shownAsText: true, blurred: (row) => !row.original.revealed },
        sortingFn: (a, b) => dayjs(a.original.updatedTime).diff(dayjs(b.original.updatedTime)),
      },
      {
        header: ' ',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <SellerLeadActions lead={info.row.original} />,
      },
    ];

    const leads = data?.pages.flatMap((page) => page.leads) ?? [];

    const groups: RowGroupConfig<SellerLead>[] = [];

    orderedStatuses.forEach((status) => {
      const statusConfig = getStatus(status);

      groups.push({
        rows: leads?.filter((lead) => lead.status === status) ?? [],
        columnDefs: columns,
        id: status,
        color: statusConfig.color,
        icon: statusConfig.smallIcon,
        title: statusConfig.displayValue,
        tooltip: statusConfig.tooltip,
      });
    });

    rowGroupsSignal.value = groups;
  }, [data, rowGroupsSignal]);

  if (isLoading) return <FallbackSpinner />;

  return (
    <ListBoard rowGroups={rowGroupsSignal.value} columnSort={[{ id: l.updated, desc: true }]} />
  );
};

const SellerLeadActions = ({ lead }: { lead: SellerLead, }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { mutateAsync: sendPhoneCallRequest, isLoading: isCalling } = useMutatePhoneCall();

  const call = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Call',
      called: 'Seller',
      id: lead.id,
    });

    if (lead.sellerContact?.phoneNumber) {
      toast.error(l['error.missingContactPhone']);
      return;
    }

    try {
      await sendPhoneCallRequest(lead.sellerContact?.id!);
      toast.success(l['leads.call.success']);
    } catch (err) {
      console.error(err);
      toast.error(l['error.unknownError']);
    }
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  return !lead.revealed ? (
    <LeadReveal
      id={lead.id}
      revealed={!!lead.revealed}
      leadType={LeadType.SELLER}
      onReveal={() => {
        markListItemAsRevealed(rowGroupsSignal, lead);
      }}
    />
  ) : lead.sellerContact?.phoneNumber && (
    <InvertedButton
      disabled={isCalling}
      variant="contained"
      size="small"
      onClick={call}
      startIcon={isCalling ? null : <MdPhone />}
    >
      {isCalling ? spinner : l.owner}
    </InvertedButton>
  );
};

const SellerLeadStatusChanger = ({ item }: { item: SellerLead }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useSellerLeadStatuses();
  const queryClient = useQueryClient();

  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateSellerLead();

  const handleStatusChange = async (status: SellerLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Seller Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: item.id,
        status,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.SELLER_LEADS]);
      setInvalidatingQueries(false);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  const isLoading = invalidatingQueries || isUpdatingStatus;

  return (
    <StatusChanger
      id={item.id}
      status={item.status}
      statuses={statuses}
      changeStatus={handleStatusChange}
      isLoading={isLoading}
    />
  );
};

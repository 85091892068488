/* eslint-disable no-console */
import {
  Analytics as AnalyticsType, TrackFunction, TrackingProperties,
} from 'lib';
import UAParser from 'ua-parser-js';

type Options = {
  devMode?: boolean
};

declare global {
  interface Window { analytics: any; }
}

const setUTMParamsToSessionStorage = () => {
  const lowerCase = window.location.href.toLowerCase();
  if (lowerCase.includes('utm')) {
    const url = new URL(lowerCase);
    const urlParamsObject: Record<string, string> = {};
    url.searchParams.forEach((val, key) => {
      if (key.startsWith('utm_')) {
        urlParamsObject[key] = url.searchParams.get(key) || '';
        sessionStorage.setItem(key, val);
      }
    });
  }
};

const skipAnalytics = (): boolean => {
  if (!window.analytics) {
    return true;
  }

  const userIdentifier: string = window.analytics.user?.().id() ?? '';
  if (userIdentifier.toLowerCase().endsWith('blankethomes.com')
    || userIdentifier.toLowerCase().endsWith('blips.us')) {
    return true;
  }

  return false;
};

/*
  * Util class we will use to wrap the 3d party and expose our analytics functions across our app,
  * currently we using Segment, but we will easy could switch it to alternatives.
*/
class Analytics implements AnalyticsType {
  state: any;

  deviceData: any;

  devMode: boolean;

  constructor({ devMode = false }: Options) {
    this.state = {};
    this.deviceData = {};
    this.track = this.track.bind(this);
    this.devMode = devMode;
  }

  enrich() {
    const res = UAParser(navigator.userAgent);

    this.deviceData = {
      deviceType: res.device.type,
      deviceManufacturer: res.device.vendor,
      deviceModel: res.device.model,
      osName: res.os.name,
      osVersion: res.os.version,
      userAgent: navigator.userAgent,
      isMobile: res.device.type === 'mobile',
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
    };

    if (this.devMode) {
      console.log('device data', JSON.stringify(this.deviceData, null, 2));
    }
  }

  /*
   * we have to load the token itself in the index.html because currently we working with Segment
    * @param token the key received from the service
  */
  // eslint-disable-next-line class-methods-use-this
  init() {
    setUTMParamsToSessionStorage();
    if (sessionStorage.getItem('utm_source')) {
      this.register({
        utm_source: sessionStorage.getItem('utm_source'),
        utm_content: sessionStorage.getItem('utm_content'),
        utm_campaign: sessionStorage.getItem('utm_campaign'),
        utm_medium: sessionStorage.getItem('utm_medium'),
      });
    }
  }

  /*
    * @param event - event name -> should be enum
    * @param properties - all kind of info you'd want to pass to the analytics
  */
  track: TrackFunction = (event, properties) => {
    const props = { ...this.state, ...this.deviceData, ...properties };

    if (skipAnalytics()) return;

    window.analytics?.track(event, props);

    if (this.devMode) {
      console.log('track', event, JSON.stringify(props, null, 2));
    }
  };

  /*
    * @param userId - our internal userId
    * @param properties - email, names, etc...
  */
  // eslint-disable-next-line class-methods-use-this
  identify(userId: string, properties: TrackingProperties): void {
    if (skipAnalytics()) return;

    const props = {
      ...this.state,
      ...properties,
    };

    if (userId) {
      window.analytics?.identify(userId, props);
    } else {
      window.analytics?.identify(props);
    }

    if (this.devMode) {
      console.log('identify', userId, JSON.stringify(props, null, 2));
    }
  }

  /**
   *
   * @param path - path of the page
   * @param properties - all kind of info you'd want to pass to the analytics
   */
  // eslint-disable-next-line class-methods-use-this
  async page() {
    if (skipAnalytics()) return;
    window.analytics?.page(undefined, undefined, { ...this.state, ...this.deviceData });
  }

  /**
     * Register a set of super properties, which are included with all events.
     * This will overwrite previous super property values.
     *
     * inspiration from mixpanel SDK.
     */
  register(properties: TrackingProperties): void {
    // we can expand this to localStorage etc.. I don't think its really necessary right now
    this.state = {
      ...this.state,
      ...properties,
    };

    if (this.devMode) {
      console.log('register', JSON.stringify(this.state, null, 2));
    }
  }
}

export const analytics = new Analytics({
  devMode: process.env.NODE_ENV === 'development',
});

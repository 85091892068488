import {
  useCallback, useEffect, useState,
} from 'react';

import { FieldPath, useForm } from 'react-hook-form';
import { BsArrowRightCircle } from 'react-icons/bs';
import {
  FallbackSpinner,
  SemiBoldTypography, useLabels,
} from 'ui';
import {
  IconButton, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { AskingPrice } from './AskingPrice';
import { CapRate } from './CapRate';
import { CashFlow } from './CashFlow';
import { EditableMetrics } from './EditableMetrics';
import { ExpectedRent } from './ExpectedRent';
import { Expenses } from './Expenses';
import { HomePrice } from './HomePrice';
import { Income } from './Income';
import { LoanPayments } from './LoanPayments';
import { OperatingIncome } from './OperatingIncome';
import { DetailRow } from './Row';
import { loadFieldsFromSessionStorage } from './storage';
import {
  createInitialData, Form, MetricRelatedExpense,
  metricRelatedExpenses, PropertyProformaData, PropertyProformaFormData,
} from './types';
import { useProjections } from './useProjections';
import { useGetPMPreferences } from '../../api/pm-preferences';
import { Property } from '../../types';

const desktopTableWidth = 1400;
const mobileTableWidth = 700;

const loadSessionFormValues = (form: Form) => {
  const fields = loadFieldsFromSessionStorage();

  if (fields) {
    Object.entries(fields).forEach(([propertyID, values]) => {
      const rent = values['income.rent'];
      const skipMetricRelatedExpenses = !!rent;

      if (rent) {
        metricRelatedExpenses.forEach((key) => {
          const metricValue = values[`metrics.${key}`] ?? form.getValues(`${propertyID}.metrics.${key}`);
          const expenseValue = rent * (metricValue / 100);
          form.setValue(`${propertyID}.expenses.${key}`, expenseValue);
        });
      }

      Object.entries(values).forEach(([key, value]) => {
        if (skipMetricRelatedExpenses && metricRelatedExpenses.includes(key as MetricRelatedExpense)) {
          return;
        }

        form.setValue(`${propertyID}.${key as FieldPath<PropertyProformaData>}`, value);
      });
    });
  }
};

export const PropertyProforma = ({ properties }: { properties: Property[] }) => {
  const l = useLabels();
  const theme = useTheme();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [scrollButtonClicked, setScrollButtonClicked] = useState(false);
  const [width, setWidth] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data: pmPreferences, isLoading: pmPreferencesLoading } = useGetPMPreferences();

  const tableWidth = isMobile ? mobileTableWidth : desktopTableWidth;

  const form = useForm<PropertyProformaFormData>({
    defaultValues: createInitialData(properties),
  });

  const { years } = useProjections(form);

  const ref = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setContainer(node);
      setWidth(node.offsetWidth);
    }
  }, []);

  const handleClickArrow = () => {
    setScrollButtonClicked(true);
    container?.scrollBy({
      left: tableWidth,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (container) {
        setWidth(container.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [container]);

  useEffect(() => {
    loadSessionFormValues(form);
  }, [form]);

  useEffect(() => {
    if (pmPreferences) {
      const values = form.getValues();

      Object.keys(values).forEach((propertyID) => {
        let percent = pmPreferences.managementFee;
        let dollarValue = pmPreferences.managementFee;

        if (pmPreferences.isManagementFeeDollars) {
          const { rent } = values[propertyID].income;

          percent = (percent / rent) * 100;
        } else {
          dollarValue = (dollarValue / 100) * values[propertyID].income.rent;
        }

        const storage = loadFieldsFromSessionStorage();
        const property = storage?.[propertyID];

        if (property && ('metrics.managementFee' in property || 'expenses.managementFee' in property)) {
          return;
        }

        form.setValue(`${propertyID}.metrics.managementFee`, percent);
        form.setValue(`${propertyID}.expenses.managementFee`, dollarValue);
      });
    }
  }, [pmPreferences, form]);

  if (pmPreferencesLoading) {
    return <FallbackSpinner />;
  }

  return (
    <>
      <Stack>
        <EditableMetrics form={form} properties={properties} />
      </Stack>
      <Stack pb={3} position="relative">
        <Stack
          ref={ref}
          sx={{
            overflowX: 'scroll',
            overflowY: 'hidden',
            maxWidth: '100%',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '6px',
          }}
        >
          {width < tableWidth && !scrollButtonClicked && (
            <Stack
              sx={{
                position: 'absolute',
                top: theme.spacing(9),
                right: theme.spacing(4),
                bottom: 0,
              }}
            >
              <IconButton
                onClick={handleClickArrow}
                sx={{
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                  background: `${theme.palette.background.paper} !important`,
                }}
              >
                <BsArrowRightCircle />
              </IconButton>
            </Stack>
          )}
          <Stack minWidth={tableWidth}>
            <DetailRow
              rowID="year-projections"
              bottomBorder={false}
              columns={[
                {},
                ...years.map((year) => ({
                  id: `year-${year}`,
                  content: (
                    <SemiBoldTypography variant="body1">
                      {`${year} ${l.year}`}
                    </SemiBoldTypography>
                  ),
                })),
              ]}
            />
            <HomePrice form={form} />
            <AskingPrice form={form} />
            <Income form={form} />
            <ExpectedRent form={form} />
            <Expenses form={form} />
            <OperatingIncome form={form} />
            <CapRate form={form} />
            <LoanPayments form={form} />
            <CashFlow form={form} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

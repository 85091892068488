import { ComponentPropsWithRef } from 'react';

import { Select, styled } from '@mui/material';

export const IconSelector = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&&&': {
    minWidth: '0 !important',
    width: 40,
    maxWidth: '40px !important',
    p: '0 !important',
    '& .MuiSelect-select': {
      minWidth: '0 !important',
    },
  },
  // This is sadly necessary in order to inject the original value type to the un-styled component.
})) as unknown as <T = unknown>(props: ComponentPropsWithRef<typeof Select<T>>) => ReturnType<typeof Select>;

import { useEffect } from 'react';

export const useEnableScroll = (enable: boolean) => {
  useEffect(() => {
    document.body.style.overflow = enable ? 'auto' : 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [enable]);
};

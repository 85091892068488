import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useAnalytics } from 'lib';
import { Controller, FieldErrors } from 'react-hook-form';
import {
  BoldTypography, ControlledFormattedNumberField, formatNumberToCurrency, useLabels,
} from 'ui';
import {
  Box,
  Divider, Stack, TextField, Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { PropertyFormValues, PurchaseDataProps } from './types';

const dateFormat = 'MM/DD/YYYY';

export const PurchaseData = ({
  control, watch, sx, revalidate, showDays = false, formSubKey = '', ...rest
}: PurchaseDataProps) => {
  const l = useLabels();
  const analytics = useAnalytics();

  const formPrefix = formSubKey ? `${formSubKey}.` : '';

  const [
    purchaseDate,
    purchaseAmount,
    purchaseClosingCost,
    purchaseHomeImprovementCost,
  ] = watch([
    `${formPrefix}purchase.date`,
    `${formPrefix}purchase.amount`,
    `${formPrefix}purchase.closingCost`,
    `${formPrefix}purchase.homeImprovementCost`,
  ]);

  const [homeImprovementsFocused, setHomeImprovementsFocused] = useState(false);
  const homeImprovementsLabel = useMemo(() => {
    if (homeImprovementsFocused) {
      return l.homeImprovements;
    }

    return purchaseHomeImprovementCost ? l.homeImprovements : l.homeImprovementsAbbr;
  }, [purchaseHomeImprovementCost, homeImprovementsFocused]);

  const validatePurchaseDate = (date: any) => {
    if (purchaseAmount) {
      return (date && dayjs(date, dateFormat).isValid()) || l.errorFieldIsInvalid;
    }

    return (date === null || dayjs(date, dateFormat).isValid()) || l.errorFieldIsInvalid;
  };

  const validatePurchaseAmount = (amount: number) => {
    if (purchaseDate) {
      return (purchaseDate && dayjs(purchaseDate, dateFormat).isValid() && amount > 0) || l.errorFieldIsInvalid;
    }

    return true;
  };

  let errors: FieldErrors<PropertyFormValues> = {};
  if (rest.useSubKey) {
    errors = rest.errors[formSubKey] ?? {};
  } else {
    errors = rest.errors;
  }

  useEffect(() => {
    revalidate();
  }, [purchaseDate, purchaseAmount]);

  return (
    <Stack spacing={0} sx={{ mt: 5, ...sx }} gap={4} width="100%">
      <Stack direction="row" justifyContent="space-between" gap={3}>
        <ControlledFormattedNumberField
          error={!!errors.purchase?.amount}
          helperText={errors.purchase?.amount?.message}
          name={`${formPrefix}purchase.amount`}
          label={l.purchasePrice}
          control={control}
          rules={{
            required: !!purchaseDate && l.errorFieldIsRequired,
            validate: validatePurchaseAmount,
          }}
          onBlur={() => {
            analytics.track('Input Changed', {
              inputName: 'Purchase Price',
              value: purchaseAmount,
            });
          }}
          fullWidth
        />
        <Controller
          name={`${formPrefix}purchase.date`}
          control={control}
          rules={{
            required: !!purchaseAmount && l.errorFieldIsRequired,
            validate: validatePurchaseDate,
          }}
          render={({ field }) => (
            <DesktopDatePicker
              disableFuture
              inputFormat={dateFormat}
              views={showDays ? undefined : ['year', 'month']}
              {...field}
              onChange={(...args) => {
                field.onChange?.(...args);

                if (dayjs(field.value).isValid()) {
                  analytics.track('Input Changed', {
                    inputName: 'Purchase Date',
                    value: dayjs(field.value).format(dateFormat),
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  data-hj-allow
                  {...params}
                  fullWidth
                  label={l.purchaseDate}
                  inputProps={{ placeholder: dateFormat, ...params.inputProps }}
                  error={!!errors.purchase?.date}
                  helperText={errors.purchase?.date?.message}
                  onBlur={() => {
                    if (dayjs(purchaseDate).isValid()) {
                      analytics.track('Input Changed', {
                        inputName: 'Purchase Date',
                        value: dayjs(purchaseDate).format(dateFormat),
                      });
                    }
                  }}
                />
              )}
            />
          )}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
        <ControlledFormattedNumberField
          name={`${formPrefix}purchase.closingCost`}
          label={l.closingCost}
          control={control}
          fullWidth
          onBlur={() => {
            analytics.track('Input Changed', {
              inputName: 'Closing Cost',
              value: purchaseClosingCost,
            });
          }}
        />
        <ControlledFormattedNumberField
          name={`${formPrefix}purchase.homeImprovementCost`}
          label={homeImprovementsLabel}
          placeholder={l.homeImprovementsAbbr}
          onFocus={() => {
            setHomeImprovementsFocused(true);
          }}
          onBlur={() => {
            setHomeImprovementsFocused(false);

            analytics.track('Input Changed', {
              inputName: 'Home Improvement Cost',
              value: purchaseHomeImprovementCost,
            });
          }}
          control={control}
          fullWidth
        />
      </Stack>
      <Box>
        <Divider />
        <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3} px={2}>
          <Typography
            variant="body2"
            data-testid="total-price"
          >
            {l.totalPurchasePrice}
          </Typography>
          <BoldTypography
            sx={{ mt: 1 }}
            variant="body1"
            data-testid="total-price"
          >
            {/* keep using || here instead of ?? because the amounts could be empty strings */}
            {formatNumberToCurrency((purchaseAmount || 0) + (purchaseClosingCost || 0) + (purchaseHomeImprovementCost || 0), 0)}
          </BoldTypography>
        </Stack>
      </Box>
    </Stack>
  );
};

import { API } from 'aws-amplify';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import * as Sentry from '@sentry/react';

export const queryGraphQL = async (options: GraphQLOptions) => {
  try {
    return await API.graphql(options);
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setContext('query', {
        query: options.query,
        variables: options.variables,
      });
      if (e instanceof Error) {
        Sentry.captureException(e);
      } else if (typeof e === 'object' && e !== null && 'errors' in e) {
        Sentry.captureMessage(JSON.stringify((e as any).errors), 'error');
      } else {
        // we cant use Sentry.captureException(e) because e is not a standard error
        Sentry.captureMessage(JSON.stringify(e), 'error');
      }
    });

    throw e;
  }
};

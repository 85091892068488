import { Navigate, Route, Routes } from 'react-router-dom';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useIsDashboardPropertyProformaEnabled } from 'lib';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Home } from './views/home';
import { Onboarding } from './views/onboarding';
import { Proforma } from './views/proforma';
import { Properties } from './views/properties';
import { Reports } from './views/reports';
import { Transactions } from './views/transactions';

dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const RoutesWithLayout = () => {
  const {
    value: isDashboardProformaEnabled,
    loading: loadingIsDashboardProformaEnabled,
  } = useIsDashboardPropertyProformaEnabled();

  if (loadingIsDashboardProformaEnabled) return null;

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/onboarding/:propertyID" element={<Onboarding />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/properties/:propertyID" element={<Properties />} />
      <Route path="/properties" element={<Properties />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/reports/:reportId" element={<Reports />} />
      {isDashboardProformaEnabled && (
        <>
          <Route path="/proforma" element={<Proforma />} />
          <Route path="/proforma/:propertyID" element={<Proforma />} />
        </>
      )}
      <Route
        path="*"
        element={
          <Navigate to="/" />
        }
      />
    </Routes>
  );
};

export const App = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <RoutesWithLayout />
  </LocalizationProvider>
);

/* eslint-disable max-len */

export const OwnerIcon = ({ size = 24, color = undefined }: { size?: number, color?: string }) => {
  const clipPathID = `clip-path-${Math.random()}`;
  const clipPathID2 = `clip-path-${Math.random()}`;

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${clipPathID2})`}>
        <circle
          cx="14.1342"
          cy="6.13437"
          r="5.25356"
          transform="rotate(-100.653 14.1342 6.13437)"
          fill={`url(#${clipPathID})`}
        />
        <path
          d="M9.5 12.7391C12.2614 12.7391 14.5 10.559 14.5 7.86957C14.5 5.18018 12.2614 3 9.5 3C6.73858 3 4.5 5.18018 4.5 7.86957C4.5 10.559 6.73858 12.7391 9.5 12.7391ZM9.5 12.7391C6.26172 12.7391 3.51328 14.4534 2 17M9.5 12.7391C12.7383 12.7391 15.4867 14.4534 17 17"
          stroke={color ?? 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id={clipPathID}
          x1="9.58114"
          y1="11.3879"
          x2="16.557"
          y2="3.48211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F24476" />
          <stop offset="1" stopColor="#E7C8D1" stopOpacity="0.46" />
        </linearGradient>
        <clipPath id={clipPathID2}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { sum } from 'lib';

import { CalculationType, EView, Form } from '../types';

const multiplierPerYear = 1.03;

const calculateProjections = (value: number, rent: number, calculationType: CalculationType) => {
  if (calculationType === CalculationType.NUMBER) {
    return {
      year1: value,
      year2: value * multiplierPerYear,
      year3: value * multiplierPerYear ** 2,
    };
  }

  return {
    year1: rent * (value / 100),
    year2: rent * (value / 100) * multiplierPerYear,
    year3: rent * (value / 100) * multiplierPerYear ** 2,
  };
};

export const useProjections = (form: Form) => {
  const rentAmount = form.watch('details.income.rent');
  const estimatedVacancy = form.watch('details.estimatedVacancy');
  const askingPriceAmount = form.watch('details.purchasePrice');
  const tax = form.watch('details.expenses.tax');
  const insurance = form.watch('details.expenses.insurance');
  const hoa = form.watch('details.expenses.hoa');
  const managementFee = form.watch('details.expenses.managementFee');
  const maintenance = form.watch('details.expenses.maintenance');

  let vacancyPercentage = estimatedVacancy.value || 0;

  if (estimatedVacancy.type === CalculationType.NUMBER) {
    vacancyPercentage = (estimatedVacancy.value / rentAmount) * 100;
  }

  const rent = {
    year1: rentAmount,
    year2: rentAmount * multiplierPerYear,
    year3: rentAmount * multiplierPerYear ** 2,
  };

  const vacancy = {
    year1: rentAmount * (vacancyPercentage / 100),
    year2: rent.year2 * (vacancyPercentage / 100),
    year3: rent.year3 * (vacancyPercentage / 100),
  };

  const expectedRent = {
    year1: rent.year1 - vacancy.year1,
    year2: rent.year2 - vacancy.year2,
    year3: rent.year3 - vacancy.year3,
  };

  const taxProjections = calculateProjections(tax.value || 0, rentAmount, tax.type);
  const insuranceProjections = calculateProjections(insurance.value || 0, rentAmount, insurance.type);
  const hoaProjections = calculateProjections(hoa.value || 0, rentAmount, hoa.type);
  const managementFeeProjections = calculateProjections(managementFee.value || 0, rentAmount, managementFee.type);
  const maintenanceProjections = calculateProjections(maintenance.value || 0, rentAmount, maintenance.type);

  const year1Sum = sum([
    taxProjections.year1,
    insuranceProjections.year1,
    hoaProjections.year1,
    managementFeeProjections.year1,
    maintenanceProjections.year1,
  ]);
  const year2Sum = sum([
    taxProjections.year2,
    insuranceProjections.year2,
    hoaProjections.year2,
    managementFeeProjections.year2,
    maintenanceProjections.year2,
  ]);
  const year3Sum = sum([
    taxProjections.year3,
    insuranceProjections.year3,
    hoaProjections.year3,
    managementFeeProjections.year3,
    maintenanceProjections.year3,
  ]);

  return {
    rent,
    vacancy,
    expectedRent,
    askingPrice: calculateProjections(askingPriceAmount, rentAmount, CalculationType.NUMBER),
    tax: taxProjections,
    insurance: insuranceProjections,
    hoa: hoaProjections,
    managementFee: managementFeeProjections,
    maintenance: maintenanceProjections,
    totalExpenses: {
      year1: year1Sum,
      year2: year2Sum,
      year3: year3Sum,
    },
  };
};

export const getCalculationValue = (value: number, view: EView) => {
  if (view === EView.YEARLY) {
    return value / 12;
  }

  return value;
};

/* eslint-disable max-len */
export const FireIcon = ({ scale = 1 }: { scale?: number }) => {
  const clipPathID = `clip-path-${Math.random()}`;
  const clipPathID2 = `clip-path-${Math.random()}`;

  const baseHeight = 23;
  const baseWidth = 18;
  const height = baseHeight * scale;
  const width = baseWidth * scale;

  return (
    <svg width={width} height={height} viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.78878 6.88055C3.68428 7.9952 3.61095 9.96784 4.26911 10.8075C4.26911 10.8075 3.95928 8.64053 6.73674 5.92173C7.85505 4.82725 8.11355 3.33861 7.72305 2.22213C7.50122 1.58964 7.09606 1.06714 6.74407 0.702315C6.53874 0.487819 6.6964 0.133991 6.99523 0.146824C8.80287 0.227489 11.7325 0.729815 12.9773 3.85377C13.5236 5.22508 13.564 6.64222 13.3036 8.0832C13.1386 9.00352 12.552 11.0495 13.8903 11.3007C14.8454 11.4803 15.3074 10.7213 15.5146 10.175C15.6008 9.94767 15.8996 9.89084 16.0609 10.0723C17.6742 11.9075 17.8117 14.0689 17.4781 15.9297C16.8327 19.5267 13.19 22.1447 9.57103 22.1447C5.0501 22.1447 1.45132 19.5579 0.518166 14.8756C0.142338 12.9855 0.333002 9.24552 3.24796 6.60556C3.46429 6.40756 3.81812 6.58356 3.78878 6.88055Z"
        fill={`url(#${clipPathID})`}
      />
      <path
        d="M11.2246 13.6071C9.55815 11.4621 10.3043 9.01463 10.7131 8.03931C10.7681 7.91098 10.6215 7.78998 10.506 7.86881C9.78914 8.35647 8.32066 9.50412 7.63684 11.1193C6.71102 13.3027 6.77702 14.3715 7.32518 15.6769C7.65517 16.4633 7.27201 16.6302 7.07952 16.6595C6.89252 16.6888 6.72019 16.5642 6.58269 16.434C6.18853 16.0582 5.90437 15.5687 5.7687 15.0407C5.73937 14.927 5.59087 14.8959 5.52304 14.9894C5.00971 15.6989 4.74389 16.8373 4.73105 17.6422C4.69072 20.13 6.74585 22.1466 9.23182 22.1466C12.3649 22.1466 14.6474 18.6816 12.8471 15.785C12.3246 14.9417 11.8333 14.3899 11.2246 13.6071Z"
        fill={`url(#${clipPathID2})`}
      />
      <defs>
        <radialGradient
          id={clipPathID}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.67565 22.2017) rotate(-179.751) scale(12.9409 21.2334)"
        >
          <stop offset="0.3144" stopColor="#FF9800" />
          <stop offset="0.6616" stopColor="#FF6D00" />
          <stop offset="0.9715" stopColor="#F44336" />
        </radialGradient>
        <radialGradient
          id={clipPathID2}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.40412 9.32425) rotate(90.5787) scale(13.5401 10.19)"
        >
          <stop offset="0.2141" stopColor="#FFF176" />
          <stop offset="0.3275" stopColor="#FFF27D" />
          <stop offset="0.4868" stopColor="#FFF48F" />
          <stop offset="0.6722" stopColor="#FFF7AD" />
          <stop offset="0.7931" stopColor="#FFF9C4" />
          <stop offset="0.8221" stopColor="#FFF8BD" stopOpacity="0.804" />
          <stop offset="0.8627" stopColor="#FFF6AB" stopOpacity="0.529" />
          <stop offset="0.9101" stopColor="#FFF38D" stopOpacity="0.2088" />
          <stop offset="0.9409" stopColor="#FFF176" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

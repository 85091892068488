import { useState } from 'react';

import { Question } from 'lib';
import { BoldTypography } from 'ui';
import { Stack, TextareaAutosize } from '@mui/material';

export const LongTextQuestion = ({
  question,
  submitQuestion,
}: {
  question: Question,
  submitQuestion: (question: Question, answer: string) => void,
}) => {
  const [answer, setAnswer] = useState('');

  return (
    <Stack spacing={2}>
      <BoldTypography variant="body1">
        {question.name}
      </BoldTypography>
      <TextareaAutosize
        minRows={20}
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        onBlur={() => submitQuestion(question, answer)}
      />
    </Stack>
  );
};

import { buyBoxBackgroundURL } from 'ui';
import {
  Button,
  Card,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const ListingCard = styled(Card)(({ theme }) => ({
  maxHeight: '100%',
  position: 'relative',
  '&:hover': {
    background: theme.palette.background.default,
    cursor: 'pointer',
  },
}));

export const BuyBoxCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: 140,
  width: 320,
  background: '#EBEDF5',
  '&::before': {
    position: 'absolute',
    backgroundImage: `url(${buyBoxBackgroundURL})`,
    backgroundSize: 'cover',
    content: '""',
    width: 231,
    height: '100%',
    top: '5%',
  },
  [theme.breakpoints.up('md')]: {
    '&::before': {
      left: '47%',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '&::before': {
      right: '-8%',
    },
  },
})) as unknown as typeof Card;

export const FilterButtonContainer = styled(Stack)(({ theme }) => ({
  display: 'inline-flex',
  height: 40,
  transition: '0.4s',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.divider}`,
}));

export const FilterButton = styled(Button)(({ theme }) => ({
  '&&&': {
    border: 'none',
    borderRadius: 0,
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    '&:hover': {
      border: 'none',
    },
  },
}));

export const ClearFiltersButton = styled(Button)(({ theme }) => ({
  '&&&': {
    padding: '0 !important',
    minWidth: `calc(24px + ${theme.spacing(3)})`,
    width: `calc(24px + ${theme.spacing(3)})`,
    border: 'none',
    borderRadius: 0,
    '& svg': {
      fill: theme.palette.text.primary,
    },
    '&:hover': {
      border: 'none',
    },
  },
}));

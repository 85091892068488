import { Spinner, useLabels } from 'ui';
import { Button } from '@mui/material';

type Props = {
  onClick: () => void;
  isLoading: boolean;
};

export const NoHoaButton = ({ onClick, isLoading }: Props) => {
  const l = useLabels();

  return (
    <Button size="small" disabled={isLoading} variant="outlined" color="primary" onClick={onClick}>
      {isLoading ? <Spinner size={30} /> : l.noHoa}
    </Button>
  );
};

import { ListingProperty } from '../../types/property';

export const getListingsFromPages = (pages: { properties: ListingProperty[] }[]) => {
  // this is necessary for sales users who get listing items from all pms
  const uniquePropertyIDs = new Set<string>();
  const properties: ListingProperty[] = [];

  pages?.forEach((p) => {
    p?.properties?.forEach((property) => {
      if (uniquePropertyIDs.has(property.id)) return;

      uniquePropertyIDs.add(property.id);
      properties.push(property);
    });
  });

  return properties;
};

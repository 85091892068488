import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const dateToUTC = (date: Date | string) => dayjs.utc(date);

export const convertToUserTimezone = (date: string) => dateToUTC(date).tz(dayjs.tz.guess());

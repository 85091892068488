import { useState } from 'react';

import { useAnalytics, useAuth } from 'lib';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useLabels } from 'ui';
import {
  Button,
  ButtonProps, IconButton, useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useCreateFavorite, useDeleteFavorite } from '../../api/favorites';
import { QueryKey } from '../../types/enums';

export const FavoriteButton = ({
  onChangeFavorite = () => {},
  favoriteId: initFavoriteId,
  marketplacePropertyId,
  buttonSx = {},
  showAsButton = false,
} : {
  onChangeFavorite?: (fabId: string | null) => void,
  favoriteId: string | null,
  marketplacePropertyId: string,
  buttonSx?: ButtonProps['sx'],
  showAsButton?: boolean,
}) => {
  const theme = useTheme();
  const l = useLabels();
  const { user } = useAuth();
  const [submitting, setSubmitting] = useState(false);

  const [isFav, setIsFav] = useState(initFavoriteId !== null);

  const analytics = useAnalytics();
  const createFavorite = useCreateFavorite();
  const deleteFavorite = useDeleteFavorite();
  const queryClient = useQueryClient();

  const handleFavoriteToggle = async (event: React.MouseEvent<HTMLButtonElement>) => {
    analytics.track('Checkbox Toggled', {
      checkboxName: 'Favorite',
      value: isFav,
      listingId: marketplacePropertyId,
    });

    event.preventDefault();
    event.stopPropagation();

    setSubmitting(true);
    try {
      const newIsFav = !isFav;
      setIsFav(newIsFav);

      if (newIsFav) {
        const data = await createFavorite.mutateAsync(marketplacePropertyId);
        onChangeFavorite(data.data.id);
      } else {
        await deleteFavorite.mutateAsync(initFavoriteId as string);
        onChangeFavorite(null);
      }
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    } finally {
      setSubmitting(false);
      queryClient.invalidateQueries({ queryKey: [QueryKey.MARKETPLACE_PROPERTY, marketplacePropertyId, user?.pm] })
        .catch((err: Error) => {
          console.error('Error invalidating query for property', err);
        });
    }
  };

  if (showAsButton) {
    return (
      <Button
        variant="outlined"
        onClick={handleFavoriteToggle}
        startIcon={isFav ? <MdFavorite fill={theme.palette.primary.dark} /> : <MdFavoriteBorder />}
        sx={buttonSx}
      >
        {l.favorite}
      </Button>
    );
  }

  return (
    <IconButton
      aria-label="favorite"
      disabled={submitting}
      onClick={handleFavoriteToggle}
      sx={{
        background: 'white',
        position: 'absolute',
        top: 8,
        right: 8,
        zIndex: 1,
        '& > svg': {
          transition: '0.3s',
        },
        '&:active,&:focus,&:hover,&:disabled': {
          background: theme.palette.background.paper,
        },
        '&:hover > svg': {
          fill: isFav ? theme.palette.grey[600] : theme.palette.primary.dark,
        },
        ...buttonSx,
      }}
    >
      {isFav ? <MdFavorite fill={theme.palette.primary.dark} /> : <MdFavoriteBorder />}
    </IconButton>
  );
};

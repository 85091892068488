import { NotificationTemplate, queryGraphQL, useAuth } from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '../../types/enums';
import { getNotificationTemplate } from '../graphql/queries';

export const useGetNotificationTemplate = (id: string | undefined) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.NOTIFICATION_TEMPLATE, id], async (): Promise<NotificationTemplate | null> => {
    if (!id) return null;

    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getNotificationTemplate,
      variables: { id },
      authToken: token,
    }) as GraphQLResult<{ getNotificationTemplate: NotificationTemplate } >;

    if (!res.data?.getNotificationTemplate) {
      return null;
    }

    return res.data.getNotificationTemplate;
  });
};

import {
  useEffect, useState,
} from 'react';

export const useTimer = ({
  enabled = true,
  timeoutSeconds,
}: {
  enabled?: boolean,
  timeoutSeconds: number,
}) => {
  const [countdown, setCountdown] = useState<number>(timeoutSeconds);
  const [stopCountdown, setStopCountdown] = useState<boolean>(false);

  useEffect(() => {
    if (enabled && !stopCountdown) {
      const timer = setTimeout(() => {
        setCountdown((currCount) => currCount - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }

    return () => null;
  }, [countdown, setCountdown, stopCountdown, enabled]);

  useEffect(() => () => {
    if (countdown <= 0) {
      setStopCountdown(true);
    }
  }, [countdown]);

  useEffect(() => {
    setStopCountdown(!enabled);

    if (!enabled) {
      setCountdown(timeoutSeconds);
    }
  }, [enabled]);

  return { countdown, stopCountdown };
};

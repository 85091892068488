import dayjs from 'dayjs';

import {
  Order, OrderByType, TableItem,
} from './types';
import { dateComparator, numberComparator } from '../../lib/sort';

export const descendingComparator = (a: TableItem, b: TableItem, orderBy: OrderByType) => {
  if (orderBy === 'date') {
    return dateComparator(dayjs(a.date), dayjs(b.date));
  }

  if (orderBy === 'amount') {
    return numberComparator(a.amount, b.amount);
  }
  return 0;
};

export const getComparator = (order: Order, orderBy: OrderByType): (a: TableItem, b: TableItem) => number => (
  order === Order.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
);

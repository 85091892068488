import axios, { AxiosRequestConfig } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { convertQuestionsAndSubmissionResponseToQuestions } from './converters';
import {
  PropertiesResponse,
  Question, QuestionsResponse, SubmissionRequest, SubmissionsResponse,
} from './types';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { createHeaders } from '../axios';

const QUERY_KEY = 'jotform';

export const useGetQuestions = ({
  formID,
  ownerEmail,
  enabled,
  subQuestionID,
}: {
  formID: string,
  ownerEmail: string,
  enabled: boolean,
  subQuestionID?: string,
}) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QUERY_KEY, formID, ownerEmail], async (): Promise<Question[]> => {
    const token = await getAccessTokenSilently();

    const cfg: AxiosRequestConfig = {
      headers: createHeaders(token),
    };

    if (subQuestionID) {
      cfg.params = {
        filter: JSON.stringify({
          [`q${subQuestionID}:matches:answer`]: ownerEmail,
        }),
      };
    }

    const promises = [
      axios.get<SubmissionsResponse>(`${config.apiBaseURL}/form/${formID}/submissions`, cfg),
      axios.get<PropertiesResponse>(`${config.apiBaseURL}/form/${formID}/properties`, {
        headers: createHeaders(token),
      }),
      axios.get<QuestionsResponse>(`${config.apiBaseURL}/form/${formID}/questions`, {
        headers: createHeaders(token),
      }),
    ] as const;

    const [
      submissions,
      properties,
      questions,
    ] = await Promise.all(promises);

    return convertQuestionsAndSubmissionResponseToQuestions(submissions.data, questions.data, properties.data);
  }, {
    enabled,
  });
};

export const useCreateSubmission = (formID: string) => {
  const { getAccessTokenSilently } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (answers : SubmissionRequest[]) => {
    const params = new URLSearchParams();

    answers.forEach((an) => {
      if (an.answer) {
        params.append(`submission[${an.id}]`, an.answer);
      }
    });

    const token = await getAccessTokenSilently();
    const res = await axios.post(`${config.apiBaseURL}/form/${formID}/submissions`,
      params,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      });

    queryClient.invalidateQueries([QUERY_KEY, formID]);

    return res;
  });
};

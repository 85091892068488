import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { VerticalLayout } from './VerticalLayout';
import { HeaderLogoProps } from '../types';

export const UserLayout = (
  { headerLogoProps, navigationMenu }: { headerLogoProps: HeaderLogoProps, navigationMenu: ReactNode },
) => {
  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <VerticalLayout hidden={hidden} headerLogoProps={headerLogoProps} navigationMenu={navigationMenu}>
      <Outlet />
    </VerticalLayout>
  );
};

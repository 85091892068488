import { toast } from 'react-toastify';
import { useConfetti, useLabels } from 'ui';
import { Stack } from '@mui/material';

import {
  useMutateBuyBoxLead, useMutateBuyerLead, useMutateOwnerLead, useMutateSellerLead,
} from '../../../api/workflows';
import { QueryKey } from '../../../types/enums';
import { RevealOverlayList } from '../list/RevealOverlayList';

export enum LeadType {
  BUYER = 'buyer',
  SELLER = 'seller',
  BUY_BOX = 'buy_box',
  OWNER = 'owner',
}

export const LeadReveal = ({
  id,
  revealed,
  leadType,
  onReveal,
}: {
  id: string,
  revealed: boolean,
  leadType: LeadType,
  onReveal: () => void,
}) => {
  const l = useLabels();
  const confetti = useConfetti();

  const leadTypeToMutation = {
    [LeadType.BUYER]: {
      useMutate: useMutateBuyerLead,
      queryKey: QueryKey.BUYER_LEADS,
    },
    [LeadType.SELLER]: {
      useMutate: useMutateSellerLead,
      queryKey: QueryKey.SELLER_LEADS,
    },
    [LeadType.BUY_BOX]: {
      useMutate: useMutateBuyBoxLead,
      queryKey: QueryKey.BUY_BOX_LEADS,
    },
    [LeadType.OWNER]: {
      useMutate: useMutateOwnerLead,
      queryKey: QueryKey.OWNER_LEADS,
    },
  };

  const { mutateAsync, isLoading: isUpdatingLead } = leadTypeToMutation[leadType].useMutate();

  const revealLoading = isUpdatingLead;

  const revealLead = async () => {
    try {
      await mutateAsync({
        id,
        revealed: true,
      });

      onReveal();

      confetti.trigger();
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  return (
    <Stack py={2}>
      <RevealOverlayList
        reveal={revealLead}
        revealed={!!revealed}
        isRevealing={revealLoading}
        disabled={revealLoading}
      />
    </Stack>
  );
};

export type Activity = {
  id: string,
  picture: string | null,
  status: ActivityStatus,
  address: string,
  askingPrice: number,
  offers: ActivityOffer[]
};

export type ActivityOffer = {
  status: ActivityOfferStatus,
  bid: number,
  id: string
};

export enum ActivityOfferStatus {
  NEW_OFFER = 'New Offer',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_REJECTED = 'Offer Rejected',
  EMPTY = '',
}

export enum ActivityStatus {
  PENDING_LISTING = 'Pending Listing',
  ACTIVE = 'Active Listing',
  UNDER_CONTRACT = 'Under Contract',
  SOLD = 'Property Sold',
  ESCROW = 'Escrow',
  INVISIBLE = 'invisible',
  PENDING_SALE = 'Pending Sale',
}

import { useAnalytics } from 'lib';
import { BiMailSend } from 'react-icons/bi';
import { toast } from 'react-toastify';
import {
  BoldTypography, useLabels,
} from 'ui';
import {
  IconButton,
  Stack, Typography, useTheme,
} from '@mui/material';

export const LeadCardHeader = ({
  firstName = undefined,
  lastName = undefined,
  email = undefined,
}: {
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
}) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const handleClickEmail = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Copy Email',
      email,
    });

    if (!email) {
      toast.error(l['error.missingContactEmail']);
      return;
    }

    window.navigator.clipboard.writeText(email);
    toast.success(l['leads.emailCopied']);
  };

  return (
    <Stack mb={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <BoldTypography variant="body1">
          {firstName || lastName ? `${firstName ?? ''} ${lastName ?? ''}` : l.na}
        </BoldTypography>
        <IconButton onClick={handleClickEmail}>
          <BiMailSend color={theme.palette.primary.main} size={16} />
        </IconButton>
      </Stack>
      <Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {email}
      </Typography>
    </Stack>
  );
};

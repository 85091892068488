import { useEffect, useMemo } from 'react';

import dayjs from 'dayjs';
import { useAnalytics } from 'lib';
import { Controller, FieldErrors } from 'react-hook-form';
import { ControlledFormattedNumberField, useLabels } from 'ui';
import {
  Collapse, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { PropertyFormValues, PropertyMortgageDataProps } from './types';
import { MortgageType } from '../../types/property';

const dateFormat = 'MM/DD/YYYY';

export const PropertyMortgageData = ({
  control, watch, revalidate, formSubKey = '', ...rest
}: PropertyMortgageDataProps) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const formPrefix = formSubKey ? `${formSubKey}.` : '';

  const [
    loanTerm,
    date,
    mortgageType,
    dueDate,
    amount,
    otherLoanTerm,
    interestRate,
  ] = watch([
    `${formPrefix}mortgage.loanTerm`,
    `${formPrefix}mortgage.date`,
    `${formPrefix}mortgage.mortgageType`,
    `${formPrefix}mortgage.mortgageDueDay`,
    `${formPrefix}mortgage.amount`,
    `${formPrefix}mortgage.otherLoanTerm`,
    `${formPrefix}mortgage.interestRate`,
  ]);

  const showOtherLoanTerm = useMemo<boolean>(() => loanTerm === 'other', [loanTerm]);
  const enablePaymentDay = useMemo<boolean>(() => date !== null, [date]);

  let errors: FieldErrors<PropertyFormValues> = {};
  if (rest.useSubKey) {
    errors = rest.errors[formSubKey] ?? {};
  } else {
    errors = rest.errors;
  }

  const days = useMemo<string[]>(() => {
    const daysArray = [];
    for (let i = 1; i <= date?.daysInMonth(); i += 1) {
      daysArray.push(i.toString());
    }
    return daysArray;
  }, [date]);

  useEffect(() => {
    revalidate();
  }, [loanTerm, date, mortgageType, dueDate, amount, otherLoanTerm, interestRate]);

  return (
    <>
      <Controller
        name={`${formPrefix}mortgage.mortgageType`}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth sx={{ mt: 5 }}>
            <InputLabel
              shrink
              sx={{
                pl: 2,
                pr: 2,
                ml: -1,
                background: theme.palette.background.paper,
              }}
            >
              {l.loanType}
            </InputLabel>
            <Select
              label={l.loanType}
              {...field}
              onChange={(e, ...args) => {
                field.onChange?.(e, ...args);

                analytics.track('Selector Changed', {
                  selectorName: 'Mortgage Type',
                  value: e.target.value,
                });
              }}
              displayEmpty
            >
              <MenuItem value="">
                {l.none}
              </MenuItem>
              <MenuItem value={MortgageType.PURCHASE}>
                {l['mortgageType.purchase']}
              </MenuItem>
              <MenuItem value={MortgageType.REFINANCE}>
                {l['mortgageType.refinance']}
              </MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <Collapse in={!!mortgageType}>
        <ControlledFormattedNumberField
          error={!!errors.mortgage?.amount}
          helperText={errors.mortgage?.amount?.message}
          name={`${formPrefix}mortgage.amount`}
          label={l.amount}
          control={control}
          sx={{ mt: 5 }}
          rules={{ required: !!mortgageType && l.errorFieldIsRequired }}
          fullWidth
          onBlur={() => {
            analytics.track('Input Changed', {
              inputName: 'Mortgage Amount',
              value: amount,
            });
          }}
        />
        <Stack direction="row" justifyContent="space-between" mt={5} gap={2}>
          <Controller
            name={`${formPrefix}mortgage.loanTerm`}
            control={control}
            rules={{ required: !!mortgageType }}
            render={({ field }) => (
              <FormControl
                fullWidth
              >
                <InputLabel>{l.loanTerm}</InputLabel>
                <Select
                  label={l.loanTerm}
                  data-hj-allow
                  {...field}
                  onChange={(e, ...args) => {
                    field.onChange?.(e, ...args);

                    analytics.track('Selector Changed', {
                      selectorName: 'Loan Term',
                      value: e.target.value,
                    });
                  }}
                  error={!!errors.mortgage?.loanTerm}
                >
                  <MenuItem value={120}>
                    {l['120-months']}
                  </MenuItem>
                  <MenuItem value={180}>
                    {l['180-months']}
                  </MenuItem>
                  <MenuItem value={240}>
                    {l['240-months']}
                  </MenuItem>
                  <MenuItem value={360}>
                    {l['360-months']}
                  </MenuItem>
                  <MenuItem value="other">
                    {l.other}
                  </MenuItem>
                </Select>
                <FormHelperText>{errors?.mortgage?.loanTerm?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <ControlledFormattedNumberField
            name={`${formPrefix}mortgage.interestRate`}
            label={l.interestRate}
            control={control}
            prefix=""
            rules={{ required: !!mortgageType && l.errorFieldIsRequired }}
            error={!!errors.mortgage?.interestRate}
            helperText={errors.mortgage?.interestRate?.message}
            fullWidth
            InputProps={{
              endAdornment: '%',
            }}
            onBlur={() => {
              analytics.track('Input Changed', {
                inputName: 'Interest Rate',
                value: interestRate,
              });
            }}
          />
        </Stack>
        <Collapse in={showOtherLoanTerm}>
          <ControlledFormattedNumberField
            name={`${formPrefix}mortgage.otherLoanTerm`}
            label={l.otherLoanTerm}
            rules={{ required: !!mortgageType && showOtherLoanTerm && l.errorFieldIsRequired }}
            error={!!errors.mortgage?.otherLoanTerm}
            helperText={errors.mortgage?.otherLoanTerm?.message}
            prefix=""
            control={control}
            sx={{ mt: 5 }}
            fullWidth
            onBlur={() => {
              analytics.track('Input Changed', {
                inputName: 'Other Loan Term',
                value: otherLoanTerm,
              });
            }}
          />
        </Collapse>
        <Stack direction="row" justifyContent="space-between" mt={5} gap={2}>
          <Controller
            rules={{
              required: !!mortgageType && l.errorFieldIsRequired,
            }}
            name={`${formPrefix}mortgage.date`}
            control={control}
            render={({ field }) => (
              <DatePicker
                inputFormat="MMM, YYYY"
                views={['year', 'month']}
                minDate={dayjs('1900-01-01')}
                maxDate={dayjs().startOf('month')}
                openTo="year"
                label={l.originationMonthShort}
                {...field}
                onChange={(...args) => {
                  field.onChange?.(...args);

                  if (dayjs(field.value).isValid()) {
                    analytics.track('Input Changed', {
                      inputName: 'Purchase Date',
                      value: dayjs(field.value).format(dateFormat),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    error={!!errors?.mortgage?.date}
                    helperText={errors?.mortgage?.date?.message}
                    sx={{ width: '100%' }}
                    onBlur={() => {
                      if (dayjs(date).isValid()) {
                        analytics.track('Input Changed', {
                          inputName: 'Purchase Date',
                          value: dayjs(date).format(dateFormat),
                        });
                      }
                    }}
                  />
                )}
              />
            )}
          />
          <Controller
            name={`${formPrefix}mortgage.mortgageDueDay`}
            control={control}
            rules={{ required: !!mortgageType && !!date && l.errorFieldIsRequired }}
            render={({ field }) => (
              <FormControl
                sx={{ width: '100%' }}
                disabled={!enablePaymentDay}
              >
                <InputLabel error={!!errors.mortgage?.mortgageDueDay}>{l.paymentDay}</InputLabel>
                <Select
                  label={l.paymentDay}
                  {...field}
                  error={!!errors.mortgage?.mortgageDueDay}
                  onChange={(e, ...args) => {
                    field.onChange?.(e, ...args);

                    analytics.track('Selector Changed', {
                      selectorName: 'Payment Day',
                      value: e.target.value,
                    });
                  }}
                >
                  {days.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  sx={{ color: theme.palette.error.main }}
                >
                  {errors?.mortgage?.mortgageDueDay?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Stack>
      </Collapse>
    </>
  );
};

import { Navigate, Route, Routes } from 'react-router-dom';

import { useIsMarketplaceEnabled, useIsOldNavigationEnabled, useIsSellPropertyEnabled } from 'lib';
import { ComingSoonPage, PriceTagIcon } from 'ui';

import { Activities } from './views/activities';
import { ListingsPage } from './views/listings';
import { DetailedListing } from './views/listings/detailed-listing';
import { SellProperty } from './views/sell-property';

export const App = () => {
  const { value: sellPropertyEnabled, loading } = useIsSellPropertyEnabled();
  const { value: marketplaceEnabled, loading: loadingMarketplaceFF } = useIsMarketplaceEnabled();
  const { value: isOldNavigationEnabled, loading: loadingIsOldNavigationEnabled } = useIsOldNavigationEnabled();

  if (loading || loadingMarketplaceFF || loadingIsOldNavigationEnabled) return null;

  return (
    <Routes>
      {marketplaceEnabled && (
        <>
          <Route path="/" element={<ListingsPage />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/:propertyID" element={<DetailedListing />} />
        </>
      )}
      {sellPropertyEnabled && (
        <Route path="/sell-property" element={<SellProperty />} />
      )}
      {!isOldNavigationEnabled && (
        <Route path="investment-planner" element={<ComingSoonPage icon={<PriceTagIcon size={40} />} />} />
      )}
      <Route
        path="*"
        element={
          <Navigate to="/" />
        }
      />
    </Routes>
  );
};

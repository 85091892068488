import dayjs from 'dayjs';
import { convertToUserTimezone } from 'lib';

export const formatPotentialTodayDate = (date: string) => {
  const time = convertToUserTimezone(date);
  if (time.isSame(dayjs(), 'day')) {
    return 'Today';
  }

  if (time.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  }

  return time.format('D MMM');
};

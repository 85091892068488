import { useParams } from 'react-router-dom';

import { signal } from '@preact/signals-react';

export const PORTFOLIO = 'portfolio';

export const editModeSignal = signal<boolean>(false);

export const useGetPropertyIdParam = () => {
  const { propertyID } = useParams();

  return propertyID ?? PORTFOLIO;
};

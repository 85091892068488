import { Theme } from '@mui/material/styles';

export const overrideFab = (theme: Theme) => ({
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: theme.shadows[5],
      },
    },
  },
});

import { InsightContext, OwnerRiskInsight } from 'lib';
import { capitalize, formatNumberToCurrency, useLabels } from 'ui';

export type InsightDescription = {
  title: string;
  context: string[];
};

const l = useLabels();

const metricsDescriptionMap: Map<string, string> = new Map([
  ['cashflowIn2Months', l['metric.context.cashflow'].replace('$months', '2')],
  ['cashflowIn3Months', l['metric.context.cashflow'].replace('$months', '3')],
  ['cashflowIn4Months', l['metric.context.cashflow'].replace('$months', '4')],
  ['cashflowIn5Months', l['metric.context.cashflow'].replace('$months', '5')],
  ['cashflowIn6Months', l['metric.context.cashflow'].replace('$months', '6')],
  ['cashflowIn7Months', l['metric.context.cashflow'].replace('$months', '7')],
  ['cashflowIn8Months', l['metric.context.cashflow'].replace('$months', '8')],
  ['cashflowIn9Months', l['metric.context.cashflow'].replace('$months', '9')],
  ['cashflowIn10Months', l['metric.context.cashflow'].replace('$months', '10')],
  ['cashflowIn11Months', l['metric.context.cashflow'].replace('$months', '11')],
  ['cashflowIn12Months', l['metric.context.cashflow'].replace('$months', '12')],

  ['noiIn2Months', l['metric.context.noi'].replace('$months', '2')],
  ['noiIn3Months', l['metric.context.noi'].replace('$months', '3')],
  ['noiIn4Months', l['metric.context.noi'].replace('$months', '4')],
  ['noiIn5Months', l['metric.context.noi'].replace('$months', '5')],
  ['noiIn6Months', l['metric.context.noi'].replace('$months', '6')],
  ['noiIn7Months', l['metric.context.noi'].replace('$months', '7')],
  ['noiIn8Months', l['metric.context.noi'].replace('$months', '8')],
  ['noiIn9Months', l['metric.context.noi'].replace('$months', '9')],
  ['noiIn10Months', l['metric.context.noi'].replace('$months', '10')],
  ['noiIn11Months', l['metric.context.noi'].replace('$months', '11')],
  ['noiIn12Months', l['metric.context.noi'].replace('$months', '12')],

  ['maintenanceIn2Months', l['metric.context.maintenance'].replace('$months', '2')],
  ['maintenanceIn3Months', l['metric.context.maintenance'].replace('$months', '3')],
  ['maintenanceIn4Months', l['metric.context.maintenance'].replace('$months', '4')],
  ['maintenanceIn5Months', l['metric.context.maintenance'].replace('$months', '5')],
  ['maintenanceIn6Months', l['metric.context.maintenance'].replace('$months', '6')],
  ['maintenanceIn7Months', l['metric.context.maintenance'].replace('$months', '7')],
  ['maintenanceIn8Months', l['metric.context.maintenance'].replace('$months', '8')],
  ['maintenanceIn9Months', l['metric.context.maintenance'].replace('$months', '9')],
  ['maintenanceIn10Months', l['metric.context.maintenance'].replace('$months', '10')],
  ['maintenanceIn11Months', l['metric.context.maintenance'].replace('$months', '11')],
  ['maintenanceIn12Months', l['metric.context.maintenance'].replace('$months', '12')],

  ['incomeIn2Months', l['metric.context.income'].replace('$months', '2')],
  ['incomeIn3Months', l['metric.context.income'].replace('$months', '3')],
  ['incomeIn4Months', l['metric.context.income'].replace('$months', '4')],
  ['incomeIn5Months', l['metric.context.income'].replace('$months', '5')],
  ['incomeIn6Months', l['metric.context.income'].replace('$months', '6')],
  ['incomeIn7Months', l['metric.context.income'].replace('$months', '7')],
  ['incomeIn8Months', l['metric.context.income'].replace('$months', '8')],
  ['incomeIn9Months', l['metric.context.income'].replace('$months', '9')],
  ['incomeIn10Months', l['metric.context.income'].replace('$months', '10')],
  ['incomeIn11Months', l['metric.context.income'].replace('$months', '11')],
  ['incomeIn12Months', l['metric.context.income'].replace('$months', '12')],

  ['managementFeeIn2Months', l['metric.context.managementFee'].replace('$months', '2')],
  ['managementFeeIn3Months', l['metric.context.managementFee'].replace('$months', '3')],
  ['managementFeeIn4Months', l['metric.context.managementFee'].replace('$months', '4')],
  ['managementFeeIn5Months', l['metric.context.managementFee'].replace('$months', '5')],
  ['managementFeeIn6Months', l['metric.context.managementFee'].replace('$months', '6')],
  ['managementFeeIn7Months', l['metric.context.managementFee'].replace('$months', '7')],
  ['managementFeeIn8Months', l['metric.context.managementFee'].replace('$months', '8')],
  ['managementFeeIn9Months', l['metric.context.managementFee'].replace('$months', '9')],
  ['managementFeeIn10Months', l['metric.context.managementFee'].replace('$months', '10')],
  ['managementFeeIn11Months', l['metric.context.managementFee'].replace('$months', '11')],
  ['managementFeeIn12Months', l['metric.context.managementFee'].replace('$months', '12')],

  ['maintenanceEventsIn2Months', l['metric.context.maintenance.events'].replace('$months', '2')],
  ['maintenanceEventsIn3Months', l['metric.context.maintenance.events'].replace('$months', '3')],
  ['maintenanceEventsIn4Months', l['metric.context.maintenance.events'].replace('$months', '4')],
  ['maintenanceEventsIn5Months', l['metric.context.maintenance.events'].replace('$months', '5')],
  ['maintenanceEventsIn6Months', l['metric.context.maintenance.events'].replace('$months', '6')],
  ['maintenanceEventsIn7Months', l['metric.context.maintenance.events'].replace('$months', '7')],
  ['maintenanceEventsIn8Months', l['metric.context.maintenance.events'].replace('$months', '8')],
  ['maintenanceEventsIn9Months', l['metric.context.maintenance.events'].replace('$months', '9')],
  ['maintenanceEventsIn10Months', l['metric.context.maintenance.events'].replace('$months', '10')],
  ['maintenanceEventsIn11Months', l['metric.context.maintenance.events'].replace('$months', '11')],
  ['maintenanceEventsIn12Months', l['metric.context.maintenance.events'].replace('$months', '12')],

  ['monthsRentIn2Months', l['metric.context.months.rent'].replace('$months', '2')],
  ['monthsRentIn3Months', l['metric.context.months.rent'].replace('$months', '3')],
  ['monthsRentIn4Months', l['metric.context.months.rent'].replace('$months', '4')],
  ['monthsRentIn5Months', l['metric.context.months.rent'].replace('$months', '5')],
  ['monthsRentIn6Months', l['metric.context.months.rent'].replace('$months', '6')],
  ['monthsRentIn7Months', l['metric.context.months.rent'].replace('$months', '7')],
  ['monthsRentIn8Months', l['metric.context.months.rent'].replace('$months', '8')],
  ['monthsRentIn9Months', l['metric.context.months.rent'].replace('$months', '9')],
  ['monthsRentIn10Months', l['metric.context.months.rent'].replace('$months', '10')],
  ['monthsRentIn11Months', l['metric.context.months.rent'].replace('$months', '11')],
  ['monthsRentIn12Months', l['metric.context.months.rent'].replace('$months', '12')],

  ['currentVacancy', l['metric.current.vacancy']],
  ['monthsToEndLease', l['metric.months.to.end.lease']],
  ['turnoverTenants', l['metric.turnover.tenants']],
  ['appreciation', l['metric.appreciation']],
  ['averageAppreciation', l['metric.averageAppreciation']],

]);

const getContextDescription = (
  metrics: Array<InsightContext> | null | undefined,
): string[] => {
  if (!metrics) {
    return [];
  }
  const context = metrics
    .filter(
      (md) => metricsDescriptionMap.has(md.Key ?? '') && md.Value && md.Value !== 0,
    ).sort((a, b) => {
      const aKey = a.Key ?? '';
      const bKey = b.Key ?? '';

      // Extract non-numeric prefix
      const aPrefix = aKey.match(/^\D*/)?.[0] ?? '';
      const bPrefix = bKey.match(/^\D*/)?.[0] ?? '';

      // Extract numeric parts
      const aKeyNumeric = aKey.match(/\d+/);
      const bKeyNumeric = bKey.match(/\d+/);

      // Check if both keys contain numeric parts
      if (aKeyNumeric && bKeyNumeric) {
        // Compare non-numeric prefixes
        const prefixComparison = aPrefix.localeCompare(bPrefix);
        if (prefixComparison !== 0) {
          return prefixComparison;
        }
        // Compare numeric parts
        return Number(aKeyNumeric[0]) - Number(bKeyNumeric[0]);
      }

      // Fallback to comparing the entire keys
      return aKey.localeCompare(bKey);
    })
    .map((md) => metricsDescriptionMap
      .get(md.Key ?? '')
      ?.replace('$currency', formatNumberToCurrency(md.Value ?? 0, 0))
      .replace('$number', md?.Value?.toString() ?? ''));

  return context as string[];
};

const getInsightDescription = (
  titlePrefix: string,
  ownerRiskInsight: OwnerRiskInsight,
): InsightDescription => ({
  title: `${titlePrefix} (${capitalize(`${ownerRiskInsight.level.toString()} Level`)})`,
  context: getContextDescription(ownerRiskInsight.context),
});

export const insightDescriptionMap: Map<
string,
(ownerRiskInsight: OwnerRiskInsight) => InsightDescription
> = new Map([
  [
    'cashflow',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.cashflow'],
      ownerRiskInsight,
    ),
  ],
  [
    'vacancy',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.currently.vacancy'],
      ownerRiskInsight,
    ),
  ],
  [
    'noi',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(l['insight.name.noi'], ownerRiskInsight),
  ],
  [
    'maintenance',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.maintenance'],
      ownerRiskInsight,
    ),
  ],
  [
    'turnoverTenants',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.turnover.tenants'],
      ownerRiskInsight,
    ),
  ],
  [
    'managementFee',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.management.fee'],
      ownerRiskInsight,
    ),
  ],
  [
    'maintenanceEvents',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.maintenance.events'],
      ownerRiskInsight,
    ),
  ],
  [
    'monthsToEndLease',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.months.to.end.lease'],
      ownerRiskInsight,
    ),
  ],
  [
    'appreciation',
    (ownerRiskInsight: OwnerRiskInsight) => getInsightDescription(
      l['insight.name.appreciation'],
      ownerRiskInsight,
    ),
  ],
]);

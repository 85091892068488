import { Navigate, Route, Routes } from 'react-router-dom';

import { usePermissions } from 'lib';

import { PartnerDashboard } from './views/dashboard';

export const App = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = usePermissions();

  if (isLoadingPermissions) return null;

  return (
    <Routes>
      {permissions?.viewReferralPartner && <Route path="/" element={<PartnerDashboard />} />}
      <Route
        path="*"
        element={
          <Navigate to="/" />
        }
      />
    </Routes>
  );
};

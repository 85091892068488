import { Box, useTheme } from '@mui/material';

export const EmptyDash = () => {
  const theme = useTheme();

  return (
    <Box
      component="span"
      height="1px"
      width={20}
      sx={{ background: theme.palette.primary.main, display: 'inline-block' }}
    />
  );
};

import dayjs from 'dayjs';

import { Property } from '../types';

export const sortPropertiesByPurchaseDate = (a: Property, b: Property) => {
  const firstPurchaseDate = a.purchaseHistory && dayjs(a.purchaseHistory.date);
  const secondPurchaseDate = b.purchaseHistory && dayjs(b.purchaseHistory.date);
  if (firstPurchaseDate && secondPurchaseDate) {
    return secondPurchaseDate.diff(firstPurchaseDate);
  }
  if (firstPurchaseDate) {
    return -1;
  }
  if (secondPurchaseDate) {
    return 1;
  }
  return 0;
};

export const dateComparator = (a: dayjs.Dayjs, b: dayjs.Dayjs) => {
  if (a.isBefore(b)) {
    return 1;
  }
  if (b.isBefore(a)) {
    return -1;
  }
  return 0;
};

export const numberComparator = (a: number, b: number) => {
  if (a < b) {
    return 1;
  }
  if (b < a) {
    return -1;
  }
  return 0;
};

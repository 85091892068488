import { Grid, styled } from '@mui/material';

export const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  maxWidth: '100% !important',
  flexBasis: '100% !important',
  flexGrow: '1 !important',
}));

import { UseFormReturn } from 'react-hook-form';

export enum EView {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum CalculationType {
  PERCENTAGE = 'percentage',
  NUMBER = 'number',
}

export type PropertyDetailsFormData = {
  view: EView
  editMode: boolean,
  details: {
    purchasePrice: number,
    income: {
      rent: number,
    },
    estimatedVacancy: {
      value: number,
      type: CalculationType,
    },
    expenses: {
      tax: {
        value: number,
        type: CalculationType,
      },
      insurance: {
        value: number,
        type: CalculationType,
      },
      hoa: {
        value: number,
        type: CalculationType,
      },
      managementFee: {
        value: number,
        type: CalculationType,
      },
      maintenance: {
        value: number,
        type: CalculationType,
      },
    },
    loanPayments: {
      downpayment: number,
      downpaymentPercent: number,
      interestRate: number,
      mortgageAmortizationPeriod: 120 | 180 | 240 | 360,
    },
  },
};

export type PropertyDetailProps = {
  form: UseFormReturn<PropertyDetailsFormData>
  editMode: boolean
};

export type Form = UseFormReturn<PropertyDetailsFormData>;

export type RowProps = {
  form: Form;
};

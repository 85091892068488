import { useEffect } from 'react';

import { useAnalytics, useAuth, useHideChat } from 'lib';
import {
  MediumTypography, RightTransition, useLabels, WelcomeCircleCheck,
} from 'ui';
import {
  Box, Button, Container, Dialog, DialogContent, Stack, Typography,
} from '@mui/material';

import { WelcomeDialogProps } from './types';

export const WelcomeDialog = ({
  open, openNextDialog, fullScreen = false,
}: WelcomeDialogProps) => {
  const { user } = useAuth();
  const l = useLabels();
  const analytics = useAnalytics();

  useHideChat(fullScreen);

  useEffect(() => {
    analytics.track('Milestone Reached', {
      milestone: 'Welcome Screen',
    });
  }, []);

  if (!user) {
    return null;
  }

  return (
    <Dialog fullScreen={fullScreen} open={open} TransitionComponent={RightTransition} maxWidth="md">
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          background: 'linear-gradient(180deg, #173771 0%, #9EAAC1 100%)',
          pt: 10,
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <WelcomeCircleCheck />
          <Typography variant="h5" color="primary.contrastText" sx={{ mt: 1 }}>
            {l['good-to-go']}
          </Typography>
        </Stack>
        <Container
          maxWidth="sm"
          sx={{
            p: 0,
            pt: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <img
            width="100%"
            src="/onboarding-welcome.png"
            alt="Welcome to the platform"
          />
        </Container>
      </Stack>
      <Container maxWidth="sm" sx={{ p: 0 }}>
        <Box
          component={DialogContent}
          sx={{
            mt: 10,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            {l.letsGetStarted}
          </Typography>
          <MediumTypography
            variant="body1"
            sx={{
              textAlign: 'center', mt: 3, whiteSpace: 'pre-line', px: 6,
            }}
          >
            {l['onboarding-welcome-next']}
          </MediumTypography>
          <Button fullWidth variant="contained" sx={{ mt: 8 }} onClick={openNextDialog}>
            {l.continue}
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
};

import { Column, ColumnCard } from './types';

export const getCardAndColumnID = <Data extends ColumnCard>(
  columns: Column<Data>[], id: string,
): {
    card: Data,
    columnID: string,
  } | null => {
  // eslint-disable-next-line no-restricted-syntax
  for (const column of columns) {
    // eslint-disable-next-line no-restricted-syntax
    for (const card of column.cards) {
      if (card.id === id) {
        return {
          card,
          columnID: column.id,
        };
      }
    }
  }

  return null;
};

import {
  convertArrayToFirstElement, filterNulls, OwnerRisk, OwnerRiskSeverity,
} from 'lib';

import { BasicOwnerRisk, ExpandedOwnerRisk } from '../properties/types';

export const convertBasicOwnerRisk = (ownerRisk: OwnerRisk): BasicOwnerRisk => {
  const recommendations = filterNulls(ownerRisk.recommendations?.items);

  return {
    id: ownerRisk.id,
    ownerID: ownerRisk.ownerID,
    clientValue: ownerRisk.clientValue,
    ownerDetails: ownerRisk.ownerDetails,
    updatedTime: ownerRisk.updatedTime,
    risk: ownerRisk.risk,
    insights: ownerRisk.insights,
    status: ownerRisk.status,
    severity: ownerRisk.severity,
    resolvedDate: ownerRisk.resolvedDate,
    resolvedReason: ownerRisk.resolvedReason,
    recommendation: convertArrayToFirstElement({ items: recommendations }),
    isNoRisk: ownerRisk.severity === OwnerRiskSeverity.NO_RISK,
  };
};

export const convertExpandedOwnerRisk = (risk: OwnerRisk): ExpandedOwnerRisk => {
  const ownerRisk = risk!;
  const items = filterNulls(ownerRisk.recommendations?.items);

  return {
    id: ownerRisk.id,
    ownerID: ownerRisk.ownerID,
    recommendation: convertArrayToFirstElement({ items }),
    insights: ownerRisk.insights ?? [],
    ownerDetails: ownerRisk.ownerDetails,
  };
};

import { Theme } from '@mui/material/styles';

export const overrideSwitches = (theme: Theme) => ({
  MuiSwitch: {
    styleOverrides: {
      root: {
        '& .MuiSwitch-track': {
          borderRadius: theme.shape.borderRadius,
        },

        '& .MuiSwitch-switchBase': {
          '& .MuiSwitch-thumb': {
            color: theme.palette.primary.dark,
          },
          '&:not(.Mui-checked)': {
            '& .MuiSwitch-thumb': {
              color: theme.palette.grey[50],
            },
          },
        },
        '& .Mui-disabled + .MuiSwitch-track': {
          backgroundColor: `rgb(${theme.palette.customColors.main})`,
        },
      },
    },
  },
});

import { forwardRef, ReactElement, Ref } from 'react';

import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

export const GrowTransition = forwardRef((
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) => (<Grow ref={ref} {...props} />));

import { useMemo, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { MdExpandMore, MdReportProblem } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useLabels } from 'ui';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { AlertsProps, OnClickAddTransaction } from './types';
import { useUpdateProperty } from '../../api/properties';
import { calcYearlyExpenseByCategory, getCategoryDisplayName, getMissingCategories } from '../../lib/calc';
import { NoHoaButton } from '../../templates/dashboard/NoHOAButton';
import { Category, Property } from '../../types';
import { QueryKey } from '../../types/enums';
import { PropertyForUpdate, PropertyWithAlerts } from '../../types/property';

const MdExpandMoreWithOpen = styled(MdExpandMore)({
  transition: '0.3s',
  '&.open': {
    transform: 'rotate(180deg)',
  },
});

export const Alert = ({ property, onClick, missingCategories }: {
  property: Property,
  onClick: OnClickAddTransaction,
  missingCategories: Category[]
}) => {
  const queryClient = useQueryClient();
  const l = useLabels();

  const { mutateAsync: updateProperty, isLoading } = useUpdateProperty();
  const [isLoadingProperties, setIsLoadingProperties] = useState<boolean>(false);
  const shouldDisplayNoHoa = missingCategories.includes(Category.PROPERTY_HOA);
  const setNoHoaMutation = async () => {
    try {
      setIsLoadingProperties(() => true);
      const propertyForUpdate: PropertyForUpdate = _.pick(property, [
        'bathrooms',
        'bedrooms',
        'propertyDashboardPropertyId',
        'id',
        'owner',
        'name',
        'pmId',
        'createdTime',
        'updatedTime',
        'address',
        'displayName',
        'hoaMuted',
        'isVacant',
        'sqft',
      ]);

      await updateProperty({
        ...propertyForUpdate,
        hoaMuted: true,
      });
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      toast.success(l.noHoaText);
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    } finally {
      setIsLoadingProperties(() => false);
    }
  };

  if (missingCategories.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ textDecoration: 'underline' }}
      >
        {property.displayName}
      </Typography>
      <Stack direction="column" alignItems="stretch" gap={2}>

        {missingCategories.map((category) => (
          <Stack
            key={`button${category}${property.id}`}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1">{getCategoryDisplayName(category)}</Typography>
            <Stack
              direction="row-reverse"
              alignItems="right"
              justifyContent="space-between"
              alignSelf="right"
              gap={2}
            >
              <Button variant="outlined" size="small" onClick={() => onClick(property.id, category)}>
                {l.add}
              </Button>
              {shouldDisplayNoHoa && category === Category.PROPERTY_HOA && (
                <NoHoaButton
                  onClick={setNoHoaMutation}
                  isLoading={isLoading || isLoadingProperties}
                />
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export const Alerts = ({
  properties, onClick, initialExpanded,
}: AlertsProps) => {
  const [expanded, setExpanded] = useState(initialExpanded);

  const handleExpandClick = () => {
    setExpanded((currExpanded) => !currExpanded);
  };

  const propsWithAlerts = useMemo<PropertyWithAlerts[]>((): PropertyWithAlerts[] => {
    const propertiesWithAlerts : PropertyWithAlerts[] = [];
    properties.forEach((property) => {
      const expenseByCategory = calcYearlyExpenseByCategory(property);
      const missingCategories = getMissingCategories(
        expenseByCategory,
        property.hoaMuted ? [] : [Category.PROPERTY_HOA],
      );
      if (missingCategories.length > 0) {
        propertiesWithAlerts.push({ ...property, missingExpensesCategories: missingCategories });
      }
    });
    return propertiesWithAlerts;
  }, [properties]);

  const shouldDisplayAlerts = propsWithAlerts?.length > 0;
  const l = useLabels();

  return shouldDisplayAlerts ? (
    <Card sx={{ '&:hover': { cursor: 'pointer' } }}>
      <CardHeader
        onClick={handleExpandClick}
        sx={{ '.MuiCardHeader-action': { alignSelf: 'center' } }}
        avatar={(
          <Avatar
            variant="square"
            sx={{
              bgcolor: 'error.light', width: 48, height: 48, borderRadius: 1,
            }}
          >
            <MdReportProblem color="white" />
          </Avatar>
        )}
        title={l['transactions.alerts.title']}
        titleTypographyProps={{ variant: 'h6' }}
        subheader={l['transactions.alerts.subtitle']}
        action={(
          <MdExpandMoreWithOpen size={22} className={expanded ? 'open' : ''} />
        )}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent sx={{ mt: 5 }}>
          <Stack gap={3}>
            {propsWithAlerts.map((propertyWithAlert) => (
              <Alert
                property={propertyWithAlert}
                onClick={(propertyID, category) => {
                  onClick(propertyID, category);
                }}
                key={propertyWithAlert.id}
                missingCategories={propertyWithAlert.missingExpensesCategories}
              />
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  ) : null;
};

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { config } from 'lib';

import { analytics } from './lib/3rd-parties/analytics';
import { BaseApp } from './BaseApp';
import reportWebVitals from './reportWebVitals';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// import sentry react and tracing lazily
import('@sentry/react').then(async (Sentry) => {
  const { BrowserTracing } = await import('@sentry/browser');
  const { CaptureConsole } = await import('@sentry/integrations');
  Sentry.init({
    dsn: config.sentryDsn,
    ignoreErrors: ['AxiosError'],
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0, // Don't record valid sessions
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    debug: config.sentryDebug,
  });
});

analytics.init();

root.render(
  <StrictMode>
    <BaseApp />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

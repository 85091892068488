import { formatNumberToCurrency } from 'ui';
import {
  Theme, useMediaQuery, useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';

export const SumLabel = ({ value, overrideDesktopFont = false }:
{ value: number, overrideDesktopFont?: boolean }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery((_theme: Theme) => _theme.breakpoints.down('sm'));

  const variant = overrideDesktopFont || isMobile ? 'body1' : 'h6';

  return (
    <Typography
      variant={variant}
      sx={{
        fontWeight: 'bold',
        color: value > 0
          ? theme.palette.success.main
          : theme.palette.error.main,
      }}
    >
      {formatNumberToCurrency(value, 2)}
    </Typography>
  );
};

export const ArrowCircleRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#prefix__clip0_11086_73214)">
      <path
        d="M16.828 18l4.95 4.95-1.414 1.414L14 18l6.364-6.364 1.414 1.414-4.95 4.95z"
        fill="#6D788D"
      />
    </g>
    <rect
      x={0.5}
      y={0.5}
      width={35}
      height={35}
      rx={17.5}
      stroke="#173771"
      strokeOpacity={0.22}
    />
    <defs>
      <clipPath id="prefix__clip0_11086_73214">
        <path fill="#fff" transform="translate(6 6)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

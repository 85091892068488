import { useAnalytics } from 'lib';
import { toast } from 'react-toastify';
import {
  ModalContainer, useLabels,
} from 'ui';
import {
  Modal, Stack, Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { useUpdateOneTimeExpense } from './apiHelpers';
import { TransactionForm } from './Form';
import { EditTransactionModalProps, ITransactionForm } from './types';
import { QueryKey } from '../../types/enums';

export const EditTransactionsModal = ({
  isOpen, toggle, initialValues,
}: EditTransactionModalProps) => {
  const l = useLabels();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync: updateMutate } = useUpdateOneTimeExpense();
  const analytics = useAnalytics();

  const onSubmit = async (data: ITransactionForm) => {
    analytics.track('Form Submitted', {
      formName: 'Edit Transaction',
      type: data.type,
      propertyId: data.propertyId,
      category: data.category,
      date: data.dates.date,
      amount: data.amount,
      transactionId: data.id,
    });

    try {
      await updateMutate(data);
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      sx={{
        zIndex: 1600,
      }}
    >
      <ModalContainer onClose={toggle}>
        <Stack gap={3}>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            {l.editTransaction}
          </Typography>
          <TransactionForm
            hideOptions
            toggle={toggle}
            onSubmit={onSubmit}
            isLoading={isLoading}
            initialValues={initialValues}
          />
        </Stack>
      </ModalContainer>
    </Modal>
  );
};

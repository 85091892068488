import { useAnalytics } from 'lib';
import {
  BoldTypography, ControlledFormattedNumberField, formatNumberToCurrency, SemiBoldTypography, useLabels,
} from 'ui';
import { Stack } from '@mui/material';

import { refreshDownpaymentPercent } from './LoanPayments';
import { DetailRow } from './Row';
import { useProjections } from './useProjections';
import { RowProps } from '../types';

export const AskingPrice = ({ form }: RowProps) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const askingPrice = form.watch('details.purchasePrice');
  const editMode = form.watch('editMode');
  const projections = useProjections(form);

  return (
    <DetailRow
      form={form}
      rowID="asking-price"
      bottomBorder={false}
      columns={[
        {
          id: 'asking-price',
          content: (
            <SemiBoldTypography variant="body1">
              {l.priceOrValue}
            </SemiBoldTypography>
          ),
        },
        {
          id: 'asking-price-year-1',
          content: editMode ? (
            <Stack>
              <ControlledFormattedNumberField
                control={form.control}
                name="details.purchasePrice"
                label={l.askingPrice}
                prefix="$"
                size="small"
                onBlur={() => {
                  refreshDownpaymentPercent(form);

                  analytics.track('Input Changed', {
                    value: askingPrice,
                    inputName: 'Listing Details - Purchase Price',
                  });
                }}
              />
            </Stack>
          ) : (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.askingPrice.year1, 0)}
            </BoldTypography>
          ),
        },
        {
          id: 'asking-price-year-2',
          content: (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.askingPrice.year2, 0)}
            </BoldTypography>
          ),
        },
        {
          id: 'asking-price-year-3',
          content: (
            <BoldTypography variant="body1">
              {formatNumberToCurrency(projections.askingPrice.year3, 0)}
            </BoldTypography>
          ),
        },
      ]}
    />
  );
};

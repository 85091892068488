import { BoldTypography, InfoTooltip, useLabels } from 'ui';
import { Stack, Typography } from '@mui/material';

import { ExpenseRow } from './Expense';
import { DetailRow } from './Row';
import { TotalExpenses } from './TotalExpenses';
import { RowProps } from '../types';

export const Expenses = ({ form }: RowProps) => {
  const l = useLabels();

  return (
    <>
      <DetailRow
        form={form}
        rowID="expenses"
        bottomBorder={false}
        columns={[
          {
            id: 'expenses',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.expenses}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.expenses']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          {}, {}, {},
        ]}
      />
      <ExpenseRow
        form={form}
        projectionKey="tax"
        label={l.propertyTax}
        dataKey="details.expenses.tax"
      />
      <ExpenseRow
        form={form}
        projectionKey="insurance"
        label={l.insurance}
        dataKey="details.expenses.insurance"
      />
      <ExpenseRow
        form={form}
        projectionKey="hoa"
        label={l.hoa}
        dataKey="details.expenses.hoa"
      />
      <ExpenseRow
        form={form}
        projectionKey="managementFee"
        label={l.managementFees}
        dataKey="details.expenses.managementFee"
      />
      <ExpenseRow
        form={form}
        projectionKey="maintenance"
        label={l.maintenanceReserve}
        dataKey="details.expenses.maintenance"
      />
      <TotalExpenses form={form} />
    </>
  );
};

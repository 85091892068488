import { calculateCapRate, calculateNOI, sum } from 'lib';
import { useMediaQuery, useTheme } from '@mui/material';

import { useGetPropertyIdParam } from './state';
import {
  Form, getCalculatedMortgage, PropertyProformaData,
} from './types';

const desktopYears = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const mobileYears = [1, 5, 10];

const calculateProjections = ({
  value, years, multiplier,
}: {
  value: number,
  years: number[],
  multiplier: number,
}) => {
  const amounts: number[] = [];

  years.forEach((year, i) => {
    if (year === 1) {
      amounts.push(value);
      return;
    }

    const prevYear = amounts[i - 1];
    const prevYearNumber = years[i - 1];
    const isConsecutiveYear = prevYearNumber && prevYearNumber === year - 1;

    if (isConsecutiveYear) {
      amounts.push(prevYear * multiplier + prevYear);
    } else {
      let amount = prevYear * multiplier + prevYear;
      let nextYear = prevYearNumber + 1;

      while (nextYear < year) {
        amount += amount * multiplier;

        nextYear += 1;
      }

      amounts.push(amount);
    }
  });

  return amounts;
};

export const useProjections = (form: Form) => {
  const theme = useTheme();
  const propertyID = useGetPropertyIdParam();

  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const years = isLowerThanMd ? mobileYears : desktopYears;

  const rentMultiplierByYear = (form.watch(`${propertyID}.metrics.annualRentGrowth`) || 0) / 100;
  const askingPriceMultiplierByYear = (form.watch(`${propertyID}.metrics.annualHomeAppreciation`) || 0) / 100;

  const rentAmount = form.watch(`${propertyID}.income.rent`);
  const estimatedVacancyPercentage = form.watch(`${propertyID}.metrics.estimatedVacancy`);
  const askingPriceAmount = form.watch(`${propertyID}.askingPrice`);
  const expenses = form.watch(`${propertyID}.expenses`);
  const inflationRate = (form.watch(`${propertyID}.metrics.inflationRate`) || 0) / 100;

  const rents = calculateProjections({
    years,
    value: rentAmount,
    multiplier: rentMultiplierByYear,
  });
  const askingPrices = calculateProjections({
    years,
    value: askingPriceAmount,
    multiplier: askingPriceMultiplierByYear,
  });
  const estimatedVacancy = rents.map((rent) => rent * (estimatedVacancyPercentage / 100));
  const expectedRent = years.map((_, i) => rents[i] - estimatedVacancy[i]);

  const expenseProjections: Record<keyof PropertyProformaData['expenses'], number[]> = {
    tax: [],
    insurance: [],
    hoa: [],
    managementFee: [],
    maintenance: [],
    capEx: [],
    estimatedVacancy: [],
  };

  Object.entries(expenses).forEach(([expenseKey, expense]) => {
    expenseProjections[expenseKey as keyof PropertyProformaData['expenses']] = calculateProjections({
      value: expense,
      years,
      multiplier: inflationRate,
    });
  });

  const totalExpenses = years.map((_, i) => sum([
    expenseProjections.tax[i],
    expenseProjections.insurance[i],
    expenseProjections.hoa[i],
    expenseProjections.managementFee[i],
    expenseProjections.maintenance[i],
    expenseProjections.capEx[i],
  ]));

  const calculations = {
    getNOI: (index: number) => calculateNOI(expectedRent[index], totalExpenses[index]),
    getCapRate: (index: number) => calculateCapRate(
      calculateNOI(expectedRent[index], totalExpenses[index]) / 12,
      askingPrices[index],
    ),
    getNetCashFlow: (index: number) => {
      const calculatedMortgage = getCalculatedMortgage(form.getValues(), propertyID) * 12;
      const noi = calculations.getNOI(index);

      return noi - calculatedMortgage;
    },
    getCashOnCash: (index: number) => {
      const askingPrice = askingPrices[index];
      const netCashFlow = calculations.getNetCashFlow(index);

      return netCashFlow / askingPrice;
    },
  };

  return {
    years,
    rents,
    expectedRent,
    askingPrices,
    ...expenseProjections,
    totalExpenses,
    calculations,
  };
};

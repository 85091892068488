import { ReactNode } from 'react';

import { useAnalytics } from 'lib';
import {
  Badge, Skeleton,
  Stack, Tab as MuiTab, Tabs as MuiTabs, useTheme,
} from '@mui/material';

import { Spinner } from '../spinner';
import { BoldTypography } from '../typography/BoldTypography';

export type Tab<Value extends string> = {
  label: ReactNode,
  value: Value,
  icon: ReactNode,
  badgeContent?: number
  onClick?: () => void
  isLoading?: boolean
};

export const Tabs = <TabValue extends string>({
  tabs,
  activeTab,
  setTab,
  isLoading = false,
}: {
  tabs: (Tab<TabValue> | null)[],
  activeTab: TabValue | null,
  setTab: (tab: TabValue) => void,
  isLoading?: boolean,
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  const badgeSx = {
    '& .MuiBadge-badge': {
      background: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  };

  const spinner = (
    <Stack height="100%" width="100%" justifyContent="center" alignItems="center" position="relative">
      &nbsp;
      <Spinner size={20} />
    </Stack>
  );

  return (
    <MuiTabs
      value={activeTab ?? false}
      onChange={(_, newValue) => {
        analytics.track('Button Clicked', {
          buttonName: 'Tab',
          value: newValue,
        });

        const tab = tabs.find((t) => t?.value === newValue);

        if (tab?.onClick) {
          tab.onClick();
        } else {
          setTab(newValue);
        }
      }}
      sx={{
        '& .MuiTabs-scroller': {
          overflowX: 'auto !important',
        },
        '& .MuiTabs-indicator': {
          background: theme.palette.primary.dark,
        },
        '& .MuiTab-root': {
          color: theme.palette.secondary.light,
          padding: theme.spacing(2, 5),
        },
        '&&& .Mui-selected': {
          color: theme.palette.primary.dark,
        },
      }}
    >
      {tabs.map((tab) => tab && (
        <MuiTab
          key={tab.value}
          disabled={isLoading || tab.isLoading}
          value={tab.value}
          label={tab.isLoading ? spinner : (
            <Badge
              badgeContent={!isLoading && tab.badgeContent}
              sx={badgeSx}
            >
              <Stack direction="row" alignItems="center" gap={2}>
                {isLoading ? (
                  <>
                    <Skeleton variant="circular" width={18} height={18} />
                    <Skeleton variant="text" width={60} />
                  </>
                ) : (
                  <>
                    {tab.icon}
                    <BoldTypography variant="body2" color="inherit">
                      {tab.label}
                    </BoldTypography>
                  </>
                )}
              </Stack>
            </Badge>
          )}
        />
      ))}
    </MuiTabs>
  );
};

import {
  convertArrayToFirstElement, createDisplayAddress, DashboardProperty,
  DashboardPropertyMetric, filterNulls,
  getActualCashflow, getCapRate, getValuation,
  sum,
} from 'lib';

import { OwnerPropertyData } from './types';

export const convertOwnerPropertyData = (properties: DashboardProperty[]): OwnerPropertyData => {
  const metrics: DashboardPropertyMetric[] = filterNulls(
    properties.map((property) => convertArrayToFirstElement<DashboardPropertyMetric>({
      items: filterNulls(property.metrics?.items),
    })),
  );
  const portfolioValue = getValuation(metrics);
  const cashFlow = getActualCashflow(metrics);
  const capRate = getCapRate(metrics);

  const propertyData = properties.map((property) => {
    const latestMetric = convertArrayToFirstElement<DashboardPropertyMetric>({
      items: filterNulls(property.metrics?.items),
    });

    return {
      id: property.id,
      street1: property.address.street1,
      fullAddress: createDisplayAddress(property.address),
      valuation: latestMetric?.valuation ?? 0,
      isVacant: (latestMetric?.currentVacancy ?? 0) > 0,
      capRate: getCapRate([latestMetric]),
      cashFlow: latestMetric?.cashFlow ?? 0,
      noi: latestMetric?.NOI ?? 0,
    };
  });

  return {
    portfolioValue,
    capRate,
    cashFlow,
    unitCount: sum(properties.map((p) => (p.property?.units?.items.length ?? 0))),
    nonVacantUnits: sum(properties.map((p) => (p.property?.units?.items.filter((u) => u && !u.isVacant).length ?? 0))),
    propertyData,
    isEstimated: metrics.some((metric) => metric.isDataPartial),
  };
};

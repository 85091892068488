import { useEffect } from 'react';

import { pmState, useAppSettings } from 'lib';

export const AppSettingsMonitor = () => {
  const { data: appSettings } = useAppSettings();

  useEffect(() => {
    if (appSettings) {
      if (appSettings.title) {
        document.title = appSettings.title;
      }

      if (appSettings.pmName) {
        pmState.value = {
          ...pmState.value,
          name: appSettings.pmName,
        };
      }

      if (appSettings.favicon) {
        const links = document.querySelectorAll('link[rel="icon"]');
        links.forEach((link) => {
          link.setAttribute('href', appSettings.favicon!);
        });
      }
    }
  }, [appSettings]);

  return null;
};

import { ContactDetails } from 'lib';
import {
  ColumnCard, Columns, getCardAndColumnID,
  RowGroup,
} from 'ui';
import { Signal } from '@preact/signals-react';

export const moveCardToColumn = <Card extends ColumnCard>(
  columns: Columns<Card>,
  lead: Card,
): Columns<Card> => {
  const cols = { ...columns };

  const card = getCardAndColumnID(Object.values(cols), lead.id);

  if (!card) {
    return columns;
  }

  cols[card.columnID].cards = cols[card.columnID].cards.filter((c) => c.id !== lead.id);
  cols[lead.originalColumnID].cards.unshift({ ...card.card, ...lead });

  return cols;
};

export const updateCard = <Card extends ColumnCard>(columns: Columns<Card>, lead: Card): Columns<Card> => {
  const cols = { ...columns };

  const card = getCardAndColumnID(Object.values(cols), lead.id);

  if (card) {
    cols[card.columnID].cards = cols[card.columnID].cards.map((c) => {
      if (c.id === lead.id) {
        return lead;
      }

      return c;
    });
  }

  return cols;
};

type ListItem<TStatus extends string> = {
  id: string,
  status: TStatus,
  revealed?: boolean | null,
};

export const markListItemAsRevealed = <TStatus extends string>(
  rowGroupsSignal: Signal<Pick<RowGroup<ListItem<TStatus>>, 'rows' | 'id'>[]>,
  listItem: ListItem<TStatus>,
) => {
  const value = [...rowGroupsSignal.value];

  const rowGroupIndex = value.findIndex((rowGroup) => rowGroup.id === listItem.status);
  if (rowGroupIndex === -1) return;

  const rowIndex = value[rowGroupIndex].rows.findIndex((row: ListItem<TStatus>) => row.id === listItem.id);
  if (rowIndex === -1) return;

  value[rowGroupIndex].rows[rowIndex].revealed = true;

  // eslint-disable-next-line no-param-reassign
  rowGroupsSignal.value = value;
};

type ContactData = Pick<ContactDetails, 'firstName' | 'lastName' | 'email'> | null | undefined;

export const sortContacts = (a: ContactData, b: ContactData) => {
  const aName = `${a?.firstName ?? ''} ${a?.lastName ?? ''}`.toLocaleLowerCase();
  const bName = b?.firstName ?? '';
  if (aName < bName) return -1;
  if (aName > bName) return 1;

  const aEmail = a?.email ?? '';
  const bEmail = b?.email ?? '';
  if (aEmail < bEmail) return -1;
  if (aEmail > bEmail) return 1;

  return 0;
};

import { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

const colorPalette = {
  low: '#16AC52',
  medium: '#EEBA00',
  high: '#FD646F',
};

export const ScaleGraph = ({
  leftLabel, rightLabel, percentage, isArrow = true,
} :{
  leftLabel: string, rightLabel: string, percentage: number, isArrow?: boolean
}) => {
  const color = useMemo(() => {
    if (percentage <= 35) {
      return colorPalette.low;
    }
    if (percentage <= 70) {
      return colorPalette.medium;
    }
    return colorPalette.high;
  }, [percentage]);

  return (
    <Stack gap={2}>
      {isArrow && (
        <Stack position="relative" mb={2}>
          <div style={{
            position: 'absolute',
            left: `${percentage}%`,
            top: 0,
            width: '0',
            height: '0',
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: `8px solid ${color}`,
            transform: 'translateX(-50%)',
            transition: 'all .3s linear',
          }}
          />
        </Stack>
      )}
      <Stack flexDirection="row">
        <Box height="4px" width="35%" sx={{ background: colorPalette.low }} />
        <Box height="4px" width="35%" sx={{ background: colorPalette.medium }} />
        <Box height="4px" width="30%" sx={{ background: colorPalette.high }} />
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="subtitle2">
          {leftLabel}
        </Typography>
        <Typography variant="subtitle2">
          {rightLabel}
        </Typography>
      </Stack>
    </Stack>
  );
};
